import React from "react"
import styles from "../styles/AccountNav.module.scss"

const AccountNav = ({ savedCards }) => {
  const handleMixtapeAnchor = (e) => {
    e.preventDefault()
    mm.drawer.setState("mixtapes")
  }

  return (
    <nav className={styles.nav} aria-label="Sub Menu">
      <ol className={styles.list}>
        <li>
          <a data-bypass="true" href="/account/history">
            Order History
          </a>
        </li>
        <li>
          <a onClick={handleMixtapeAnchor}>My Mixtapes</a>
        </li>
        <li>
          <a href="#editProfile">Edit Profile</a>
        </li>
        <li>
          <a href="#resetPassword">Reset Password</a>
        </li>
        {savedCards && (
          <li>
            <a href="#savedCards">Saved Cards</a>
          </li>
        )}
        <li>
          <a href="#cookiePreferences">Cookie Preferences</a>
        </li>
        <li>
          <a href="#cancelAccount">Cancel Account</a>
        </li>
      </ol>
    </nav>
  )
}

export default AccountNav
