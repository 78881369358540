import cx from "classnames"

const PartialExclusivityTooltip = ({
  tooltipType,
  vintage,
  exclusive,
  action,
  cy,
  exclusivityMessage,
  show,
  nudge = "",
}) => {
  return (
    <div className={cx("tooltip-wrapper", tooltipType)}>
      <li
        className={cx("tooltip", "partial-exclusivity-tooltip", show && "show", nudge, {
          ["double-tip"]: vintage && exclusive,
        })}
        data-connect-action={action}
        data-cy={cy}
      >
        {exclusivityMessage}
        <span className="tri">&#9656;</span>
      </li>
    </div>
  )
}

export default PartialExclusivityTooltip
