import { useMutation } from "@apollo/client"
import { DELETE_PROJECT_SEARCH } from "../../../graphql/mutations/projectSearches"

const DeleteProjectSearch = ({ projectSearchId }) => {
  const [deleteProjectSearch] = useMutation(DELETE_PROJECT_SEARCH)

  const postDelete = () => {
    const confirmation = confirm("Are you sure you want to delete this entire Search?")

    if (confirmation) {
      deleteProjectSearch({ variables: { id: projectSearchId } })
        .then(() => mm.router.navigate("/browse", { trigger: true }))
        .catch((error) => Sentry.captureException(error))
    }
  }

  return (
    <button className="delete action">
      <a data-prevent-default onClick={postDelete}>
        Delete Search
      </a>
    </button>
  )
}

export default DeleteProjectSearch
