import SvgIcon from "../shared/components/SvgIcon"

const MoreIcon = () => {
  return (
    <SvgIcon svgClass="MoreIcon">
      <path d="M15.0003091 0C23.2842524 0 30 6.71620257 30 15c0 8.2842054-6.7155374 15-14.9996909 15C6.71563627 30 0 23.2843043 0 15 0 6.71610367 6.71584649 0 15.0003091 0zm0 .99940586C7.26782766.99940586.99942645 7.2680471.99942645 15c0 7.7323543 6.2681844 14.0005941 14.00088265 14.0005941 7.7321724 0 14.0002644-6.2683321 14.0002644-14.0005941 0-7.73186059-6.2683088-14.00059414-14.0002644-14.00059414z" />
      <path d="M21.3513932 15.0068014l-6.7134461 6.7128476.7208933.7209576 7.4344358-7.433773-7.4344197-7.43441981-.7209255.72092543z" />
      <path d="M7.92413793 15.5275862H22.0713892v-1.0344828H7.92413793z" />
    </SvgIcon>
  )
}

export default MoreIcon
