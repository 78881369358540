import SvgIcon from "../shared/components/SvgIcon"

const CloseIcon = () => {
  return (
    <SvgIcon svgClass="CloseIcon">
      <path
        className="icon-border"
        d="M14.9985746 28.9375653c-7.69742474 0-13.93613993-6.2396655-13.93613993-13.9370902 0-7.69837494 6.23966549-13.93804043 13.93613993-13.93804043 7.6993252 0 13.9380404 6.23966549 13.9380404 13.93804043 0 7.698375-6.2387152 13.9370902-13.9380404 13.9370902m0-28.9375653C6.71481517 0 0 6.71576547 0 15.0004751 0 23.2851848 6.71386487 30 14.9985746 30 23.2851848 30 30 23.2851848 30 15.0004751 30 6.71481517 23.2842345 0 14.9985746 0"
      />
      <path d="M20.0802802 9.16293103l-5.0780173 5.07122847-5.07219824-5.07898709-.76713363.7661638 5.07122847 5.07995689-5.07898709 5.0712285.7661638.7671336 5.07995689-5.0702586 5.0702586 5.0780172.7671336-.7661638-5.0702586-5.0789871 5.0789871-5.07122842z" />
    </SvgIcon>
  )
}

export default CloseIcon
