import SvgIcon from "../shared/components/SvgIcon"

const ForwardIcon = () => {
  return (
    <SvgIcon svgClass="ForwardIcon">
      <path
        className="icon-border"
        d="M15.0004823 28.9205441c-7.68835008 0-13.92199103-6.2326763-13.92199103-13.9210264 0-7.68835014 6.23364095-13.92102643 13.92199103-13.92102643 7.6883501 0 13.9219911 6.23267629 13.9219911 13.92102643 0 7.6883501-6.233641 13.9210264-13.9219911 13.9210264m0-28.9205441C6.71597157 0 0 6.71500691 0 14.9995177 0 23.2840284 6.71597157 30 15.0004823 30 23.2849931 30 30 23.2840284 30 14.9995177 30 6.71500691 23.2849931 0 15.0004823 0"
      />
      <path d="M10.9965517 6.04299569l9.3549569 9.35495691-9.3549569 9.3549569.7671336.7671336 10.1220906-10.1220905L11.7636853 5.27586207z" />
    </SvgIcon>
  )
}

export default ForwardIcon
