/**
 * A function that allows for customization of the
 * HTML elements received from Prismic.
 *
 * Other than the custom classNames, this code
 * comes directly from the Prismic docs:
 * https://prismic.io/docs/reactjs/beyond-the-api/html-serializer
 */

import React from "react"
import { Element } from "@prismicio/react"

// -- Function to add unique key to props
const propsWithUniqueKey = function (props, key) {
  return Object.assign(props || {}, { key })
}

// -- HTML Serializer
const htmlSerializer = function (type, element, content, children, key) {
  let props = {}

  switch (type) {
    case Element.heading1: // Heading 1
      props = { className: "hOne" }
      return React.createElement("h1", propsWithUniqueKey(props, key), children)

    case Element.heading2: // Heading 2
      props = { className: "hTwo" }
      return React.createElement("h2", propsWithUniqueKey(props, key), children)

    case Element.heading3: // Heading 3
      props = { className: "hThree" }
      return React.createElement("h3", propsWithUniqueKey(props, key), children)

    case Element.heading4: // Heading 4
      props = { className: "hFour" }
      return React.createElement("h4", propsWithUniqueKey(props, key), children)

    case Element.heading5: // Heading 5
      props = { className: "hFive" }
      return React.createElement("h5", propsWithUniqueKey(props, key), children)

    case Element.heading6: // Heading 6
      props = { className: "hSix" }
      return React.createElement("h6", propsWithUniqueKey(props, key), children)

    case Element.paragraph: // Paragraph
      props = { className: "paragraph" }
      return React.createElement("p", propsWithUniqueKey(props, key), children)

    case Element.strong: // Strong
      props = { className: "strong" }
      return React.createElement("strong", propsWithUniqueKey(props, key), children)

    case Element.em: // Emphasis
      props = { className: "emphasis" }
      return React.createElement("em", propsWithUniqueKey(props, key), children)

    case Element.listItem: // Unordered List Item
      props = { className: "listItem" }
      return React.createElement("li", propsWithUniqueKey(props, key), children)

    case Element.oListItem: // Ordered List Item
      props = { className: "oListItem" }
      return React.createElement("li", propsWithUniqueKey(props, key), children)

    case Element.list: // Unordered List
      props = { className: "unorderedList" }
      return React.createElement("ul", propsWithUniqueKey(props, key), children)

    case Element.oList: // Ordered List
      props = { className: "orderedList" }
      return React.createElement("ol", propsWithUniqueKey(props, key), children)

    case Element.hyperlink: // Image
      const targetAttr = element.data.target ? { target: element.data.target } : {}
      const relAttr = element.data.target ? { rel: "noopener" } : {}

      // INTERNAL LINKS:
      // The commented out code below is for allowing internal links from Prismic.
      // Enter the complete address for the internal link, in Prismic, then
      // we'll parse the code and apply the correct attributes the element.

      // let href
      // let dataBypass
      // if (element.data.url && element.data.url.includes("marmosetmusic.com/")) {
      //   href = element.data.url.split(".com")[1]
      //   dataBypass = true
      // } else {
      //   href = element.data.url
      //   dataBypass = false
      // }
      props = Object.assign(
        {
          href: element.data.url || linkResolver(element.data), // INTERNAL LINKS: replace "element.data.url" with "href"
          className: "hyperLink",
          // "data-bypass": dataBypass // INTERNAL LINKS: uncomment this line
        },
        targetAttr,
        relAttr
      )
      return React.createElement("a", propsWithUniqueKey(props, key), children)

    case Element.label: // Label
      props = element.data
        ? Object.assign({}, { className: `${element.data.label} label` })
        : { className: "label" }
      return React.createElement("span", propsWithUniqueKey(props, key), children)

    case Element.span: // Span
      if (content) {
        return content.split("\n").reduce((acc, p) => {
          if (acc.length === 0) {
            return [p]
          } else {
            const brIndex = (acc.length + 1) / 2 - 1
            const br = React.createElement("br", propsWithUniqueKey({ className: "span" }, brIndex))
            return acc.concat([br, p])
          }
        }, [])
      } else {
        return null
      }

    default: // Always include a default that returns null
      return null
  }
}

export default htmlSerializer
