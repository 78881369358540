import React from "react"

const MissingContentMessage = ({ message }) => {
  return (
    <div className="missing-content-message">
      <h3 className="hThree">{message}</h3>
    </div>
  )
}

export default MissingContentMessage
