import React, { Component } from "react"

export default class Swatches extends Component {
  constructor(props) {
    super(props)
    this.state = { tabText: "Copy" }
    this.textAreaRef = React.createRef()
  }

  copyHexToClipboard(hex) {
    let placeholder = this.textAreaRef.current
    placeholder.value = hex
    placeholder.select()
    document.execCommand("Copy")
    placeholder.value = ""
    this.setState({ tabText: "Copied!" })
  }

  render() {
    return (
      <div className="swatches">
        {this.props.swatches.map(({ name, hex }) => {
          return (
            <div
              key={name}
              className="swatch"
              onMouseLeave={() => this.setState({ tabText: "Copy" })}
            >
              <div className={name}>
                {hex}
                <div className="copy-tab" onClick={() => this.copyHexToClipboard(hex)}>
                  {this.state.tabText}
                </div>
              </div>
              <p className="label">{name}</p>
            </div>
          )
        })}
        <textarea ref={this.textAreaRef} />
      </div>
    )
  }
}
