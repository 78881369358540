import CountryRegionData from "/node_modules/country-region-data/data.json"

/*
 *  COUNTRY, REGION, AND STATE
 */
export const countryOptions = CountryRegionData.map(({ countryName }) => {
  return { value: countryName, label: countryName }
})

export const regionOptions = (country) => {
  if (country === "") return []
  const regionData = CountryRegionData.find((c) => c.countryName === country)

  return regionData.regions.map((region) => {
    return {
      value: country === "United States" ? region.shortCode : region.name,
      label: region.name,
    }
  })
}

/*
 *  DATE
 */
export const monthOptions = [
  { label: 1, value: 1 },
  { label: 2, value: 2 },
  { label: 3, value: 3 },
  { label: 4, value: 4 },
  { label: 5, value: 5 },
  { label: 6, value: 6 },
  { label: 7, value: 7 },
  { label: 8, value: 8 },
  { label: 9, value: 9 },
  { label: 10, value: 10 },
  { label: 11, value: 11 },
  { label: 12, value: 12 },
]

export const yearOptions = () => {
  const currentYear = new Date().getFullYear()
  const years = Array.from({ length: 10 }, (_, n) => n + currentYear)
  return years.map((year) => ({ label: year, value: year }))
}

/*
 * Exclusivity options for custom request form
 */
export const exclusivityOptions = [
  { value: "no", label: "No" },
  { value: "industry_exclusivity", label: "Industry Exclusivity" },
  { value: "full_exclusivity", label: "Full Exclusivity" },
]
