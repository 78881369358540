import { gql } from "@apollo/client"

export const DELETE_PROJECT_SEARCH = gql`
  mutation deleteProjectSearch($id: ID!) {
    deleteProjectSearch(id: $id) {
      projectSearch {
        id
      }
    }
  }
`

export const DUPLICATE_PROJECT_SEARCH = gql`
  mutation duplicateProjectSearch($id: ID!) {
    duplicateProjectSearch(id: $id) {
      projectSearch {
        id
        slug
      }
    }
  }
`

export const SET_STATUS = gql`
  mutation setProjectSearchStatus($id: ID!, $status: String!) {
    setProjectSearchStatus(id: $id, status: $status) {
      projectSearch {
        id
        status
      }
    }
  }
`

export const UPDATE_NAME_OR_DESCRIPTION = gql`
  mutation updateProjectSearch($id: ID!, $name: String, $description: String) {
    updateProjectSearch(id: $id, name: $name, description: $description) {
      projectSearch {
        id
        name
        description
      }
    }
  }
`
