const QuoteIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="96"
      height="78"
      viewBox="0 0 96 78"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.3462 0L40.984 10.2469C30.054 17.4197 20.1487 31.0821 19.124 42.0121C19.4656 42.0121 21.8565 41.6706 23.5643 41.6706C33.1281 41.6706 39.9593 48.8433 39.9593 59.0902C39.9593 68.9955 32.1034 77.193 21.8565 77.193C10.585 77.193 0.679688 67.9708 0.679688 51.2343C0.679688 30.399 12.6344 11.2715 28.3462 0ZM82.9961 0L95.6339 10.2469C84.7039 17.4197 75.1402 31.0821 73.7739 42.0121C74.1155 42.0121 76.5064 41.6706 78.2142 41.6706C87.7779 41.6706 94.9507 48.8433 94.9507 59.0902C94.9507 68.9955 86.7533 77.193 76.5064 77.193C65.2349 77.193 55.3296 67.9708 55.3296 51.2343C55.3296 30.399 67.2842 11.2715 82.9961 0Z"
        fill="black"
      />
    </svg>
  )
}

export default QuoteIcon
