import cx from "classnames"
import { useRefinementList } from "react-instantsearch-hooks-web"
import { useWorkbenchStore } from "../../store/store"
import { transformFlags } from "./FilterTransforms"
import { useToggleRefinement } from "react-instantsearch-hooks-web"

const InternalToggleRefinement = ({
  label,
  value,
  refineOn,
  refineOff,
  selection,
  setSelection,
}) => {
  const handleClick = () => {
    if (selection == "") {
      setSelection("selected")
      refineOn({ isRefined: selection === "selected" })
    } else if (selection === "selected") {
      setSelection("selected-negative")
      refineOn({ isRefined: selection === "selected" })
      refineOff({ isRefined: selection === "selected-negative" })
    } else {
      // "selected-negative"
      setSelection("")
      refineOff({ isRefined: selection === "selected-negative" })
    }
  }

  return (
    <li className={cx("actionable", selection)}>
      {" "}
      {/* selected, selected-negative */}
      <a onClick={handleClick}>{label}</a>
    </li>
  )
}

const FlagRefinementListItem = ({ item, refine }) => {
  const flagsToggledOn = useWorkbenchStore((state) => state.flagsToggledOn)
  const addToFlagsOn = useWorkbenchStore((state) => state.addToFlagsOn)
  const removeFromFlagsOn = useWorkbenchStore((state) => state.removeFromFlagsOn)

  const handleClick = (e) => {
    e.preventDefault()
    if (!flagsToggledOn.includes(item.value)) {
      addToFlagsOn(item.value)
      refine(item.value)
    } else {
      removeFromFlagsOn(item.value)
      refine(item.value)
    }
  }

  return (
    <li
      data-val={item.label}
      data-cy={`internal-option-${item.value}`}
      data-pretty={`${item.label} Flag`}
      className={cx(
        "actionable",
        item.label.toLowerCase(),
        flagsToggledOn.includes(item.value) ? "selected" : ""
      )}
    >
      {" "}
      <a onClick={handleClick}>{item.label}</a>
    </li>
  )
}

const FlagsRefinementList = (props) => {
  const { items, refine } = useRefinementList(props)

  //For toggleable
  const { value: valueHasStemsOn, refine: refineHasStemsOn } = useToggleRefinement({
    attribute: "is_has_stems",
  })
  const { refine: refineHasStemsOff } = useToggleRefinement({ attribute: "is_not_has_stems" })
  const hasStemsSelection = useWorkbenchStore((state) => state.hasStemsSelection)
  const setHasStemsSelection = useWorkbenchStore((state) => state.setHasStemsSelection)

  const { value: valueIsInTrackClubOn, refine: refineIsInTrackClubOn } = useToggleRefinement({
    attribute: "is_in_track_club",
  })
  const { refine: refineIsInTrackClubOff } = useToggleRefinement({
    attribute: "is_not_in_track_club",
  })
  const isInTrackClubSelection = useWorkbenchStore((state) => state.isInTrackClubSelection)
  const setIsInTrackClubSelection = useWorkbenchStore((state) => state.setIsInTrackClubSelection)

  return (
    <>
      <ul>
        {items.map((item, index) => (
          <FlagRefinementListItem key={index} item={item} refine={refine} />
        ))}
      </ul>

      <ol>
        <InternalToggleRefinement
          label="Has Stems"
          value={valueHasStemsOn}
          refineOn={refineHasStemsOn}
          refineOff={refineHasStemsOff}
          selection={hasStemsSelection}
          setSelection={setHasStemsSelection}
        />
        <InternalToggleRefinement
          label="Track Club"
          value={valueIsInTrackClubOn}
          refineOn={refineIsInTrackClubOn}
          refineOff={refineIsInTrackClubOff}
          selection={isInTrackClubSelection}
          setSelection={setIsInTrackClubSelection}
        />
      </ol>
    </>
  )
}

const WorkbenchFilterInternalBeta = ({ id }) => {
  const currentFilter = useWorkbenchStore((state) => state.currentFilter)

  return (
    <fieldset id={id} className={cx("mm-filter", currentFilter === id ? "current" : "")}>
      <FlagsRefinementList
        attribute="internal_flags"
        operator="or"
        transformItems={transformFlags}
      />
    </fieldset>
  )
}

export default WorkbenchFilterInternalBeta
