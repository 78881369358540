import React from "react"
import { Field, ErrorMessage } from "formik"
import Cleave from "cleave.js/react"
import cx from "classnames"
import formStyles from "../styles/form.module.scss"
import { formatId, formatAriaLabel } from "../utils/form"

const MaskedTextField = (props) => {
  const {
    type,
    label,
    fieldName,
    wrapperClass,
    inputClass,
    placeholder,
    showErrorBorder,
    disabled,
    options,
  } = props

  const handleOnChange = (e, fieldName, setFieldValue) => {
    setFieldValue(fieldName, e.target.rawValue)
  }

  return (
    <div className={cx(formStyles.fieldRow, wrapperClass)}>
      <label htmlFor={fieldName} className={formStyles.label}>
        {label}
      </label>
      <Field name="card_number">
        {({ field, form }) => {
          return (
            <Cleave
              {...field}
              type={type}
              id={formatId(fieldName)}
              placeholder={placeholder}
              disabled={disabled}
              options={options}
              onChange={(e) => handleOnChange(e, fieldName, form.setFieldValue)}
              aria-label={formatAriaLabel(fieldName)}
              className={cx(formStyles.input, inputClass, {
                [formStyles.inputError]: showErrorBorder,
              })}
            />
          )
        }}
      </Field>
      <ErrorMessage
        name={fieldName}
        className={cx(formStyles.errorMsg, "error-msg")}
        component="div"
      />
    </div>
  )
}

MaskedTextField.defaultProps = {
  inputClass: "",
  wrapperClass: "",
  type: "text",
  showErrorBorder: false,
}

export default MaskedTextField
