import cx from "classnames"
import SearchIcon from "../../icons/SearchIcon"
import { useContext, useState } from "react"
import { SimilaritySearchContext } from "./SimilaritySearchContainer"
import { SPOTIFY_TRACK_LINK_REGEX, YOUTUBE_LINK_REGEX } from "../../shared/utils/helpers"

const SimilaritySearchInput = () => {
  const { setSearchQueryTerm } = useContext(SimilaritySearchContext)
  const [searchQueryForm, setSearchQueryForm] = useState("")

  const handleSearchChange = (e) => {
    setSearchQueryForm(e.target.value)
  }

  const handleSearchSubmit = (e) => {
    e.preventDefault()
    if (YOUTUBE_LINK_REGEX.test(searchQueryForm)) {
      setSearchQueryTerm(YOUTUBE_LINK_REGEX.exec(searchQueryForm)[0])
    } else if (SPOTIFY_TRACK_LINK_REGEX.test(searchQueryForm)) {
      setSearchQueryTerm(searchQueryForm)
    } else {
      setSearchQueryTerm("")
    }
  }

  return (
    <form id="search" className="search mm-filter" onSubmit={handleSearchSubmit}>
      <div id="mm-search-input" className={cx("")}>
        <div className="search-input-group">
          <div className="icon">
            <SearchIcon />
          </div>
          <label>Search</label>
          <input
            autoFocus
            type="text"
            value={searchQueryForm}
            onChange={handleSearchChange}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="none"
            spellCheck="false"
            placeholder="Paste a YouTube or Spotify link to see similar songs"
            aria-label="Enter search text"
          />
        </div>
      </div>
      <button className="youtube-submit" type="submit">
        Search
      </button>
    </form>
  )
}

export default SimilaritySearchInput
