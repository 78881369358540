import { PrismicRichText } from "@prismicio/react"
import cx from "classnames"
import htmlSerializer from "../../../shared/utils/prismicHTMLSerializer"
import LazyLoad from "../../../shared/components/LazyLoad"
import styles from "../../../shared/styles/QuoteTestimonial.module.scss"
import QuoteIcon from "../../../icons/QuoteIcon"

const QuoteTestimonial = ({ slice }) => {
  const { primary } = slice

  const backgroundColorMap = {
    Purple: "purple",
    Coral: "coral",
    Gold: "gold",
    Green: "green",
    Black: "black",
    White: "white",
    Track_Red: "track-red",
    Track_Yellow: "track-yellow",
    Track_Blue: "track-blue",
    Track_Pink: "track-pink",
    Track_Orange: "track-orange",
    Track_Ocean_Blue: "track-ocean-blue",
    Track_Sunflower: "track-sunflower",
  }
  const backgroundColor = backgroundColorMap[primary.background_color]

  return (
    <article className={styles.quoteTestimonial}>
      <div className={styles.contain}>
        <LazyLoad>
          <div className={cx("lazy")}>
            <div
              className={styles.quoteBox}
              style={{ backgroundColor: `var(--${backgroundColor})` }}
            >
              <section className={styles.quoteTextArea}>
                <QuoteIcon className="quoteIconTop" />
                <QuoteIcon className="quoteIconBottom" />
                <div className={styles.quoteText}>
                  <PrismicRichText
                    field={primary.quote_text}
                    components={htmlSerializer}
                    className={styles.quoteText}
                  />
                </div>
                <div className={styles.byLine}>
                  <PrismicRichText
                    field={primary.quote_by_line}
                    components={htmlSerializer}
                    className={styles.byLine}
                  />
                </div>
              </section>
              <img src={primary.artist_image.url} />
            </div>
          </div>
        </LazyLoad>
      </div>
    </article>
  )
}

export default QuoteTestimonial
