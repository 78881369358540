import { useMutation } from "@apollo/client"
import cx from "classnames"
import { Formik, Form } from "formik"
import * as yup from "yup"
import StatusMessage from "./StatusMessage"
import ScrollToError from "../../../shared/components/ScrollToError"
import TextField from "../../../shared/components/TextField"
import { showErrorBorder } from "../../../shared/utils/form"
import { RESET_PASSWORD } from "../../../graphql/mutations/account"
import buttonStyles from "../../../shared/styles/buttons.module.scss"
import formStyles from "../../../shared/styles/form.module.scss"
import styles from "../styles/ResetPasswordForm.module.scss"

const validationSchema = yup.object().shape({
  password: yup.string().required("New Password is required"),
  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref("password"), null], "New and Confirm passwords must match.")
    .required("Confirm Password is required"),
  currentPassword: yup.string().required("Current Password is required"),
})

const ResetPasswordForm = () => {
  const [resetPasswordMutation] = useMutation(RESET_PASSWORD)

  const handleSubmit = (params, formActions) => {
    const { id } = mm.user.attributes
    if (!id) {
      formActions.setStatus({ msg: "You must log in before resetting your password." })
      formActions.setSubmitting(false)
      return
    }

    resetPasswordMutation({
      variables: {
        user_password: { id, ...params },
      },
    })
      .then(
        ({
          data: {
            resetPassword: { errors },
          },
        }) => {
          if (errors.length > 0) {
            formActions.setStatus({ error: true, msg: errors[0] })
          } else {
            formActions.resetForm()
            formActions.setStatus({ success: true, msg: "Your password has been changed." })
          }
          formActions.setSubmitting(false)
        }
      )
      .catch((err) => console.log(err))
  }

  const initialValues = {
    password: "",
    passwordConfirmation: "",
    currentPassword: "",
  }

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {({ errors, isSubmitting, status, touched }) => (
        <article id="resetPassword" className={styles.resetPassword}>
          <div className={styles.contain}>
            <Form>
              <ScrollToError />
              <h4 className={cx("hFour", styles.header)}>Reset Password</h4>
              <StatusMessage status={status} />

              <div className={styles.inputsContainer}>
                <div className={styles.rowInput}>
                  <TextField
                    label="New Password"
                    fieldName="password"
                    type="password"
                    showErrorBorder={showErrorBorder(errors, touched, "password")}
                  />
                </div>
                <div className={styles.rowInput}>
                  <TextField
                    label="Confirm Password"
                    fieldName="passwordConfirmation"
                    type="password"
                    showErrorBorder={showErrorBorder(errors, touched, "passwordConfirmation")}
                  />
                </div>
                <div className={styles.rowInput}>
                  <TextField
                    label="Current Password"
                    fieldName="currentPassword"
                    type="password"
                    showErrorBorder={showErrorBorder(errors, touched, "currentPassword")}
                  />
                </div>
              </div>

              <button
                type="submit"
                className={cx(styles.button, buttonStyles.black, formStyles.submitBtn)}
                disabled={isSubmitting}
              >
                {isSubmitting ? "Changing..." : "Change Password"}
                <span className={cx({ [buttonStyles.spinner]: isSubmitting })} />
              </button>
            </Form>
          </div>
        </article>
      )}
    </Formik>
  )
}

export default ResetPasswordForm
