import cx from "classnames"
import Button from "./Button"
import htmlSerializer from "../../utils/prismicHTMLSerializer"
import styles from "../../styles/Switchboard.module.scss"
import BasicImage from "./BasicImage"
import BasicRichText from "./BasicRichText"

const Switchboard = ({ slice }) => {
  const { items } = slice

  const buttonSlice = ({ target_text, target_url }) => {
    return {
      primary: {
        button_url: target_url,
        button_text: target_text,
        button_icon: "Info",
      },
    }
  }

  return (
    <article className={cx(styles.switchboard, "prismicSwitchboard")} aria-label="Switchboard">
      <div className={styles.contain}>
        {items.map((item) => {
          const { button_or_email, image, rich_text, target_url, target_text, uid } = item
          return (
            <div className={styles.switchItem} key={uid}>
              <div className={styles.info}>
                <BasicRichText
                  className={styles.richText}
                  rich_text={rich_text}
                  htmlSerializer={htmlSerializer}
                />
                {button_or_email === "Button" ? (
                  <Button slice={buttonSlice(item)} />
                ) : (
                  <a
                    href={button_or_email === "Email" ? `mailto:${target_url}` : target_url}
                    className={cx("hyperLink", styles.email)}
                    target={button_or_email === "Email" ? "_blank" : "_self"}
                  >
                    {target_text}
                  </a>
                )}
              </div>
              <BasicImage className={styles.imgWrapper} image={image} />
            </div>
          )
        })}
      </div>
    </article>
  )
}

export default Switchboard
