import SvgIcon from "../shared/components/SvgIcon"

const UserIcon = () => {
  return (
    <SvgIcon svgClass="UserIcon">
      <path
        className="icon-border"
        d="M15.0004823 28.9205441c-7.68931474 0-13.92199103-6.2326763-13.92199103-13.9210264 0-7.68835014 6.23267629-13.92102643 13.92199103-13.92102643 7.6883501 0 13.9210264 6.23267629 13.9210264 13.92102643 0 7.6883501-6.2326763 13.9210264-13.9210264 13.9210264m0-28.9205441C6.71597157 0 0 6.71500691 0 14.9995177 0 23.2840284 6.71597157 30 15.0004823 30 23.2849931 30 30 23.2840284 30 14.9995177 30 6.71500691 23.2849931 0 15.0004823 0"
      />
      <path d="M11.3065733 11.6725216c0-2.03760781 1.6506465-3.68825436 3.6882543-3.68825436 2.0356681 0 3.6863146 1.65064655 3.6863146 3.68825436 0 2.0376077-1.6506465 3.6882543-3.6863146 3.6882543-2.0376078 0-3.6882543-1.6506466-3.6882543-3.6882543m6.0992457 4.1198275c1.4382543-.8427801 2.3615301-2.3964439 2.3615301-4.1198275C19.7673491 9.03653017 17.630819 6.9 14.9948276 6.9c-2.6369612 0-4.7734914 2.13653017-4.7734914 4.7725216 0 1.6991379.8961207 3.2324353 2.2984914 4.0829741l.062069.0368534c-2.4100216.708944-4.40592677 2.452694-5.42327591 4.7851293l-.09310345.2123923.99310345.4344827.1299569-.2948275c1.20355603-2.6709052 3.86088361-4.3981681 6.80625001-4.3981681 2.9880388 0 5.675431 1.7776939 6.8528017 4.5087284l.0921336.2143319.9969828-.4296336-.1289871-.2948276c-1.0231681-2.3091595-3.0084052-4.0344828-5.4019396-4.7376078" />
    </SvgIcon>
  )
}

export default UserIcon
