const DownloadSongVersionsItem = ({ songVersion, stemsUrl }) => {
  const style = {
    background: `url( ${songVersion.primary_album.art.url} ) center center / cover no-repeat`,
  }

  return (
    <li style={style}>
      <div className="gradient-overlay">
        <hgroup>
          <h1>{songVersion.display_name}</h1>
          <h2>{songVersion.artist.name}</h2>
        </hgroup>
      </div>
      <div className="over">
        <div>
          {songVersion.song_version_file && (
            <a
              href={songVersion.song_version_file}
              className="download-file box-button"
              target="_blank"
            >
              Download Song
            </a>
          )}
          {stemsUrl && (
            <a href={stemsUrl} className="download-file box-button" target="_blank">
              Download Stems
            </a>
          )}
        </div>
      </div>
    </li>
  )
}

export default DownloadSongVersionsItem
