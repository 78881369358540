import React from "react"

const NoResults = () => (
  <div className="no-results showing">
    <h2>Sorry, there are no results matching your search terms.</h2>
    <h3>Try removing some genres or maybe clear all and start over.</h3>
  </div>
)

export default NoResults
