import { useState, useContext, useEffect } from "react"
import cx from "classnames"
import LazyLoad from "../LazyLoad"
import Modal from "../Modal"
import VideoPlayer from "./VideoPlayer"
import PlayIcon from "../../../icons/PlayIcon"
import styles from "../../styles/VideoItem.module.scss"
import { resizeVimeoThumbnail, escapeToClose, createVimeoSrcSet } from "../../utils/helpers"
import { PrismicPageContext } from "../../utils/prismicPageParser"

const VideoItem = ({ video, featuredComposer }) => {
  const colorTheme = useContext(PrismicPageContext)
  const [showThumb, setShowThumb] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const { title, thumbnail_url, video_id } = video

  useEffect(() => {
    return escapeToClose(setOpenModal)
  })

  const handleMouseEnter = () => {
    if (!openModal) setShowThumb(true)
  }

  const handleMouseLeave = () => {
    if (!openModal) setShowThumb(false)
  }

  const toggleModal = (e) => {
    e.stopPropagation()
    setOpenModal(false)
  }

  return (
    <div
      key={video_id}
      className={cx(styles.videoItem, "prismicVideoItem", { [styles.expanded]: showThumb })}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={() => setOpenModal(true)}
    >
      <div className={styles.topBorder} />
      <div className={styles.videoContent}>
        <div className={cx(styles.titleAndComposer, { [styles.textWrap]: showThumb })}>
          <h3 className={cx("hThree", styles.title)}>{title}</h3>
          <span style={{ color: `var(--${colorTheme})` }} className={styles.composer}>
            {featuredComposer}
          </span>
        </div>
        <PlayIcon />
      </div>
      <div className={styles.imgWrapper}>
        <LazyLoad>
          <img
            data-src={resizeVimeoThumbnail(thumbnail_url)}
            data-srcset={createVimeoSrcSet(thumbnail_url)}
            className={cx("lazy", styles.thumb, { [styles.showThumb]: showThumb })}
          />
        </LazyLoad>
      </div>
      {openModal && (
        <Modal>
          <VideoPlayer videoId={video_id} toggleModal={toggleModal} />
        </Modal>
      )}
      <div className={styles.bottomBorder} />
    </div>
  )
}

export default VideoItem
