import { PrismicRichText } from "@prismicio/react"
import htmlSerializer from "../../utils/prismicHTMLSerializer"
import { createSrcSet } from "../../utils/helpers"
import styles from "../../styles/LogoGrid.module.scss"

const LogoGrid = ({ slice, title }) => {
  const { items, primary } = slice
  const sizeobj = { Lowres: "1x", Hires: "2x" }

  return (
    <>
      <article className={styles.logoGrid} aria-label="Featured collaborators">
        <div className={styles.contain}>
          <div
            className={
              title === "Music Licensing" ? styles.richTextMusicLicensing : styles.richText
            }
          >
            <PrismicRichText field={primary.rich_text} components={htmlSerializer} />
          </div>
          <div className={styles.logosWrapper}>
            <div className={styles.logos}>
              {items.map((item) => {
                const { alt, url, uid } = item.logo_image
                return (
                  <img
                    key={url}
                    src={url}
                    srcSet={createSrcSet(item.logo_image, sizeobj)}
                    className={
                      title === "Music Licensing" ? styles.logoMusicLicensing : styles.logo
                    }
                    style={{ maxHeight: "60px", maxWidth: "150px" }}
                    alt={alt}
                  />
                )
              })}
            </div>
          </div>
        </div>
      </article>
    </>
  )
}

export default LogoGrid
