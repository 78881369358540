import cx from "classnames"
import LazyLoad from "../../components/LazyLoad"

const BasicImage = (props) => {
  return (
    <div className={cx("lazyWrapper", props.className)}>
      <LazyLoad>
        <img className="lazy" data-src={props.image.url} alt={props.image.alt} />
      </LazyLoad>
    </div>
  )
}

export default BasicImage
