import cx from "classnames"

const ViewInPortal = ({ projectSearchId }) => {
  return (
    <a style={{ textDecoration: "none" }} href={`/manage/project_searches/${projectSearchId}`}>
      <button className={cx("action view-in-portal")}>View in Portal</button>
    </a>
  )
}

export default ViewInPortal
