export const colorsDescription = <p>These are Marmoset's gray and primary color palettes.</p>

export const typefaceDescription = (
  <p>
    We use <strong>Elena Basic</strong> as the default font for our content, headers, and form
    inputs. <strong>Proxima Nova</strong> is used for our buttons and input labels.
  </p>
)

export const ctaButtonDescription = (
  <div className="description-wrapper">
    <h3>Call to Action Button</h3>
    <div className="text">
      <p>font-size: 13.2px</p>
      <p>font-weight: 600</p>
      <p>letter-spacing: 1.85px</p>
      <p>height: 40px</p>
      <p>line-height: 40px</p>
      <p>border: 2px solid</p>
      <p className="bottom-padding">padding: 0 38px</p>
      <p>Passive bg/color: transparent/#fff</p>
      <p>Hover bg/color: #fff/#8a4fab</p>
      <p>Active bg/color: #8a4fab/#fff</p>
    </div>
  </div>
)

export const primaryButtonDescription = (
  <div className="description-wrapper">
    <h3>Primary Button</h3>
    <div className="text">
      <p>font-size: 15px</p>
      <p>font-weight: 600</p>
      <p>letter-spacing: 1.85px</p>
      <p>height: 60px</p>
      <p className="bottom-padding">line-height: 60px</p>
      <p>Passive bg/color: #3a3532/#fff</p>
      <p>Hover bg/color: #262220/#fff</p>
      <p>Active bg/color: #8a4fab/#fff</p>
      <p>Disabled bg/color: #bab7b5/#fff</p>
    </div>
  </div>
)

export const actionButtonDescription = (
  <div className="description-wrapper">
    <h3>Action Button</h3>
    <div className="text">
      <p>font-size: 14.4px</p>
      <p>font-weight: 600</p>
      <p>height: 47px</p>
      <p>width: 62px</p>
      <p className="bottom-padding">margin: 0 1px</p>
      <p>Passive bg/color: #fff/#3a3532</p>
      <p>Hover bg/color: #3a3532/#fff</p>
      <p>Active bg/color: #8a4fab/#3a3532</p>
      <p>Disabled opacity: 0.5</p>
    </div>
  </div>
)

export const inputsDescription = (
  <div className="description-wrapper">
    <h3>Label</h3>
    <div className="text">
      <p>font-size: 12px</p>
      <p>font-weight: 400</p>
      <p>color: #333</p>
      <p>line-height: 20px</p>
      <p>padding-top: 12px</p>
    </div>
    <h3>Input</h3>
    <div className="text">
      <p>font-size: 16px</p>
      <p>font-weight: 400</p>
      <p>height: 42px</p>
      <p>width: 326px</p>
      <p>border: 2px solid #999</p>
      <p>padding: 0 10px</p>
    </div>
  </div>
)

export const buttons = ["passive", "hover", "active"]

export const buttonsWithDisabled = ["passive", "hover", "active", "disabled"]

export const fonts = [
  {
    style: { fontWeight: 400, fontSize: "46px", lineHeight: "46px" },
    text: "Section Title 400/46/46",
  },
  {
    style: { fontWeight: 400, fontSize: "36px", lineHeight: "36px" },
    text: "Article Title 400/36/36",
  },
  {
    style: { fontWeight: 400, fontSize: "24px", lineHeight: "24px" },
    text: "Body Title 400/24/24",
  },
  {
    style: { fontWeight: 400, fontSize: "18px", lineHeight: "21px" },
    text: "Body Text 400/18/21",
  },
  {
    style: { fontWeight: 600, fontSize: "13px", lineHeight: "40px", letterSpacing: "1.65px" },
    text: "BUTTON 600/13/40/1.65",
    proxima: true,
  },
]

export const logos = {
  primaryWhite: {
    spanClass: "primary-white",
    downloadLink:
      "https://s3-us-west-2.amazonaws.com/marmosetmusic-com/assets/RGB-marmoset-logo-white.png",
  },
  primaryPurple: {
    spanClass: "primary-purple",
    downloadLink:
      "https://s3-us-west-2.amazonaws.com/marmosetmusic-com/assets/RGB-marmoset-logo-purple.png",
  },
  sealWhite: {
    spanClass: "seal-white",
    downloadLink:
      "https://s3-us-west-2.amazonaws.com/marmosetmusic-com/assets/RGB-marmoset-seal-white-2017.png",
  },
  sealPurple: {
    spanClass: "seal-purple",
    downloadLink:
      "https://s3-us-west-2.amazonaws.com/marmosetmusic-com/assets/RGB-marmoset-seal-purple-2017.png",
  },
}

export const swatches = [
  { name: "coal", hex: "#3a3532" },
  { name: "charcoal", hex: "#999999" },
  { name: "ash", hex: "#efefed" },
  { name: "purple", hex: "#8a4fab" },
  { name: "green", hex: "#37b480" },
]
