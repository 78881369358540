import { gql, useQuery } from "@apollo/client"

const GET_PAST_LICENSING_INFO = gql`
  query searchRoundSongVersion($id: ID) {
    searchRoundSongVersion(id: $id) {
      hasPastLicenses
    }
  }
`

const LicenseButton = ({ srsvId, toggleModal }) => {
  const variables = { id: srsvId }
  const { loading, error, data } = useQuery(GET_PAST_LICENSING_INFO, { variables })

  if (loading) return null
  if (error) console.log(error)

  const { searchRoundSongVersion } = data

  return (
    <>
      {searchRoundSongVersion.hasPastLicenses && (
        <mark className="license-button" onClick={toggleModal}>
          L
        </mark>
      )}
    </>
  )
}

export default LicenseButton
