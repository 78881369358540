import { useState } from "react"
import { useToggleRefinement } from "react-instantsearch-hooks-web"
import cx from "classnames"
import FilterCustomizableYesIcon from "../../icons/FilterCustomizableYesIcon"
import FilterCustomizableNoIcon from "../../icons/FilterCustomizableNoIcon"
import { useWorkbenchStore } from "../../store/store"

const WorkbenchFilterCustomizable = ({ id }) => {
  const currentFilter = useWorkbenchStore((state) => state.currentFilter)
  const { value: valueYes, refine: refineYes } = useToggleRefinement({
    attribute: "is_customizable",
  })
  const { value: valueNo, refine: refineNo } = useToggleRefinement({
    attribute: "is_not_customizable",
  })
  const customizableSelection = useWorkbenchStore((state) => state.customizableSelection)
  const setCustomizableSelection = useWorkbenchStore((state) => state.setCustomizableSelection)

  const handleClickYes = () => {
    if (customizableSelection == "") {
      setCustomizableSelection("yes")
    } else if (customizableSelection === "yes") {
      setCustomizableSelection("")
    } else {
      // switch from 'no' to 'yes'
      setCustomizableSelection("yes")
      refineNo({ isRefined: customizableSelection === "no" })
    }
    refineYes({ isRefined: customizableSelection === "yes" })
  }

  const handleClickNo = () => {
    if (customizableSelection == "") {
      setCustomizableSelection("no")
    } else if (customizableSelection === "no") {
      setCustomizableSelection("")
    } else {
      // switch from 'yes' to 'no'
      setCustomizableSelection("no")
      refineYes({ isRefined: customizableSelection === "yes" })
    }
    refineNo({ isRefined: customizableSelection === "no" })
  }

  return (
    <fieldset id={id} className={cx("mm-filter", currentFilter === id ? "current" : "")}>
      <ul>
        <li className={cx("actionable", customizableSelection === "yes" ? "selected" : "")}>
          <a data-prevent-default className="inner" onClick={handleClickYes}>
            <figure>
              <span className="icon">
                <FilterCustomizableYesIcon />
              </span>
              <figcaption>Yes</figcaption>
            </figure>
          </a>
        </li>

        <li className={cx("actionable", customizableSelection === "no" ? "selected" : "")}>
          <a data-prevent-default className="inner" onClick={handleClickNo}>
            <figure>
              <span className="icon">
                <FilterCustomizableNoIcon />
              </span>
              <figcaption>No</figcaption>
            </figure>
          </a>
        </li>
      </ul>
    </fieldset>
  )
}

export default WorkbenchFilterCustomizable
