import SvgIcon from "../shared/components/SvgIcon"

const BookIcon = () => {
  return (
    <SvgIcon svgClass="BookIcon" fillRule="evenodd" fill="#21928e">
      <path
        className="icon-border"
        d="M14.9995177 28.9205441c-7.68835014 0-13.92102643-6.2326763-13.92102643-13.9210264 0-7.68835014 6.23267629-13.92102643 13.92102643-13.92102643 7.6883501 0 13.921991 6.23267629 13.921991 13.92102643 0 7.6883501-6.2336409 13.9210264-13.921991 13.9210264m0-28.9205441C6.71500691 0 0 6.71500691 0 14.9995177 0 23.2840284 6.71500691 30 14.9995177 30 23.2840284 30 30 23.2840284 30 14.9995177 30 6.71500691 23.2840284 0 14.9995177 0"
      />
      <path d="M17.2448276 17.9153017h3.5670258v-1.0842672h-3.5670258zM17.2448276 13.4773707h5.428125v-1.0842673h-5.428125zM7.46896552 15.6911638h5.42812498v-1.0842672H7.46896552zM9.33103448 17.9153017h3.56702582v-1.0842672H9.33103448z" />
      <path d="M23.8102371 20.2289871h-6.3009699l-.0620689.0009698-.0717673.0019397c-.6895474.0300646-1.3315732.2890086-1.8387931.7215517v-9.2463362l-.0009698-.0310345.0019397-.062069c.0475215-1.0474138.9135775-1.88049567 1.9726293-1.88049567h6.3V20.2289871zm-9.3598061.7128232l-.0533405-.0436422c-.5314655-.4267241-1.1987069-.669181-1.906681-.669181H6.17489224V9.7325431h6.30096986l.0484913.00096983c1.0425647.02424569 1.8872845.85829737 1.9251078 1.90086207l.0009698.0533405v9.2540948zm2.9967673-12.29256461c-.9979526.02036638-1.9037716.52273707-2.4546336 1.31799569-.5625-.81368535-1.4945044-1.31896552-2.5167026-1.31896552H5.08965517V21.3142241l5.66573273-.0009698v.0009698h1.7825431c.9640087.0223061 1.7485992.7419181 1.8882543 1.6778018l.0038794.0019396c.0135775.1105604.0261853.2182112.0261853.2511854l.0009698.0455819.6914871-.0019397.39375.0019397v-.0484914c.0232759-1.0706897.894181-1.9280173 1.9668103-1.9280173h1.5478449v-.0009698h5.838362V8.64827586h-7.385237l-.0630388.00096983z" />
      <path d="M17.2448276 15.6911638h5.428125v-1.0842672h-5.428125z" />
      <path d="M7.46896552 13.4773707h5.42812498v-1.0842673H7.46896552z" />
    </SvgIcon>
  )
}

export default BookIcon
