import { useState, useEffect } from "react"
import { PrismicRichText } from "@prismicio/react"
import cx from "classnames"
import htmlSerializer from "../../utils/prismicHTMLSerializer"
import { replaceMMUrl, escapeToClose, createSrcSet } from "../../utils/helpers"
import LazyLoad from "../../components/LazyLoad"
import MoreIcon from "../../../icons/MoreIcon"
import PlayIcon from "../../../icons/PlayIcon"
import Modal from "../../components/Modal"
import modalStyles from "../../styles/modal.module.scss"
import styles from "../../styles/TeamItems.module.scss"

const TeamPhoto = ({ classname, photoSource, sizeObject }) => {
  return (
    <div className={cx("lazyWrapper", classname)}>
      <img
        className="lazy"
        data-src={photoSource.url}
        data-srcset={createSrcSet(photoSource, sizeObject)}
        alt={photoSource.alt}
      />
    </div>
  )
}

const RoleAndName = ({ role, name }) => {
  return (
    <div className={styles.teamItemText}>
      <p className="smallFont">{role}</p>
      <h5 className="hFive">{name}</h5>
    </div>
  )
}

//Separate component for modal content specifically for team page usage
const TeamModalContent = ({ photo, role, name, bio, link, logo, toggleModal }) => {
  const sizeobj = { Standard: "1x", Hires: "2x" }

  const handleClick = (e) => {
    const checkForModal = document.getElementById("modal-box")
    e.target !== checkForModal && !checkForModal.contains(e.target) ? toggleModal(event) : null
  }

  useEffect(() => {
    document.addEventListener("click", handleClick)
    return () => document.removeEventListener("click", handleClick)
  }, [])

  return (
    <div className={modalStyles.containerTeamPage}>
      <div className={styles.modalContent} id="modal-box">
        <div className={styles.topSection}>
          <LazyLoad>
            <TeamPhoto classname={styles.photoContainer} photoSource={photo} sizeObject={sizeobj} />
          </LazyLoad>
          <button onClick={toggleModal} className={styles.exitButton}>
            X
          </button>
        </div>
        <RoleAndName role={role} name={name} />
        <div className={styles.bioSection}>
          <PrismicRichText field={bio} components={htmlSerializer} />
        </div>
        <div className={styles.outsideLink}>
          <a href={link.url} target="_blank">
            <img src={logo.url} srcSet={createSrcSet(logo, sizeobj)} />
          </a>
        </div>
      </div>
    </div>
  )
}

const TeamItems = ({ item, pos, shouldUseModal }) => {
  const {
    team_member_image,
    role_description,
    name,
    featured_work,
    more_info,
    long_description,
    online_profile_link,
    online_profile_logo,
    uid,
    tags,
  } = item
  const [openModal, setOpenModal] = useState(false)
  const sizeobj = { Standard: "1x", Hires: "2x" }

  useEffect(() => {
    openModal ? (document.body.style.position = "fixed") : (document.body.style.position = "")

    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty("--vh", `${vh}px`)

    const resizeModalForMobileView = () => {
      let vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty("--vh", `${vh}px`)
    }
    window.addEventListener("resize", resizeModalForMobileView)

    return (
      () => window.removeEventListener("resize", resizeModalForMobileView),
      escapeToClose(setOpenModal)
    )
  }, [])

  const toggleModal = (e) => {
    e.stopPropagation()
    setOpenModal(false)
  }

  return (
    <div
      className={cx(styles.teamItem, "prismicTeamItem")}
      key={uid}
      data-display="normal"
      data-tags={tags}
      data-position={pos}
    >
      {openModal && (
        <Modal>
          <TeamModalContent
            photo={team_member_image}
            role={role_description}
            name={name}
            bio={long_description}
            link={online_profile_link}
            logo={online_profile_logo}
            toggleModal={toggleModal}
          />
        </Modal>
      )}

      {shouldUseModal ? (
        <div className={styles.teamItemLinks}>
          <button onClick={() => setOpenModal(true)}>
            <MoreIcon />
          </button>
        </div>
      ) : (
        <div className={styles.teamItemLinks}>
          {more_info.url && (
            <a href={replaceMMUrl(more_info.url)} target="_blank" data-bypass="true">
              <MoreIcon />
            </a>
          )}
          {featured_work.url && (
            <a href={featured_work.url} target="_blank">
              <PlayIcon />
            </a>
          )}
        </div>
      )}
      <LazyLoad>
        <TeamPhoto
          classname={styles.imgWrapper}
          photoSource={team_member_image}
          sizeObject={sizeobj}
        />
      </LazyLoad>
      <RoleAndName role={role_description} name={name} />
    </div>
  )
}

export default TeamItems
