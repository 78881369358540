import { PrismicPageParser } from "../../shared/utils/prismicPageParser"

const WhatWeDoContainer = () => {
  const data = JSON.parse(window.prismic_data)
  const pageId = window.pageId

  return (
    <section id="mm-what-we-do" className="pageContainer" aria-label="What We Do page">
      <PrismicPageParser pageData={data} pageId={pageId} />
    </section>
  )
}

export default WhatWeDoContainer
