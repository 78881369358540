import { useState, useEffect, createContext } from "react"
import { PrismicClient } from "./prismicConfig"
import { addUIDs } from "./helpers"
import Text from "../components/prismic/Text"
import Button from "../components/prismic/Button"
import CallToAction from "../components/prismic/CallToAction"
import FeaturedArtists from "../../pages/home/components/FeaturedArtists"
import FeaturedContent from "../../pages/home/components/FeaturedContent"
import EmailSignUpContainer from "../../pages/home/components/EmailSignUpContainer"
import Masthead from "../components/prismic/Masthead"
import Image from "../components/prismic/Image"
import ImageSizedByFile from "../components/prismic/ImageSizedByFile"
import ImageCollage from "../components/prismic/ImageCollage"
import Hero from "../components/prismic/Hero"
import LogoGrid from "../components/prismic/LogoGrid"
import HorizontalRule from "../components/prismic/HorizontalRule"
import VideoPlaylist from "../components/prismic/VideoPlaylist"
import SingleVideoPlayer from "../components/prismic/SingleVideoPlayer"
import Switchboard from "../components/prismic/Switchboard"
import Team from "../components/prismic/Team"
import Subnav from "../components/prismic/Subnav"
import Accordion from "../components/prismic/Accordion"
import CSSSandbox from "../components/prismic/CSSSandbox"
import FeaturedMixtapes from "../../pages/home/components/FeaturedMixtapes"
import HomeHero from "../../pages/home/components/HomeHero"
import StudioHero from "../../pages/studio/components/StudioHero"
import colorThemeMap from "../data/prismicColorThemeMap"
import AnnouncementBanner from "../components/prismic/AnnouncementBanner"
import QuoteTestimonial from "../components/prismic/QuoteTestimonial"
import AnimatedBanner from "../components/prismic/AnimatedBanner"
import LogoGridRow from "../components/prismic/LogoGridRow"

const PrismicPageContext = createContext()

const PrismicPageParser = ({ pageData, pageId, pageName = null }) => {
  const [doc, setDocData] = useState(null)

  useEffect(() => {
    function addClassJS(el, newclass) {
      if (el.className.search("\b" + newclass + "\b") == -1) {
        var newClassname = el.className + " " + newclass
        newClassname = newClassname.trim()
        return newClassname
      }
    }

    if (!pageData || pageData === "") {
      const fetchData = async () => {
        const res = await PrismicClient.getByID(pageId)
        if (res) {
          setDocData(res)
          $.ajax({
            url: "/api/v1/prismic/cache_data",
            type: "POST",
            data: { json: JSON.stringify(res), id: pageId },
          })
          const container = document.querySelector(".pageContainer")
          container.className = addClassJS(container, "backgroundWhite")
        }
      }
      fetchData()
    } else {
      setDocData(pageData)

      const container = document.querySelector(".pageContainer")
      container.className = addClassJS(container, "backgroundWhite")
    }
  }, [])

  if (!doc) return null

  const { body, color_theme, title } = doc.data
  const colorTheme = colorThemeMap[color_theme]

  const parseTags = (slice) => {
    let team = body.filter((item) => item.slice_type === "team")
    if (team.length > 0) {
      var all_tags = ["0-All"]
      team[0].items.map((item) => {
        var my_tags = item.tags.split(",")
        my_tags.map((tag) => {
          tag = tag.trim()
          all_tags.push(tag)
        })
      })
      all_tags.sort((a, b) =>
        a.localeCompare(b, navigator.languages[0] || navigator.language, {
          numeric: true,
          ignorePunctuation: true,
        })
      ) //sorting the tags by their numbered prefix using localeCompare
      var tags_without_prefix = all_tags.map((tag) => tag.replace(/(\d+-)/, ""))
      slice.items = [...new Set(tags_without_prefix)]
    }
    return slice
  }

  return (
    <PrismicPageContext.Provider value={colorTheme}>
      {body.map((slice, ind) => {
        const { slice_type } = slice
        const sliceWithIDs = addUIDs(slice)
        const keyProp = sliceWithIDs.uid

        switch (slice_type) {
          case "announcement_banner":
            return <AnnouncementBanner key={keyProp} slice={sliceWithIDs} />
          case "hero":
            return <Hero key={keyProp} slice={sliceWithIDs} />
          case "horizontal_line":
            return <HorizontalRule key={keyProp} slice={sliceWithIDs} title={title} />
          case "masthead":
            return <Masthead key={keyProp} slice={sliceWithIDs} page={pageName} />
          case "featured_content":
            return <FeaturedContent key={keyProp} slice={sliceWithIDs} />
          case "featured_artists":
            return <FeaturedArtists key={keyProp} slice={sliceWithIDs} />
          case "featured_mixtapes":
            return <FeaturedMixtapes key={keyProp} slice={sliceWithIDs} />
          case "text":
            return <Text key={keyProp} globalClass={keyProp} slice={sliceWithIDs} />
          case "quotebox_text":
            return <Text key={keyProp} globalClass={keyProp} slice={sliceWithIDs} quoteboxText />
          case "button":
            return <Button key={keyProp} slice={sliceWithIDs} />
          case "image":
            return <Image key={keyProp} globalClass={keyProp} slice={sliceWithIDs} />
          case "image_sized_by_file":
            return <ImageSizedByFile key={keyProp} slice={sliceWithIDs} />
          case "image_collage":
            return <ImageCollage key={keyProp} slice={sliceWithIDs} />
          case "cta":
            return <CallToAction key={keyProp} slice={sliceWithIDs} />
          case "logo_grid":
            return <LogoGrid key={keyProp} slice={sliceWithIDs} title={title} />
          case "logo_grid_row":
            return <LogoGridRow key={keyProp} slice={sliceWithIDs} title={title} />
          case "video_playlist":
            return <VideoPlaylist key={keyProp} slice={sliceWithIDs} />
          case "single_video_player":
            return <SingleVideoPlayer key={keyProp} slice={sliceWithIDs} />
          case "switchboard":
            return <Switchboard key={keyProp} slice={sliceWithIDs} />
          case "email_sign_up_form":
            return <EmailSignUpContainer key={keyProp} slice={sliceWithIDs} />
          case "team":
            return <Team key={keyProp} slice={sliceWithIDs} />
          case "sub_nav":
            if (slice.primary.nav_type === "tags") {
              const sliceWithTags = parseTags(sliceWithIDs)
              return <Subnav key={keyProp} slice={sliceWithTags} />
            } else {
              return <Subnav key={keyProp} slice={sliceWithIDs} />
            }
          case "accordion":
            return <Accordion key={keyProp} slice={sliceWithIDs} />
          case "css_sandbox":
            return <CSSSandbox key={keyProp} slice={sliceWithIDs} />
          case "home_hero":
            return <HomeHero key={keyProp} slice={sliceWithIDs} />
          case "studio_hero":
            return <StudioHero key={keyProp} slice={sliceWithIDs} />
          case "quote_testimonial":
            return <QuoteTestimonial key={keyProp} slice={sliceWithIDs} />
          case "animated_banner":
            return <AnimatedBanner key={keyProp} slice={sliceWithIDs} />
          default:
            return null
        }
      })}
    </PrismicPageContext.Provider>
  )
}

export { PrismicPageParser, PrismicPageContext }
