import SvgIcon from "../shared/components/SvgIcon"

const MarmosetIcon = () => {
  return (
    <SvgIcon svgClass="MarmosetIcon">
      <path d="M15.0006079 28.9843904c-7.71152872 0-13.98476387-6.2730228-13.98476387-13.9842904 0-7.71126761 6.27323515-13.98429036 13.98476387-13.98429036 7.7105129 0 13.9837481 6.27302275 13.9837481 13.98429036 0 7.7112676-6.2732352 13.9842904-13.9837481 13.9842904m0-28.9842904C6.72940422.0001.0001 6.72917638.0001 15.0001c0 8.2709236 6.72930422 15 15.0005079 15 8.2712036 0 14.9994921-6.7290764 14.9994921-15 0-8.27092362-6.7282885-15-14.9994921-15" />
      <path d="M23.144 15.757c-.004 4.541-3.732 8.376-8.142 8.376-4.489 0-8.142-3.652-8.138-8.215L6.86 6.903c1.362.249 2.001 1.493 2.001 2.363v1.28l.827-.714c1.481-1.278 3.368-1.982 5.314-1.982 1.946 0 3.833.704 5.314 1.982l.827.714V9.454c0-.925.895-2.066 2.001-2.343v8.646zm-2.84-7.205C18.76 7.449 16.906 6.85 15.002 6.85c-1.879 0-3.709.584-5.241 1.659C9.439 7.251 8.336 5.86 6.36 5.86h-.5l.001 9.739.006.249-.007.144c0 5.04 4.101 9.141 9.142 9.141 4.873 0 9.005-4.158 9.138-9.134l.004-9.951h-.5c-1.57 0-2.893 1.221-3.34 2.504z" />
      <path d="M11.735 13.8c-.605 0-1.095.49-1.095 1.095s.49 1.095 1.095 1.095c.604 0 1.095-.49 1.095-1.095s-.491-1.095-1.095-1.095M18.265 13.8c-.604 0-1.095.49-1.095 1.095s.491 1.095 1.095 1.095c.605 0 1.095-.49 1.095-1.095s-.49-1.095-1.095-1.095" />
      <path d="M18.895 17.556l-.384.092v1.821h-.662v-.004c-.508 0-.758-.231-1.102-.55-.393-.363-.882-.815-1.78-.815-.9 0-1.386.453-1.777.816-.342.319-.589.549-1.096.549v.004h-.58v-1.814l-.39-.088c-1.262-.285-2.144-1.386-2.144-2.677 0-1.516 1.234-2.75 2.75-2.75s2.749 1.234 2.749 2.75h1.028c0-1.516 1.233-2.75 2.749-2.75s2.75 1.234 2.75 2.75c0 1.273-.868 2.369-2.111 2.666m-3.882 4.021c-.858 0-1.554-.688-1.573-1.568.159-.119.305-.243.432-.361.341-.318.588-.548 1.095-.548s.756.23 1.1.549c.15.138.323.284.518.418-.037.838-.726 1.51-1.572 1.51m3.243-10.437c-1.397 0-2.618.769-3.263 1.905-.645-1.136-1.866-1.905-3.263-1.905-2.067 0-3.75 1.683-3.75 3.75 0 1.626 1.024 3.03 2.534 3.542v2.037h1.963c.222 1.198 1.274 2.108 2.536 2.108 1.254 0 2.302-.9 2.53-2.108h1.968v-2.054c1.488-.524 2.495-1.922 2.495-3.525 0-2.067-1.682-3.75-3.75-3.75" />
      <path d="M13.932 16.47c-.288 0-.522.233-.522.522 0 .289.234.522.522.522.289 0 .522-.233.522-.522 0-.289-.233-.522-.522-.522M16.062 16.47c-.289 0-.522.233-.522.522 0 .289.233.522.522.522.289 0 .522-.233.522-.522 0-.289-.233-.522-.522-.522" />
    </SvgIcon>
  )
}

export default MarmosetIcon
