import { useContext } from "react"
import cx from "classnames"
import InfoIcon from "../../../icons/InfoIcon"
import MoreIcon from "../../../icons/MoreIcon"
import PlayIcon from "../../../icons/PlayIcon"
import styles from "../../styles/Button.module.scss"
import { PrismicPageContext } from "../../utils/prismicPageParser"
import { replaceMMUrl } from "../../utils/helpers"

const Button = ({ slice, white }) => {
  const colorTheme = useContext(PrismicPageContext)
  const { primary } = slice
  const Icon = IconMap[primary.button_icon]

  return (
    <div className={cx("prismicButtonWrapper", styles.wrapper)}>
      {primary.button_url && (
        <button
          className={cx("prismicButton", styles[colorTheme], styles.button, {
            [styles.white]: white,
          })}
        >
          <div className={styles.iconAndText}>
            {Icon && <Icon />}
            <a
              href={replaceMMUrl(primary.button_url)}
              className={cx(styles.link, { [styles.noIndent]: !Icon })}
              data-bypass="true"
            >
              {primary.button_text}
            </a>
          </div>
        </button>
      )}
    </div>
  )
}

const IconMap = {
  Info: InfoIcon,
  More: MoreIcon,
  Play: PlayIcon,
}

export default Button
