import React from "react"
import { formatMoney } from "accounting"

const LicenseVersion = ({ licenseVersion, isCustom }) => {
  return (
    <div>
      <dt>{licenseVersion.name}</dt>
      <dd>
        <ul>
          <Row label="Permitted Content:" value={licenseVersion.permitted_content} />
          <Row label="Non-Permitted Content:" value={licenseVersion.non_permitted_content} />
          <Row label="Number of Spots:" value={licenseVersion.number_of_spots} />
          <Row label="Usage:" value={licenseVersion.usage} />
          <Row label="Term:" value={licenseVersion.term} />
          <Row label="Notes:" value={licenseVersion.notes} />
          <Row label="Cost:" value={formatMoney(licenseVersion.price)} />
          {isCustom && (
            <div>
              <Row label="Start Date:" value={licenseVersion.start_date} />
              <Row label="End Date:" value={licenseVersion.end_date} />
              <Row label="Territory:" value={licenseVersion.territory} />
              <Row label="Song:" value={licenseVersion.song} />
            </div>
          )}
        </ul>
      </dd>
    </div>
  )
}

const Row = ({ value, label }) => {
  if (!value) return null

  return (
    <li>
      <b style={{ marginRight: "6px" }}>{label}</b>
      <span dangerouslySetInnerHTML={{ __html: value }} />
    </li>
  )
}

export default LicenseVersion
