import SvgIcon from "../shared/components/SvgIcon"

const StarIcon = () => {
  return (
    <SvgIcon svgClass="StarIcon" fillRule="evenodd" fill="#21928e">
      <path
        className="icon-border"
        d="M15.0004823 28.9205441c-7.68835008 0-13.92102637-6.2326763-13.92102637-13.9210264 0-7.68835014 6.23267629-13.92102643 13.92102637-13.92102643 7.6893148 0 13.9210264 6.23267629 13.9210264 13.92102643 0 7.6883501-6.2317116 13.9210264-13.9210264 13.9210264m0-28.9205441C6.71597157 0 0 6.71500691 0 14.9995177 0 23.2840284 6.71597157 30 15.0004823 30 23.2849931 30 30 23.2840284 30 14.9995177 30 6.71500691 23.2849931 0 15.0004823 0"
      />
      <path d="M19.6231681 22.2274784l-4.6241379-2.3431034-4.6231681 2.3431034.7991379-5.1226293-3.65721983-3.672737 5.11778013-.8224138 2.3634699-4.61346985 2.3644396 4.61346985 5.1168104.8224138-3.65625 3.672737.7991379 5.1226293zm-1.5459052-10.6021551l-3.0782327-6.00808192-3.0782328 6.00808192-6.66562499 1.0716595 4.76282329 4.7831896-1.04062501 6.671444 6.02165951-3.0510776 6.0216595 3.0510776-1.040625-6.671444 4.7628232-4.7831896-6.665625-1.0716595z" />
    </SvgIcon>
  )
}

export default StarIcon
