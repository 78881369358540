import { Fragment, useState, useEffect } from "react"
import axios from "axios"
import DownloadSongVersionsError from "./components/DownloadSongVersionsError"
import DownloadSongVersionsItem from "./components/DownloadSongVersionsItem"
import DownloadIcon from "../../icons/DownloadIcon"

const DownloadSongVersions = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [songVersionItems, setSongVersionItems] = useState([])
  const [downloadAllUrl, setDownloadAllUrl] = useState("")
  const [downloadAllStems, setDownloadAllStems] = useState({})
  const [showError, setShowError] = useState(false)
  const [expiredToken, setExpiredToken] = useState(false)
  const id = document.location.pathname.split("/")[2]
  const download_token = document.location.pathname.split("/")[4]
  const token_error = document
    .getElementById("mm-download-song-versions")
    .getAttribute("data-token-error")

  useEffect(() => {
    if (token_error) {
      setShowError(true)
      setExpiredToken(true)
      return
    }

    axios
      .get("/song_version_items", {
        params: {
          id,
          download_token,
        },
      })
      .then(({ data }) => {
        if (data.success) {
          setDownloadAllStems(data.song_version_stems)
          setSongVersionItems(data.song_versions)
          setDownloadAllUrl(data.download_all_url)
        } else {
          setShowError(true)
        }
        setIsLoading(false)
      })
      .catch((err) => console.log(err))
  }, [])

  return (
    <Fragment>
      <header id="mm-download-song-versions-landing">
        <div className="cover-image"></div>
        <div className="nav-shadow"></div>
        <hgroup>
          <div className="contain">
            <div className="download-icon">
              <DownloadIcon />
            </div>
            <h1 className="hThree">Download Your Songs Here</h1>
            <p className="paragraph">{`Order ${id}`}</p>
          </div>
        </hgroup>
      </header>

      <div className="download-song-versions-message">
        <div className="contain">
          <p>Ready to get started? You can temporarily download your song files on this page.</p>
        </div>
      </div>

      <article>
        <div className="contain">
          {showError && <DownloadSongVersionsError expiredToken={expiredToken} />}
          {isLoading && !showError && <div className="loading">Loading ...</div>}
          {!isLoading && !showError && (
            <div className="mm-download-song-versions-list">
              <ul>
                {_.map(songVersionItems, (item) => (
                  <DownloadSongVersionsItem
                    key={item.id}
                    songVersion={item}
                    stemsUrl={downloadAllStems[item.id]}
                  />
                ))}
              </ul>
              <div className="download-all-files-container">
                <a href={downloadAllUrl} className="box-button-black" target="_blank">
                  Download All Files
                </a>
              </div>
            </div>
          )}
        </div>
      </article>
    </Fragment>
  )
}

export default DownloadSongVersions
