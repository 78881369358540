import styles from "../styles/OrderItem.module.scss"

const OrderItemTouch = ({ order }) => {
  return (
    <a href={`/account/history/${order.id}`} style={{ textDecoration: "none" }} data-bypass="true">
      <li className={styles.orderItem} data-order-id={order.id}>
        <ol className={styles.orderInfo}>
          <div className={styles.left}>
            <li className={styles.orderNumber}>{order.id}</li>
            <li className={styles.endClient}>{order.endClient ? order.endClient : "n/a"}</li>
          </div>
          <div className={styles.right}>
            <li className={styles.purchaseDate}>{order.purchaseDate}</li>
            <li className={styles.total}>{order.total}</li>
          </div>
        </ol>
      </li>
    </a>
  )
}

export default OrderItemTouch
