import { useRef } from "react"
import styles from "../styles/SongVersionItem.module.scss"

const SongVersionItem = ({ lineItemId, songVersion, handleShowConfirmation }) => {
  const { id, artist, displayName, length } = songVersion
  const selectButton = useRef()

  return (
    <div className={styles.results}>
      <div className={styles.songArtist}>
        <a href={`/browse/${id}`} className={styles.link} target="_blank">
          {displayName}
        </a>
        <div>
          <em style={{ color: "#666" }}>by </em>
          <a href={`/artists/${artist.slug}`} className={styles.link} target="_blank">
            {artist.name}
          </a>
        </div>
      </div>
      <div className={styles.length}>{length}</div>
      <div className={styles.select}>
        <button
          className={styles.selectButton}
          aria-label="select song"
          ref={selectButton}
          onClick={() => handleShowConfirmation(lineItemId, songVersion, selectButton)}
        >
          <img
            src="/images/icons/plus_sign.svg"
            alt="select song icon"
            style={{ height: 20, width: 20 }}
          />
        </button>
      </div>
    </div>
  )
}

export default SongVersionItem
