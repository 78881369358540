import { Fragment, useRef } from "react"
import axios from "axios"
import { Formik } from "formik"
import * as yup from "yup"
import MarmosetIcon from "../../icons/MarmosetIcon"
import { distributionOptions } from "../../shared/data/checkboxOptions"
import CustomRequestForm from "./components/CustomRequestForm"
import CustomerServiceEmail from "../../shared/components/CustomerServiceEmail"

const validationSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  email: yup.string().email("Email is invalid").required("Email is required."),
  phone: yup.string().required("Phone Number is required"),
  company: yup.string().required("End Client is required"),
  company_location: yup.string().required("Company Location is Required"),
  end_client: yup.string().required("End Client is required"),
  project_title: yup.string().required("Project Title is required"),
  distribution: yup.array().required("Distribution is required - please select at least one"),
  term: yup.string().required("Term is required"),
  territory: yup.string().required("Territory is required"),
  use: yup.string().required("Use is required"),
  music_budget: yup.string().required("Budget is required"),
  exclusivity: yup.object().shape({
    label: yup.string(),
    value: yup.string().required("Exclusivity is required"),
  }),
  notes: yup.string().nullable(),
})

const CustomRequestFormContainer = () => {
  const el = document.getElementById("mm-custom-request")
  const userName = (el && el.getAttribute("data-user-name")) || ""
  const userEmail = (el && el.getAttribute("data-user-email")) || ""
  const messageRef = useRef(null)
  // Allow form to be submitted without a songVersion attached
  const songVersion = sessionStorage.customRequestData
    ? JSON.parse(sessionStorage.customRequestData)
    : undefined

  const getReadableDistributionValues = (distributionValues) => {
    let readableDistributionValues = []
    distributionValues.forEach((distValue) => {
      const distLabel = distributionOptions.find((dist) => dist.value === distValue).label
      readableDistributionValues.push(distLabel)
    })
    return readableDistributionValues
  }

  const handleSubmit = (params, formActions) => {
    params.exclusivity = params.exclusivity.label
    params.distribution = getReadableDistributionValues(params.distribution)

    axios
      .post("/api/v1/licenses/custom_request", { custom_request: params })
      .then(({ data }) => {
        const { errors, message, success } = data
        fbq("track", "Lead", "customLicense")

        if (success) formActions.resetForm()
        formActions.setStatus({ errors, message, success })
        formActions.setSubmitting(false)
        messageRef.current.scrollIntoView({ behavior: "smooth", block: "center" })
      })
      .catch((err) => console.log(err))
  }

  const initialValues = {
    song_version_id: songVersion?.id,
    name: userName,
    email: userEmail,
    phone: "",
    company: "",
    company_location: "",
    end_client: "",
    project_title: "",
    distribution: [],
    other: "",
    term: "",
    territory: "",
    use: "",
    music_budget: "",
    exclusivity: "",
    notes: "",
  }

  return (
    <Fragment>
      <header id="mm-custom-request-landing">
        <div className="cover-image header-bg" />
        <hgroup>
          <div className="contain">
            <div className="custom-request-icon">
              <span>
                <MarmosetIcon />
              </span>
            </div>
            <h1 className="hThree">Custom Request Form</h1>
          </div>
        </hgroup>
      </header>

      <div className="custom-request-message">
        <div className="contain">
          <p>
            Let's tailor a license to your needs! Please complete the form below to get started.{" "}
          </p>
          <p>
            If you prefer to skip the form and talk to one of our team members, email us at{" "}
            <CustomerServiceEmail />. Please include where you and/or your company are located so we
            can connect you with your dedicated team.
          </p>
          <p>
            <em>Please note that vintage songs are priced at a higher rate.</em>
          </p>
        </div>
      </div>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formikProps) => (
          <CustomRequestForm {...formikProps} messageRef={messageRef} songVersion={songVersion} />
        )}
      </Formik>
    </Fragment>
  )
}

export default CustomRequestFormContainer
