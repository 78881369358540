export const fetchDataForMessagePopUp = (route) => {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  }

  fetch(route, requestOptions)
    .then((res) => res.text())
    .then((data) => {
      var msg = mm.Message(data)
      if (msg) {
        msg.send()
      }
    })
    .catch((e) => console.log("messageQueueService.js | fetchDataForMessagePopUp() | *error* ", e))
}
