import React from "react"
import cx from "classnames"
import Button from "../../../shared/components/prismic/Button"
import styles from "../styles/HomeHero.module.scss"

const HomeHero = ({ slice }) => {
  const { primary } = slice
  const backgroundImg = {
    background: `url(${primary.image.url}) center center / cover no-repeat`,
  }

  return (
    <header id="mm-home-hero" className={cx("homeHero", styles.homeHero, "fullWidth")}>
      <div style={backgroundImg} className={cx("img", styles.img)} />
      <div className={styles.contain}>
        <div className={styles.content}>
          <h1 className={cx("hOne", styles.hOne, styles.header)}>{primary.header}</h1>
          <div className={cx("buttonAndLink", styles.buttonAndLink)}>
            <Button slice={slice} white />
            {primary.link_text && (
              <a
                href={primary.link_url}
                className={cx("hyperLink", "link", styles.hyperLink, styles.link)}
                data-bypass="true"
              >
                {primary.link_text}
              </a>
            )}
          </div>
        </div>
      </div>
    </header>
  )
}

export default HomeHero
