import cx from "classnames"
import { useState } from "react"
import SongVersionItem from "../../shared/components/SongVersionItem"
import { getSongColor } from "../../shared/utils/browse"

//TODO: use this to pass hidden data between components
//also maybe move other props to context and use them along the tree

const MixtapeSongItem = ({
  song,
  user_role,
  handleSetExpandedSongVersionId,
  expandedSongVersionId,
  currentBlanketDealModalRef,
  mixtape,
}) => {
  const [visibility, setVisibility] = useState({
    visibility: "hidden",
    position: "absolute",
    zindex: "-99999",
  })

  const artist = song.artist

  // the top song version is handled slightly differently
  const topSongVersion = song.songVersions[0]
  const altSongVersions = song.songVersions.slice(1)

  // toggle alt song versions visibility
  const setAltVersionsVisibility = () => {
    var obj = {
      visibility: "hidden",
      position: "absolute",
      zindex: "-99999",
    }
    if (visibility.visibility === "hidden") {
      obj = {
        visibility: "visible",
        position: "inherit",
        zindex: "1",
      }
    }
    setVisibility(obj)
  }

  const songColor = getSongColor(artist, user_role, song.forMarmoset)

  // TODO we will want to add a separate alt_versions_count without the +1 when the legacy browse is retired and use song_versions_count for total song version count
  // at that point we can refactor the SongVersionItem component without breaking the old /browse page
  const songVersionProps = {
    artist,
    song_versions_count: altSongVersions.length + 1,
    song,
    setAltVersionsVisibility,
    user_role,
    songColor,
    handleSetExpandedSongVersionId,
    expandedSongVersionId,
    currentBlanketDealModalRef,
    mixtape,
  }

  return (
    <li
      className={cx("song-item", { ["expanded"]: visibility.visibility === "visible" })}
      data-song-id={song.songId}
      data-catalog-color={songColor}
    >
      <ol className="top-version">
        <SongVersionItem
          key={topSongVersion.id}
          sv={topSongVersion}
          topSongVersion={true}
          {...songVersionProps}
        />
      </ol>
    </li>
  )
}

export default MixtapeSongItem
