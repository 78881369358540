import cx from "classnames"

const SongActionsTooltip = ({
  tooltipType,
  vintage,
  exclusive,
  action,
  cy,
  exclusivityMessage,
  show,
  additionalClass,
}) => {
  return (
    <li
      className={cx("tooltip", "partial-exclusivity-tooltip", show && "show", additionalClass, {
        ["double-tip"]: vintage && exclusive,
      })}
      data-connect-action={action}
      data-cy={cy}
    >
      {exclusivityMessage}
      <span className="tri">&#9656;</span>
    </li>
  )
}

export default SongActionsTooltip
