import { gql, useMutation } from "@apollo/client"
import cx from "classnames"
import LazyLoad from "../../../shared/components/LazyLoad"
import MoreIcon from "../../../icons/MoreIcon"
import PlayIcon from "../../../icons/PlayIcon"
import styles from "../styles/Artist.module.scss"
import spinnerStyles from "../../../shared/styles/spinners.module.scss"

const PLAY_FEATURED_ARTIST = gql`
  mutation PlayFeaturedArtist($id: ID!, $limit: Int) {
    playFeaturedArtist(id: $id, limit: $limit) {
      songVersions {
        id
        customizable
        displayName
        exclusive
        hasClickLicenses
        length
        lyrics
        playerArt
        songVersionFile
        artist {
          id
          name
        }
      }
    }
  }
`

const Artist = ({ artist, index, previouslySelected, selected, handleSelectedArtist }) => {
  const [playFeaturedArtist, { loading }] = useMutation(PLAY_FEATURED_ARTIST)
  const { id, featuredArtistImages, slug } = artist
  const indexedClassName = `artist${index}`
  const artistImage = featuredArtistImages[ImageMap[index]]

  const handlePlayFeaturedArtist = (e) => {
    e.preventDefault()
    e.stopPropagation()
    const variables = {
      id,
      limit: 10, // amount of songs to be returned
    }
    playFeaturedArtist({ variables })
      .then(({ data: { playFeaturedArtist } }) => {
        mm.playerProxy.trigger("play", {
          songVersions: playFeaturedArtist.songVersions,
        })
      })
      .catch((err) => console.log(err))
  }

  const handleViewArtist = (e) => {
    e.preventDefault()
    window.mm.router.navigate(`/artists/${slug}`, { trigger: true })
  }

  return (
    <LazyLoad>
      <div
        className={cx(
          "lazy",
          styles.artist,
          styles[indexedClassName],
          { [styles.selected]: selected },
          { [styles.previouslySelected]: previouslySelected }
        )}
        data-style={`background: url(${artistImage}) center top / cover no-repeat;`}
        onMouseEnter={() => handleSelectedArtist(artist, index)}
      >
        <div className={styles.actions}>
          {loading ? (
            <div className={spinnerStyles.mediumSpinner} />
          ) : (
            <a className={styles.playButton} onClick={handlePlayFeaturedArtist}>
              <PlayIcon />
            </a>
          )}
          <a onClick={handleViewArtist} className={styles.more}>
            <MoreIcon />
          </a>
        </div>
      </div>
    </LazyLoad>
  )
}

const ImageMap = {
  0: "goldPhoto",
  1: "purplePhoto",
  2: "greenPhoto",
}

export default Artist
