import cx from "classnames"
import styles from "../../styles/ImageSizedByFile.module.scss"
import LazyLoad from "../../components/LazyLoad"
import { createSrcSet } from "../../utils/helpers"

const ImageSizedByFile = ({ slice }) => {
  const { primary } = slice

  const sizeobj = { Standard: "1x", Hires: "2x" }

  return (
    <article className={styles.imageWrapper}>
      <div className={styles.contain}>
        <LazyLoad>
          <img
            srcSet={createSrcSet(primary.img, sizeobj)}
            className={cx("lazy", styles.image)}
            src={primary.img.url}
            alt={primary.img.alt}
          />
        </LazyLoad>
      </div>
    </article>
  )
}

export default ImageSizedByFile
