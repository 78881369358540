import React, { Component } from "react"
import axios from "axios"
import Mixtapes from "../../shared/components/Mixtapes"
import MixtapeFilterSort from "./components/MixtapeFilterSort"
import NoResults from "./components/NoResults"
import genreSelectOptions from "../../shared/data/genreSelectOptions"

class MixtapesContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      mixtapes: [],
      genreSelectOptions: genreSelectOptions,
      selectedGenres: [""],
      selectedSort: "",
      selectedFilter: "",
      offset: 0,
      viewMore: false,
      loading: true,
      noResults: false,
    }

    this.updateMixtapes = this.updateMixtapes.bind(this)
    this.viewMore = this.viewMore.bind(this)
    this.showLoader = this.showLoader.bind(this)
  }

  componentDidMount() {
    const filterKeyword = window.location.search.split("=")[1]
    if (filterKeyword) {
      this.showLoader()
      this.updateMixtapes(filterKeyword)
    } else {
      axios.get("/mixtapes/feed").then((response) => {
        this.setState({
          ...this.state,
          viewMore: true,
          loading: false,
          mixtapes: response.data.mixtapes,
        })
      })
    }
  }

  showLoader() {
    this.setState({ ...this.state, loading: true })
  }

  updateMixtapes(selected) {
    this.showLoader()
    const filterKeyword = window.location.search.split("=")[1]

    const genres = selected.options
      ? selected.options.map((o) => o.value)
      : this.state.selectedGenres
    const sort = selected.name === "sort" ? selected.value : this.state.selectedSort
    const filter = selected.name === "filter" ? selected.value : filterKeyword
    const params = { genres, sort, filter }

    // prevents more than 3 genres from being selected
    if (selected.options) {
      const selectedGenres = selected.options.map((option) => option.value)
      var updatedGenreSelectOptions = genreSelectOptions.map((option) => {
        if (
          selected.options.length < 3 ||
          option.text === "All" ||
          selectedGenres.includes(option.text)
        ) {
          return option
        } else {
          return { ...option, disabled: true }
        }
      })
    }

    axios.get("/mixtapes/feed", { params }).then((response) => {
      const { mixtapes } = response.data
      window.mm.router.navigate(`/mixtapes?show=${filter}`)

      this.setState({
        ...this.state,
        loading: false,
        viewMore: mixtapes.length !== response.data.total,
        offset: 0,
        selectedSort: sort,
        selectedGenres: genres,
        selectedFilter: filter,
        genreSelectOptions: updatedGenreSelectOptions || this.state.genreSelectOptions,
        mixtapes: mixtapes,
        noResults: mixtapes.length === 0,
      })
    })
  }

  viewMore() {
    const offset = this.state.offset + 16
    const params = {
      offset: offset,
      genres: this.state.selectedGenres,
      sort: this.state.selectedSort,
      filter: this.state.selectedFilter,
    }

    axios.get("/mixtapes/feed", { params }).then((response) => {
      const { mixtapes } = response.data

      this.setState({
        ...this.state,
        offset: offset,
        loading: false,
        viewMore: this.state.mixtapes.length + mixtapes.length !== response.data.total,
        mixtapes: this.state.mixtapes.concat(mixtapes),
      })
    })
  }

  render() {
    const {
      mixtapes,
      loading,
      genreSelectOptions,
      selectedGenres,
      selectedSort,
      selectedFilter,
      noResults,
    } = this.state

    return (
      <section id="mm-mixtapes">
        <header id="mm-mixtapes-landing">
          <div className="cover-image" />
          <div className="heading">
            <h1 className="hThree">Mixtapes and Albums</h1>
          </div>
        </header>

        <article className="mixtapes">
          <div className="contain">
            <MixtapeFilterSort
              loading={loading}
              filterOptions={genreSelectOptions}
              selectedGenres={selectedGenres}
              selectedSort={selectedSort}
              selectedFilter={selectedFilter}
              updateMixtapes={this.updateMixtapes}
            />

            {mixtapes.length > 0 && (
              <div className="mixtapes-container">
                <Mixtapes mixtapes={mixtapes} />
              </div>
            )}
            {noResults && <NoResults />}

            {this.state.viewMore && mixtapes.length > 0 ? (
              <div className="view-more-container">
                <a className="box-button-black" onClick={() => this.viewMore()}>
                  View More
                </a>
              </div>
            ) : (
              <div style={{ marginBottom: "100px" }} />
            )}
          </div>
        </article>
      </section>
    )
  }
}

export default MixtapesContainer
