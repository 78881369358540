import SvgIcon from "../shared/components/SvgIcon"

const PlayLargeIcon = ({ fill }) => {
  return (
    <SvgIcon svgClass="PlayLargeIcon" fill={fill}>
      <path
        className="icon-border"
        d="M14.9985746 28.9375653c-7.69742474 0-13.93518963-6.2396655-13.93518963-13.9370902 0-7.69837494 6.23871519-13.93804043 13.93518963-13.93804043 7.6993252 0 13.9389907 6.23966549 13.9389907 13.93804043 0 7.698375-6.2396655 13.9370902-13.9389907 13.9370902m0-28.9375653C6.71481517 0 0 6.71576547 0 15.0004751 0 23.2851848 6.71481517 30 14.9985746 30 23.2851848 30 30 23.2851848 30 15.0004751 30 6.71481517 23.2842345 0 14.9985746 0"
      />
      <path d="M12.7739224 8.70581897l7.7935345 6.29612073-7.7935345 6.2961206V8.70581897zM11.6896552 23.5684267l10.6040948-8.566487-10.6040948-8.56745694V23.5684267z" />
    </SvgIcon>
  )
}

PlayLargeIcon.defaultProps = {
  fill: "#f1eff0",
}

export default PlayLargeIcon
