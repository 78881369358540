import React from "react"
import { formatMoney } from "accounting"

const OrderTotal = (props) => {
  const { subtotal, promoCode, discount, total, isLoggedIn } = props

  return (
    <div className="totals">
      <div className="price-row">
        <span>Subtotal</span>
        <span>{formatMoney(subtotal)}</span>
      </div>
      {promoCode && isLoggedIn && (
        <div className="price-row promo-code">
          <span>Promotion</span>
          <span>
            {promoCode} - {formatMoney(discount)}
          </span>
        </div>
      )}
      <div className="price-row total">
        <span>Total</span>
        <span>{formatMoney(total)}</span>
      </div>
    </div>
  )
}

export default OrderTotal
