const FilterVocalsIcon = () => {
  return (
    <svg className="filterVocalsIcon" viewBox="0 0 120 182">
      <g fill="#21928e" fillRule="nonzero">
        <path d="M51 1.4C43.6 3.3 37.8 8 33.9 15.2l-3.4 6.3v66l2.7 5.1c3 5.7 9.3 11.9 14.7 14.2 5 2.2 19.3 2.1 24.4-.2 9.2-4.1 16.1-13.9 17.4-24.7l.6-4.9H64v-7.9l12.8-.3 12.7-.3.3-4.8.3-4.7H64v-9h26v-9H64v-9H90.3l-.7-5.7C87.4 7.5 70-3.8 51 1.4z" />
        <path d="M.9 76.4c-1.1 1.2 1.2 14.9 3.7 21.4 3.2 8.8 8.2 16 16.1 23.1 7.7 7.1 16.8 11.7 27.3 13.9l8 1.7V173H18v8h84v-8H64v-36.5l8-1.7c12.2-2.5 20.2-6.9 29.5-16.3 10.9-10.9 16.7-23.2 17.7-37.5l.3-4.5h-4c-3.5 0-4 .3-4.3 2.5-.6 6.2-2.6 14-5.1 19.5-3.2 7-12 17-19.1 21.5-10.4 6.7-24.6 9.2-37 6.6-10.4-2.2-17.1-5.8-25.2-13.4-9.3-8.8-14.6-20.1-16-34.2-.3-2.1-.9-2.5-3.8-2.8-2-.2-3.8-.1-4.1.2z" />
      </g>
    </svg>
  )
}

export default FilterVocalsIcon
