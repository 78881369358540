export default function Logo({ downloadLink, spanClass }) {
  return (
    <div className="logo">
      <span className={spanClass} />
      <div className="logo-overlay">
        <a href={downloadLink} target="_blank" className="box-button">
          Download .png
        </a>
      </div>
    </div>
  )
}
