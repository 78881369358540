import { useContext, useState, useRef } from "react"
import { MixtapePageContext } from "./MixtapePageContainer"
import MixtapeIcon from "../../icons/MixtapeIcon"

const MixtapeDetails = () => {
  const {
    user_role,
    is_owner,
    mixtapeDetails,
    mixtapeSerializer,
    setMixtapeDetails,
    setMixtapeSerializer,
  } = useContext(MixtapePageContext)
  const { updated_at, created_at } = mixtapeDetails
  const [isEditing, setIsEditing] = useState(false)
  const [newName, setNewName] = useState(mixtapeSerializer.name)
  const [newDescription, setNewDescription] = useState(mixtapeDetails.description)
  const labelRef = useRef(null)
  const descriptionRef = useRef(null)

  var updated_at_array = new Date(updated_at).toString().split(" ")
  var created_at_array = new Date(created_at).toString().split(" ")
  var readableUpdatedAt = `${updated_at_array[1]} ${updated_at_array[2]} ${updated_at_array[3]}`
  var readableCreatedAt = `${created_at_array[1]} ${created_at_array[2]} ${created_at_array[3]}`

  const handleEdit = (e) => {
    e.target.focus()
    setIsEditing(true)
  }

  const handleCancel = () => {
    setNewName(mixtapeSerializer.name)
    setNewDescription(mixtapeDetails.description)
    setIsEditing(false)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (newName.trim().length === 0 || newDescription.trim().length === 0) {
      return
    }

    const response = await fetch(`/mixtapes/${mixtapeSerializer.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": mm.csrf,
      },
      body: JSON.stringify({
        mixtape: {
          description: newDescription,
          name: newName,
        },
      }),
    })
    if (!response.ok) {
      console.log("ERROR: Unable to perform action at this time")
      setNewDescription(mixtapeDetails.description)
      setNewName(mixtapeSerializer.name)
      setIsEditing(false)
      return
    }
    // IF fetch succeeds
    setMixtapeDetails({ ...mixtapeDetails, description: newDescription })
    setMixtapeSerializer({ ...mixtapeSerializer, name: newName })
    setIsEditing(false)
  }

  return (
    <>
      <header id="mm-mixtape-landing">
        <div className="heading">
          <div className="contain">
            {/*  THIS WAS FOR Animating, which we won't do anymore, however it is now just used for spacing*/}
            <div id="mixtape-wrapper">
              <div id="mixtape-playing">
                <div id="mixtape-spools">
                  <div id="mixtape-spool-left"></div>
                  <div id="mixtape-spool-right"></div>
                </div>

                <div id="mixtape-reels">
                  <div id="mixtape-reel-left"></div>
                  <div id="mixtape-reel-right"></div>
                </div>

                <div id="mixtape-cassette">
                  <div className="label title">{mixtapeSerializer.name}</div>
                  <div className="label description">Marmoset Mixtape</div>
                </div>
              </div>
            </div>

            <div id="mixtape-default">
              <div className="mixtape-icon">
                <MixtapeIcon />
              </div>
              <hgroup>
                {is_owner ? (
                  <form onSubmit={handleSubmit}>
                    <div className="mixtape-form">
                      <label
                        ref={labelRef}
                        className="input-sizer mixtape-name"
                        data-value={mixtapeSerializer.name}
                        onClick={handleEdit}
                      >
                        <input
                          type="text"
                          style={{
                            color: !isEditing && "light-dark(rgb(84, 84, 84), rgb(170, 170, 170))",
                          }}
                          onInput={(e) => setNewName(e.target.value)}
                          size="1"
                          value={newName}
                          id="name"
                          name="name"
                          aria-label="mixtape name"
                        />
                        <span className="icon-pencil">
                          {/*Saving incase there's a request to add back <%= show_svg('pencil.svg') %>*/}
                        </span>
                      </label>
                      <label
                        className="input-sizer mixtape-description"
                        data-value={mixtapeDetails.description}
                        onClick={handleEdit}
                      >
                        <textarea
                          id="desc-input"
                          ref={descriptionRef}
                          rows="1"
                          onClick={handleEdit}
                          style={{
                            color: !isEditing && "light-dark(rgb(84, 84, 84), rgb(170, 170, 170))",
                          }}
                          onInput={(e) => setNewDescription(e.target.value)}
                          maxLength={1000}
                          aria-label="mixtape description"
                          placeholder="Click to add a description..."
                          value={newDescription}
                        />
                        {/**Add back pencil icon if there's a request for it */}
                        {/* <span className="icon-pencil"><%= show_svg('pencil.svg') %></span> */}
                      </label>
                    </div>
                    <div
                      className="buttons-control"
                      style={{ display: isEditing ? "inline-block" : "none" }}
                    >
                      <button id="cancel-button" onClick={handleCancel} type="button" name="Cancel">
                        Cancel
                      </button>
                      <button
                        id="save-button"
                        type="submit"
                        name="Save"
                        disabled={
                          newName.trim().length === 0 || newDescription.trim().length === 0
                            ? true
                            : false
                        }
                      >
                        Save
                      </button>
                    </div>
                  </form>
                ) : (
                  <>
                    <h1 className="hThree">{mixtapeSerializer.name}</h1>
                    <div className="mixtape-alternative-description">
                      <p>{mixtapeDetails.description != "" && mixtapeDetails.description}</p>
                    </div>
                  </>
                )}
              </hgroup>
            </div>

            <div className="info">
              <ul>
                <li>{`Song Count:  ${mixtapeDetails.song_version_count}`}</li>
                <li>{mixtapeDetails.runtime}</li>
                <li className="status">{mixtapeDetails.is_secret ? "Secret" : "Public"}</li>
                <li>
                  <em>Curated by </em>
                  {mixtapeDetails.owner_name}
                  <em> on </em>
                  <time dateTime={mixtapeDetails.created_at}>{readableCreatedAt}</time>
                </li>
                <li>
                  <em>Last updated </em>
                  <time dateTime={mixtapeDetails.updated_at}>{readableUpdatedAt}</time>
                </li>
              </ul>
            </div>

            {mixtapeDetails.collaborative_in_some_way && mixtapeDetails.collaborators != "" && (
              <div className="collaborators">
                <p>Collaborators:</p>
                <p>{mixtapeDetails.collaborators}</p>
              </div>
            )}
          </div>
        </div>
      </header>
    </>
  )
}

export default MixtapeDetails
