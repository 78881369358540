import { useState, useEffect, useRef } from "react"
import cx from "classnames"
import styles from "../../styles/SingleVideoPlayer.module.scss"
import spinnerStyles from "../../styles/spinners.module.scss"

const SingleVideoPlayer = ({ slice }) => {
  const { primary } = slice
  const [loading, setLoading] = useState(true)
  const iframeRef = useRef()

  useEffect(() => {
    iframeRef.current.onload = () => setLoading(false)
  })

  return (
    <article className={cx(styles.playerWrapper, "prismicPlayerWrapper")}>
      {loading && (
        <div className={styles.loading}>
          <div className={spinnerStyles.largeSpinner} />
        </div>
      )}
      <div className={styles.iframeWrapper}>
        <iframe
          src={`https://player.vimeo.com/video/${primary.video.video_id}?app_id=122963&playsinline=0`}
          className={styles.iframe}
          frameBorder="0"
          allow="autoplay; fullscreen"
          allowFullScreen
          ref={iframeRef}
        />
      </div>
    </article>
  )
}

export default SingleVideoPlayer
