import { useContext, useEffect, useRef, useState } from "react"
import { gql, useQuery } from "@apollo/client"
import cx from "classnames"
import Modal from "./Modal"
import SelectBlanketOrderModal from "./SelectBlanketOrderModal"
import PartialExclusivityTooltip from "./PartialExclusivityTooltip"
import StarIcon from "../../icons/StarIcon"
import FlashlightIcon from "../../icons/FlashlightIcon"
import DownloadIcon from "../../icons/DownloadIcon"
import BookIcon from "../../icons/BookIcon"
import CassetteIcon from "../../icons/CassetteIcon"
import CustomizeIcon from "../../icons/CustomizeIcon"
import ShowIcon from "../../icons/ShowIcon"
import HideIcon from "../../icons/HideIcon"
import PlusIcon from "../../icons/PlusIcon"
import { fetchDataForMessagePopUp } from "../utils/backbone/messageQueueService"
import { favoriteUnfavoriteSongVersion, hideUnhideSong } from "../utils/backbone/songVersionActions"
import { SongVersionItemContext } from "./SongVersionItem"
import { internalRoles } from "../utils/helpers"
import {
  addToMixtape,
  addToProjectSearch,
  customizeSongRequest,
  triggerLogin,
} from "../utils/backbone/drawerProxyService"
import TrashIcon from "../../icons/TrashIcon"
import ForwardIcon from "../../icons/ForwardIcon"

const GET_USER_ORDERS = gql`
  {
    account {
      orders {
        id
        allowClientToSelectTbd
        endClient
        projectName
        lineItems {
          id
          songVersionTitle
          songVersionId
          artistName
          clientNotes
        }
      }
    }
  }
`
// TODO: for some reason if a song is hidden,
// it'll just display any tooltip under the actions menu
// has to do with .hidden class applied to whole song version item
// issue existed before this ticket.
const SongVersionActionsMenu = ({
  expanded,
  shouldShowTooltip,
  sv,
  exclusive,
  exclusivityMessage,
  user_role,
  song,
  mixtape = null,
}) => {
  const { loading, error, data } = useQuery(GET_USER_ORDERS)
  const [openModal, setOpenModal] = useState(false)
  const [currentTooltip, setCurrentTooltip] = useState("")
  const [isFavorited, setIsFavorited] = useState(mm.user.favoriteSongVersions.hasOwnProperty(sv.id))
  const [optionsExpanded, setOptionsExpanded] = useState(false)
  const isLoading = useRef(false)
  const isInternal = internalRoles.includes(user_role)
  const blanketDealModalRef = useRef(null)
  let currentUserOrders = []
  let openBlanketOrder = false
  let openOrders = {}
  const { isHidden, setIsHidden, currentBlanketDealModalRef } = useContext(SongVersionItemContext)

  if (!loading) {
    currentUserOrders = data.account.orders
    currentUserOrders.map((deal) => {
      if (deal.allowClientToSelectTbd) {
        deal.lineItems.map((item) => {
          if (item.songVersionTitle === "TBD") {
            openBlanketOrder = true
            !openOrders[deal.projectName]
              ? (openOrders[deal.projectName] = [item.id])
              : openOrders[deal.projectName].push(item.id)
          }
        })
      }
    })
  }

  const removeFromMixtape = () => {
    mixtape.removeSongFromMixtape(sv.id)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  const handleHideUnhide = async () => {
    if (!loading.current) {
      isLoading.current = true
      setIsHidden(!isHidden)
      const request = isHidden ? "unhide" : "hide"
      const res = await hideUnhideSong(song.songId, request)
      if (!res.ok) setIsHidden(request !== "hide")
      isLoading.current = false
    }
  }

  const handleLyricsPopUp = () => {
    fetchDataForMessagePopUp(`/song_versions/lyrics/${sv.id}`)
  }

  const handleHover = (menuItem) => {
    setCurrentTooltip(menuItem)
  }

  const handleExpandDownloads = () => {
    setOptionsExpanded(!optionsExpanded)
  }

  const handleFavoriteUnfavorite = async () => {
    const request = isFavorited ? "remove" : "add"
    setIsFavorited(!isFavorited)
    const res = await favoriteUnfavoriteSongVersion(sv.id, request)
    if (!res.ok) {
      // undo if failed
      setIsFavorited(request == "remove" ? true : false)
      return
    }
  }
  // Checks backbone state on expanded to make sure the current song version is favorited
  useEffect(() => {
    setIsFavorited(mm.user.favoriteSongVersions.hasOwnProperty(sv.id))
    if (openModal) {
      currentBlanketDealModalRef.current = blanketDealModalRef.current
    } else {
      currentBlanketDealModalRef.current = null
    }
  }, [openModal])

  return (
    <li className={cx("actions-menu", { ["expanded"]: expanded })}>
      <ol>
        {shouldShowTooltip && (
          <PartialExclusivityTooltip
            tooltipType="project-search-tooltip"
            vintage={sv.vintage}
            exclusive={exclusive}
            action="project-search"
            cy="search-partial-exclusivity-tooltip"
            exclusivityMessage={exclusivityMessage}
            show={currentTooltip == "projectSearch"}
          />
        )}
        {isInternal && (
          <li
            className="action project-search"
            data-action="projectSearch"
            data-cy="add-to-search"
            onMouseEnter={() => handleHover("projectSearch")}
            onMouseLeave={() => setCurrentTooltip("")}
            onClick={() => addToProjectSearch(sv.id, song.songId)}
          >
            <a data-prevent-default>
              <span className="option-text">Add To Search</span>
              <span className="icon">
                <FlashlightIcon />
              </span>
            </a>
          </li>
        )}
        {shouldShowTooltip && (
          <PartialExclusivityTooltip
            tooltipType="download-tooltip"
            vintage={sv.vintage}
            exclusive={exclusive}
            action="download"
            cy="download-partial-exclusivity-tooltip"
            exclusivityMessage={exclusivityMessage}
            show={currentTooltip == "download"}
          />
        )}

        <li
          className={cx("action download", user_role === "guest" && "disabled")}
          data-action={user_role === "guest" ? "login" : "download"}
          data-cy={cx("download", user_role === "guest" && "disabled")}
          onMouseEnter={() => handleHover("download")}
          onMouseLeave={() => setCurrentTooltip("")}
        >
          {user_role != "guest" ? (
            <a id="downloadLink" onClick={handleExpandDownloads}>
              <span className="option-text">Download Options</span>
              <span data-cy="download-temp-song" className="icon">
                <ForwardIcon />
              </span>
            </a>
          ) : (
            <a itemProp="contentUrl" data-prevent-default onClick={triggerLogin}>
              <span className="option-text">Download Song</span>
              <span data-cy="download-temp-song" className="icon">
                <DownloadIcon />
              </span>
            </a>
          )}
        </li>
        {/* Expandable Sub-menu for downloads */}
        <li className={cx("download-sub", optionsExpanded && "open")}>
          <ol>
            <li
              className={cx("action download sub", user_role === "guest" && "disabled")}
              data-action={user_role === "guest" ? "login" : "download"}
              data-cy={cx("download-low", user_role === "guest" && "disabled")}
              onMouseEnter={() => handleHover("download")}
              onMouseLeave={() => setCurrentTooltip("")}
            >
              <a id="downloadLink" href={`/song_versions/${sv.id}/download/low`} target="_blank">
                <span className="option-text">Low Res (MP3)</span>
                <span data-cy="download-temp-song" className="icon">
                  <DownloadIcon />
                </span>
              </a>
            </li>
            <li
              className={cx("action download sub", user_role === "guest" && "disabled")}
              data-action={user_role === "guest" ? "login" : "download"}
              data-cy={cx("download-high", user_role === "guest" && "disabled")}
              onMouseEnter={() => handleHover("download")}
              onMouseLeave={() => setCurrentTooltip("")}
            >
              <a id="downloadLink" href={`/song_versions/${sv.id}/download/high`} target="_blank">
                <span className="option-text">Hi Res (WAV/AIFF)</span>
                <span data-cy="download-temp-song" className="icon">
                  <DownloadIcon />
                </span>
              </a>
            </li>
            <li
              className={cx("action download sub", !sv.hasStems && "disabled")}
              data-action={user_role === "guest" ? "login" : "download"}
              data-cy={cx("download-stems", user_role === "guest" && "disabled")}
              onMouseEnter={() => handleHover("download")}
              onMouseLeave={() => setCurrentTooltip("")}
            >
              <a id="downloadLink" href={`/song_versions/${sv.id}/download_stems`} target="_blank">
                <span className="option-text">Download Stems</span>
                <span data-cy="download-temp-song" className="icon">
                  <DownloadIcon />
                </span>
              </a>
            </li>
          </ol>
        </li>
        {mixtape && (mixtape.includes_current_user || mixtape.is_owner) ? (
          <li
            className={cx("action mixtape", user_role === "guest" && "disabled")}
            data-action={user_role === "guest" ? "login" : "remove"}
            onClick={() => (user_role === "guest" ? triggerLogin : removeFromMixtape(sv.id))}
          >
            <a data-prevent-default>
              <span className="option-text">Remove</span>
              <span className="icon">
                <TrashIcon />
              </span>
            </a>
          </li>
        ) : (
          <li
            className={cx("action mixtape", user_role === "guest" && "disabled")}
            data-action={user_role === "guest" ? "login" : "mixtape"}
            onClick={() => (user_role === "guest" ? triggerLogin : addToMixtape(sv.id))}
          >
            <a data-prevent-default>
              <span className="option-text">Add To Mixtape</span>
              <span data-cy="add-to-mixtape" className="icon">
                <CassetteIcon />
              </span>
            </a>
          </li>
        )}
        <li
          className={cx("action favorite", user_role === "guest" && "disabled")}
          data-action={user_role === "guest" ? "login" : "favorite"}
          onClick={() => (user_role !== "guest" ? handleFavoriteUnfavorite() : triggerLogin())}
        >
          <a data-prevent-default>
            <span data-cy="add-to-favorites-text" className="option-text favorite-text">
              {isFavorited ? "Remove Favorite" : "Add To Favorites"}
            </span>
            <span data-cy="add-to-favorites" className="icon">
              <span
                className={cx("favorite-icon", isFavorited ? "favorited selected" : "unfavorited")}
              >
                <StarIcon />
              </span>
            </span>
          </a>
        </li>
        {openBlanketOrder && (
          <li
            onClick={() => {
              setOpenModal(!openModal)
            }}
            className="action blanket-deal"
            data-action="blanket-deal"
          >
            <a data-prevent-default>
              <span data-cy="add-to-blanket-deal-text" className="option-text blanket-deal-text">
                Add To Blanket Deal
              </span>
              <span data-cy="add-to-blanket-deal" className="icon">
                <PlusIcon />
              </span>
            </a>
          </li>
        )}

        <li
          className={cx("action show-hide", user_role === "guest" && "disabled")}
          data-action={user_role === "guest" ? "login" : "hide"}
        >
          <a
            data-prevent-default
            onClick={() => (user_role !== "guest" ? handleHideUnhide() : triggerLogin())}
          >
            <span data-cy="hide-text" className="option-text">
              {isHidden ? "Show" : "Hide"}
            </span>
            {isHidden ? (
              <span className="icon hide-icon">
                <ShowIcon />
              </span>
            ) : (
              <span data-cy="hide" className="icon unhide-icon">
                <HideIcon />
              </span>
            )}
          </a>
        </li>

        {sv.lyrics && (
          <li className="action lyrics" data-action="lyrics">
            <a data-prevent-default onClick={handleLyricsPopUp}>
              <span className="option-text">Lyrics</span>
              <span className="icon">
                <BookIcon />
              </span>
            </a>
          </li>
        )}
        <li className="action customize" data-action="customize">
          <a data-prevent-default onClick={() => customizeSongRequest(sv.id)}>
            <span className="option-text">Customize</span>
            <span className="icon">
              <CustomizeIcon />
            </span>
          </a>
        </li>

        <span className="tri">&#9656;</span>
      </ol>
      {openModal && (
        <Modal>
          <SelectBlanketOrderModal
            handleCloseModal={handleCloseModal}
            artist={song.artist}
            sv={sv}
            currentUserOrders={currentUserOrders}
            openOrders={openOrders}
            ref={blanketDealModalRef}
          />
        </Modal>
      )}
    </li>
  )
}

export default SongVersionActionsMenu
