import { useState, useEffect } from "react"
import cx from "classnames"
import { gql, useMutation } from "@apollo/client"
import styles from "../styles/ConfirmSelection.module.scss"
import buttonStyles from "../../../shared/styles/buttons.module.scss"

const SET_TBD_SONG_VERSION = gql`
  mutation selectTbdSongVersion($lineItemId: ID!, $songVersionId: ID!) {
    selectTbdSongVersion(lineItemId: $lineItemId, songVersionId: $songVersionId) {
      lineItem {
        id
        songVersionTitle
        artistName
        license
        price
      }
    }
  }
`

const ConfirmSelection = ({
  selection,
  confirmCheckbox,
  selectRef,
  setOpenModal,
  handleSetError,
  handleShowConfirmation,
}) => {
  const { lineItemId, songVersion } = selection
  const { id, artist, displayName } = songVersion
  const [checked, setChecked] = useState(false)
  const [loading, setLoading] = useState(false)
  const [setTbdSongVersion] = useMutation(SET_TBD_SONG_VERSION)

  useEffect(() => {
    return () => {
      if (selectRef.current) selectRef.current.focus()
    }
  }, [])

  const handleChange = () => setChecked(!checked)

  const handleSelect = () => {
    const variables = {
      lineItemId,
      songVersionId: id,
    }

    setLoading(true)

    setTbdSongVersion({ variables })
      .then(() => setOpenModal(false))
      .catch((err) => handleSetError(err.graphQLErrors))
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.headerContainer}>
        <h2>Confirm Song Selection</h2>
      </div>
      <div className={styles.messageContainer}>
        <div>
          Are you sure you want to choose{" "}
          <a className={styles.link} href={`/browse/${id}`} target="_blank">
            {displayName}
          </a>{" "}
          <em>by </em>
          <a className={styles.link} href={`/artists/${artist.slug}`} target="_blank">
            {artist.name}
          </a>
          ?
        </div>
        <div>
          <label>
            <input
              type="checkbox"
              name="confirm"
              ref={confirmCheckbox}
              className={styles.checkbox}
              checked={checked}
              aria-label="confirm"
              onChange={handleChange}
            />
            Yes, I want to choose this song and{" "}
            <strong>understand this action cannot be undone.</strong>
          </label>
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <button
          className={styles.selectButton}
          disabled={!checked || loading}
          aria-label="select"
          onClick={handleSelect}
        >
          Select
          <span className={cx({ [buttonStyles.spinner]: loading })} />
        </button>
        <button
          className={styles.cancelButton}
          disabled={loading}
          aria-label="cancel"
          onClick={handleShowConfirmation}
        >
          Cancel
        </button>
      </div>
    </div>
  )
}

export default ConfirmSelection
