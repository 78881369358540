import { useState, useRef, useContext, useEffect, useCallback } from "react"
import StarIcon from "../../icons/StarIcon"
import Modal from "../../shared/components/Modal"
import SelectBlanketOrderModal from "../../shared/components/SelectBlanketOrderModal"
import FlashlightIcon from "../../icons/FlashlightIcon"
import DownloadIcon from "../../icons/DownloadIcon"
import BookIcon from "../../icons/BookIcon"
import CassetteIcon from "../../icons/CassetteIcon"
import CustomizeIcon from "../../icons/CustomizeIcon"
import PlayLargeIcon from "../../icons/PlayLargeIcon"
import PlusIcon from "../../icons/PlusIcon"
import ShareIcon from "../../icons/ShareIcon"
import cx from "classnames"
import GET_USER_ORDERS from "../../graphql/queries/GetUserOrders"
import { SongPageContext } from "./SongVersionPageContainer"
import { useQuery } from "@apollo/client"
import {
  addToCart,
  addToMixtape,
  addToProjectSearch,
  customizeSongRequest,
  shareSongVersion,
  triggerLogin,
} from "../../shared/utils/backbone/drawerProxyService"
import { favoriteUnfavoriteSongVersion } from "../../shared/utils/backbone/songVersionActions"
import { setExclusivityMessage } from "../../shared/utils/browse"
import { fetchDataForMessagePopUp } from "../../shared/utils/backbone/messageQueueService"
import { playSong } from "../../shared/utils/backbone/playerProxyService"
import SongActionsTooltip from "./SongActionsTooltip"

const SongVersionPageActions = () => {
  const { user_role, sv, shouldShowTooltip, svPlaySerializer, isInternal } =
    useContext(SongPageContext)
  const exclusivityMessage = setExclusivityMessage(sv.is_vintage, sv.song?.exclusive)
  const availableForClick = sv.is_available_for_click
  const display_name = sv.title + (sv.instrumental ? " (Instrumental" : "")
  const { loading, error, data } = useQuery(GET_USER_ORDERS)
  const [openModal, setOpenModal] = useState(false)
  const [currentTooltip, setCurrentTooltip] = useState("")
  const [actionHoverText, setActionHoverText] = useState("")
  const [expandedDownloads, setExpandedDownloads] = useState(false)
  const [isFavorited, setIsFavorited] = useState(mm.user.favoriteSongVersions.hasOwnProperty(sv.id))

  let currentUserOrders = []
  let openBlanketOrder = false
  let openOrders = {}

  if (!loading) {
    currentUserOrders = data.account.orders
    currentUserOrders.map((deal) => {
      if (deal.allowClientToSelectTbd) {
        deal.lineItems.map((item) => {
          if (item.songVersionTitle === "TBD") {
            openBlanketOrder = true
            !openOrders[deal.projectName]
              ? (openOrders[deal.projectName] = [item.id])
              : openOrders[deal.projectName].push(item.id)
          }
        })
      }
    })
  }

  const handlePlaySong = () => {
    const songData = JSON.parse(svPlaySerializer)
    playSong(songData)
  }

  const handleOpenModal = () => {
    if (!openModal) {
      setOpenModal(true)
    }
  }

  const toggleExpandDownloads = () => {
    setExpandedDownloads(!expandedDownloads)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  const handleLicenseClick = () => {
    const songVersionData = JSON.parse(svPlaySerializer)
    // put the song version in session storage regardless of if it's click or custom only
    sessionStorage.setItem("customRequestData", svPlaySerializer)
    if (availableForClick) {
      addToCart(songVersionData)
    } else {
      mm.router.navigate("/licensing/custom-request", { trigger: true })
    }
  }

  const handleFavoriteUnfavorite = async () => {
    const request = isFavorited ? "remove" : "add"
    setIsFavorited(!isFavorited)
    const res = await favoriteUnfavoriteSongVersion(sv.id, request)
    if (!res.ok) {
      setIsFavorited(request == "remove" ? true : false)
      return
    }
  }

  const handleHover = (hoverText, menuItem = "") => {
    setActionHoverText(hoverText)
    setCurrentTooltip(menuItem)
  }

  const handleLyricsPopUp = () => {
    fetchDataForMessagePopUp(`/song_versions/lyrics/${sv.id}`)
  }

  const handleOnClick = (e) => {
    if (
      // if not clicked in song download options menu
      !e.target.closest(["div.download-options-menu", ".download"])
    ) {
      setExpandedDownloads(false)
    }
  }

  useEffect(() => {
    document.body.addEventListener("click", handleOnClick)

    return () => {
      document.body.removeEventListener("click", handleOnClick)
    }
  }, [])

  useEffect(() => {
    setIsFavorited(mm.user.favoriteSongVersions.hasOwnProperty(sv.id))
  }, [sv])

  return (
    <>
      <div className="actions">
        <ol>
          <div className="mm-connect-song-version-actions">
            <div className="connect-label connected">{actionHoverText}</div>
          </div>

          <li
            className="action play mm-hover-connect"
            data-action="play"
            data-connect="song-version-actions"
            data-connect-text="Play"
            onMouseOver={() => handleHover("Play")}
            onMouseOut={() => handleHover("")}
            onClick={() => handlePlaySong()}
          >
            <span className="icon">
              <PlayLargeIcon />
            </span>
            <span className="label">Play this Song</span>
          </li>
          {shouldShowTooltip && (
            <SongActionsTooltip
              tooltipType="license-tooltip"
              vintage={sv.vintage}
              exclusive={sv.exclusive}
              action="license"
              cy="license-partial-exclusivity-tooltip"
              exclusivityMessage={exclusivityMessage}
              show={currentTooltip == "license"}
            />
          )}
          {!availableForClick ? (
            <li
              className="action license custom-only"
              data-action="license"
              data-cy="custom-license"
              onMouseEnter={() => handleHover("Custom License", "license")}
              onMouseLeave={() => handleHover("")}
              onClick={() => handleLicenseClick()}
            >
              <a data-prevent-default>
                <span className="label">Custom License</span>
                <span className="button-text">
                  Custom
                  <br />
                  License
                </span>
              </a>
            </li>
          ) : (
            <li
              className="action license"
              data-action="license"
              onMouseEnter={() => handleHover("Buy License", "license")}
              onMouseLeave={() => handleHover("")}
              onClick={() => handleLicenseClick()}
            >
              <a data-prevent-default data-cy="buy-license">
                <span className="label">Buy License</span>
                <span className="button-text">
                  Buy
                  <br />
                  License
                </span>
              </a>
            </li>
          )}
          <li
            className={cx("action favorite", user_role === "guest" && "disabled")}
            data-action={user_role === "guest" ? "login" : "favorite"}
            onClick={() => (user_role !== "guest" ? handleFavoriteUnfavorite() : triggerLogin())}
            onMouseEnter={() =>
              handleHover(isFavorited ? "Remove Favorite" : "Add Favorite", "favorite")
            }
            onMouseLeave={() => handleHover("")}
          >
            <a data-prevent-default>
              <span className="label">{isFavorited ? "Remove Favorite" : "Add To Favorites"}</span>
              <span
                data-cy="add-to-favorites"
                className={cx("icon", isFavorited ? "selected" : "")}
              >
                <span className={cx("favorite-icon", isFavorited ? "favorited selected" : "")}>
                  <StarIcon />
                </span>
              </span>
            </a>
          </li>

          <li
            className={cx("action mixtape", user_role === "guest" && "disabled")}
            data-action={user_role === "guest" ? "login" : "mixtape"}
            onClick={() => (user_role === "guest" ? triggerLogin() : addToMixtape(sv.id))}
            onMouseEnter={() => handleHover("Add To Mixtape", "mixtape")}
            onMouseLeave={() => handleHover("")}
          >
            <a data-prevent-default>
              <span className="label">Add To Mixtape</span>
              <span data-cy="add-to-mixtape" className="icon">
                <CassetteIcon />
              </span>
            </a>
          </li>

          {/** Current user has open blanket deal */}
          {openBlanketOrder && (
            <li
              onMouseEnter={() => handleHover("Add to Blanket Deal", "blanketDeal")}
              onMouseLeave={() => handleHover("")}
              className="action blanket-deal"
              data-action="blanket-deal"
              onClick={handleOpenModal}
            >
              <a data-prevent-default>
                <span className="label">Add to Blanket Deal</span>
                <span data-cy="add-to-blanket-deal" className="icon">
                  <PlusIcon />
                </span>
              </a>
              {openModal && (
                <Modal>
                  <SelectBlanketOrderModal
                    handleCloseModal={handleCloseModal}
                    artist={sv.artist}
                    sv={sv}
                    svid={sv.id}
                    currentUserOrders={currentUserOrders}
                    openOrders={openOrders}
                  />
                </Modal>
              )}
            </li>
          )}
        </ol>

        <ol className="break">
          {sv.lyrics && (
            <li
              className="action lyrics"
              data-action="lyrics"
              onMouseEnter={() => handleHover("Lyrics", "lyrics")}
              onMouseLeave={() => handleHover("")}
            >
              <a data-prevent-default onClick={handleLyricsPopUp}>
                <span className="label">Lyrics</span>
                <span className="icon">
                  <BookIcon />
                </span>
              </a>
            </li>
          )}

          {/* Clicking this opens download options menu */}
          <li
            className={cx("action download", user_role === "guest" && "disabled")}
            data-action={user_role === "guest" ? "login" : "download"}
            data-cy={cx("download", user_role === "guest" && "disabled")}
            onMouseEnter={() => handleHover("Download Song", "download")}
            onMouseLeave={() => handleHover("")}
          >
            {user_role != "guest" ? (
              <>
                <a id="downloadLink desktop" onClick={toggleExpandDownloads}>
                  <span className="label">Download Song</span>
                  <span data-cy="download-temp-song" className="icon">
                    <DownloadIcon />
                  </span>
                </a>
                {/* Mobile button behaves differently */}
                <a
                  id="downloadLink mobile"
                  href={`/song_versions/${sv.id}/download/low`}
                  target="_blank"
                >
                  <span className="label">Download Song</span>
                  <span data-cy="download-temp-song" className="icon">
                    <DownloadIcon />
                  </span>
                </a>
              </>
            ) : (
              <a itemProp="contentUrl" data-prevent-default onClick={triggerLogin}>
                <span className="label">Download Temp Song</span>
                <span data-cy="download-temp-song" className="icon">
                  <DownloadIcon />
                </span>
              </a>
            )}
          </li>

          <li
            className="action customize"
            data-action="customize"
            onMouseEnter={() => handleHover("Customize", "customize")}
            onMouseLeave={() => handleHover("")}
          >
            <a data-prevent-default onClick={() => customizeSongRequest(sv.id)}>
              <span className="label">Customize</span>
              <span className="icon">
                <CustomizeIcon />
              </span>
            </a>
          </li>

          {isInternal ? (
            <li
              className="action project-search"
              data-action="projectSearch"
              data-cy="add-to-search"
              onMouseEnter={() => handleHover("Add To Search", "projectSearch")}
              onMouseLeave={() => handleHover("")}
              onClick={() => addToProjectSearch(sv.id, sv.song_id)}
            >
              <a data-prevent-default>
                <span className="label">Add to Search</span>
                <span className="icon">
                  <FlashlightIcon />
                </span>
              </a>
            </li>
          ) : (
            <li
              className="action share half mm-hover-connect"
              data-action="share"
              data-connect="song-version-actions"
              data-connect-text="Share"
              onMouseEnter={() => handleHover("Share")}
              onMouseLeave={() => handleHover("")}
              onClick={() => shareSongVersion(sv.id, display_name, sv.artist.name)}
            >
              <a data-prevent-default className="mm-hover-connect" data-connect="right">
                <span className="icon">
                  <ShareIcon />
                </span>
                <span className="label">Share</span>
              </a>
            </li>
          )}

          {/**if song is exclusive, tooltip is visible for project-search button */}
          {/** else display share button */}

          {shouldShowTooltip && (
            <SongActionsTooltip
              tooltipType="download-tooltip"
              vintage={sv.vintage}
              exclusive={sv.exclusive}
              action="download"
              cy="download-partial-exclusivity-tooltip"
              exclusivityMessage={exclusivityMessage}
              show={currentTooltip == "download"}
              additionalClass={sv.lyrics && "nudge"}
            />
          )}
          {shouldShowTooltip && (
            <SongActionsTooltip
              tooltipType="project-search-tooltip"
              vintage={sv.vintage}
              exclusive={sv.exclusive}
              action="project-search"
              cy="search-partial-exclusivity-tooltip"
              exclusivityMessage={exclusivityMessage}
              show={currentTooltip == "projectSearch"}
              additionalClass={sv.lyrics && "de-nudge"}
            />
          )}
        </ol>
        {/**ONLY include if logged in */}
        {user_role != "guest" && (
          <div
            className={cx("download-options-menu", expandedDownloads && "expanded")}
            data-no-touch
          >
            <li className="action download mm-low-res desktop" data-no-touch>
              <a href={`/song_versions/${sv.id}/download/low`} target="_blank">
                <span className="label">Low Res (MP3)</span>
                <span className="icon">
                  <DownloadIcon />
                </span>
              </a>
            </li>

            <li className="action download mm-high-res desktop" data-no-touch>
              <a href={`/song_versions/${sv.id}/download/high`} target="_blank">
                <span className="label">Hi Res (WAV/AIFF)</span>
                <span className="icon">
                  <DownloadIcon />
                </span>
              </a>
            </li>

            <li
              className={cx(
                "action download mm-download-stems desktop",
                !sv.has_stems && "disabled"
              )}
              data-no-touch
            >
              <a href={`/song_versions/${sv.id}/download_stems`} target="_blank">
                <span className="label">Download Stems</span>
                <span className="icon">
                  <DownloadIcon />
                </span>
              </a>
            </li>
          </div>
        )}
      </div>
    </>
  )
}

export default SongVersionPageActions
