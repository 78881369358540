import SvgIcon from "../shared/components/SvgIcon"

const LeaveIcon = () => {
  return (
    <SvgIcon svgClass="LeaveIcon" fillRule="evenodd" fill="#21928e">
      <path
        className="icon-border"
        d="M15.0004823 28.9205441c-7.68931474 0-13.92199103-6.2326763-13.92199103-13.9210264 0-7.68835014 6.23267629-13.92102643 13.92199103-13.92102643 7.6883501 0 13.9200618 6.23267629 13.9200618 13.92102643 0 7.6883501-6.2317117 13.9210264-13.9200618 13.9210264m0-28.9205441C6.71500691 0 0 6.71500691 0 14.9995177 0 23.2840284 6.71500691 30 15.0004823 30 23.2849931 30 30 23.2840284 30 14.9995177 30 6.71500691 23.2849931 0 15.0004823 0"
      />
      <path d="M18.5793103 21.6830819h1.0852371v-5.4417026h-1.0852371z" />
      <path d="M21.0772629 11.9188578l2.5467673 2.5457974h-6.6481681v1.085237h6.7984913l-2.6970905 2.6961207.7671337.7671337 3.9307112-3.9297414-3.9307112-3.9316811z" />
      <path d="M15.0882543 24.2101293l-6.91778016-3.1179957V7.85786638l6.91778016 3.11799572v13.2342672zm.765194-14.0799569l-5.7025862-2.57004309h8.4307112v6.13221979h1.0842672V6.47586207H7.0862069V21.4422414c0 .2143319.12510776.4073276.31907327.4955819l8.00107763 3.6058189c.359806.1619612.7661638-.100862.7661638-.494612V10.6247845c0-.2143319-.1260776-.4073276-.3190733-.4946121z" />
    </SvgIcon>
  )
}

export default LeaveIcon
