import { PrismicPageParser } from "../../shared/utils/prismicPageParser"
import cx from "classnames"
import styles from "./PrismicDevTestContainer.module.scss"

const PrismicDevTestContainer = () => {
  return (
    <section className={cx(styles.prismicTest, "pageContainer")}>
      <PrismicPageParser pageId={"XmlsXRUAACUADDTy"} />
    </section>
  )
}

export default PrismicDevTestContainer
