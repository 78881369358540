import { useState, useEffect, useRef } from "react"
import { gql, useLazyQuery } from "@apollo/client"
import { Formik, Form } from "formik"
import cx from "classnames"
import ConfirmSelection from "./ConfirmSelection"
import SongVersionItem from "./SongVersionItem"
import TextField from "../../../shared/components/TextField"
import styles from "../styles/SongSearchModal.module.scss"
import tbdSvItemStyles from "../styles/SongVersionItem.module.scss"
import buttonStyles from "../../../shared/styles/buttons.module.scss"
import formStyles from "../../../shared/styles/form.module.scss"
import modalStyles from "../../../shared/styles/modal.module.scss"

const GET_SONG_VERSION_SEARCH = gql`
  query songVersionSearch($songVersionTitle: String, $artistName: String) {
    songVersionSearch(songVersionTitle: $songVersionTitle, artistName: $artistName) {
      results {
        id
        artist {
          name
          slug
        }
        displayName
        length
      }
    }
  }
`

const SongSearchModal = ({
  lineItemId,
  openModalButton,
  songInput,
  setOpenModal,
  handleCloseModal,
  handleSetError,
}) => {
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [selection, setSelection] = useState({})
  const [selectRef, setSelectRef] = useState(null)
  const confirmCheckbox = useRef()
  const [getTbdSVResults, { called, error, loading, data }] = useLazyQuery(GET_SONG_VERSION_SEARCH)

  useEffect(() => {
    if (showConfirmation) confirmCheckbox.current.focus()
  })

  useEffect(() => {
    return () => {
      if (openModalButton.current) openModalButton.current.focus()
    }
  }, [])

  const handleSubmit = (params) => {
    getTbdSVResults({
      variables: {
        songVersionTitle: params.songVersion,
        artistName: params.artist,
      },
    })
  }

  const handleShowConfirmation = (lineItemId, songVersion, selectButton) => {
    setSelection({
      lineItemId,
      songVersion,
    })
    setShowConfirmation(!showConfirmation)
    setSelectRef(selectButton)
  }

  const renderResults = () => {
    if (loading) return <div className={styles.noResults}>Loading ...</div>
    if (error) {
      return (
        <div className={styles.errorMessage}>
          <p>An error occurred. Please try again.</p>
        </div>
      )
    }
    if (called && data.songVersionSearch.results.length > 0) {
      return data.songVersionSearch.results.map((songVersion) => (
        <SongVersionItem
          key={songVersion.id}
          lineItemId={lineItemId}
          songVersion={songVersion}
          handleShowConfirmation={handleShowConfirmation}
        />
      ))
    }
    return <div className={styles.noResults}>No Results</div>
  }

  const initialValues = {
    artist: "",
    songVersion: "",
  }

  return (
    <div className={modalStyles.container}>
      <div className={styles.selectSongWrapper}>
        <Formik onSubmit={handleSubmit} initialValues={initialValues}>
          {() => (
            <Form id="selectTbdSong" className={styles.selectSongForm}>
              <div className={styles.selectSongFormContainer}>
                <TextField
                  label="Song"
                  fieldName="songVersion"
                  innerRef={songInput}
                  wrapperClass={styles.input}
                />
                <TextField label="Artist" fieldName="artist" wrapperClass={styles.input} />
                <button
                  type="submit"
                  aria-label="search"
                  className={cx(buttonStyles.black, formStyles.submitBtn, styles.submit)}
                  disabled={loading}
                >
                  Search
                </button>
              </div>
            </Form>
          )}
        </Formik>

        <div className={styles.resultsWrapper}>
          <ol className={styles.resultsHeader}>
            <li className={tbdSvItemStyles.songArtist}>Song / Artist</li>
            <li className={tbdSvItemStyles.length}>Length</li>
            <li className={tbdSvItemStyles.select}></li>
          </ol>

          <div className={styles.resultsScroll}>{renderResults()}</div>
        </div>

        <div className={styles.buttonWrapper}>
          <button
            className={styles.cancelSelectionButton}
            aria-label="cancel selection"
            onClick={handleCloseModal}
          >
            Cancel Selection
          </button>
        </div>
        {showConfirmation && (
          <ConfirmSelection
            selection={selection}
            confirmCheckbox={confirmCheckbox}
            selectRef={selectRef}
            setOpenModal={setOpenModal}
            handleSetError={handleSetError}
            handleShowConfirmation={handleShowConfirmation}
          />
        )}
      </div>
    </div>
  )
}

export default SongSearchModal
