import ArcSvgIcon from "../shared/components/ArcSvgIcon"

const ArcMultipleCrescendosIcon = () => {
  return (
    <ArcSvgIcon svgClass="ArcMultipleCrescendosIcon" fill="none" fillRule="nonzero">
      <path fill="#FFF" d="M0 0h200v40H0z" />
      <path fill="#21928E" d="M51.9992939 0L0 40h108.3z" />
      <path fill="#21928E" d="M147.427184 0L200 40H91.7z" />
    </ArcSvgIcon>
  )
}

export default ArcMultipleCrescendosIcon
