const FilterHelpIcon = () => {
  return (
    <svg className="filterHelpIcon" viewBox="0 0 30 30">
      <g fill="#21928e" fillRule="evenodd">
        <path
          className="icon-border"
          d="M15.0004823 28.9205441c-7.68931474 0-13.92199103-6.2326763-13.92199103-13.9210264 0-7.68835014 6.23267629-13.92102643 13.92199103-13.92102643 7.6883501 0 13.9210264 6.23267629 13.9210264 13.92102643 0 7.6883501-6.2326763 13.9210264-13.9210264 13.9210264m0-28.9205441C6.71500691 0 0 6.71500691 0 14.9995177 0 23.2840284 6.71500691 30 15.0004823 30 23.2840284 30 30 23.2840284 30 14.9995177 30 6.71500691 23.2840284 0 15.0004823 0"
        />
        <path d="M14.2965517 22.6144397h1.0842673v-2.6282328h-1.0842673zM17.8199353 8.63330279c-1.0765086-1.04838362-2.7126077-1.45668103-4.1314655-1.02607758-1.6613146.50431034-2.8057112 2.04245689-2.8057112 3.76971979v.2327587h1.0823276l.0019397-.2890087c.0242457-1.2268319.8505388-2.31497842 2.0356681-2.67478445 1.040625-.31519397 2.2645474-.00969828 3.059806.76422414 1.040625 1.01443961 1.2578664 2.55161641.5237069 3.70765091-.3413793.5382543-.6846983.9688577-1.0803879 1.3606681-.1076509.1057112-.2133621.2046336-.3404095.3171336l-.5925647.5159483-.0455819.0417025c-.7894396.7264009-1.1123922 1.4450431-1.2287715 2.784375l-.0252155.309375 1.0803879.0863147.0252155-.303556c.090194-1.046444.2899785-1.5168104.8311423-2.030819l.1328663-.1212285.3927802-.3394396.0659483-.0572198c.1803879-.1580819.3229526-.2890087.466487-.4315733.4538794-.4490302.849569-.9436423 1.2336207-1.5497845 1.0008621-1.5779095.7273707-3.650431-.6342672-5.01885776l-.0475216-.04752155z" />
      </g>
    </svg>
  )
}

export default FilterHelpIcon
