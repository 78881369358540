import { useContext } from "react"
import { PrismicPageContext } from "../../utils/prismicPageParser"
import styles from "../../styles/HorizontalRule.module.scss"
import cx from "classnames"

const HorizontalRule = ({ title }) => {
  const colorTheme = useContext(PrismicPageContext)

  return (
    <div
      style={title === "Music Licensing" ? {} : { borderColor: `var(--${colorTheme})` }}
      className={cx(styles.hr, "horizontalRule")}
    />
  )
}

export default HorizontalRule
