import { Component } from "react"
import styles from "../styles/ErrorBoundary.module.scss"

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { error: null }
  }

  static getDerivedStateFromError(error) {
    return { error }
  }

  render() {
    const { error } = this.state

    if (error) {
      return (
        <div className={styles.wrapper}>
          <h3>An error occurred.</h3>
          <h3>Please reload the page and try again.</h3>
        </div>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
