import { useContext } from "react"
import cx from "classnames"
import SecretIcon from "../../../icons/SecretIcon"
import { ProjectSearchContext } from "./ProjectSearch"

const SearchRoundSelector = () => {
  const { selectedRound, searchRounds, setSelectedRoundId } = useContext(ProjectSearchContext)
  const allRoundsListClass = cx("action", "round-selector", "all-selector", {
    selected: selectedRound.length > 1,
  })

  return (
    <div className="round-list">
      {searchRounds.map((round, index) => {
        return <RoundSelectorButton key={round.id} index={index} round={round} />
      })}

      <button
        className={allRoundsListClass}
        data-cy="allRounds"
        onClick={() => setSelectedRoundId("all")}
      >
        ALL
      </button>
    </div>
  )
}

const RoundSelectorButton = ({ round, index }) => {
  const { selectedRound, setSelectedRoundId } = useContext(ProjectSearchContext)
  return (
    <button
      key={round.id}
      onClick={() => setSelectedRoundId(round.id)}
      className={cx("action", "round-selector", {
        selected: selectedRound.length === 1 && selectedRound[0].id === round.id,
        hidden: round.hidden,
      })}
    >
      {index + 1}
      {round.hidden && (
        <span className="icon">
          <SecretIcon />
        </span>
      )}
    </button>
  )
}

export default SearchRoundSelector
