import cx from "classnames"
import { PrismicRichText } from "@prismicio/react"
import htmlSerializer from "../../../shared/utils/prismicHTMLSerializer"
import ctaStyles from "../../../shared/styles/CallToAction.module.scss"
import LazyLoad from "../../../shared/components/LazyLoad"
import EmailSignUpForm from "./EmailSignUpForm"
import styles from "../styles/EmailSignUpContainer.module.scss"

const EmailSignUpContainer = ({ slice }) => {
  const { primary } = slice
  const bgImg = `background: url( ${primary.background_image.url} ) center center / cover no-repeat;`

  return (
    <article className={styles.emailSignUpContainer}>
      <LazyLoad>
        <div className={cx("lazy", ctaStyles.contain, styles.contain)} data-style={bgImg}>
          <div className={cx(ctaStyles.text, styles.text)}>
            <PrismicRichText field={primary.rich_text} components={htmlSerializer} />
          </div>
          <EmailSignUpForm primary={primary} />
        </div>
      </LazyLoad>
    </article>
  )
}

export default EmailSignUpContainer
