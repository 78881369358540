import { PrismicPageParser } from "../../shared/utils/prismicPageParser"
import cx from "classnames"
import styles from "./PodcastLicenseContainer.module.scss"

const PodcastLicenseContainer = () => {
  const data = JSON.parse(window.prismic_data)
  const pageId = window.pageId

  return (
    <section
      id="mm-podcast-license"
      className={cx(styles.podcast, "pageContainer")}
      aria-label="Podcast License"
    >
      <PrismicPageParser pageData={data} pageId={pageId} />
    </section>
  )
}

export default PodcastLicenseContainer
