import ArcSvgIcon from "../shared/components/ArcSvgIcon"

const ArcMissingIcon = () => {
  return (
    <ArcSvgIcon svgClass="ArcMissingIcon" fill="none" fillRule="nonzero">
      <path fill="#FFF" d="M0 0h200v40H0z" />
    </ArcSvgIcon>
  )
}

export default ArcMissingIcon
