const DiscoverIcon = () => {
  return (
    <svg className="discoverIcon" height="48px" width="48px" viewBox="0 0 48 48">
      <path
        fill="#fff"
        d="M45 35c0 2.2-1.8 4-4 4H7c-2.2 0-4-1.8-4-4V13c0-2.2 1.8-4 4-4h34c2.2 0 4 1.8 4 4z"
      />
      <path
        fill="#ff6d00"
        d="M45 35c0 2.2-1.8 4-4 4H16s23.602-3.8 29-15zM22 24c0 1.7 1.3 3 3 3s3-1.3 3-3-1.3-3-3-3-3 1.3-3 3z"
      />
      <path d="M11.2 21h1.1v6h-1.1zm6 3c0 1.7 1.3 3 3 3 .5 0 .902-.102 1.402-.3v-1.302c-.403.403-.801.602-1.403.602-1.097 0-1.898-.8-1.898-2 0-1.102.8-2 1.898-2 .5 0 .903.2 1.403.602V21.3c-.5-.2-.903-.403-1.403-.403-1.699.102-3 1.5-3 3.102zm13.402.898L29 21h-1.2l2.5 6h.598l2.5-6H32.2zM33.898 27h3.204v-1H35v-1.602h2v-1h-2V22h2.102v-1h-3.204zm7.602-4.2c0-1.1-.7-1.8-2-1.8h-1.7v6h1.098v-2.398H39L40.602 27H42l-1.8-2.5c.8-.2 1.3-.8 1.3-1.7zm-2.3 1h-.302V22h.301c.7 0 1.102.3 1.102.898 0 .5-.301.903-1.102.903zM7.7 21H6v6h1.602c2.5 0 3.097-2.102 3.097-3 .102-1.8-1.199-3-3-3zm-.302 5h-.296v-4H7.5c1.5 0 2.102 1 2.102 2 0 .398-.102 2-2.204 2zm7.903-2.7c-.7-.3-.903-.402-.903-.698 0-.403.403-.602.801-.602.301 0 .602.102.903.5l.597-.8c-.5-.5-1-.7-1.699-.7-1 0-1.8.7-1.8 1.7 0 .8.402 1.198 1.402 1.6.597.2 1.097.4 1.097.9s-.398.8-.898.8-1-.3-1.2-.8l-.703.698c.5.801 1.102 1.102 2 1.102 1.204 0 2-.8 2-1.898 0-.903-.398-1.301-1.597-1.801z" />
    </svg>
  )
}

export default DiscoverIcon
