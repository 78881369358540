import { useState, forwardRef, useEffect } from "react"
import cx from "classnames"
import { gql, useMutation, useQuery } from "@apollo/client"
import modalStyles from "../styles/modal.module.scss"
import buttonStyles from "../styles/buttons.module.scss"
import selectionStyles from "../../pages/order_history/styles/ConfirmSelection.module.scss"
import songSearchStyles from "../../pages/order_history/styles/SongSearchModal.module.scss"
import noteStyles from "../../pages/order_history/styles/ClientNoteModal.module.scss"
import LoadingIcon from "../../icons/LoadingIcon"

const SELECT_TBD_SONG_VERSION = gql`
  mutation selectTbdSongVersion($lineItemId: ID!, $songVersionId: ID!) {
    selectTbdSongVersion(lineItemId: $lineItemId, songVersionId: $songVersionId) {
      lineItem {
        id
        songVersionTitle
        artistName
        license
        price
      }
    }
  }
`

const ADD_CLIENT_NOTE = gql`
  mutation addClientNote($id: ID!, $clientNotes: String!) {
    addClientNote(id: $id, clientNotes: $clientNotes) {
      lineItem {
        id
        clientNotes
      }
    }
  }
`

const GET_SV_ARTIST_AND_ORDERS = gql`
  query getSvArtistAndOrders($id: ID!) {
    songVersionById(id: $id) {
      displayName
      artist {
        name
        slug
      }
    }
    account {
      orders {
        id
        allowClientToSelectTbd
        endClient
        projectName
        lineItems {
          id
          songVersionTitle
          songVersionId
          artistName
          clientNotes
        }
      }
    }
  }
`

const SelectBlanketOrderModal = forwardRef(function SelectBlanketOrderModal(props, ref) {
  //const SelectBlanketOrderModal = (props) => {
  const [addClientNote] = useMutation(ADD_CLIENT_NOTE)
  const [setTbdSongVersion] = useMutation(SELECT_TBD_SONG_VERSION)

  let id = "",
    displayName = "",
    artist = props.artist || {},
    openOrders = props.openOrders || {},
    orders = {}

  if (!props.sv) {
    id = props.svid
    const variables = { id: id }
    var { loading: loadingData, error, data } = useQuery(GET_SV_ARTIST_AND_ORDERS, { variables })

    if (!loadingData) {
      const currentUserOrders = data.account.orders
      currentUserOrders.map((deal) => {
        if (deal.allowClientToSelectTbd) {
          deal.lineItems.map((item) => {
            if (item.songVersionTitle === "TBD") {
              !openOrders[deal.projectName]
                ? (openOrders[deal.projectName] = [item.id])
                : openOrders[deal.projectName].push(item.id)
            }
          })
        }
      })
      orders = Object.keys(openOrders)
      artist = data.songVersionById.artist
      displayName = data.songVersionById.displayName
    }
  } else {
    id = props.sv.id
    displayName = props.sv.displayName
    orders = Object.keys(props.openOrders)
  }

  const [checked, setChecked] = useState(false)
  const [loading, setLoading] = useState(false)
  const [noteText, setNoteText] = useState("")
  const [lineItemId, setLineItemId] = useState("")

  const handleChange = () => setChecked(!checked)

  const handleBlanketOrderChange = (e) => {
    let checked = e.target.checked
    if (checked) {
      let id = e.target.id
      let order = orders[id[id.length - 1]]
      setLineItemId(openOrders[order][0])
    }
  }

  const handleTextChange = (e) => {
    setNoteText(e.target.value)
  }

  const handleSelect = () => {
    const variables = {
      lineItemId,
      songVersionId: id,
    }
    setLoading(true)

    setTbdSongVersion({ variables })
      .then(async () => {
        if (noteText.length > 0) {
          const variables = {
            id: lineItemId,
            clientNotes: noteText,
          }

          await addClientNote({ variables })
            .then(() => console.log("success logging notes"))
            .catch((err) => console.log(err.graphQLErrors))
        }
        console.log("tbd added")
        if (props.handleCloseModal) {
          props.handleCloseModal()
        } else {
          const allModal = document.querySelectorAll(".blanket-deal-modal")
          allModal.forEach((el) => {
            if (!el.classList.contains("hidden")) {
              el.classList.add("hidden")
            }
          })
        }
      })
      .catch((err) => console.log(err.graphQLErrors))
  }

  return !loadingData ? (
    <div className={modalStyles.container}>
      <div className={songSearchStyles.selectSongWrapper} ref={ref}>
        <div className={selectionStyles.wrapper}>
          <div className={selectionStyles.headerContainer}>
            <h2>Confirm Song Selection</h2>
          </div>
          <div className={selectionStyles.messageContainer}>
            <div className={selectionStyles.chooseSongPrompt}>
              Are you sure you want to choose{" "}
              <a className={selectionStyles.link} href={`/browse/${id}`} target="_blank">
                {displayName}
              </a>{" "}
              <em>by </em>
              <a className={selectionStyles.link} href={`/artists/${artist.slug}`} target="_blank">
                {artist.name}
              </a>
              ?
            </div>
            <div className={selectionStyles.blanketDealPrompt}>
              <div>Which Blanket Deal would you like to add to?</div>
              <ul>
                {orders.map((order, index) => (
                  <li key={index}>
                    <label>
                      <input
                        type="radio"
                        id={`blanket-order${index}`}
                        name={"blanket-order"}
                        className={selectionStyles.checkbox}
                        aria-label={"blanket-order"}
                        onChange={handleBlanketOrderChange}
                      />
                      {order}
                    </label>
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <div style={{ fontSize: "1.1rem" }}>
                <label>
                  <input
                    type="checkbox"
                    name="confirm"
                    className={selectionStyles.checkbox}
                    checked={checked}
                    aria-label="confirm"
                    onChange={handleChange}
                  />
                  Yes, I want to choose this song and{" "}
                  <strong>understand this action cannot be undone.</strong>
                </label>
              </div>
              <div
                style={{ minHeight: "79px", minWidth: "530px", width: "100%" }}
                className={noteStyles.addNoteWrapper}
              >
                <div id="addClientNote" className={noteStyles.addNoteFormContainer}>
                  <div className={cx(noteStyles.inputWrapper, noteStyles.inputAddOn)}>
                    <label className={noteStyles.label}>Start/Air Date + Project Title/Notes</label>
                    <input
                      className={noteStyles.input}
                      value={noteText}
                      onChange={(e) => handleTextChange(e)}
                      disabled={lineItemId === ""}
                    />
                    <label
                      className={
                        noteText.length === 0
                          ? cx(noteStyles.required, noteStyles.warning)
                          : noteStyles.required
                      }
                    >
                      Start/Air Date is required
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={selectionStyles.buttonContainer}>
            <button
              className={cx(selectionStyles.cancelButton, "blanket-order-cancel-button")}
              disabled={loading}
              aria-label="cancel"
              onClick={props.handleCloseModal || null}
            >
              Cancel
            </button>
            <button
              className={selectionStyles.selectButton}
              disabled={!checked || loading || lineItemId === "" || noteText.length === 0}
              aria-label="select"
              onClick={handleSelect}
            >
              Confirm
              <span className={cx({ [buttonStyles.spinner]: loading })} />
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className={modalStyles.container} ref={ref}>
      <LoadingIcon />
    </div>
  )
})

export default SelectBlanketOrderModal
