import Header from "./components/Header"
import Section from "./components/Section"
import ContentBox from "./components/ContentBox"
import Buttons from "./components/Buttons"
import Description from "./components/Description"
import Fonts from "./components/Fonts"
import Logo from "./components/Logo"
import Swatches from "./components/Swatches"
import {
  logos,
  fonts,
  swatches,
  buttons,
  buttonsWithDisabled,
  colorsDescription,
  typefaceDescription,
  ctaButtonDescription,
  primaryButtonDescription,
  actionButtonDescription,
  inputsDescription,
} from "./data/styleGuideProps"

export default function StyleGuideContainer() {
  const { primaryWhite, primaryPurple, sealWhite, sealPurple } = logos

  return (
    <section id="mm-style-guide">
      <Header />

      <article className="style-guide">
        <div className="contain">
          <Section name="logos">
            <ContentBox>
              <Logo spanClass={primaryWhite.spanClass} downloadLink={primaryWhite.downloadLink} />
              <Logo spanClass={primaryPurple.spanClass} downloadLink={primaryPurple.downloadLink} />
            </ContentBox>
            <p className="caption">Primary</p>

            <ContentBox>
              <Logo spanClass={sealWhite.spanClass} downloadLink={sealWhite.downloadLink} />
              <Logo spanClass={sealPurple.spanClass} downloadLink={sealPurple.downloadLink} />
            </ContentBox>
            <p className="caption">Seal</p>
          </Section>

          <Section name="colors">
            <ContentBox>
              <Description description={colorsDescription} />
              <Swatches swatches={swatches} />
            </ContentBox>
          </Section>

          <Section name="typeface">
            <ContentBox>
              <Description description={typefaceDescription} />
              <Fonts fonts={fonts} />
            </ContentBox>
          </Section>

          <Section name="buttons">
            <ContentBox flip={true}>
              <Description description={ctaButtonDescription} />
              <Buttons wrapperClass="cta-button" buttons={buttons} />
            </ContentBox>

            <ContentBox>
              <Description description={primaryButtonDescription} />
              <Buttons wrapperClass="primary-button" buttons={buttonsWithDisabled} />
            </ContentBox>

            <ContentBox flip={true}>
              <Description description={actionButtonDescription} />
              <Buttons wrapperClass="action-button" buttons={buttonsWithDisabled} />
            </ContentBox>
          </Section>

          <Section name="inputs">
            <ContentBox>
              <Description description={inputsDescription} />
              <div className="inputs">
                <div className="input-wrapper">
                  <div className="label">Label</div>
                  <div className="input">Text Input</div>
                </div>
                <div className="input-wrapper">
                  <div className="label">Label</div>
                  <div className="input select">
                    <div className="selected-option">Select Input</div>
                    <div className="list-toggle">
                      <span />
                    </div>
                  </div>
                </div>
              </div>
            </ContentBox>
          </Section>
        </div>
      </article>
    </section>
  )
}
