import { useState, createContext } from "react"
import NameDescription from "./NameDescription"
import ProjectSearchActions from "./ProjectSearchActions"
import ProjectSearchHeader from "./ProjectSearchHeader"
import SearchRoundSelector from "./SearchRoundSelector"
import SelectedSearchRound from "./SelectedSearchRound"

const ProjectSearchContext = createContext()

const ProjectSearch = ({ projectSearch }) => {
  const [openNote, setOpenNote] = useState(null)
  const [openMenu, setOpenMenu] = useState(false)
  const [downloadMenu, setDownloadMenu] = useState(null)
  const [internalUser] = useState(
    JSON.parse(
      document.getElementById("mm-project-search-container").getAttribute("data-internal-user")
    )
  )
  const [selectedRoundId, setSelectedRoundId] = useState(null)
  const { id, searchRounds } = projectSearch

  const handleCloseNoteOrMenu = (e) => {
    if (!e.target.parentElement.closest([".notes", ".notes-field"])) setOpenNote(null)
    if (!e.target.parentElement.closest([".dropdown-menu", ".dropdown-panel"])) setOpenMenu(false)
    if (!e.target.parentElement.closest([".download", ".download-menu"])) setDownloadMenu(null)
  }

  // Generates object used to optimistically render UI while mutation is sent to DB
  const calculateUpdatedSearchRound = (updatedSort) => {
    // Make a copy of deep frozen cache object - see https://github.com/apollographql/apollo-client/issues/5987
    let updatedSelectedRound = JSON.parse(JSON.stringify(selectedRound()[0]))

    // If the selected round has directions,
    // iterate through each direction (dir) in the round.
    // If the same direction is found in the updatedSort object,
    // replace existing SRSVs in the direction with the ones from updatedSort.
    // Update the counts for each direction.
    if (updatedSelectedRound.directions.length) {
      _.mapObject(updatedSelectedRound.directions, (dir) => {
        if (_.has(updatedSort, dir.id)) {
          dir.searchRoundSongVersions = [...updatedSort[dir.id]]
        }
      })
      updatedSelectedRound.directions.map((dir) => {
        dir.songVersionsCount = dir.searchRoundSongVersions.length
      })
    } else {
      // If the selected round has no directions,
      // replace existing SRSV's with those in the "songVersions" key (instead of a key with the direction ID)
      updatedSelectedRound.searchRoundSongVersions = updatedSort.songVersions
    }
    return updatedSelectedRound
  }

  const songCount = () => {
    return selectedRound().reduce((count, round) => {
      count += round.searchRoundSongVersions.length
      return count
    }, 0)
  }

  const selectedRound = () => {
    let currentRound
    const arrEnd = searchRounds.length - 1
    const lastRound = [searchRounds[arrEnd]]

    if (selectedRoundId === "all") {
      currentRound = searchRounds
    } else if (selectedRoundId) {
      currentRound = searchRounds.filter((round) => selectedRoundId === round.id)
      if (currentRound.length < 1) currentRound = lastRound
    } else {
      currentRound = lastRound
    }
    return currentRound
  }

  const contextValue = {
    ...projectSearch,
    openMenu,
    internalUser,
    setOpenMenu,
    downloadMenu,
    setDownloadMenu,
    openNote,
    setOpenNote,
    setSelectedRoundId,
    selectedRound: selectedRound(),
    songCount: songCount(),
    calculateUpdatedSearchRound,
  }

  return (
    <section
      id="mm-project-search"
      className={`${internalUser ? "internal-user" : "client-user"}`}
      onClick={handleCloseNoteOrMenu}
    >
      <ProjectSearchContext.Provider value={contextValue}>
        <ProjectSearchHeader key={`header-${id}`}>
          <NameDescription key={`desc-${id}`} />
        </ProjectSearchHeader>
        <ProjectSearchActions key={`actions-${id}`} selectedRound={selectedRound()}>
          <SearchRoundSelector key={`selector-${id}`} />
        </ProjectSearchActions>
        <SelectedSearchRound />
      </ProjectSearchContext.Provider>
    </section>
  )
}

export { ProjectSearch, ProjectSearchContext }
