import SvgIcon from "../shared/components/SvgIcon"

const PlayIcon = () => {
  return (
    <SvgIcon svgClass="PlayIcon">
      <path d="M15.0004823 28.9205441c-7.68835008 0-13.92102637-6.2326763-13.92102637-13.9210264 0-7.68835014 6.23267629-13.92102643 13.92102637-13.92102643 7.6883501 0 13.9210264 6.23267629 13.9210264 13.92102643 0 7.6883501-6.2326763 13.9210264-13.9210264 13.9210264m0-28.9205441C6.71597157 0 0 6.71500691 0 14.9995177 0 23.2840284 6.71597157 30 15.0004823 30 23.2840284 30 30 23.2840284 30 14.9995177 30 6.71500691 23.2840284 0 15.0004823 0" />
      <path d="M13.6852371 9.35301724l5.6424569 5.64245686-5.6424569 5.6424569V9.35301724zM12.6 23.2564655l8.2619612-8.2609914L12.6 6.73448276V23.2564655z" />
    </SvgIcon>
  )
}

export default PlayIcon
