import SvgIcon from "../shared/components/SvgIcon"

const ShowIcon = () => {
  return (
    <SvgIcon svgClass="ShowIcon" fillRule="evenodd" fill="#21928e">
      <path
        className="icon-border"
        d="M15.0004823 28.9205441c-7.68931474 0-13.92199103-6.2326763-13.92199103-13.9210264 0-7.68835014 6.23267629-13.92102643 13.92199103-13.92102643 7.6883501 0 13.9210264 6.23267629 13.9210264 13.92102643 0 7.6883501-6.2326763 13.9210264-13.9210264 13.9210264m0-28.9205441C6.71597157 0 0 6.71500691 0 14.9995177 0 23.2840284 6.71597157 30 15.0004823 30 23.2849931 30 30 23.2840284 30 14.9995177 30 6.71500691 23.2849931 0 15.0004823 0"
      />
      <path d="M14.9951509 18.5292026c-1.952263 0-3.5350216-1.5827586-3.5350216-3.5350216 0-1.9512931 1.5827586-3.5340517 3.5350216-3.5340517 1.9522629 0 3.5350215 1.5827586 3.5350215 3.5340517 0 1.952263-1.5827586 3.5350216-3.5350215 3.5350216m0-8.1533405c-2.5516164 0-4.6192888 2.0676724-4.6192888 4.6183189 0 2.5506466 2.0676724 4.6192888 4.6192888 4.6192888 2.5516163 0 4.6192888-2.0686422 4.6192888-4.6192888 0-2.5506465-2.0676725-4.6183189-4.6192888-4.6183189" />
      <path d="M24.1273707 15.2078664l-.0581897.0804957c-.078556.1076508-.1609913.2201508-.2482758.3326508-.5605604.7264009-1.192888 1.4149785-1.8834052 2.036638-2.1278017 1.9173491-4.4573276 2.9279094-6.9391164 2.9279094-2.4546336 0-4.7793103-1.0105603-6.91681032-2.9288793C7.38717672 17.0340517 6.75 16.3454741 6.18265086 15.6190733c-.03685345-.0484914-.07273707-.096013-.10959052-.1445043l-.20366379-.2744612-.14644396-.2104526.05625-.0824354.06109913-.0853448c.10280173-.1474138.21530173-.2996767.33556035-.455819.55959052-.7264008 1.19288793-1.4140086 1.88340517-2.0376077 2.12683186-1.9173492 4.45732756-2.92693968 6.93911636-2.92693968 2.4546336 0 4.7783405 1.01056038 6.9158405 2.92790948.6953664.6235991 1.333513 1.3121767 1.8998923 2.0395474.072737.0940733.1445043.1891164.213362.28125l.1920259.2647629.0543104.0804957-.146444.2123923zm-1.4886854-3.6853448c-2.3324353-2.09288798-4.9024784-3.20528022-7.6403017-3.20528022-2.7659483 0-5.34374998 1.11239224-7.66551722 3.20625002-.74094828.6672414-1.4169181 1.4023707-2.01627155 2.1801724-.07952586.1037715-.15517242.2046336-.22790949.3045259l-.20172413.2822198-.14159483.2104526-.08243535.1251077-.225.3695043.19493535.3171337.11443965.1726293.14353449.2104526c.1338362.1900862.27931034.3869612.43642241.5896551.60517241.7768319 1.28696121 1.5119612 2.02984914 2.1782328 2.33243534 2.0938577 4.90247843 3.20625 7.64127153 3.20625 2.7649785 0 5.34375-1.1123923 7.6655173-3.2072198.7409482-.6662716 1.4169181-1.4023707 2.0153017-2.1801725.1561422-.2017241.3006465-.3995689.4325431-.5877155l.1396552-.2075431.078556-.1212284c.005819-.0096983.0116379-.0174569.0174569-.0261854l.0290948-.0465517.1813578-.3025862-.1716595-.2802802-.134806-.2085129-.1445043-.2094828c-.1348061-.1920258-.2802802-.3889008-.4383621-.590625-.6051724-.7768319-1.2869612-1.5119612-2.0298492-2.1792025z" />
    </SvgIcon>
  )
}

export default ShowIcon
