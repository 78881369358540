import SvgIcon from "../shared/components/SvgIcon"

const PinwheelIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 43 43" width="43px">
      <g fill="none" fillRule="evenodd">
        <path d="M4.1072 9.9912c7.486-.104 14.555 4.46 17.26 11.893 0-7.906-5.076-14.609-12.14-17.075" />
        <path d="M1.096 16.6059c6.996-2.649 15.191-.776 20.271 5.278-2.705-7.432-9.774-11.997-17.26-11.893" />
        <path d="M15.7996 1.687c5.794 4.734 8.271 12.769 5.567 20.197 5.082-6.056 5.502-14.454 1.676-20.884" />
        <path d="M9.2268 4.809c7.064 2.466 12.14 9.169 12.14 17.075 2.704-7.429.227-15.463-5.567-20.197" />
        <path d="M23.0428 1.0004c3.826 6.43 3.406 14.828-1.676 20.883 6.847-3.952 10.114-11.701 8.717-19.052" />
        <path d="M30.0838 2.831c1.397 7.351-1.87 15.1-8.717 19.053 7.79-1.374 13.512-7.543 14.71-14.933" />
        <path d="M40.2889 12.8955c-3.656 6.527-11.138 10.361-18.922 8.988 6.85 3.955 15.2 2.907 20.868-1.985" />
        <path d="M42.2347 19.8989c-5.668 4.892-14.018 5.94-20.868 1.985 5.081 6.055 13.276 7.927 20.272 5.278" />
        <path d="M36.0765 6.9511c-1.198 7.39-6.92 13.559-14.709 14.933 7.784 1.372 15.266-2.462 18.921-8.989M26.9349 42.0805c-5.794-4.733-8.271-12.769-5.568-20.197-5.081 6.056-5.501 14.454-1.676 20.884" />
        <path d="M33.5076 38.9584c-7.064-2.466-12.141-9.169-12.141-17.075-2.703 7.429-.226 15.464 5.568 20.197" />
        <path d="M.4998 23.8686c5.668-4.892 14.017-5.94 20.867-1.985-5.08-6.054-13.275-7.927-20.271-5.278M38.6272 33.7763c-7.486.104-14.555-4.46-17.26-11.893 0 7.906 5.076 14.609 12.14 17.075" />
        <path d="M41.6385 27.1616c-6.996 2.649-15.191.777-20.271-5.278 2.705 7.432 9.774 11.997 17.26 11.893M6.658 36.8164c1.198-7.39 6.92-13.559 14.709-14.933-7.784-1.372-15.266 2.462-18.922 8.989" />
        <path d="M19.6912 42.767c-3.826-6.43-3.405-14.828 1.676-20.883-6.846 3.952-10.114 11.701-8.717 19.052M2.4451 30.872c3.656-6.527 11.138-10.361 18.922-8.988-6.85-3.955-15.199-2.908-20.867 1.985" />
        <path d="M12.6502 40.9365c-1.396-7.351 1.871-15.1 8.717-19.053-7.789 1.374-13.511 7.543-14.709 14.933" />
      </g>
    </svg>
  )
}

export default PinwheelIcon
