import { Fragment } from "react"
import cx from "classnames"
import { range } from "../../../shared/utils/helpers"
import styles from "../styles/OrderItemLoading.module.scss"
import orderItemStyles from "../styles/OrderItem.module.scss"
import spinnerStyles from "../../../shared/styles/spinners.module.scss"

const OrderItemLoading = ({ items }) => {
  return (
    <Fragment>
      <div className={styles.loading}>
        <span className={spinnerStyles.blackSpinner} />
      </div>
      {range(items).map((n) => {
        return (
          <li key={n} className={cx(orderItemStyles.orderItem, styles.itemBlur)}>
            <ol className={orderItemStyles.orderInfo}>
              <div className={orderItemStyles.left}>
                <li className={orderItemStyles.orderNumber}>12345678</li>
                <li className={orderItemStyles.endClient}>End Client</li>
              </div>
              <div className={orderItemStyles.right}>
                <li className={orderItemStyles.purchaseDate}>January 1st, 2020</li>
                <li className={orderItemStyles.total}>$1,000.00</li>
              </div>
            </ol>
          </li>
        )
      })}
    </Fragment>
  )
}

OrderItemLoading.defaultProps = { items: 3 }

export default OrderItemLoading
