import SvgIcon from "../shared/components/SvgIcon"

const MusicalNotesIcon = () => {
  return (
    <svg width="21" height="19" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.43119 14.914C7.43119 16.6239 6.04506 18.01 4.33518 18.01C2.6253 18.01 1.23917 16.6239 1.23917 14.914C1.23917 13.2042 2.6253 11.818 4.33518 11.818C6.04506 11.818 7.43119 13.2042 7.43119 14.914Z"
        stroke="#C88E2A"
        strokeWidth="1.29083"
      />
      <path
        d="M19.4117 14.9602C19.4117 16.6701 18.0255 18.0562 16.3156 18.0562C14.6058 18.0562 13.2196 16.6701 13.2196 14.9602C13.2196 13.2503 14.6058 11.8642 16.3156 11.8642C18.0255 11.8642 19.4117 13.2503 19.4117 14.9602Z"
        stroke="#C88E2A"
        strokeWidth="1.29083"
      />
      <path
        d="M7.4375 14.9603V2.62303C7.4375 1.99794 7.94423 1.49121 8.56932 1.49121H18.2782C18.9033 1.49121 19.4101 1.99794 19.4101 2.62303V14.9603"
        stroke="#C88E2A"
        strokeWidth="1.29083"
      />
      <path d="M7.4375 6.729H19.4101" stroke="#C88E2A" strokeWidth="1.29083" />
    </svg>
  )
}

export default MusicalNotesIcon
