import SvgIcon from "../shared/components/SvgIcon"

const DownloadIcon = () => {
  return (
    <SvgIcon svgClass="DownloadIcon">
      <path
        className="icon-border"
        d="M15.0009646 28.9205441c-7.68906749 0-13.92154338-6.2326763-13.92154338-13.9210264 0-7.68835014 6.23247589-13.92102643 13.92154338-13.92102643 7.6871383 0 13.9196142 6.23267629 13.9196142 13.92102643 0 7.6883501-6.2324759 13.9210264-13.9196142 13.9210264m0-28.9205441C6.71575563 0 0 6.71500691 0 14.9995177 0 23.2840284 6.71575563 30 15.0009646 30 23.2832797 30 30 23.2840284 30 14.9995177 30 6.71500691 23.2832797 0 15.0009646 0"
      />
      <path d="M20.1459052 20.1630388H9.84730603v-3.6320043H8.76206897v4.7162715H21.2301724v-4.7162715h-1.0842672z" />
      <path d="M20.0207974 12.8123922l-.7671336-.7671336-3.6164871 3.6164871.0009699-7.90312501h-1.0842673l-.0009698 8.10193961-3.8143319-3.8153017-.76616381.7671336 5.02467671 5.0256466z" />
    </SvgIcon>
  )
}

export default DownloadIcon
