import { gql } from "@apollo/client"

const GET_PROJECT_SEARCH = gql`
  query projectSearch($slug: String!) {
    projectSearch(slug: $slug) {
      id
      name
      description
      pointPerson
      slug
      status
      createdAt
      updatedAt
      searchRounds {
        id
        name
        projectSearchId
        hidden
        showHighResDownload
        songVersionsPresent
        directions {
          id
          name
          searchRoundId
          songVersionsCount
          createdAt
          searchRoundSongVersions {
            id
            searchRoundId
            songVersionId
            artistName
            artistSlug
            catalogColor
            customizable
            length
            notes
            playerArt
            position
            rating
            title
            users
            winner
            isPreRelease
            songVersion {
              id
              customizable
              displayName
              exclusive
              hasClickLicenses
              length
              lyrics
              playerArt
              songVersionFile
              cyaniteId
              hasStems
              artist {
                id
                name
              }
            }
          }
        }
        searchRoundSongVersions {
          id
          searchRoundId
          songVersionId
          artistName
          artistSlug
          catalogColor
          customizable
          length
          notes
          playerArt
          position
          rating
          title
          users
          winner
          isPreRelease
          songVersion {
            id
            customizable
            displayName
            exclusive
            hasClickLicenses
            length
            lyrics
            playerArt
            songVersionFile
            cyaniteId
            hasStems
            artist {
              id
              name
            }
          }
        }
      }
    }
  }
`

export default GET_PROJECT_SEARCH
