import { useEffect, useState, useRef, useContext } from "react"
import PlayLargeIcon from "../../icons/PlayLargeIcon"
import DownloadIcon from "../../icons/DownloadIcon"
import ShareIcon from "../../icons/ShareIcon"
import TrashIcon from "../../icons/TrashIcon"
import HideIcon from "../../icons/HideIcon"
import ShowIcon from "../../icons/ShowIcon"
import JoinIcon from "../../icons/JoinIcon"
import { shareSongMixtape, triggerLogin } from "../../shared/utils/backbone/drawerProxyService"
import { MixtapePageContext } from "./MixtapePageContainer"
import cx from "classnames"
import { playMixtape } from "../../shared/utils/backbone/playerProxyService"
import LeaveIcon from "../../icons/LeaveIcon"
import CollaborateOnIcon from "../../icons/CollaborateOnIcon"
import CollaborateOffIcon from "../../icons/CollaborateOffIcon"
import LongRunningProcess from "../../shared/utils/LongRunningProcess"
import Modal from "../../shared/components/Modal"
import modalStyles from "../../shared/styles/modal.module.scss"

const MixtapeActions = () => {
  const { user_role, is_owner, mixtapeSerializer, mixtapeDetails, setMixtapeDetails } =
    useContext(MixtapePageContext)
  const [actionHoverText, setActionHoverText] = useState("")
  const [isDownloading, setIsDownloading] = useState(false)
  const [downloadUrl, setDownloadUrl] = useState("")
  const downloadButtonRef = useRef(null)

  const handleHover = (hoverText) => {
    setActionHoverText(hoverText)
  }

  const handleDownloadAll = () => {
    if (isDownloading) return
    setIsDownloading(true)
  }

  const handleDeleteMixtape = async () => {
    var confirmation = window.confirm("Are you sure? This mixtape will be deleted forever.")
    if (!confirmation) return
    const response = await fetch(`/mixtapes/${mixtapeSerializer.id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": mm.csrf,
      },
    })
    if (!response.ok) {
      return
    }
    // Replacing window location stop the user from navigating back to the mixtape they deleted.
    window.location.replace("/browse")
  }

  const handlePlayAll = () => {
    playMixtape(mixtapeSerializer.song_versions, mixtapeSerializer.id)
  }

  const toggleLockUnlock = async () => {
    var newState = !mixtapeDetails.is_secret
    //optimistically set privacy to new state
    setMixtapeDetails({ ...mixtapeDetails, is_secret: newState })

    const response = await fetch(`/mixtapes/${mixtapeSerializer.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": mm.csrf,
      },
      body: JSON.stringify({
        mixtape: {
          secret: newState,
        },
      }),
    })
    if (!response.ok) {
      setMixtapeDetails({ ...mixtapeDetails, is_secret: !newState })
      return
    }
  }

  const toggleJoinUnjoin = async () => {
    const result = await fetch(
      `/mixtapes/${mixtapeSerializer.id}/${
        mixtapeDetails.includes_current_user ? "leave" : "join"
      }`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": mm.csrf,
        },
      }
    )
    if (!result.ok) {
      return
    }
    const response = await result.json()
    if (response.success) {
      setMixtapeDetails({
        ...mixtapeDetails,
        includes_current_user: !mixtapeDetails.includes_current_user,
      })
    }
  }

  const toggleMakeCollaborative = async () => {
    const result = await fetch(`/mixtapes/${mixtapeSerializer.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": mm.csrf,
      },
      body: JSON.stringify({
        mixtape: {
          collaborative: mixtapeDetails.all_collaborative ? "no" : "all",
        },
      }),
    })
    if (!result.ok) {
      return
    }
    setMixtapeDetails({ ...mixtapeDetails, all_collaborative: !mixtapeDetails.all_collaborative })
  }

  const handleDownloadSuccess = (data) => {
    if (downloadUrl !== "") return
    if (data.success) {
      setDownloadUrl(data.url)
    } else {
      setIsDownloading(false)
    }
  }

  const handleCloseModal = (e) => {
    setDownloadUrl("")
    setIsDownloading(false)
  }

  useEffect(() => {
    if (downloadUrl) {
      downloadButtonRef.current?.click()
    }
  }, [downloadUrl])

  return (
    <div className="actions">
      <div className="contain">
        <div className="spin-targ contain">
          {isDownloading && (
            <LongRunningProcess
              url={`/mixtapes/${mixtapeSerializer.id}/download`}
              pollInterval={2000}
              timeout={300000}
              callback={handleDownloadSuccess}
            ></LongRunningProcess>
          )}
        </div>
        {downloadUrl !== "" && (
          <Modal>
            <div className={modalStyles.container}>
              <div className={modalStyles.wrap}>
                <a data-prevent-default="" className={modalStyles.close} onClick={handleCloseModal}>
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
                      <g fill="#21928e" fillRule="evenodd">
                        <path
                          className="icon-border"
                          d="M14.9985746 28.9375653c-7.69742474 0-13.93613993-6.2396655-13.93613993-13.9370902 0-7.69837494 6.23966549-13.93804043 13.93613993-13.93804043 7.6993252 0 13.9380404 6.23966549 13.9380404 13.93804043 0 7.698375-6.2387152 13.9370902-13.9380404 13.9370902m0-28.9375653C6.71481517 0 0 6.71576547 0 15.0004751 0 23.2851848 6.71386487 30 14.9985746 30 23.2851848 30 30 23.2851848 30 15.0004751 30 6.71481517 23.2842345 0 14.9985746 0"
                        ></path>
                        <path d="M20.0802802 9.16293103l-5.0780173 5.07122847-5.07219824-5.07898709-.76713363.7661638 5.07122847 5.07995689-5.07898709 5.0712285.7661638.7671336 5.07995689-5.0702586 5.0702586 5.0780172.7671336-.7661638-5.0702586-5.0789871 5.0789871-5.07122842z"></path>
                      </g>
                    </svg>
                  </span>
                </a>
                <div className={modalStyles.target}>
                  <div className={modalStyles.downloadMessageContainer}>
                    <h2 className={modalStyles.hTwo}>Your download is ready!</h2>
                    <p className={modalStyles.messageText}>
                      If your download has not started yet, click the link below.
                    </p>
                    <p className={modalStyles.messageText}>
                      <a
                        href={downloadUrl}
                        target="_blank"
                        ref={downloadButtonRef}
                        className={modalStyles.downloadTarget}
                      >
                        Click to start download.
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        )}
        <ul className={cx(is_owner && "owner")}>
          <div className="mm-connect-mixtape-actions">
            <div className="connect-label connected">{actionHoverText}</div>
          </div>
          <li
            className="action play mm-hover-connect"
            onMouseOver={() => handleHover("Play All")}
            onMouseOut={() => handleHover("")}
            onClick={() => handlePlayAll()}
          >
            <span className="icon">
              <PlayLargeIcon />
            </span>
          </li>

          {/** Display the download button only if there are more than 0 songs */}
          <li
            className={cx(
              "desktop action download mm-hover-connect",
              user_role == "guest" && "login-required",
              isDownloading && "disabled"
            )}
            data-action={user_role !== "guest" && "download"}
            data-connect="mixtape-actions"
            data-cy={cx("download", user_role === "guest" && "disabled")}
            onMouseOver={() => handleHover("Download Mixtape")}
            onMouseOut={() => handleHover("")}
          >
            {user_role != "guest" ? (
              <a id="downloadLink" onClick={handleDownloadAll}>
                <span data-cy="download-temp-song" className="icon">
                  <DownloadIcon />
                </span>
              </a>
            ) : (
              <a itemProp="contentUrl" data-prevent-default onClick={triggerLogin}>
                <span data-cy="download-temp-song" className="icon">
                  <DownloadIcon />
                </span>
              </a>
            )}
          </li>

          {!mixtapeDetails.unreleased /** If mixtape is NOT Unreleased, display share button */ && (
            <li
              className="action share half mm-hover-connect"
              onMouseEnter={() => handleHover("Share")}
              onMouseLeave={() => handleHover("")}
              onClick={() => shareSongMixtape(mixtapeSerializer.slug, mixtapeSerializer.id)}
            >
              <a data-prevent-default className="mm-hover-connect">
                <span className="icon">
                  <ShareIcon />
                </span>
              </a>
            </li>
          )}

          {/** If you own the mixtape, you can hide the mixtape */}
          {is_owner && (
            <li
              className={cx(
                "action lock mm-hover-connect",
                mixtapeDetails.is_secret ? "locked" : "unlocked"
              )}
              data-connect="mixtape-actions"
              onMouseOver={() =>
                handleHover(mixtapeDetails.is_secret ? "Make Public" : "Make Secret")
              }
              onMouseOut={() => handleHover("")}
              onClick={() => toggleLockUnlock()}
            >
              <span className="icon make-secret">
                <HideIcon />
              </span>
              <span className="icon make-public">
                <ShowIcon />
              </span>
            </li>
          )}

          {is_owner && mixtapeDetails.collaborative != "marmo" && (
            <li
              className={cx(
                "action collaborate",
                mixtapeDetails.all_collaborative ? "collaborative" : "uncollaborative"
              )}
              onMouseOver={() =>
                handleHover(
                  mixtapeDetails.all_collaborative ? "Make Uncollaborative" : "Make Collaborative"
                )
              }
              onMouseOut={() => handleHover("")}
              onClick={toggleMakeCollaborative}
            >
              <span className="icon add-collab">
                <CollaborateOnIcon />
              </span>
              <span className="icon remove-collab">
                <CollaborateOffIcon />
              </span>
            </li>
          )}
          {!is_owner &&
            ((user_role !== "guest" && mixtapeDetails.all_collaborative) ||
              mixtapeDetails.marmo_only_and_internal) && (
              <li
                className={cx(
                  "action join",
                  mixtapeDetails.includes_current_user ? "joined" : "unjoined",
                  "mm-hover-connect"
                )}
                onMouseOver={() =>
                  handleHover(
                    mixtapeDetails.includes_current_user ? "Leave Mixtape" : "Join Mixtape"
                  )
                }
                onMouseOut={() => handleHover("")}
                onClick={toggleJoinUnjoin}
              >
                <span className="label">Join</span>
                <span className="icon join-mixtape">
                  <JoinIcon />
                </span>
                <span className="icon leave-mixtape">
                  <LeaveIcon />
                </span>
              </li>
            )}
        </ul>
        {is_owner && (
          <>
            <div className="mm-connect-mixtape-destroy"></div>
            <a
              data-prevent-default
              className="delete action mm-hover-connect"
              onMouseOver={() => handleHover("Delete")}
              onMouseOut={() => handleHover("")}
              onClick={handleDeleteMixtape}
            >
              <span className="icon">
                <TrashIcon />
              </span>
            </a>
          </>
        )}
      </div>
    </div>
  )
}

export default MixtapeActions
