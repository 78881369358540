import { PrismicPageParser } from "../../shared/utils/prismicPageParser"

const LabelPartnershipsContainer = () => {
  const data = JSON.parse(window.prismic_data)
  const pageId = window.pageId

  return (
    <section
      id="mm-label-partnerships"
      className="pageContainer"
      aria-label="Label Partnerships page"
    >
      <PrismicPageParser pageData={data} pageId={pageId} />
    </section>
  )
}

export default LabelPartnershipsContainer
