import React from "react"

export const caretIcon = (
  <svg className="caret-icon" width="11.848" height="6.338" viewBox="351.584 2118.292 11.848 6.338">
    <path d="M363.311 2118.414a.4195.4195 0 0 0-.592 0l-5.205 5.216-5.215-5.216c-.163-.163-.429-.163-.592 0s-.163.429 0 .592l5.501 5.501a.409.409 0 0 0 .296.123c.103 0 .215-.041.296-.123l5.501-5.501a.4108.4108 0 0 0 .01-.592z" />
  </svg>
)

export const multiSelectOptionMarkup = (text) => (
  <div>
    <span className="checkbox">
      <svg className="checkbox-icon" width="10" height="10" viewBox="0 0 488.878 488.878">
        <path d="M143.294 340.058l-92.457-92.456L0 298.439l122.009 122.008.14-.141 22.274 22.274L488.878 98.123l-51.823-51.825z" />
      </svg>
    </span>
    <span> {text}</span>
  </div>
)
