import { Formik, Form, Field } from "formik"
import cx from "classnames"
import TextField from "../../shared/components/TextField"
import { validationSchema } from "../../shared/utils/signUpValidationSchema"
import { useState } from "react"
import axios from "axios"

const CreateAccountForm = ({ props }) => {
  const { first_name, last_name, email, id } = props
  const [isLoading, setIsLoading] = useState(false)
  const [termsRead, setTermsRead] = useState(false)
  const [disableAll, setDisableAll] = useState(false)
  const [didFail, setDidFail] = useState(false)
  const [resMessage, setResMessage] = useState("")

  const handleErrorMessage = (errors) => {
    const keys = Object.keys(errors)
    var errorsText = ""
    keys.forEach((k, i) => {
      if (i > 0) errorsText += ", "
      errorsText += `${k} ${errors[k]}`
    })
    return errorsText.at(0).toUpperCase() + errorsText.slice(1)
  }

  const handleSubmit = (params, formActions) => {
    setIsLoading(true)
    axios
      .post(`/register`, {
        user: params,
      })
      .then((res) => {
        const data = res.data
        if (!data.success) {
          setIsLoading(false)
          setDidFail(true)
          let errorMessage = handleErrorMessage(data.errors)
          setResMessage(`${data.message}: ${errorMessage}`)
          return
        }
        setDisableAll(true)
        setDidFail(false)
        setIsLoading(false)
        setResMessage(data.message)
      })
      .catch((error) => {
        setIsLoading(false)
        setDidFail(true)
        setResMessage(error.message)
      })
  }

  const initialValues = {
    first_name: first_name || "",
    last_name: last_name || "",
    email: email || "",
    email_confirmation: "",
    id: id,
  }

  return (
    <>
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {({ setStatus, status }) => (
          <Form id="create-account-from-guest">
            {status && status.msg && (
              <div className={styles.errorMsg}>
                {`${status.msg}.`}
                <button onClick={(e) => handleCloseErrorMsg(e, setStatus)}>CLOSE</button>
              </div>
            )}
            <div className="left">
              <TextField placeholder="First Name" fieldName="first_name" wrapperClass="" />
              <TextField placeholder="Last Name" fieldName="last_name" />
            </div>
            <div className="right">
              <TextField placeholder="Email Address" fieldName="email" />
              <TextField placeholder="Confirm Email Address" fieldName="email_confirmation" />
            </div>
            <Field type="hidden" name="id" fieldName="id" value={id} />
            <button
              type="submit"
              className="box-button-black"
              disabled={isLoading || !termsRead || disableAll}
            >
              {isLoading ? <span>Loading...</span> : <span>Create Account</span>}
              <div className="buttonText" id={status?.success && "success"}>
                {status && status.success && "Success!"}
              </div>
            </button>

            <p className={cx("success-message", didFail && "fail", resMessage != "" && "show")}>
              {resMessage}
            </p>
          </Form>
        )}
      </Formik>
      <section style={{ marginTop: "5px" }}>
        <input
          type="checkbox"
          id="agree_to_terms"
          name="agree_to_terms"
          disabled={isLoading || disableAll}
          value={termsRead}
          onChange={() => setTermsRead(!termsRead)}
        />
        <label for="agree_to_terms" className="terms-checkbox">
          I have read and agreed to the Marmoset{" "}
          <a href="/legal" data-bypass="true">
            Terms of Service
          </a>
          .
        </label>
      </section>
    </>
  )
}

export default CreateAccountForm
