export default function Header() {
  return (
    <header id="mm-style-guide-landing">
      <div className="cover-image" />
      <div className="heading">
        <h1 className="spread-headline">Style Guide</h1>
      </div>
    </header>
  )
}
