import SvgIcon from "../shared/components/SvgIcon"

const GlobeIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 35" width="35px">
      <g fill="none" fillRule="evenodd">
        <path d="M30.4487 7.0415c-1.256 1.496-2.769 2.769-4.473 3.749.535 2.12.834 4.479.834 6.965 0 2.487-.299 4.846-.834 6.966 1.704.98 3.217 2.253 4.473 3.749 2.433-2.898 3.899-6.635 3.899-10.715 0-4.079-1.466-7.816-3.899-10.714zM9.371 24.7211c-.535-2.12-.833-4.479-.833-6.965 0-2.487.298-4.846.833-6.966-1.703-.98-3.216-2.253-4.472-3.749C2.466 9.9391 1 13.6761 1 17.7561c0 4.079 1.466 7.816 3.899 10.714 1.256-1.496 2.769-2.769 4.472-3.749z" />
        <path d="M17.6738 1.082c3.683 0 6.857 3.978 8.302 9.708 1.704-.979 3.217-2.252 4.473-3.749-3.059-3.643-7.646-5.959-12.775-5.959z" />
        <path d="M9.371 10.79c1.446-5.73 4.62-9.708 8.303-9.708-5.129 0-9.717 2.316-12.775 5.959 1.256 1.497 2.769 2.77 4.472 3.749zM25.976 24.7211c-1.445 5.73-4.619 9.708-8.302 9.708 5.129 0 9.716-2.316 12.775-5.959-1.256-1.496-2.769-2.769-4.473-3.749zM17.6738 34.4296c-3.683 0-6.857-3.979-8.303-9.708-1.703.979-3.216 2.252-4.472 3.749 3.059 3.643 7.646 5.959 12.775 5.959zM9.371 10.79c-.535 2.12-.833 4.479-.833 6.966s.298 4.846.833 6.965c2.445-1.406 5.28-2.211 8.303-2.211 3.023 0 5.858.805 8.302 2.211.535-2.119.834-4.478.834-6.965s-.299-4.846-.834-6.966c-2.444 1.407-5.279 2.211-8.302 2.211-3.023 0-5.858-.804-8.303-2.211z" />
        <path d="M17.6738 1.082c-3.683 0-6.857 3.978-8.303 9.708 2.445 1.407 5.28 2.211 8.303 2.211 3.023 0 5.858-.804 8.302-2.211-1.445-5.73-4.619-9.708-8.302-9.708zM9.371 24.7211c1.446 5.73 4.62 9.708 8.303 9.708 3.683 0 6.857-3.978 8.302-9.708-2.444-1.406-5.279-2.211-8.302-2.211-3.023 0-5.858.805-8.303 2.211zM1.0346 17.7558h33.278M17.6738 34.1533V1.3583" />
      </g>
    </svg>
  )
}

export default GlobeIcon
