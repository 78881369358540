import { gql } from "@apollo/client"

export const CANCEL_ACCOUNT = gql`
  mutation cancelAccount($account: AccountInput) {
    cancelAccount(account: $account) {
      account {
        id
      }
      errors
    }
  }
`

export const DELETE_CARD = gql`
  mutation deleteSavedCard($id: ID!) {
    deleteSavedCard(id: $id) {
      account {
        id
        cards {
          id
        }
      }
    }
  }
`

export const EDIT_PROFILE = gql`
  mutation editProfile($account: AccountInput) {
    editProfile(account: $account) {
      account {
        id
        fullName
        firstName
        lastName
        email
        createdAt
        profile {
          company
          phoneNumber
          job
          address {
            line1
            line2
            city
            subregion
            country
            postalCode
          }
        }
      }
      errors
    }
  }
`

export const RESET_PASSWORD = gql`
  mutation resetPassword($user_password: UserPasswordInput) {
    resetPassword(userPassword: $user_password) {
      userPassword {
        id
      }
      errors
    }
  }
`
