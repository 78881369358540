import RibbonIcon from "../../../icons/RibbonIcon"
import VideoIcon from "../../../icons/VideoIcon"
import UserIcon from "../../../icons/UserIcon"

const jobOptions = [
  {
    label: "Agency Creative",
    Icon: RibbonIcon,
  },
  {
    label: "Filmmaker",
    Icon: VideoIcon,
  },
  {
    label: "Other Storyteller",
    Icon: UserIcon,
  },
]

export default jobOptions
