import { PrismicPageParser } from "../../shared/utils/prismicPageParser"

const ContactContainer = () => {
  const data = JSON.parse(window.prismic_data)
  const pageId = window.pageId

  return (
    <section id="mm-contact" className="pageContainer" aria-label="Contact">
      <PrismicPageParser pageData={data} pageId={pageId} />
    </section>
  )
}

export default ContactContainer
