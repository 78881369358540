const BrowseBetaNoResults = () => {
  return (
    <div className="no-results">
      <h5 className="hFive">Sorry, there are no results matching your search terms.</h5>
      <p>Try removing some of your search criteria or maybe clear all and start over.</p>
    </div>
  )
}

export default BrowseBetaNoResults
