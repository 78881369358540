import { useContext, useEffect, useState } from "react"
import { SongPageContext } from "./SongVersionPageContainer"
import { getSongVersionColor } from "../../shared/utils/browse"
import { fetchDataForMessagePopUp } from "../../shared/utils/backbone/messageQueueService"

const SongVersionDetails = () => {
  const { sv, user_role, isInternal } = useContext(SongPageContext)

  const instantiateWaveform = () => {
    var wavesurfer = window.WaveSurfer.create({
      backend: "MediaElement",
      barGap: 1.5,
      barWidth: 1.5,
      container: ".song-version-waveform",
      cursorColor: "transparent",
      cursorWidth: 0,
      fillParent: true,
      height: 50,
      pixelRatio: 2,
      progressColor: "transparent",
      waveColor: "#ccc",
      interact: false,
    })
    wavesurfer.load(sv.song_version_file)
  }

  const handleProButtonClick = (e) => {
    fetchDataForMessagePopUp(`/song_versions/pro_information/${sv.id}`)
    //Emit a custom Google Analytics event to track use of PRO info button
    window.ga("send", "event", "Button Clicks", "PRO Button Click", "PRO Info Button")
  }

  useEffect(() => {
    if (sv.song_version_file) {
      instantiateWaveform()
    }
  }, [sv])

  if (sv) {
    return (
      <article className="details contain" aria-label="Song details">
        <div className="left">
          <h4 className="hFour">Instruments</h4>
          <p>
            {sv.instruments &&
              sv.instruments.map((i) => <a key={i} href={`/browse?instruments=${i}`}>{`${i}`}</a>)}
          </p>

          <h4 className="hFour">Mood</h4>
          <p>
            {sv.keyword_list &&
              sv.keyword_list.map((k) => <a key={k} href={`/browse?moods=${k}`}>{`${k}`}</a>)}
          </p>

          <h4 className="hFour">Waveform</h4>
          <div className="song-version-waveform"></div>
        </div>

        <div className="right">
          <dl>
            <div>
              <dt>Energy</dt>
              <dd>
                <a href={`/browse?energies=${sv.energy}`}>{sv.energy}</a>
              </dd>
            </div>
            <div>
              <dt>Arc&trade;</dt>
              <dd>
                <a href={`/browse?arcs=${sv.arc}`}>{sv.arc} </a>
              </dd>
            </div>
            <div>
              <dt>Length</dt>
              <dd>{sv.pretty_duration}</dd>
            </div>

            <div>
              <dt>Vocals</dt>
              <dd>
                <a
                  href={`/browse?vocals=${sv.instrumental ? "instrumental" : "vocals"}`}
                  aria-label={`Browse all songs ${sv.instrumental ? "without" : "with"} vocals`}
                >
                  {sv.instrumental ? "No" : "Yes"}
                </a>
                <a
                  href={`/browse?vocals=${sv.male_vocals ? "male_vocals" : "female_vocals"}`}
                  aria-label={`'Browse all songs with ${
                    sv.male_vocals ? "male" : "female"
                  } vocals'`}
                >
                  {sv.male_vocals && "Male"}
                  {sv.female_vocals && "Female"}
                </a>
              </dd>
            </div>

            <div>
              <dt>Genres</dt>
              <dd>
                {sv.joined_genre_list &&
                  sv.joined_genre_list
                    .split(", ")
                    .map((g) => <a key={g} href={`/browse?genres=${g}`}>{`${g}`}</a>)}
              </dd>
            </div>
            {isInternal && (
              <div>
                <dt>Customizable</dt>
                <dd>
                  <a href={`/browse?customizable=${sv.customizable}`}>
                    {sv.customizable ? "Yes" : "No"}
                  </a>
                </dd>
              </div>
            )}
            {sv.release_year && (
              <div>
                <dt>Release Year</dt>
                <dd>{sv.release_year}</dd>
              </div>
            )}
            {sv.artist?.catalog?.id && (
              <div style={{ background: getSongVersionColor(null, user_role, sv) }}>
                <dt>Collection</dt>
                <dd>{sv.artist.catalog.name}</dd>
              </div>
            )}
            {isInternal && (
              <>
                {sv.song?.exclusive && (
                  <div className="exclusivities">
                    <dt>Exclusivities</dt>
                    <dd>Yes</dd>
                  </div>
                )}
                <div>
                  <dt></dt>
                  <dd>
                    <a href={`/manage/song_versions/${sv.id}-${sv.slug}`}>View in Portal</a>
                  </dd>
                </div>
              </>
            )}
          </dl>

          {user_role != "guest" && (
            <div
              className="action pro-info-button"
              data-action="proInformation"
              data-connect="song-version-actions"
              data-connect-text="pro-info"
              aria-label="cue_sheet_info"
              data-prevent-default
              onClick={handleProButtonClick}
            >
              Cue Sheet Info
            </div>
          )}
        </div>
      </article>
    )
  } else {
    return <></>
  }
}
export default SongVersionDetails
