import { useState, useEffect, useContext } from "react"
import Rating from "./Rating"
import SearchRoundSongVersionActions from "./SearchRoundSongVersionActions"
import Modal from "../../../shared/components/Modal"
import { escapeToClose } from "../../../shared/utils/helpers"
import PlayLargeIcon from "../../../icons/PlayLargeIcon"
import { ProjectSearchContext } from "./ProjectSearch"
import playerData from "../utils/playerData"
import LicensingHistory from "./LicensingHistory"
import LicenseButton from "./LicenseButton"

const SearchRoundSongVersionItem = ({ position, searchRoundId, searchRoundSongVersion }) => {
  const [songVersions, setSongVersions] = useState([])
  const [openModal, setOpenModal] = useState(false)
  const { internalUser, selectedRound } = useContext(ProjectSearchContext)
  const {
    id,
    artistName,
    artistSlug,
    catalogColor,
    customizable,
    length,
    notes,
    playerArt,
    rating,
    songVersionId,
    title,
    users,
    winner,
    isPreRelease,
    songVersion,
  } = searchRoundSongVersion
  const playerStyle = {
    backgroundImage: `url(${playerArt})`,
    backgroundSize: "contain",
    backgroundPosition: "center",
  }

  useEffect(() => {
    setSongVersions(playerData(selectedRound))
  }, [selectedRound])

  // this controls modal pop-up
  useEffect(() => {
    return escapeToClose(setOpenModal)
  })

  const toggleModal = (e) => {
    e.stopPropagation()
    setOpenModal(!openModal)
  }

  const handlePlay = () => {
    // If the element is already playing, pause it. This fixes an issue with mobile not having a way to stop play.
    var audioElement = $(`audio[src^="/song_versions/play/${songVersionId}"]`)
    if (audioElement.length > 0) {
      if (!audioElement[0].paused) {
        audioElement[0].pause()
        return
      }
    }
    mm.playerProxy.trigger("play", {
      pointer: songVersion,
      songVersions,
      type: "mixtape",
    })
  }

  return (
    <li
      className="song-version-item"
      data-song-version-id={songVersionId}
      data-search-round-id={searchRoundId}
      style={{ background: internalUser ? catalogColor : null }}
    >
      <ol className="default">
        {!mm.isMobile && <li className="position">{searchRoundSongVersion.position + 1}</li>}
        <li className="action play" data-action="play">
          <a data-prevent-default style={playerStyle} onClick={handlePlay}>
            <span className="play-icon">
              <PlayLargeIcon />
            </span>
          </a>
        </li>
        <li className="song-version">
          <div className="song-version-cell" data-cy="songVersionItem">
            <h4 className="title-and-artist">
              {isPreRelease ? (
                <span className="song-version-title">{title}</span>
              ) : (
                <a
                  href={`/browse/${songVersionId}`}
                  className="song-version-title"
                  data-bypass="true"
                  data-prevent-default
                >
                  {title}
                </a>
              )}
              <span>
                <em> by </em>
                <a
                  href={`/artists/${artistSlug}`}
                  className="song-version-artist"
                  data-bypass="true"
                  data-prevent-default
                >
                  {artistName}
                </a>
              </span>
            </h4>
            {internalUser && <LicenseButton srsvId={id} toggleModal={toggleModal} />}
          </div>
        </li>
        {!mm.isMobile && <li className="customizable">{customizable}</li>}
        <Rating id={id} rating={rating} />
        {!mm.isMobile && <li className="length">{length}</li>}
        {internalUser && !mm.isMobile && (
          <li className="chosen-by">
            <span>{users}</span>
          </li>
        )}
        <SearchRoundSongVersionActions
          key={`srsv-actions-${id}`}
          notes={notes}
          searchRoundId={searchRoundId}
          searchRoundSongVersion={searchRoundSongVersion}
          winner={winner}
        />
      </ol>
      {openModal && (
        <Modal>
          <LicensingHistory toggleModal={toggleModal} srsvId={id} />
        </Modal>
      )}
    </li>
  )
}

export default SearchRoundSongVersionItem
