import { useSearchBox } from "react-instantsearch-hooks-web"
import cx from "classnames"
import SearchIcon from "../../icons/SearchIcon"
import { useWorkbenchStore } from "../../store/store"
import { resultsFilters } from "./FilterTransforms"
import { useEffect, useState } from "react"

const WorkbenchSearchFilterListItem = ({ item }) => {
  const setSelectedResultsFilter = useWorkbenchStore((state) => state.setSelectedResultsFilter)
  const selectedResultsFilter = useWorkbenchStore((state) => state.selectedResultsFilter)

  const handleSearchFilterClick = (e) => {
    setSelectedResultsFilter(e.target.dataset.value)
  }

  return (
    <div
      className={cx("item", selectedResultsFilter === item.name ? "selected" : "")}
      data-value={item.name}
      data-cy={item.name}
      onClick={handleSearchFilterClick}
    >
      {item.name}
    </div>
  )
}

const WorkbenchSearch = () => {
  const { refine } = useSearchBox()
  const searchQuery = useWorkbenchStore((state) => state.searchQuery)
  const setSearchQuery = useWorkbenchStore((state) => state.setSearchQuery)
  const selectedResultsFilter = useWorkbenchStore((state) => state.selectedResultsFilter)
  const [searchQueryForm, setSearchQueryForm] = useState(searchQuery)
  const [isMenuVisible, setIsMenuVisible] = useState(false)

  const handleSearchChange = (e) => {
    setSearchQueryForm(e.target.value)
  }

  const handleSearchSubmit = (e) => {
    e.preventDefault()
    setSearchQuery(searchQueryForm)
  }

  const handleMenuToggle = () => {
    setIsMenuVisible(!isMenuVisible)
  }

  useEffect(() => {
    refine(searchQuery)
    setSearchQueryForm(searchQuery) // Resyncs form state in case of back navigation
  }, [searchQuery])

  return (
    <form id="search" className="search mm-filter" onSubmit={handleSearchSubmit}>
      <div id="mm-search-input">
        <div className="search-input-group">
          <div className="icon">
            <SearchIcon />
          </div>
          <label>Search</label>
          <input
            autoFocus
            type="text"
            value={searchQueryForm}
            onChange={handleSearchChange}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="none"
            spellCheck="false"
            placeholder="Search by song, artist or lyrics"
            aria-label="Enter search text"
          />
        </div>
        <div className="search-filter" onClick={handleMenuToggle}>
          <span className="expand icon"></span>
          <div className="target">
            {selectedResultsFilter} <span></span>
          </div>
          <div className={cx("menu", isMenuVisible ? "visible" : "")}>
            {resultsFilters.map((item, index) => (
              <WorkbenchSearchFilterListItem key={index} item={item} />
            ))}
          </div>
        </div>
      </div>
    </form>
  )
}

export default WorkbenchSearch
