import cx from "classnames"
import styles from "../../styles/ImageCollage.module.scss"
import { createSrcSet } from "../../utils/helpers"

const ImageCollage = ({ slice }) => {
  const { primary } = slice

  return (
    <article className={cx(styles.imageCollage, "prismicImageCollage")} aria-label="Image collage">
      <div className={styles.contain}>
        <div className={styles.topRow}>
          <Img
            image={primary.top_left_image}
            sizes="(min-width: 768px) calc(50vw - 2.35rem), 100vw"
            stylesClass={styles.topLeftImage}
          />
          <Img
            image={primary.top_right_image}
            sizes="(min-width: 768px) calc(50vw - 2.35rem), 100vw"
            stylesClass={styles.topRightImage}
          />
        </div>
        <div className={styles.bottomRow}>
          <Img image={primary.bottom_image} sizes="(min-width: 768px) 50vw, 100vw" />
        </div>
      </div>
    </article>
  )
}

const Img = ({ image, sizes, stylesClass }) => {
  const sizeobj = {
    Mobile: "480w",
    Tablet: "768w",
    Standard: "1024w",
    HomepageLarge: "1280w",
    LargeDesktop: "1440w",
  }

  return (
    <img
      src={image.url}
      srcSet={createSrcSet(image, sizeobj)}
      sizes={sizes}
      className={cx(stylesClass, styles.image)}
      alt={image.alt}
    />
  )
}

export default ImageCollage
