export const hideUnhideSong = async (songId, request) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": mm.csrf,
    },
  }
  const route = "/song/" + songId + "/" + request

  const result = await fetch(route, requestOptions)

  mm.user.fetchUserSongs()
  return result
}

export const favoriteUnfavoriteSongVersion = async (songVersionId, request) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "X-CSRF-Token": mm.csrf,
      "Content-Type": "application/json",
    },
  }
  var route = "/favorites/" + request + "/song_version/" + songVersionId
  const result = await fetch(route, requestOptions)
  mm.user.fetchUserSongs()
  // Tell player you liked a song, and it'll update it's icon if the current song is the favorited song
  mm.playerProxy.trigger("favorite", { songVersionId, request })
  return result
}
