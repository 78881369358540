import React, { useRef, useEffect, forwardRef } from "react"

const Canvas = forwardRef(function MyInput(props, ref) {
  const { draw, width, height, id } = props

  useEffect(() => {
    const canvas = ref.current
    const context = canvas.getContext("2d")
    let animationFrameId

    const render = () => {
      draw(context)
      animationFrameId = window.requestAnimationFrame(render)
    }
    render()

    return () => {
      window.cancelAnimationFrame(animationFrameId)
    }
  }, [draw, ref.current])

  return <canvas ref={ref} width={width} height={height} id={id} />
})

export default Canvas
