import { Draggable } from "react-beautiful-dnd"
import SearchRoundSongVersionItem from "../components/SearchRoundSongVersionItem"

export const DraggableItems = ({ searchRoundSongVersions, disableSort, round }) => {
  return searchRoundSongVersions.map((srsv, index) => (
    <Draggable key={srsv.id} draggableId={srsv.id} index={index} isDragDisabled={disableSort}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
        >
          <SearchRoundSongVersionItem
            key={srsv.id}
            searchRoundSongVersion={srsv}
            searchRoundId={round.id}
            position={index + 1}
          />
        </div>
      )}
    </Draggable>
  ))
}

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

export const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source)
  const destClone = Array.from(destination)
  const [removed] = sourceClone.splice(droppableSource.index, 1)

  destClone.splice(droppableDestination.index, 0, removed)

  const result = {}
  result[droppableSource.droppableId] = sourceClone
  result[droppableDestination.droppableId] = destClone

  return result
}

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  background: isDragging ? "rgba(138, 71, 131, 0.6)" : "",
  ...draggableStyle,
})

export const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "rgba(102, 102, 102, 0.2)" : "",
})
