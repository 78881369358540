import { gql, useQuery } from "@apollo/client"
import CloseIcon from "../../../icons/CloseIcon"
import modalStyles from "../../../shared/styles/modal.module.scss"
import styles from "../../../shared/styles/LicensingHistory.module.scss"

const GET_PAST_LICENSING_INFO = gql`
  query searchRoundSongVersion($id: ID) {
    searchRoundSongVersion(id: $id) {
      hasPastLicenses
      portalSongSlug
      pastLicensingInfo
    }
  }
`

const LicensingHistory = ({ toggleModal, srsvId }) => {
  const variables = { id: srsvId }
  const { loading, error, data } = useQuery(GET_PAST_LICENSING_INFO, { variables })

  if (loading) return null
  if (error) console.log(error)

  const { searchRoundSongVersion } = data

  const fullLicensingHistory = searchRoundSongVersion.pastLicensingInfo.map((info, index) => {
    const splitData = info.split(" + ")
    return (
      <ul key={index} className={styles.licenseInformation}>
        <li>{splitData[0]}</li>
        <li>{splitData[1]}</li>
      </ul>
    )
  })

  return (
    <div className={modalStyles.containerTeamPage} onClick={toggleModal}>
      <div className={styles.pastLicensingContent}>
        <span className={styles.exitButton} onClick={toggleModal}>
          <CloseIcon />
        </span>
        <h3 className={styles.portalLink}>
          <a href={`/manage/songs/${searchRoundSongVersion.portalSongSlug}`}>
            View Licensing Info in Portal
          </a>
        </h3>
        <div className={styles.licenseHeaders}>
          <p>End Client</p>
          <p>Processed At</p>
        </div>
        {fullLicensingHistory}
      </div>
    </div>
  )
}

export default LicensingHistory
