import { PrismicPageParser } from "../../shared/utils/prismicPageParser"
import cx from "classnames"
import styles from "./StudioContainer.module.scss"

const StudioContainer = () => {
  const data = JSON.parse(window.prismic_data)
  const pageId = window.pageId

  return (
    <section id="mm-studio" className={cx(styles.studio, "pageContainer")} aria-label="Studio page">
      <PrismicPageParser pageData={data} pageId={pageId} pageName="Studio" />
    </section>
  )
}

export default StudioContainer
