import cx from "classnames"
import styles from "../../styles/AnimatedBanner.module.scss"
import { useEffect } from "react"
import SmileyFaceIcon from "../../../icons/SmileyFaceIcon"
import MoutainWavesIcon from "../../../icons/MountainWavesIcon"
import CassetteReelIcon from "../../../icons/CassetteReelIcon"
import PinwheelIcon from "../../../icons/PinwheelIcon"
import GlobeIcon from "../../../icons/GlobeIcon"

const AnimatedBanner = ({ slice }) => {
  const { items, primary } = slice
  const iconMap = {
    MountainWaves: <MoutainWavesIcon classes={styles.mountainWavesIcon} />,
    CassetteReel: <CassetteReelIcon />,
    Pinwheel: <PinwheelIcon />,
    Globe: <GlobeIcon />,
    SmileyFace: <SmileyFaceIcon />,
  }

  const backgroundColorMap = {
    Purple: "purple",
    Coral: "coral",
    Gold: "gold",
    Green: "green",
    Black: "black",
    White: "white",
    Track_Red: "track-red",
    Track_Yellow: "track-yellow",
    Track_Blue: "track-blue",
    Track_Pink: "track-pink",
    Track_Orange: "track-orange",
    Track_Ocean_Blue: "track-ocean-blue",
    Track_Sunflower: "track-sunflower",
  }
  const backgroundColor = backgroundColorMap[primary.background_color]

  useEffect(() => {
    const scrollers = document.querySelectorAll(".bannerScroller")
    // If a user hasn't opted in for recuded motion, then we add the animation
    if (!window.matchMedia("(prefers-reduced-motion: reduce)").matches) {
      addAnimation()
    }

    function addAnimation() {
      scrollers.forEach((scroller) => {
        // add data-animated="true" to every `.scroller` on the page
        scroller.setAttribute("data-animated", true)

        // Make an array from the elements within `.scroller-inner`
        const scrollerInner = scroller.querySelector(".scroller__inner")
        const scrollerContent = Array.from(scrollerInner.children)

        // For each item in the array, clone it
        // add aria-hidden to it
        // add it into the `.scroller-inner`
        scrollerContent.forEach((item) => {
          const duplicatedItem = item.cloneNode(true)
          duplicatedItem.setAttribute("aria-hidden", true)
          scrollerInner.appendChild(duplicatedItem)
        })
      })
    }
  }, [slice])

  return (
    <article className={cx(styles.contain, styles.banner)} aria-label="Banner Box">
      <div
        className={cx(styles.bannerBox, styles.scroller, "bannerScroller")}
        style={{ backgroundColor: `var(--${backgroundColor})` }}
      >
        <ul className={cx(styles.scroller__inner, "scroller__inner")}>
          {
            //This doesn't look continuous/unbroken if there is only 1 or maybe even 2 line items.
            items.map((item, index) => {
              return (
                <>
                  <li key={`text-${index}`} className={styles.bannerText}>
                    {item.line_text[0].text}
                  </li>
                  <li key={`icon-${index}`} className={styles.bannerIcon}>
                    {iconMap[item.icon_name]}
                  </li>
                </>
              )
            })
          }
        </ul>
      </div>
    </article>
  )
}

export default AnimatedBanner
