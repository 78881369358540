import SvgIcon from "../shared/components/SvgIcon"

const InfoIcon = () => {
  return (
    <SvgIcon svgClass="InfoIcon">
      <path
        className="icon-border"
        d="M14.9995177 28.9205441c-7.68835014 0-13.92102643-6.2326763-13.92102643-13.9210264 0-7.68835014 6.23267629-13.92102643 13.92102643-13.92102643 7.6893147 0 13.921991 6.23267629 13.921991 13.92102643 0 7.6883501-6.2326763 13.9210264-13.921991 13.9210264m0-28.9205441C6.71500691 0 0 6.71500691 0 14.9995177 0 23.2840284 6.71500691 30 14.9995177 30 23.2840284 30 30 23.2840284 30 14.9995177 30 6.71500691 23.2840284 0 14.9995177 0"
      />
      <path d="M15.8489224 12.9827586h-4.1799569v1.0852371h3.0956897v7.5830819h-3.0956897v1.0852371h7.2737069v-1.0852371h-3.09375zM15.305819 6.74385776c.9455819 0 1.7127155.76616379 1.7127155 1.71271552 0 .94558189-.7671336 1.71174572-1.7127155 1.71174572-.9465518 0-1.7136854-.76616383-1.7136854-1.71174572 0-.9455819.7671336-1.71271552 1.7136854-1.71271552m0 4.50969824c1.5449353 0 2.7969827-1.2520474 2.7969827-2.79698272 0-1.54493535-1.2520474-2.79795259-2.7969827-2.79795259-1.5459052 0-2.7989224 1.25301724-2.7989224 2.79795259 0 1.54396552 1.2530172 2.79698272 2.7989224 2.79698272" />
    </SvgIcon>
  )
}

export default InfoIcon
