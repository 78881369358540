import { useRefinementList, useClearRefinements } from "react-instantsearch-hooks-web"
import GenresListItem from "./GenresListItem"
import { transformGenres } from "./FilterTransforms"
import cx from "classnames"
import { useWorkbenchStore } from "../../store/store"

const ClearAllGenres = () => {
  const { refine } = useClearRefinements({ includedAttributes: ["genre_list"] })
  const resetGenres = useWorkbenchStore((state) => state.resetGenres)

  const handleClick = () => {
    resetGenres()
    refine()
  }

  return (
    <li className="clear-all-genres" data-val="clear-all-genres" data-pretty="clear-all-genres">
      <a data-prevent-default onClick={handleClick}>
        Clear All
      </a>
    </li>
  )
}

const WorkbenchFilterGenres = ({ id }) => {
  const currentFilter = useWorkbenchStore((state) => state.currentFilter)
  const { items, refine } = useRefinementList({
    attribute: "genre_list",
    limit: 50,
    operator: "and",
    transformItems: transformGenres,
  })

  return (
    <fieldset id={id} className={cx("mm-filter", currentFilter === id ? "current" : "")}>
      <ul>
        {items &&
          items.map((item, index) => <GenresListItem key={index} item={item} refine={refine} />)}
        {items && <ClearAllGenres />}
      </ul>
    </fieldset>
  )
}

export default WorkbenchFilterGenres
