import React from "react"
import { useContext, useEffect } from "react"
import cx from "classnames"
import { PrismicPageContext } from "../../utils/prismicPageParser"
import "../../styles/AnnouncementBanner.module.scss"

const AnnouncementBanner = ({ slice, white }) => {
  const colorTheme = useContext(PrismicPageContext)
  const { primary } = slice
  const backgroundColorMap = {
    Purple: "purple",
    Coral: "coral",
    Gold: "gold",
    Green: "green",
    Black: "black",
    White: "white",
    Track_Red: "track-red",
    Track_Yellow: "track-yellow",
    Track_Blue: "track-blue",
    Track_Pink: "track-pink",
    Track_Orange: "track-orange",
    Track_Ocean_Blue: "track-ocean-blue",
    Track_Sunflower: "track-sunflower",
  }
  const backgroundColor = backgroundColorMap[primary.background_color]

  useEffect(() => {
    if (
      document.getElementById("mm-home-new").firstChild.classList.contains("announcementBanner")
    ) {
      document.getElementById("mm-drawer-nav").classList.add("announcement")
      document.querySelector("header.new-header").classList.add("announcement")
    }
    return () => {
      document.getElementById("mm-drawer-nav").classList.remove("announcement")
      document.querySelector("header.new-header").classList.remove("announcement")
    }
  }, [])

  return (
    <article
      className={cx(
        "announcementBanner",
        "prismicAnnouncementBanner",
        "fullWidth",
        backgroundColor
      )}
      aria-label="Announcement"
    >
      <a
        href={primary.announcement_link.url}
        target={primary.announcement_link.target === "_blank" ? "_blank" : "_self"}
        data-bypass="true"
      >
        <img
          className="desktop"
          src={primary.announcement_image_desktop.url}
          alt={primary.announcement_image_desktop.alt}
        />
        <img
          className="mobile"
          src={primary.announcement_image_mobile.url}
          alt={primary.announcement_image_mobile.alt}
        />
      </a>
    </article>
  )
}

export default AnnouncementBanner
