import { useState, useEffect } from "react"
import cx from "classnames"
import PlayLargeIcon from "../../../icons/PlayLargeIcon"
import playerData from "../utils/playerData"
import styles from "../styles/PlayAll.module.scss"

const PlayAll = ({ selectedRound }) => {
  const [songVersions, setSongVersions] = useState([])

  useEffect(() => {
    setSongVersions(playerData(selectedRound))
  }, [selectedRound])

  const handlePlayAll = () => {
    mm.playerProxy.trigger("play", {
      songVersions,
      type: "mixtape",
    })
  }

  return (
    <button
      onClick={handlePlayAll}
      className={cx(styles.playAll, { [styles.disabled]: songVersions.length === 0 })}
    >
      <PlayLargeIcon />
    </button>
  )
}

export default PlayAll
