import React, { Component } from "react"
import { Form, Field, ErrorMessage } from "formik"
import cx from "classnames"
import Cards from "./Cards"
import CheckboxField from "../../../shared/components/CheckboxField"
import FormFlashMsg from "../../../shared/components/FormFlashMsg"
import MaskedTextField from "../../../shared/components/MaskedTextField"
import MasterLicenseAgreement from "./MasterLicenseAgreement"
import ScrollToError from "../../../shared/components/ScrollToError"
import SelectedLicenses from "./SelectedLicenses"
import SelectField from "../../../shared/components/SelectField"
import TextField from "../../../shared/components/TextField"
import styles from "../styles/CheckoutForm.module.scss"
import formStyles from "../../../shared/styles/form.module.scss"
import { monthOptions, yearOptions } from "../../../shared/data/selectOptions"
import { showErrorBorder } from "../../../shared/utils/form"
import { countryOptions, regionOptions } from "../../../shared/data/selectOptions"

class CheckoutForm extends Component {
  state = {
    regions: [],
    cardFieldsDisabled: false,
    showMLA: false,
    cardType: "",
    cardMessage:
      "Sweet! You got a 100% discount! We still need your billing info, but you won't be charged.",
  }

  handleSelectedCountry = (fieldName, selectedValue) => {
    const { setFieldValue, values } = this.props
    const selectedCountry = selectedValue.value
    const prevCountry = values.billing_information_attributes.address_attributes.country.value
    setFieldValue(fieldName, selectedValue)

    if (selectedCountry !== "" && selectedCountry !== prevCountry) {
      this.setState({ regions: regionOptions(selectedCountry) })
      setFieldValue("billing_information_attributes.address_attributes.subregion", {
        label: "Select a region...",
        value: "",
      })
    }
  }

  handleSavedCard = (fieldName, selectedValue) => {
    const { setFieldValue } = this.props
    setFieldValue(fieldName, selectedValue)

    if (selectedValue.value === "Add New Card") {
      const ccFields = ["card_number", "cvc", "exp_month", "exp_year"]
      ccFields.forEach((field) => setFieldValue(field, ""))
      this.setState({ cardFieldsDisabled: false, cardType: "" })
    } else {
      const [last4, month, year] = selectedValue.label.match(/\d+/g)
      let cardType = selectedValue.label.split(" ******")[0].toLowerCase()
      cardType = cardType === "american express" ? "amex" : cardType

      setFieldValue("card_number", `000000000000${last4}`)
      setFieldValue("exp_month", { label: month, value: month })
      setFieldValue("exp_year", { label: year, value: year })
      setFieldValue("cvc", "***")
      setFieldValue("save_new_card", false)
      this.setState({ cardFieldsDisabled: true, cardType })
    }
  }

  onCreditCardTypeChanged = (type) => {
    if (type === "unknown") return false
    this.setState({ cardType: type })
  }

  toggleMasterLicenseAgreement = (e) => {
    e.preventDefault()
    this.setState({ showMLA: !this.state.showMLA })
  }

  render() {
    const { cardFieldsDisabled, showMLA, regions } = this.state
    const {
      values,
      setFieldValue,
      setFieldTouched,
      isSubmitting,
      isCustom,
      showSavedCards,
      errors,
      touched,
      licenseVersions,
      lineItems,
      masterLicenseAgreement,
      orderId,
      isClick,
      savedCards,
      isLoggedIn,
      orderType,
      discount,
      subtotal,
    } = this.props

    return (
      <Form className="checkout-form">
        {errors.server && <FormFlashMsg type="public" status="error" messages={errors.server} />}
        {subtotal > 0 && discount === subtotal && (
          <FormFlashMsg type="public" status="warning" messages={[this.state.cardMessage]} />
        )}
        <ScrollToError />

        <h4 className="hFour">Billing Information</h4>
        <fieldset className="inputs customer-information">
          <Field type="hidden" name="id" value={orderId} />
          <div className={formStyles.columnWrapper}>
            <div className={formStyles.column}>
              <div className={formStyles.groupedInputs}>
                <TextField
                  label="First Name*"
                  fieldName="billing_information_attributes.first_name"
                  showErrorBorder={showErrorBorder(
                    errors,
                    touched,
                    "billing_information_attributes.first_name"
                  )}
                />
                <TextField
                  label="Last Name*"
                  fieldName="billing_information_attributes.last_name"
                  showErrorBorder={showErrorBorder(
                    errors,
                    touched,
                    "billing_information_attributes.last_name"
                  )}
                />
              </div>
              <TextField
                label="Company/Organization"
                fieldName="billing_information_attributes.company"
              />
              <TextField
                label="End User* (Who is actually using the song?)"
                fieldName="billing_information_attributes.end_client"
                showErrorBorder={showErrorBorder(
                  touched,
                  errors,
                  "billing_information_attributes.end_client"
                )}
              />
              <TextField
                label="Email*"
                fieldName="billing_information_attributes.email"
                type="email"
                showErrorBorder={showErrorBorder(
                  errors,
                  touched,
                  "billing_information_attributes.email"
                )}
              />
              <TextField
                label="Phone Number"
                fieldName="billing_information_attributes.phone"
                type="tel"
              />
            </div>
            <div className={formStyles.column}>
              <TextField
                label="Billing Address*"
                fieldName="billing_information_attributes.address_attributes.line1"
                showErrorBorder={showErrorBorder(
                  errors,
                  touched,
                  "billing_information_attributes.address_attributes.line1"
                )}
              />
              <TextField
                label="Billing Address Line 2"
                fieldName="billing_information_attributes.address_attributes.line2"
                placeholder="Apt, suite, unit, building, etc."
              />
              <div className="country select">
                <SelectField
                  label="Country*"
                  fieldName="billing_information_attributes.address_attributes.country"
                  options={countryOptions}
                  value={values.billing_information_attributes.address_attributes.country}
                  onChange={this.handleSelectedCountry}
                  onBlur={setFieldTouched}
                  showErrorBorder={showErrorBorder(
                    errors,
                    touched,
                    "billing_information_attributes.address_attributes.country"
                  )}
                />
              </div>
              <TextField
                label="City*"
                fieldName="billing_information_attributes.address_attributes.city"
                showErrorBorder={showErrorBorder(
                  errors,
                  touched,
                  "billing_information_attributes.address_attributes.city"
                )}
              />
              <div className={formStyles.groupedInputs}>
                <SelectField
                  label="State/Region*"
                  fieldName="billing_information_attributes.address_attributes.subregion"
                  options={this.state.regions}
                  value={values.billing_information_attributes.address_attributes.subregion}
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                  isDisabled={regions.length === 0}
                  showErrorBorder={showErrorBorder(
                    errors,
                    touched,
                    "billing_information_attributes.address_attributes.subregion"
                  )}
                />
                <TextField
                  label="Postal Code*"
                  className="postalCode"
                  fieldName="billing_information_attributes.address_attributes.postal_code"
                  showErrorBorder={showErrorBorder(
                    errors,
                    touched,
                    "billing_information_attributes.address_attributes.postal_code"
                  )}
                />
              </div>
            </div>
            <div className={cx(formStyles.column, styles.paymentFields)}>
              <div className="payment-information">
                <Cards activeType={values.card_number === "" ? "" : this.state.cardType} />
                {isLoggedIn && showSavedCards && savedCards.length > 1 && (
                  <div className="inline">
                    <SelectField
                      label="Saved Cards"
                      fieldName="saved_card"
                      inputClass="saved-cards"
                      options={savedCards}
                      value={values.saved_card}
                      onChange={this.handleSavedCard}
                      onBlur={setFieldTouched}
                      placeholder="Select a saved card"
                    />
                  </div>
                )}
                <MaskedTextField
                  fieldName="card_number"
                  label="Card Number*"
                  disabled={cardFieldsDisabled}
                  showErrorBorder={touched.card_number && errors.card_number}
                  options={{
                    creditCard: true,
                    numericOnly: false,
                    onCreditCardTypeChanged: this.onCreditCardTypeChanged,
                  }}
                />
                <div className={formStyles.groupedInputs}>
                  <SelectField
                    label="Exp Month*"
                    fieldName="exp_month"
                    isDisabled={cardFieldsDisabled}
                    options={monthOptions}
                    value={values.exp_month}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    data-stripe="exp-month"
                    showErrorBorder={touched.exp_month && errors.exp_month}
                  />
                  <SelectField
                    label="Exp Year*"
                    fieldName="exp_year"
                    isDisabled={cardFieldsDisabled}
                    options={yearOptions()}
                    value={values.exp_year}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    data-stripe="exp-year"
                    showErrorBorder={touched.exp_year && errors.exp_year}
                  />
                  <TextField
                    label="CVC*"
                    fieldName="cvc"
                    className="cvc"
                    disabled={cardFieldsDisabled}
                    maxLength="4"
                    data-stripe="cvc"
                    showErrorBorder={touched.cvc && errors.cvc}
                  />
                </div>
                {isClick && (
                  <TextField label="Order Notes (PO Number, Tax ID, etc)" fieldName="notes" />
                )}
                {isLoggedIn && showSavedCards && savedCards.length < 10 && (
                  <CheckboxField
                    label="Save this card"
                    fieldName="save_new_card"
                    disabled={cardFieldsDisabled || savedCards.length > 9}
                    checked={values.save_new_card}
                  />
                )}
              </div>
            </div>
          </div>
        </fieldset>

        <SelectedLicenses
          licenseVersions={licenseVersions}
          lineItems={lineItems}
          isCustom={isCustom}
        />

        <MasterLicenseAgreement
          masterLicenseAgreement={masterLicenseAgreement}
          isCustom={isCustom}
          showMLA={showMLA}
        />

        <div className="form-submit">
          <div className="agree-to-terms">
            <div className="checkbox-field-row" aria-label="License terms agreement checkbox">
              <Field
                type="checkbox"
                name="agree_to_terms"
                checked={values.agree_to_terms}
                data-cy="agree_to_terms"
                aria-label="Agree to the legal terms"
                aria-checked="false"
              />
              <label>
                I have read the{" "}
                <a href="#" className="mla-link" onClick={this.toggleMasterLicenseAgreement}>
                  {" "}
                  Marmoset Legal Agreement
                </a>{" "}
                and agree to the terms.
              </label>
            </div>
            <ErrorMessage name="agree_to_terms" className={formStyles.errorMsg} component="div" />
          </div>

          <button
            type="submit"
            className={cx("box-button-black", {
              disabled: !values.agree_to_terms,
            })}
            disabled={(isSubmitting, !values.agree_to_terms)}
            data-cy="submit-order"
          >
            {isSubmitting ? "Submitting..." : "Submit Order"}
            <span className={cx({ spinner: isSubmitting })} />
          </button>
        </div>
      </Form>
    )
  }
}

export default CheckoutForm
