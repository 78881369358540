import { useMutation } from "@apollo/client"
import { DELETE_ALL_DIRECTIONS } from "../../../graphql/mutations/directions"

const DeleteAllDirections = ({ selectedRound }) => {
  const { id, name } = selectedRound[0]
  const [deleteAllDirections] = useMutation(DELETE_ALL_DIRECTIONS)

  const postDelete = () => {
    const confirmation = confirm(
      `Are you sure you want to delete all of the directions in ${name}?`
    )

    if (confirmation) {
      deleteAllDirections({ variables: { id } }).catch((err) => console.log(err))
    }
  }

  return (
    <button className="delete action" onClick={postDelete}>
      Delete All Directions
    </button>
  )
}

export default DeleteAllDirections
