import cx from "classnames"
import { useRefinementList } from "react-instantsearch-hooks-web"
import InstrumentsListItem from "./InstrumentsListItem"
import { transformInstruments } from "./FilterTransforms"
import { useWorkbenchStore } from "../../store/store"

const WorkbenchFilterInstruments = ({ id }) => {
  const currentFilter = useWorkbenchStore((state) => state.currentFilter)
  const { items, refine } = useRefinementList({
    attribute: "instrument_list",
    limit: 50,
    operator: "and",
    transformItems: transformInstruments,
  })

  return (
    <fieldset id={id} className={cx("mm-filter", currentFilter === id ? "current" : "")}>
      {/* Todo: Determine if we need this feature? */}
      {/* <div className="instrument-mute">
        <div className="icon">
          <span className="default selected">{ <%= show_svg('volume_on.svg') %> }</span>
          <span className="muted">{ <%= show_svg('volume_off.svg') %> }</span>
        </div>
      </div> */}
      <ul>
        {items &&
          items.map((item, index) => (
            <InstrumentsListItem key={index} item={item} refine={refine} />
          ))}
      </ul>
    </fieldset>
  )
}

export default WorkbenchFilterInstruments
