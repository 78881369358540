const VisaIcon = () => {
  return (
    <svg className="visaIcon" height="48px" width="48px" viewBox="0 0 48 48">
      <path
        fill="#1565c0"
        d="M45 35c0 2.21-1.79 4-4 4H7c-2.21 0-4-1.79-4-4V13c0-2.21 1.79-4 4-4h34c2.21 0 4 1.79 4 4z"
      />
      <path
        fill="#fff"
        d="M15.188 19l-2.63 7.832s-.663-3.312-.73-3.73c-1.496-3.41-3.703-3.22-3.703-3.22L10.727 30v-.004h3.16L18.257 19zm2.5 11h2.87l1.739-11H19.39zm20.32-11h-3.02l-4.71 11h2.85l.59-1.57h3.594l.305 1.57h2.613zm-3.496 7.328l1.562-4.156.82 4.156zm-8.145-4.121c0-.605.5-1.059 1.93-1.059.926 0 1.988.676 1.988.676l.469-2.308S29.394 19 28.062 19c-3.019 0-4.578 1.441-4.578 3.27 0 3.308 3.98 2.855 3.98 4.55 0 .293-.23.965-1.89.965s-2.758-.61-2.758-.61l-.496 2.22s1.067.605 3.117.605c2.06 0 4.918-1.54 4.918-3.754 0-2.66-3.988-2.851-3.988-4.039z"
      />
      <path
        fill="#ffc107"
        d="M12.21 24.945l-.964-4.75s-.437-1.027-1.574-1.027H5.234s5.66 1.672 6.977 5.777z"
      />
    </svg>
  )
}

export default VisaIcon
