import { useMutation } from "@apollo/client"
import cx from "classnames"
import { DUPLICATE_PROJECT_SEARCH } from "../../../graphql/mutations/projectSearches"

const DuplicateProjectSearch = ({ projectSearchId }) => {
  const [duplicateProjectSearch] = useMutation(DUPLICATE_PROJECT_SEARCH)

  const postDuplicateSearch = () => {
    duplicateProjectSearch({ variables: { id: projectSearchId } })
      .then((result) => {
        let redirect = result.data.duplicateProjectSearch.projectSearch.slug
        mm.router.navigate(`/project_searches/${redirect}`, { trigger: true })
      })
      .catch((error) => Sentry.captureException(error))
  }

  return (
    <button
      className={cx("action new-round")}
      onClick={postDuplicateSearch}
      data-cy="duplicateSearch"
    >
      Duplicate Search
    </button>
  )
}

export default DuplicateProjectSearch
