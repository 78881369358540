import { gql, useQuery } from "@apollo/client"
import OrderItemTouch from "./components/OrderItemTouch"
import OrderItem from "./components/OrderItem"
import OrderItemLoading from "./components/OrderItemLoading"
import styles from "./OrderHistoryContainer.module.scss"
import orderItemStyles from "./styles/OrderItem.module.scss"

const GET_USER_ORDERS = gql`
  {
    account {
      orders {
        id
        allowClientToSelectTbd
        endClient
        purchaseDate
        total
        showDownloadLink
        downloadToken
        lineItems {
          id
          songVersionTitle
          songVersionId
          artistName
          clientNotes
          license
          price
          showDownloadLink
          showDownloadStemsLink
          stemsAvailableUponRequest
        }
      }
    }
  }
`

const OrderHistoryContainer = () => {
  const { loading, error, data } = useQuery(GET_USER_ORDERS)

  const renderOrders = () => {
    if (error)
      return (
        <div className={styles.errorMessage}>An error has occurred. Please reload the page.</div>
      )
    const {
      account: { orders },
    } = data

    if (orders.length < 1)
      return <div className={styles.noOrders}>You don't have any orders yet :(</div>

    return _.map(orders, (order) => {
      if (mm.isMobile) {
        return <OrderItemTouch order={order} key={order.id} />
      } else {
        return <OrderItem order={order} key={order.id} />
      }
    })
  }

  return (
    <ol className={styles.orderTable}>
      <li className={styles.orderTableHead}>
        <ol>
          <div className={orderItemStyles.left}>
            <li className={styles.orderNumber}>Order Number</li>
            <li className={styles.endClient}>End Client</li>
          </div>
          <div className={orderItemStyles.right}>
            <li className={styles.purchaseDate}>Purchase Date</li>
            <li className={styles.total}>Total</li>
          </div>
        </ol>
      </li>
      <div className={styles.orderTableList}>{loading ? <OrderItemLoading /> : renderOrders()}</div>
    </ol>
  )
}

export default OrderHistoryContainer
