import { useContext } from "react"
import cx from "classnames"
import styles from "../../styles/Subnav.module.scss"
import DropdownMenu from "../DropdownMenu"
import { PrismicPageContext } from "../../utils/prismicPageParser"
import { replaceMMUrl, generateUniqueKey, filterItems } from "../../utils/helpers"

const Subnav = ({ slice }) => {
  const colorTheme = useContext(PrismicPageContext)
  const { primary, items } = slice

  const regular = primary.nav_type === "regular"
  const anchor = primary.nav_type === "anchor"
  const tags = primary.nav_type === "tags"

  const makeMobileOptions = (items, nav_type) => {
    let options = []
    if (nav_type === "tags") {
      options.push()
    } else {
      options.push({ text: "Go to:", value: "default" })
    }
    items.map((item) => {
      if (nav_type === "regular") {
        options.push({ text: item.subnav_item_text, value: replaceMMUrl(item.subnav_item_url.url) })
      } else if (nav_type === "anchor") {
        options.push({ text: item.subnav_item_text, value: "#" + item.subnav_item_anchor })
      } else {
        options.push({ text: item, value: item })
      }
    })
    return options
  }

  return (
    <div className={styles.subnavContain}>
      <nav
        className={cx(
          styles.subnav,
          "fullWidth",
          "prismicSubnav",
          regular && styles[colorTheme],
          anchor && styles.anchorSubnav,
          tags && styles.anchorSubnav
        )}
        aria-label="Sub Menu"
        data-type={primary.nav_type}
      >
        <div className={regular ? styles.contain : undefined}>
          <ul className={styles.subnavItems} aria-label="Sub Menu" key={primary.uid}>
            {primary.nav_type === "tags"
              ? items.map((item) => {
                  let uid = generateUniqueKey()
                  return (
                    <li className={cx(styles.subnavItem, styles.subnavItemAnchor)} key={uid}>
                      <a
                        href={`#${item}`}
                        data-bypass="true"
                        onClick={() => {
                          filterItems(item)
                        }}
                      >
                        {item}
                      </a>
                    </li>
                  )
                })
              : items.map((item) => {
                  const { subnav_item_anchor, subnav_item_url, subnav_item_text, uid } = item
                  return (
                    <>
                      {regular && subnav_item_url.url && (
                        <li className={styles.subnavItem} key={uid}>
                          <a href={replaceMMUrl(subnav_item_url.url)} data-bypass="true">
                            {subnav_item_text}
                          </a>
                        </li>
                      )}
                      {anchor && (
                        <li className={cx(styles.subnavItem, styles.subnavItemAnchor)} key={uid}>
                          <a href={`#${subnav_item_anchor}`} data-bypass="true">
                            {subnav_item_text}
                          </a>
                        </li>
                      )}
                    </>
                  )
                })}
          </ul>
        </div>
      </nav>
      <DropdownMenu
        nav_type={primary.nav_type}
        items={makeMobileOptions(items, primary.nav_type)}
      />
    </div>
  )
}

export default Subnav
