import cx from "classnames"
import { useRefinementList } from "react-instantsearch-hooks-web"
import MoodsListItem from "./MoodsListItem"
import { transformMoods } from "./FilterTransforms"
import { useWorkbenchStore } from "../../store/store"

const WorkbenchFilterMood = ({ id }) => {
  const currentFilter = useWorkbenchStore((state) => state.currentFilter)
  const { items, refine } = useRefinementList({
    attribute: "mood_list",
    limit: 100,
    operator: "and",
    sortBy: ["name:asc"],
    transformItems: transformMoods,
  })

  return (
    <fieldset id={id} className={cx("mm-filter", currentFilter === id ? "current" : "")}>
      <ul>
        {items &&
          items.map((item, index) => <MoodsListItem key={index} item={item} refine={refine} />)}
      </ul>
    </fieldset>
  )
}

export default WorkbenchFilterMood
