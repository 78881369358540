const FilterInstrumentalIcon = () => {
  return (
    <svg className="filterInstrumentalIcon" viewBox="0 0 200 200">
      <g fill="#21928e" fillRule="nonzero">
        <path d="M25.7 25.8c-1.6 1.7-1.7 8.3-1.7 74.4 0 69 .1 72.6 1.8 74.1 1.5 1.4 4.1 1.7 13.5 1.7H51v-48h-2.6c-1.4 0-3.4-.5-4.5-1-1.8-1-1.9-2.7-1.9-52V24h-7.3c-5.9 0-7.7.4-9 1.8z" />
        <path d="M50 71.5V119h9V24h-9v47.5zM68 74c0 48.7-.1 50.1-2 52-1.1 1.1-3.1 2-4.5 2H59v48h37v-47.7l-3.9-.7c-2.2-.3-4.1-1.3-4.5-2.2-.3-.8-.6-24-.6-51.5V24H68v50z" />
        <path d="M95 71.5V119h10V24H95v47.5z" />
        <path d="M113 73.9c0 27.5-.3 50.7-.6 51.5-.4.9-2.3 1.9-4.5 2.2l-3.9.7V176h37v-48h-2.5c-1.4 0-3.4-.9-4.5-2-1.9-1.9-2-3.3-2-52V24h-19v49.9zM141 71.5V119h9V24h-9v47.5z" />
        <path d="M158 75c0 49.3-.1 51-1.9 52-1.1.5-3.1 1-4.5 1H149v48h11.8c10.3 0 12.1-.3 13.5-1.8 1.6-1.7 1.7-8.3 1.7-74.4C176 16 177.1 24 165.2 24H158v51z" />
      </g>
    </svg>
  )
}

export default FilterInstrumentalIcon
