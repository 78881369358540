import { Fragment } from "react"
import cx from "classnames"
import { FieldArray, Field, Form, ErrorMessage } from "formik"
import ScrollToError from "../../../shared/components/ScrollToError"
import SelectField from "../../../shared/components/SelectField"
import TextAreaField from "../../../shared/components/TextAreaField"
import TextField from "../../../shared/components/TextField"
import { distributionOptions } from "../../../shared/data/checkboxOptions"
import { exclusivityOptions } from "../../../shared/data/selectOptions"
import { showErrorBorder } from "../../../shared/utils/form"
import formStyles from "../../../shared/styles/form.module.scss"

const CustomRequestForm = (props) => {
  const {
    values,
    errors,
    status,
    touched,
    setFieldValue,
    setFieldTouched,
    isSubmitting,
    messageRef,
    songVersion,
  } = props

  const handleCheckbox = (e, val, arrayHelpers) => {
    const checkboxInput = e.currentTarget.previousElementSibling

    if (checkboxInput.checked) {
      const ind = values.distribution.indexOf(val)
      arrayHelpers.remove(ind)
    } else {
      arrayHelpers.push(val)
    }
  }

  return (
    <Fragment>
      <div id="custom-request-response-container" ref={messageRef}>
        {status && status.message && (
          <div className="response-message" data-cy="custom-request-response-msg">
            <div>
              <p>{status.message}</p>
              {status.errors.map((error, ind) => (
                <p key={ind}>{error}</p>
              ))}
            </div>
          </div>
        )}
      </div>

      <article className="mm-react-form custom-request-form">
        <div className="contain">
          {songVersion && (
            <div style={{ fontSize: 15, paddingBottom: 15 }}>
              <span>
                <strong>Song Version/Artist: </strong>
                {songVersion.display_name} by {songVersion.artist.name}
              </span>
            </div>
          )}
          <Form id="new-custom-request">
            <Field type="hidden" name="song_version_id" />
            <ScrollToError />
            <fieldset className="form-inputs">
              <ol>
                <TextField
                  label="Your Name"
                  fieldName="name"
                  dataCy="name"
                  placeholder="First Last"
                  inputClass={cx({
                    invalid: showErrorBorder(errors, touched, "name"),
                  })}
                />
                <TextField
                  label="Your Email"
                  fieldName="email"
                  dataCy="email"
                  placeholder="your@email.com"
                  type="email"
                  inputClass={cx({
                    invalid: showErrorBorder(errors, touched, "email"),
                  })}
                />
                <TextField
                  label="Your Phone Number"
                  fieldName="phone"
                  dataCy="phone"
                  placeholder="(111) 111-1111"
                  inputClass={cx({
                    invalid: showErrorBorder(errors, touched, "phone"),
                  })}
                />
                <TextField
                  label="Your Company"
                  fieldName="company"
                  dataCy="company"
                  placeholder="If for personal use, your name will suffice here."
                  inputClass={cx({
                    invalid: showErrorBorder(errors, touched, "company"),
                  })}
                />
                <TextField
                  label="Where is your company located?"
                  fieldName="company_location"
                  dataCy="company_location"
                  placeholder="City, State, Country"
                  inputClass={cx({
                    invalid: showErrorBorder(errors, touched, "company"),
                  })}
                />
                <TextField
                  label="End Client"
                  fieldName="end_client"
                  dataCy="end_client"
                  placeholder="Who is the end client for this project?"
                  inputClass={cx({
                    invalid: showErrorBorder(errors, touched, "end_client"),
                  })}
                />
                <TextField
                  label="Project Title"
                  fieldName="project_title"
                  dataCy="project_title"
                  placeholder="What's a good project title we can file this under?"
                  inputClass={cx({
                    invalid: showErrorBorder(errors, touched, "project_title"),
                  })}
                />

                <FieldArray name="distribution">
                  {(arrayHelpers) => (
                    <div data-cy="distribution">
                      <label>Distribution</label>
                      <ErrorMessage
                        name="distribution"
                        className={cx(formStyles.errorMsg, "error-msg")}
                        component="div"
                      />
                      <label className="sub-label">
                        Where will this be seen? Please select all that apply.
                      </label>
                      {distributionOptions.map((distributionOption) => {
                        return (
                          <div key={distributionOption.value} className="checkbox">
                            <Field
                              type="checkbox"
                              name="distributionCheckbox"
                              value={distributionOption.value}
                              checked={values.distribution?.includes(distributionOption.value)}
                              aria-label={distributionOption.value}
                              aria-checked="false"
                            />
                            <label
                              onClick={(e) =>
                                handleCheckbox(e, distributionOption.value, arrayHelpers)
                              }
                            />
                            <p className="label">{distributionOption.label}</p>
                          </div>
                        )
                      })}
                    </div>
                  )}
                </FieldArray>
                {values.distribution?.includes("other") && (
                  <TextField
                    label="Other Distribution Information"
                    fieldName="other"
                    placeholder="If you selected Other above, please enter additional distribution information here."
                  />
                )}

                <TextField
                  label="Term"
                  fieldName="term"
                  dataCy="term"
                  placeholder="How long will this be live in total? Do all distributions require the same term?"
                  inputClass={cx({
                    invalid: showErrorBorder(errors, touched, "term"),
                  })}
                />
                <TextField
                  label="Territory"
                  fieldName="territory"
                  dataCy="territory"
                  placeholder="Please specify all regions (Please note Unpaid Web is Worldwide by default)."
                  inputClass={cx({
                    invalid: showErrorBorder(errors, touched, "territory"),
                  })}
                />
                <TextField
                  label="Use"
                  fieldName="use"
                  dataCy="use"
                  placeholder="How many videos? Will there be versions and/or cutdowns? If yes, how many?"
                  inputClass={cx({
                    invalid: showErrorBorder(errors, touched, "use"),
                  })}
                />
                <TextField
                  label="Budget"
                  fieldName="music_budget"
                  dataCy="music_budget"
                  placeholder="Please share your allocated music budget for this project."
                  inputClass={cx({
                    invalid: showErrorBorder(errors, touched, "music_budget"),
                  })}
                />
                <SelectField
                  label="Exclusivity"
                  fieldName="exclusivity"
                  dataCy="exclusivity"
                  inputClass="cr-select"
                  options={exclusivityOptions}
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                />
                <TextAreaField
                  label="Notes"
                  fieldName="notes"
                  cols="25"
                  rows="10"
                  placeholder="Anything else we should know about your project?"
                />
              </ol>
            </fieldset>
            <div className="spin-target">
              <button
                type="submit"
                className="box-button-black"
                data-cy="custom-request-submit"
                disabled={isSubmitting}
              >
                Send Custom Request
              </button>
            </div>
          </Form>
        </div>
      </article>
    </Fragment>
  )
}

export default CustomRequestForm
