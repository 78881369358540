import React from "react"
import LineItem from "./LineItem"
import OrderTotal from "./OrderTotal"

const CheckoutDetails = (props) => {
  const { lineItems, subtotal, promoCode, discount, total, isLoggedIn } = props

  return (
    <article className="order-info">
      <div className="order-table">
        <div className="order-header">Song</div>
        <div className="order-header">Artist</div>
        <div className="order-header">License</div>
        <div className="order-header order-cost">Cost</div>
        {lineItems.map(({ id, ...lineItemInfo }) => (
          <LineItem key={id} {...lineItemInfo} />
        ))}
      </div>
      <OrderTotal
        subtotal={subtotal}
        promoCode={promoCode}
        discount={discount}
        total={total}
        isLoggedIn={isLoggedIn}
      />
    </article>
  )
}

export default CheckoutDetails
