import { useEffect, useState } from "react"
import { useWorkbenchStore } from "../../store/store"
import { sortOptions } from "./FilterTransforms"

const SortedBy = () => {
  const resetSortedIndexName = useWorkbenchStore((state) => state.resetSortedIndexName)
  const sortedIndexName = useWorkbenchStore((state) => state.sortedIndexName)
  const [footerDisplayName, setFooterDisplayName] = useState("")

  const handleClearClick = () => {
    resetSortedIndexName()
  }

  useEffect(() => {
    // Sets display name in Footer for sort option
    // index name looks like "local_song_versions_[filter value]"
    // so this finds which option has the filter value that the index name ends with
    setFooterDisplayName(sortOptions.find((option) => sortedIndexName.endsWith(option.value)).text)
  }, [sortedIndexName])

  return (
    <div className="sorted-by">
      <div className="label">Sorted By:</div>
      <ul>
        <li data-filter="sort">
          {footerDisplayName}
          <span onClick={handleClearClick}>x</span>
        </li>
        <li className="clear-all" data-clear="sort" data-cy="clearSort" onClick={handleClearClick}>
          Clear
        </li>
      </ul>
    </div>
  )
}

export default SortedBy
