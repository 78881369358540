import { useMutation } from "@apollo/client"
import { Formik } from "formik"
import * as yup from "yup"
import EditProfileForm from "./EditProfileForm"
import stateAbbrNameMap from "../../../shared/data/stateAbbrNameMap"
import { profileParams } from "../../../shared/utils/paramModifiers"
import { EDIT_PROFILE } from "../../../graphql/mutations/account"

const validationSchema = yup.object().shape({
  firstName: yup.string().required("First name is required."),
  lastName: yup.string(),
  email: yup.string().email("Email is invalid").required("Email is required."),
  profile: yup.object().shape({
    company: yup.string(),
    phoneNumber: yup.string(),
    job: yup.string(),
    address: yup.object().shape({
      line1: yup.string(),
      line2: yup.string(),
      country: yup.object().shape({
        label: yup.string(),
        value: yup.string(),
      }),
      subregion: yup.object().shape({
        label: yup.string(),
        value: yup.string(),
      }),
      city: yup.string(),
      postalCode: yup.string(),
    }),
  }),
})

const EditProfileFormContainer = ({ account }) => {
  const [editProfileMutation] = useMutation(EDIT_PROFILE)
  const subregion =
    account.profile.address.country === "United States"
      ? stateAbbrNameMap[account.profile.address.subregion]
      : account.profile.address.subregion

  const handleSubmit = (params, formActions) => {
    if (_.isEqual(params, initialValues)) {
      formActions.setStatus({ msg: "No changes to update." })
      formActions.setSubmitting(false)
      return
    }

    const variables = { account: profileParams(params, initialValues) }

    editProfileMutation({ variables })
      .then(({ data }) => {
        const {
          editProfile: { errors },
        } = data

        if (errors.length > 0) {
          formActions.setStatus({ error: true, msg: errors[0] })
        } else {
          formActions.setStatus({ success: true, msg: "Your profile has been updated!" })
        }
        formActions.setSubmitting(false)
      })
      .catch((err) => console.log(err))
  }

  const initialValues = {
    id: account.id || "",
    firstName: account.firstName || "",
    lastName: account.lastName || "",
    email: account.email || "",
    otherJob: "",
    profile: {
      company: account.profile.company || "",
      phoneNumber: account.profile.phoneNumber || "",
      job: account.profile.job || "",
      address: {
        line1: account.profile.address.line1 || "",
        line2: account.profile.address.line2 || "",
        country: {
          label: account.profile.address.country || "Select...",
          value: account.profile.address.country || "",
        },
        subregion: {
          label: subregion || "Select...",
          value: subregion || "",
        },
        city: account.profile.address.city || "",
        postalCode: account.profile.address.postalCode || "",
      },
    },
  }

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={handleSubmit}
    >
      {(formikProps) => <EditProfileForm {...formikProps} />}
    </Formik>
  )
}

export default EditProfileFormContainer
