import { useMutation } from "@apollo/client"
import GET_ACCOUNT from "../../../graphql/queries/GetAccount"
import { DELETE_CARD } from "../../../graphql/mutations/account"
import AmexIcon from "../../../icons/AmexIcon"
import DiscoverIcon from "../../../icons/DiscoverIcon"
import MastercardIcon from "../../../icons/MastercardIcon"
import VisaIcon from "../../../icons/VisaIcon"
import TrashIcon from "../../../icons/TrashIcon"
import styles from "../styles/SavedCard.module.scss"
import tableHeadStyles from "../styles/SavedCards.module.scss"

const SavedCard = ({ card, setResponseMessage, userId }) => {
  const { id, brandFilename, expiryDisplay, infoDisplay } = card
  const Icon = IconMap[brandFilename]
  const [deleteCard] = useMutation(DELETE_CARD, {
    refetchQueries: [
      {
        query: GET_ACCOUNT,
        variables: { userId },
      },
    ],
  })

  const handleRemoveCard = () => {
    const confirm = window.confirm(`Are you sure you want remove ${infoDisplay}?`)
    setResponseMessage(null)

    if (confirm) {
      const variables = { id }

      deleteCard({ variables })
        .then(() => {
          setResponseMessage("Your card has been removed.")
        })
        .catch((errors) => {
          setResponseMessage(errors.graphQLErrors[0].message)
        })
    }
  }

  return (
    <li className={styles.item}>
      <div className={tableHeadStyles.icon}>
        <div className={styles.brandIcon}>{Icon && <Icon />}</div>
      </div>
      <div className={tableHeadStyles.info}>{infoDisplay}</div>
      <div className={tableHeadStyles.expires}>{expiryDisplay}</div>
      <div className={tableHeadStyles.remove}>
        <div className={styles.removeIcon} onClick={handleRemoveCard}>
          <TrashIcon />
        </div>
      </div>
    </li>
  )
}

const IconMap = {
  amex: AmexIcon,
  discover: DiscoverIcon,
  mastercard: MastercardIcon,
  visa: VisaIcon,
}

export default SavedCard
