import { PrismicPageParser } from "../../shared/utils/prismicPageParser"

const CollaborationsContainer = () => {
  const data = JSON.parse(window.prismic_data)
  const pageId = window.pageId

  return (
    <section id="mm-collaborations" className="pageContainer" aria-label="Collaborations page">
      <PrismicPageParser pageData={data} pageId={pageId} />
    </section>
  )
}

export default CollaborationsContainer
