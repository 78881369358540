import { PrismicPageParser } from "../../shared/utils/prismicPageParser"
import cx from "classnames"
import styles from "./PrismicTestContainer.module.scss"

const PrismicTestContainer = () => {
  return (
    <section className={cx(styles.prismicTest, "pageContainer")}>
      <PrismicPageParser pageId={"XaYA8hIAALQV6L5T"} />
    </section>
  )
}

export default PrismicTestContainer
