import { useRefinementList } from "react-instantsearch-hooks-web"
import cx from "classnames"
import { arcs, transformArcs } from "./FilterTransforms"
import { useWorkbenchStore } from "../../store/store"
import { createContext, useContext, useEffect, useRef, useState } from "react"
import { useStateFromAlgoliaParams } from "../../shared/utils/useStateFromAlgoliaParams"
import Canvas from "../../shared/utils/Canvas"
import anime from "animejs"

const ArcContext = createContext()

const MAP = (_w, _h) => {
  return {
    steady: {
      a: { x: 0, y: 100.5 },
      b: { x: 94.5, y: 100.5 },
      c: { x: 130.5, y: 100.5 },
      d: { x: 184.5, y: 100.5 },
      e: { x: 274.5, y: 100.5 },
      f: { x: 346.5, y: 100.5 },
      g: { x: 418.5, y: 100.5 },
      h: { x: 436.5, y: 100.5 },
      i: { x: _w, y: 100.5 },
    },
    ascending: {
      a: { x: 0, y: _h },
      b: { x: 94.5, y: 172.1225 },
      c: { x: 130.5, y: 161.5 },
      d: { x: 184.5, y: 145.5725 },
      e: { x: 274.5, y: 119.0225 },
      f: { x: 346.5, y: 97.7825 },
      g: { x: 418.5, y: 76.5425 },
      h: { x: 436.5, y: 71.2325 },
      i: { x: _w, y: 38 },
    },
    descending: {
      a: { x: 0, y: 38 },
      b: { x: 94.5, y: 65.8775 },
      c: { x: 130.5, y: 76.4975 },
      d: { x: 184.5, y: 92.4275 },
      e: { x: 274.5, y: 118.9775 },
      f: { x: 346.5, y: 140.2175 },
      g: { x: 418.5, y: 161.4575 },
      h: { x: 436.5, y: 166.7675 },
      i: { x: _w, y: _h },
    },
    crescendo: {
      a: { x: 0, y: _h },
      b: { x: 94.5, y: 140.0964 },
      c: { x: 130.5, y: 117.2761 },
      d: { x: 184.5, y: 83.04545 },
      e: { x: 274.5, y: 26 },
      f: { x: 346.5, y: 71.6353 },
      g: { x: 418.5, y: 117.2761 },
      h: { x: 436.5, y: 128.6863 },
      i: { x: _w, y: _h },
    },
    multiple: {
      a: { x: 0, y: _h },
      b: { x: 94.5, y: 74.7875 },
      c: { x: 130.5, y: 27 },
      d: { x: 184.5, y: 82.498 },
      e: { x: 274.5, y: 175 },
      f: { x: 346.5, y: 101 },
      g: { x: 418.5, y: 27 },
      h: { x: 436.5, y: 50.9375 },
      i: { x: _w, y: _h },
    },
    frenetic: {
      a: { x: 0, y: 113 },
      b: { x: 94.5, y: 140 },
      c: { x: 130.5, y: 101.97 },
      d: { x: 184.5, y: 45 },
      e: { x: 274.5, y: 119 },
      f: { x: 346.5, y: 72 },
      g: { x: 418.5, y: 117.6 },
      h: { x: 436.5, y: 129 },
      i: { x: _w + 1, y: 45 },
    },
  }
}

const LINE = (_w) => {
  return {
    a: { x: 0, y: 198 },
    b: { x: 94.5, y: 198 },
    c: { x: 130.5, y: 198 },
    d: { x: 184.5, y: 198 },
    e: { x: 274.5, y: 198 },
    f: { x: 346.5, y: 198 },
    g: { x: 418.5, y: 198 },
    h: { x: 436.5, y: 198 },
    i: { x: _w, y: 198 },
  }
}

const WorkbenchFilterArcListItem = ({ item, refine }, props) => {
  const arcsToggledOn = useWorkbenchStore((state) => state.arcsToggledOn)
  const addToArcs = useWorkbenchStore((state) => state.addToArcs)
  const removeFromArcs = useWorkbenchStore((state) => state.removeFromArcs)
  const { handleHover } = useContext(ArcContext)

  const handleClick = () => {
    if (!arcsToggledOn.includes(item.value)) {
      addToArcs(item.value)
    } else {
      removeFromArcs(item.value)
    }
    refine(item.value)
  }

  return (
    <li
      className={cx(arcsToggledOn.includes(item.value) ? "selected" : "", "actionable")}
      data-val={item.dataVal}
      data-pretty={item.label}
      data-cy={`arc-option-${item.dataVal}`}
      onMouseOver={() => handleHover(item.dataVal)}
      onMouseOut={() => handleHover(null)}
    >
      <a data-prevent-default onClick={handleClick}>
        {item.label}
      </a>
    </li>
  )
}

const WorkbenchFilterArcList = (props) => {
  const { items, refine } = useRefinementList(props)
  const rows = 3

  return (
    <>
      <ul>
        {items.slice(0, rows).map((item, index) => (
          <WorkbenchFilterArcListItem key={index} item={item} refine={refine} />
        ))}
      </ul>
      {/* Form two columns of arcs per layout */}
      <ul>
        {items.slice(rows).map((item, index) => (
          <WorkbenchFilterArcListItem key={index} item={item} refine={refine} />
        ))}
      </ul>
    </>
  )
}

const WorkbenchFilterArc = ({ id }) => {
  const currentFilter = useWorkbenchStore((state) => state.currentFilter)
  const arcsToggledOn = useWorkbenchStore((state) => state.arcsToggledOn)
  const canvasRef = useRef(null)
  const [selectedArcs, setSelectedArcs] = useState({})
  const lineRef = useRef(LINE(549))

  const getLastArc = (arcs) => {
    return arcs[Object.keys(arcs)[Object.keys(arcs).length - 1]]
  }

  const handleHover = (coordName) => {
    var lastArc = getLastArc(selectedArcs)
    if (!coordName) {
      //If you stop hovering, go back to last selected arc
      lastArc ? animateLine(lastArc) : animateLine(LINE(549))
    } else {
      animateLine(MAP(549, 200)[coordName])
    }
  }

  const animateLine = (newLine) => {
    Object.keys(lineRef.current).forEach((key) => {
      anime({
        targets: lineRef.current[key],
        x: newLine[key].x,
        y: newLine[key].y,
        duration: 200,
        easing: "easeInOutQuad",
        update: () => {
          draw(canvasRef.current.getContext("2d"))
        },
      })
    })
  }

  function draw(ctx) {
    if (!ctx) return
    let width = ctx.canvas.width
    let height = ctx.canvas.height
    ctx.clearRect(0, 0, width, height)
    var i = 0
    ctx.save()

    // stripe pattern
    ctx.fillStyle = "#FFFFFF"
    for (i; i < 549; i += 9) {
      ctx.fillRect(i, 0, 9, height)
      i += 9 // skip
    }

    // draft lines
    ctx.lineWidth = 1
    ctx.strokeStyle = "#e3e3e3"
    for (var key in MAP(width, height)) {
      ctx.beginPath()
      for (var k in MAP(width, height)[key]) {
        if (k === "a") {
          ctx.moveTo(MAP(width, height)[key][k].x, MAP(width, height)[key][k].y)
        } else {
          ctx.lineTo(MAP(width, height)[key][k].x, MAP(width, height)[key][k].y)
        }
      }
      ctx.stroke()
      ctx.closePath()
    }

    // morphing line
    ctx.lineWidth = 4
    ctx.strokeStyle = "#745bb4"
    ctx.beginPath()
    for (var key in lineRef.current) {
      if (key === "a") {
        ctx.moveTo(lineRef.current[key].x, lineRef.current[key].y)
      } else {
        ctx.lineTo(lineRef.current[key].x, lineRef.current[key].y)
      }
    }
    ctx.stroke()
    ctx.closePath()

    // all selected arcs
    ctx.lineWidth = 4
    ctx.strokeStyle = "#21928e"
    ctx.beginPath()
    for (var key in selectedArcs) {
      for (var k in selectedArcs[key]) {
        if (k === "a") {
          ctx.moveTo(selectedArcs[key][k].x, selectedArcs[key][k].y)
        } else {
          ctx.lineTo(selectedArcs[key][k].x, selectedArcs[key][k].y)
        }
      }
    }
    ctx.stroke()
    ctx.closePath()

    ctx.restore()
  }

  // Initialize only
  useEffect(() => {
    if (arcsToggledOn && arcsToggledOn.length > 0) {
      let newSelectedArcs = {}
      for (var i in arcsToggledOn) {
        let label = arcsToggledOn[i].toLowerCase().split(" ")[0]
        label = label === "middle" ? "crescendo" : label
        newSelectedArcs[label] = MAP(549, 200)[label]
      }

      var lastArc = getLastArc(newSelectedArcs)
      animateLine(lastArc)
    }
  }, [])

  useEffect(() => {
    let newSelectedArcs = {}
    for (var i in arcsToggledOn) {
      let label = arcsToggledOn[i].toLowerCase().split(" ")[0]
      label = label === "middle" ? "crescendo" : label
      newSelectedArcs[label] = MAP(549, 200)[label]
    }

    setSelectedArcs(newSelectedArcs)
    if (arcsToggledOn.length < 1) animateLine(LINE(549))
    else {
      var lastArc = getLastArc(newSelectedArcs)
      animateLine(lastArc)
    }
  }, [arcsToggledOn])

  return (
    <ArcContext.Provider value={{ handleHover }}>
      <fieldset id={id} className={cx("mm-filter", currentFilter === id ? "current" : "")}>
        <Canvas width="549" height="200" id="mm-arc-canvas" draw={draw} ref={canvasRef} />
        <WorkbenchFilterArcList attribute="arc" operator="or" transformItems={transformArcs} />
      </fieldset>
    </ArcContext.Provider>
  )
}

export default WorkbenchFilterArc
