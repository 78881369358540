import { useContext } from "react"
import { ProjectSearchContext } from "./ProjectSearch"
import FlashlightIcon from "../../../icons/FlashlightIcon"

const ProjectSearchHeader = ({ children }) => {
  const { createdAt, pointPerson, updatedAt, songCount } = useContext(ProjectSearchContext)

  return (
    <header id="mm-project-search-landing">
      <div className="cover-image" />
      <div className="heading">
        <div className="contain">
          <div id="project-search-info">
            <div className="project-search-icon">
              <FlashlightIcon />
            </div>
            {children}
          </div>
          <div className="info">
            <ul>
              <li>
                <em>Created by </em>
                {pointPerson}
                <em> on </em>
                <time dateTime={createdAt}>{createdAt}</time>
              </li>
              <li>
                <em>Last updated </em>
                <time dateTime={updatedAt}>{updatedAt}</time>
              </li>
              <li>
                {songCount}
                <em> {songCount == 1 ? "Song" : "Songs"} </em>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </header>
  )
}

export default ProjectSearchHeader
