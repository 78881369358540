import { useState, useContext, useEffect } from "react"
import cx from "classnames"
import LazyLoad from "../../../shared/components/LazyLoad"
import Modal from "../../../shared/components/Modal"
import VideoPlayer from "./VideoPlayer"
import PlayIcon from "../../../../source/icons/PlayIcon"
import styles from "../../../shared/styles/VideoGridItem.module.scss"
import {
  resizeVimeoThumbnail,
  escapeToClose,
  createVimeoSrcSet,
} from "../../../shared/utils/helpers"
import { PrismicPageContext } from "../../../shared/utils/prismicPageParser"
import MusicalNotesIcon from "../../../icons/MusicalNotesIcon"

const VideoGridItem = ({ video, featuredComposer, tags, services }) => {
  const colorTheme = useContext(PrismicPageContext)
  const [showThumb, setShowThumb] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const { title, thumbnail_url, video_id } = video

  useEffect(() => {
    return escapeToClose(setOpenModal)
  })

  const handleMouseEnter = () => {
    if (!openModal) setShowThumb(true)
  }

  const handleMouseLeave = () => {
    if (!openModal) setShowThumb(false)
  }

  const toggleModal = (e) => {
    e.stopPropagation()
    setOpenModal(false)
  }

  return (
    <figure
      key={video_id}
      className={cx(styles.videoGridItem, "videoGridItem")}
      data-tags={tags}
      data-services={services}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={() => setOpenModal(true)}
    >
      <div className={styles.imgWrapper}>
        <PlayIcon className={styles.playSvg} />
        <LazyLoad>
          <img
            data-src={resizeVimeoThumbnail(thumbnail_url)}
            data-srcset={createVimeoSrcSet(thumbnail_url)}
            className={cx("lazy", styles.thumb)}
          />
        </LazyLoad>
      </div>
      <figcaption className={cx("videoDetails", styles.videoDetails)}>
        <h3 className={cx("hThree")}>{title}</h3>
        <div className={styles.composerAndTags}>
          {featuredComposer && (
            <sub className={cx(styles.composer)}>
              <div className="icon">
                <MusicalNotesIcon />
              </div>
              {featuredComposer}
            </sub>
          )}
          <ul className={styles.tags} aria-label="tags">
            {tags &&
              tags.split(", ").map((tag) => {
                return (
                  <li key={tag} className={styles.tag}>
                    {tag}
                  </li>
                )
              })}
            {services &&
              services.split(", ").map((service) => {
                return (
                  <li key={service} className={styles.tag}>
                    {service}
                  </li>
                )
              })}
          </ul>
        </div>
      </figcaption>
      {openModal && (
        <Modal>
          <VideoPlayer videoId={video_id} toggleModal={toggleModal} />
        </Modal>
      )}
    </figure>
  )
}

export default VideoGridItem
