import { navigationItems } from "./FilterTransforms"

const WorkbenchFilterInfoItem = ({ item }) => {
  return (
    <article id={item.value} aria-label={`${item.label} filter information`}>
      <hgroup>
        <h5 className="hFive">{item.label}</h5>
      </hgroup>
      <p>{item.description}</p>
    </article>
  )
}

const WorkbenchFilterInfo = () => {
  return (
    <section id="mm-filter-info" aria-label="Filter information">
      {Object.keys(navigationItems).map((key, index) => (
        <WorkbenchFilterInfoItem key={index} item={navigationItems[key]} />
      ))}
    </section>
  )
}

export default WorkbenchFilterInfo
