export const distributionOptions = [
  { label: "Unpaid/Organic Web & Social Media", value: "unpaid_web_social_media" },
  { label: "Paid Web", value: "paid_web" },
  { label: "Internal", value: "internal" },
  { label: "Industrial (Events, In-Store, Trade Show)", value: "industrial" },
  {
    label: "OTT / Connected TV /SVOD (Subscription Video On Demand)",
    value: "ott_connected_tv_svod",
  },
  { label: "Television Broadcast", value: "television_broadcast" },
  { label: "Radio Broadcast", value: "radio_broadcast" },
  { label: "Film", value: "film" },
  { label: "Other / Write in option (anything else we should know about?)", value: "other" },
]
