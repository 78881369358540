import SvgIcon from "../shared/components/SvgIcon"

const PlusIcon = () => {
  return (
    <SvgIcon svgClass="PlusIcon">
      <path
        className="icon-border"
        d="M17.8606681 8.57424569c-.1639009.30840517-.253125.6575431-.253125 1.02025862V21.2489224l-4.3070043-3.2043103-4.18868535 3.1897629V9.49946121l.00193965-.03685345c.0349138-.51497845.45872845-.92715517.9804957-.94655173h7.7983836l-.0320043.05818966zm1.9231681-1.15700431c-.05625 0-.1115302.00193965-.1668103.00581896l-.0834052.00775863h-9.4034483l-.0484914.00096982c-1.14245686.02909483-2.05409479.96109914-2.05409479 2.10161638V23.4242457l5.28071119-4.0228448 5.3835129 4.0073275V9.59450431l.0009699-.03782328c.016487-.51982758.4015086-.95721982.9135776-1.040625l.3559267-.00096982c.5169181.084375.9126077.53631465.9126077 1.0794181v8.31336209h-1.7359913v1.0842672h2.8221982V9.59450431l-.0009698-.05043103c-.028125-1.18318966-.9940733-2.1268319-2.1762931-2.1268319z"
      />
      <path d="M23,14.3h-7.3V7c0-0.4-0.3-0.7-0.7-0.7S14.3,6.6,14.3,7v7.3H7c-0.4,0-0.7,0.3-0.7,0.7s0.3,0.7,0.7,0.7h7.3V23 c0,0.4,0.3,0.7,0.7,0.7s0.7-0.3,0.7-0.7v-7.3H23c0.4,0,0.7-0.3,0.7-0.7S23.4,14.3,23,14.3z" />
    </SvgIcon>
  )
}

export default PlusIcon
