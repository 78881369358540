export const MAX_DURATION = 1200000

export const navigationItems = {
  vocals: {
    label: "Vocals",
    value: "vocals",
    description:
      "Do you want vocals and words to help tell your story? Or would you prefer to do it with only instruments and a melody?",
  },
  mood: {
    label: "Mood",
    value: "mood",
    description:
      "This is the heart of your film, whether you are telling a Story, describing a Character, or simply defining the vibe of your Project. What feelings, emotions, or characteristics would best define what is at the core of your story?",
  },
  energy: {
    label: "Energy",
    value: "energy",
    description:
      "Energy represents the spirit and vigor of a story. Elements like momentum, tempo, intensity, speed and strength combine to define a story’s energy. What’s the energy of your story or character?",
  },
  arc: {
    label: "Arc",
    value: "arc",
    description:
      "The arc of a story is best described with words like crescendo or build. We think of it in the visual sense of a line graph, representing the rise, fall or steady characteristics. From left to right, what arc does your story or scene follow?",
  },
  length: {
    label: "Length",
    value: "length",
    description: "Choose the minimum and maximum length. How long is your story?",
  },
  instruments: {
    label: "Instruments",
    value: "instruments",
    description: "What prominent instruments best bring your story, character or project to life?",
  },
  genres: {
    label: "Genres",
    value: "genres",
    description:
      "Genres help us identify music as a particular sound, style, form or tradition. What is the sound of your story or character?",
  },
  customizable: {
    label: "Customizable",
    value: "customizable",
    description:
      "Some stories aren’t carved in stone...yet. Our customizable songs have open sessions, allowing us to make edits, tweaks, extensions, or full blown revisions to the song. Selecting a customizable song will initiate a direct conversation between you and our Production Team to evaluate your needs, objectives, and craft the perfect song for your project. How can we help you tell your story?",
  },
  sort: {
    label: "Sort",
    value: "sort",
    description: "What order do you want your song results in?",
  },
  internal: {
    label: "Internal",
    value: "internal",
    description: "Filter by certain catalogs or Artist Collabs/For Marmoset tracks.",
  },
}

export const arcs = [
  { label: "Steady", value: "Steady", dataVal: "steady" },
  { label: "Ascending", value: "Ascending", dataVal: "ascending" },
  { label: "Descending", value: "Descending", dataVal: "descending" },
  { label: "Middle Crescendo", value: "Middle Crescendo", dataVal: "crescendo" },
  { label: "Multiple Crescendos", value: "Multiple Crescendos", dataVal: "multiple" },
  { label: "Frenetic", value: "Frenetic", dataVal: "frenetic" },
]

export const energies = [
  { label: "Low", value: "Low", dataVal: "low" },
  { label: "Low-Medium", value: "Low-Medium", dataVal: "lowMedium" },
  { label: "Medium", value: "Medium", dataVal: "medium" },
  { label: "Medium-High", value: "Medium-High", dataVal: "mediumHigh" },
  { label: "High", value: "High", dataVal: "high" },
]

export const internal_flags = [
  { label: "For Marmoset", value: "is_for_marmoset" },
  { label: "Artist Collab", value: "is_artist_collab" },
  { label: "Prerelease", value: "is_pre_release" },
  { label: "Fervor Vintage & Fervor Contemporary", value: "is_fervor" },
  {
    label: "Do Not Pitch for TV Film",
    value: "is_not_for_tv_film",
  },
  { label: "Unflagged", value: "is_not_flagged" },
]

const genres = [
  { label: "Ambient", value: "Ambient" },
  { label: "Beats", value: "Beats" },
  { label: "Country", value: "Country" },
  { label: "Dance", value: "Dance" },
  { label: "Electronic", value: "Electronic" },
  { label: "Folk", value: "Folk" },
  { label: "Hip Hop & Rap", value: "Hip Hop & Rap" },
  { label: "Holiday", value: "Holiday" },
  { label: "Jazz", value: "Jazz" },
  { label: "Orchestral", value: "Orchestral" },
  { label: "Pop", value: "Pop" },
  { label: "Rock", value: "Rock" },
  { label: "RnB", value: "RnB" },
  { label: "Singer-Songwriter", value: "Singer-Songwriter" },
  { label: "Soul", value: "Soul" },
  { label: "Vintage", value: "Vintage" },
  { label: "World", value: "World" },
]

const instruments = [
  { label: "Acoustic Guitar", value: "Acoustic Guitar" },
  { label: "Banjo", value: "Banjo" },
  { label: "Big Drums", value: "Big Drums" },
  { label: "Drum Machine", value: "Drum Machine" },
  { label: "Electric Guitar", value: "Electric Guitar" },
  { label: "Glockenspiel/Toy Piano", value: "Glockenspiel/Toy Piano" },
  { label: "Heavy Bass", value: "Heavy Bass" },
  { label: "Horns/Brass", value: "Horns/Brass" },
  { label: "Mandolin", value: "Mandolin" },
  { label: "Oohs & Ahhs", value: "Oohs & Ahhs" },
  { label: "Organ", value: "Organ" },
  { label: "Piano", value: "Piano" },
  { label: "Stomps/Claps", value: "Stomps/Claps" },
  { label: "Strings", value: "Strings" },
  { label: "Synthesizer", value: "Synthesizer" },
  { label: "Ukulele", value: "Ukulele" },
]

const moods = [
  { label: "A Journey", value: "A Journey", dataVal: "a journey" },
  { label: "Aggressive", value: "Aggressive", dataVal: "aggressive" },
  { label: "Angelic", value: "Angelic", dataVal: "angelic" },
  { label: "Anthemic", value: "Anthemic", dataVal: "anthemic" },
  { label: "Bouncy", value: "Bouncy", dataVal: "bouncy" },
  { label: "Bright", value: "Bright", dataVal: "bright" },
  { label: "Burdened", value: "Burdened", dataVal: "burdened" },
  { label: "Calm", value: "Calm", dataVal: "calm" },
  { label: "Cinematic", value: "Cinematic", dataVal: "cinematic" },
  { label: "Classic & Retro", value: "Classic & Retro", dataVal: "classic & retro" },
  { label: "Cold", value: "Cold", dataVal: "cold" },
  { label: "Confident", value: "Confident", dataVal: "confident" },
  { label: "Dark", value: "Dark", dataVal: "dark" },
  { label: "Depressed", value: "Depressed", dataVal: "depressed" },
  { label: "Dynamic", value: "Dynamic", dataVal: "dynamic" },
  { label: "Ecstatic", value: "Ecstatic", dataVal: "ecstatic" },
  { label: "Emotional", value: "Emotional", dataVal: "emotional" },
  { label: "Empowering", value: "Empowering", dataVal: "empowering" },
  { label: "Energetic", value: "Energetic", dataVal: "energetic" },
  { label: "Epic", value: "Epic", dataVal: "epic" },
  { label: "Ethereal", value: "Ethereal", dataVal: "ethereal" },
  { label: "Exciting", value: "Exciting", dataVal: "exciting" },
  { label: "Fun", value: "Fun", dataVal: "fun" },
  { label: "Gritty", value: "Gritty", dataVal: "gritty" },
  { label: "Honorable", value: "Honorable", dataVal: "honorable" },
  { label: "Hopeful", value: "Hopeful", dataVal: "hopeful" },
  { label: "Human", value: "Human", dataVal: "human" },
  { label: "Imaginative", value: "Imaginative", dataVal: "imaginative" },
  { label: "Industrial", value: "Industrial", dataVal: "industrial" },
  { label: "Inspiring", value: "Inspiring", dataVal: "inspiring" },
  { label: "Intimate", value: "Intimate", dataVal: "intimate" },
  { label: "Light", value: "Light", dataVal: "light" },
  { label: "Meandering", value: "Meandering", dataVal: "meandering" },
  { label: "Mechanical", value: "Mechanical", dataVal: "mechanical" },
  { label: "Minimal", value: "Minimal", dataVal: "minimal" },
  { label: "Mischievous", value: "Mischievous", dataVal: "mischievous" },
  { label: "Mysterious", value: "Mysterious", dataVal: "mysterious" },
  { label: "Optimistic", value: "Optimistic", dataVal: "optimistic" },
  { label: "Organic", value: "Organic", dataVal: "organic" },
  { label: "Pensive", value: "Pensive", dataVal: "pensive" },
  { label: "Playful", value: "Playful", dataVal: "playful" },
  { label: "Positive", value: "Positive", dataVal: "positive" },
  { label: "Powerful", value: "Powerful", dataVal: "powerful" },
  { label: "Precise", value: "Precise", dataVal: "precise" },
  { label: "Pumped", value: "Pumped", dataVal: "pumped" },
  { label: "Quirky", value: "Quirky", dataVal: "quirky" },
  { label: "Rebellious", value: "Rebellious", dataVal: "rebellious" },
  { label: "Reflective", value: "Reflective", dataVal: "reflective" },
  { label: "Revelatory", value: "Revelatory", dataVal: "revelatory" },
  { label: "Romantic", value: "Romantic", dataVal: "romantic" },
  { label: "Sentimental", value: "Sentimental", dataVal: "sentimental" },
  { label: "Sexy", value: "Sexy", dataVal: "sexy" },
  { label: "Silly", value: "Silly", dataVal: "silly" },
  { label: "Sinister", value: "Sinister", dataVal: "sinister" },
  { label: "Slick", value: "Slick", dataVal: "slick" },
  { label: "Somber", value: "Somber", dataVal: "somber" },
  { label: "Sparse", value: "Sparse", dataVal: "sparse" },
  { label: "Spiritual", value: "Spiritual", dataVal: "spiritual" },
  { label: "Sporadic", value: "Sporadic", dataVal: "sporadic" },
  { label: "Stoic", value: "Stoic", dataVal: "stoic" },
  { label: "Upbeat", value: "Upbeat", dataVal: "upbeat" },
  { label: "Vulnerable", value: "Vulnerable", dataVal: "vulnerable" },
  { label: "Whimsical", value: "Whimsical", dataVal: "whimsical" },
  { label: "Youthful", value: "Youthful", dataVal: "youthful" },
]

const moodsSortedByPopularity = [
  "Confident",
  "Bouncy",
  "Hopeful",
  "Imaginative",
  "Playful",
  "Optimistic",
  "Upbeat",
  "Bright",
  "Reflective",
  "Positive",
  "Fun",
  "Energetic",
  "Youthful",
  "Empowering",
  "Calm",
  "A Journey",
  "Emotional",
  "Exciting",
  "Human",
  "Burdened",
  "Gritty",
  "Inspiring",
  "Honorable",
  "Intimate",
  "Sentimental",
  "Cinematic",
  "Classic & Retro",
  "Light",
  "Mysterious",
  "Pensive",
  "Pumped",
  "Ethereal",
  "Anthemic",
  "Rebellious",
  "Slick",
  "Powerful",
  "Mischievous",
  "Dynamic",
  "Vulnerable",
  "Dark",
  "Romantic",
  "Quirky",
  "Whimsical",
  "Minimal",
  "Organic",
  "Meandering",
  "Cold",
  "Mechanical",
  "Revelatory",
  "Sexy",
  "Somber",
  "Angelic",
  "Epic",
  "Stoic",
  "Aggressive",
  "Precise",
  "Sparse",
  "Sinister",
  "Industrial",
  "Spiritual",
  "Ecstatic",
  "Silly",
  "Depressed",
  "Sporadic",
]

// For displaying activated filters in the WorkbenchFooter
const clearRefinementLabels = [
  { attribute: "is_for_marmoset", label: "For Marmoset", value: "true" },
  { attribute: "is_not_for_marmoset", label: "Not for Marmoset", value: "true" },
  { attribute: "is_artist_collab", label: "Artist Collab", value: "true" },
  { attribute: "is_not_artist_collab", label: "Not Artist Collab", value: "true" },
  { attribute: "is_pre_release", label: "Prerelease", value: "true" },
  { attribute: "is_not_pre_release", label: "Not Prerelease", value: "true" },
  { attribute: "is_fervor", label: "Fervor Vintage & Fervor Contemporary", value: "true" },
  { attribute: "is_not_fervor", label: "Not Fervor Vintage & Fervor Contemporary", value: "true" },
  { attribute: "is_for_tv_film", label: "Can Pitch for TV Film", value: "true" },
  { attribute: "is_not_for_tv_film", label: "Do Not Pitch for TV Film", value: "true" },
  { attribute: "is_customizable", label: "Customizable", value: "true" },
  { attribute: "is_not_customizable", label: "Not Customizable", value: "true" },
  { attribute: "is_instrumental", label: "Instrumental", value: "true" },
  { attribute: "is_vocals", label: "Vocals", value: "true" },
  { attribute: "is_female_vocals", label: "Female Vocals", value: "true" },
  { attribute: "is_male_vocals", label: "Male Vocals", value: "true" },
  { attribute: "is_in_track_club", label: "In Track Club", value: "true" },
  { attribute: "is_not_in_track_club", label: "Not In Track Club", value: "true" },
  { attribute: "is_has_stems", label: "Stems", value: "true" },
  { attribute: "is_not_has_stems", label: "No Stems", value: "true" },
  { attribute: "internal_flags", label: "For Marmoset", value: "is_for_marmoset" },
  { attribute: "internal_flags", label: "Artist Collab", value: "is_artist_collab" },
  { attribute: "internal_flags", label: "Prerelease", value: "is_pre_release" },
  {
    attribute: "internal_flags",
    label: "Fervor Vintage & Fervor Contemporary",
    value: "is_fervor",
  },
  { attribute: "internal_flags", label: "Can Pitch for TV Film", value: "is_for_tv_film" },
  { attribute: "internal_flags", label: "Do Not Pitch for TV Film", value: "is_not_for_tv_film" },
  { attribute: "internal_flags", label: "Unflagged", value: "is_not_flagged" },
  { attribute: "energy", label: "Low Energy", value: "Low" },
  { attribute: "energy", label: "Low-Medium Energy", value: "Low-Medium" },
  { attribute: "energy", label: "Medium Energy", value: "Medium" },
  { attribute: "energy", label: "Medium-High Energy", value: "Medium-High" },
  { attribute: "energy", label: "High Energy", value: "High" },
]

export const resultsFilters = [
  { name: "songs", restrictedAttributes: ["title", "display_name"] },
  { name: "artists", restrictedAttributes: ["artist_name"] },
  { name: "lyrics", restrictedAttributes: ["lyrics"] },
  { name: "all", restrictedAttributes: [] },
]

export const sortOptions = [
  {
    text: "Marmoset Sort",
    value: "default",
    markup: <span>Marmoset Sort</span>,
  },
  {
    text: "Newest to Oldest",
    value: "newest",
    markup: <span>Newest to Oldest</span>,
  },
  {
    text: "Oldest to Newest",
    value: "oldest",
    markup: <span>Oldest to Newest</span>,
  },
  {
    text: "A to Z",
    value: "a_z",
    markup: <span>A to Z</span>,
  },
  {
    text: "Z to A",
    value: "z_a",
    markup: <span>Z to A</span>,
  },
  {
    text: "Shortest to Longest",
    value: "shortest",
    markup: <span>Shortest to Longest</span>,
  },
  {
    text: "Longest to Shortest",
    value: "longest",
    markup: <span>Longest to Shortest</span>,
  },
]

export const formatTime = (intTime) => {
  let minutes = (intTime / 1000 / 60) << 0
  let seconds = (intTime / 1000) % 60
  seconds = seconds.toFixed(0)
  if (seconds < 10) seconds = "0" + seconds
  if (minutes <= 0) minutes = ""
  return minutes + ":" + seconds
}

export const transformArcs = (refinementItems) => {
  // Iterates through the given array (i.e. arcs)
  // Spreads out the properties of each arc object into a new object
  // If a matching label is found in Algolia's refinement list,
  // it spreads out that item's properties into the object, effectively merging them.
  // If not found, we're left with the original given array's object's properties,
  // including a value to add a refinement back in once it's been removed.
  return arcs.map((arc) => ({
    ...arc,
    ...refinementItems.find((refinementItem) => refinementItem.label === arc.label),
  }))
}

export const transformEnergies = (refinementItems) => {
  return energies.map((energy) => ({
    ...energy,
    ...refinementItems.find((refinementItem) => refinementItem.label === energy.label),
  }))
}

export const transformFlags = (refinementItems) => {
  return internal_flags.map((flag) => ({
    ...flag,
    ...refinementItems.find((refinementItem) => refinementItem.label === flag.label),
  }))
}

export const transformGenres = (refinementItems) => {
  return genres.map((genre) => ({
    ...genre,
    ...refinementItems.find((refinementItem) => refinementItem.label === genre.label),
  }))
}

export const transformInstruments = (refinementItems) => {
  return instruments.map((instrument) => ({
    ...instrument,
    ...refinementItems.find((refinementItem) => refinementItem.label === instrument.label),
  }))
}

export const transformMoods = (refinementItems) => {
  // const sortedByPopularity = refinementItems.sort((a, b) => b.count - a.count)
  return moods.map((mood) => ({
    ...mood,
    ...refinementItems.find((refinementItem) => refinementItem.label === mood.label),
    rank: moodsSortedByPopularity.findIndex((moodSorted) => moodSorted === mood.label),
  }))
}

export const transformMoodsWithEmptyOption = (refinementItems) => {
  // refinementItems.unshift({ label: "Select a mood", value: "" })
  return refinementItems
}

export const transformClearRefinements = (refinementItems) => {
  // Similar to other transforms, but in reverse;
  // Start with Algolia-provided refinementItems and add items (i.e. label values) from clearRefinementLabels
  const transformedItems = refinementItems.map((refinementItem) => ({
    ...refinementItem,
    refinements: refinementItem.refinements.map((refinement) => ({
      ...refinement,
      ...clearRefinementLabels.find(
        (clearRefinementLabel) =>
          clearRefinementLabel.attribute === refinement.attribute &&
          clearRefinementLabel.value === refinement.value
      ),
    })),
  }))

  // Change Algolia's negative filter label style, i.e. "-Banjo" to "No Banjo"
  // Change time from integer to MM:SS format
  transformedItems.map((transformedItem) => {
    transformedItem.refinements.map((refinement) => {
      if (refinement.label[0] === "-") {
        refinement.label = "No " + refinement.label.substr(1)
      }
      if (refinement.attribute === "duration") {
        if (refinement.operator === ">=") {
          refinement.label = `Longer than ${formatTime(refinement.value)}`
        } else {
          refinement.label = `Shorter than ${formatTime(refinement.value)}`
        }
      }
    })
  })

  // Find and remove default max duration refinement so "Shorter than 20:00" does not appear in "Filtered by" list
  const durIndex = transformedItems.findIndex((item) => item.attribute === "duration")
  if (durIndex !== -1) {
    const valIndex = transformedItems[durIndex].refinements.findIndex(
      (duration) => duration.value === MAX_DURATION
    )
    if (valIndex !== -1) {
      delete transformedItems[durIndex].refinements[valIndex]
    }
  }

  return transformedItems
}

const sortCurrentRefinementList = (items) => {
  if (items.length) {
    items.map((item) => item.refinements.sort((a, b) => a.label.localeCompare(b.label)))
  }
  return items
}
