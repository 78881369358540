import { useState } from "react"
import cx from "classnames"
import styles from "../styles/CookiePreferences.module.scss"
import buttonStyles from "../../../shared/styles/buttons.module.scss"

const CookiePreferences = () => {
  const [loading, setLoading] = useState(false)

  const handleClick = () => {
    setLoading(true)
    displayPreferenceModal()
    setLoading(false)
  }

  return (
    <article id="cookiePreferences" className={styles.cookiePreferences}>
      <div className={styles.contain}>
        <h4 className={cx("hFour", styles.header)}>Cookie Preferences</h4>
        <div className={styles.innerContain}>
          <p className={cx(styles.text)}>
            You can easily opt in or out of cookies at any time. See our{" "}
            <a href="/legal#cookie-policy">cookie policy</a> for more information.
          </p>
          <button
            onClick={handleClick}
            className={cx(buttonStyles.black, styles.cookieBtn)}
            disabled={loading}
          >
            {loading ? "Loading..." : "Manage Preferences"}
            <span className={cx({ [buttonStyles.spinner]: loading })} />
          </button>
        </div>
      </div>
    </article>
  )
}

export default CookiePreferences
