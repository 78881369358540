import React from "react"
import cx from "classnames"

const MasterLicenseAgreement = ({ masterLicenseAgreement, showMLA }) => {
  return (
    <div className={cx("master-license-agreement", { show: showMLA })}>
      <div className="contain">
        <h6 className="hSix">Master License Agreement</h6>
        <div dangerouslySetInnerHTML={{ __html: masterLicenseAgreement }} />
      </div>
    </div>
  )
}

export default MasterLicenseAgreement
