import { Formik, Form } from "formik"
import axios from "axios"
import cx from "classnames"
import TextField from "../../../shared/components/TextField"

const PromoCodeForm = ({ handlePromoCode, id, orderType, setPromoCodeResponse }) => {
  const handleSubmit = (params, formActions) => {
    if (params.promoCode === "") {
      formActions.setSubmitting(false)
      return
    }

    axios
      .post(`/order/${id}/apply_promo_code/${params.promoCode}/type=${orderType}`)
      .then(({ data }) => {
        const { discount, promoCode, success, message } = data
        if (success) handlePromoCode(promoCode, discount)
        setPromoCodeResponse(message, success)
        formActions.setSubmitting(false)
      })
      .catch((err) => console.log(err))
  }

  const initialValues = {
    promoCode: "",
  }

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({ isSubmitting }) => (
        <fieldset className="inputs promo-code-fields">
          <Form>
            <TextField label="Promo Code" fieldName="promoCode" wrapperClass="promo_code_field" />
            <div className="form-submit">
              <button type="submit" className={cx("box-button-black")} disabled={isSubmitting}>
                {isSubmitting ? "Applying Code..." : "Apply Code"}
                <span className={cx({ spinner: isSubmitting })} />
              </button>
            </div>
          </Form>
        </fieldset>
      )}
    </Formik>
  )
}

export default PromoCodeForm
