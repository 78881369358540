import { useState } from "react"
import cx from "classnames"
import LineItem from "./LineItem"
import styles from "../styles/OrderItem.module.scss"
import lineItemStyles from "../styles/LineItem.module.scss"

const OrderItem = ({ order }) => {
  const {
    id,
    allowClientToSelectTbd,
    endClient,
    lineItems,
    purchaseDate,
    total,
    showDownloadLink,
    downloadToken,
  } = order
  const [expanded, setExpanded] = useState(false)

  const toggleExpanded = () => setExpanded(!expanded)

  const renderLineItems = () => {
    return _.map(lineItems, (item) => (
      <LineItem key={item.id} item={item} allowClientToSelectTbd={allowClientToSelectTbd} />
    ))
  }

  return (
    <li className={styles.orderItem} data-order-id={id} onClick={toggleExpanded}>
      <ol className={cx(styles.orderInfo, { [styles.expanded]: expanded })}>
        <div className={styles.left}>
          <li className={styles.orderNumber}>{id}</li>
          <li className={styles.endClient}>{endClient ? endClient : "n/a"}</li>
        </div>
        <div className={styles.right}>
          <li className={styles.purchaseDate}>{purchaseDate}</li>
          <li className={styles.total}>
            {total}
            <span className={styles.orderDetail}>
              <a
                href={`/account/history/${id}`}
                data-bypass="true"
                onClick={(e) => {
                  e.stopPropagation()
                }}
              >
                Order details
              </a>
            </span>
          </li>
        </div>
      </ol>
      <div
        className={cx(styles.lineItems, { [styles.showLineItems]: expanded })}
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <ol className={styles.lineItemTableHead}>
          <li className={lineItemStyles.song}>Song</li>
          <li className={lineItemStyles.artist}>Artist</li>
          {allowClientToSelectTbd && <li className={lineItemStyles.notes}>Notes</li>}
          <li className={lineItemStyles.item}>Item</li>
          <li className={lineItemStyles.cost}>Cost</li>
          <li className={lineItemStyles.downloadSong}>Download Song</li>
          <li className={lineItemStyles.downloadStems}>Download Stems</li>
        </ol>
        {renderLineItems()}
        {showDownloadLink && (
          <ol className={styles.downloadAll}>
            <li>
              <a href={`/custom_order/${id}/download/${downloadToken}`}>Download All Songs</a>
            </li>
          </ol>
        )}
      </div>
    </li>
  )
}

export default OrderItem
