import { PrismicPageParser } from "../../shared/utils/prismicPageParser"
import cx from "classnames"
import styles from "./OurWorkContainer.module.scss"

const OurWorkContainer = () => {
  const data = JSON.parse(window.prismic_data)
  const pageId = window.pageId

  return (
    <section
      id="mm-our-work"
      className={cx(styles.ourWork, "pageContainer")}
      aria-label="Our Work page"
    >
      <PrismicPageParser pageData={data} pageId={pageId} />
    </section>
  )
}

export default OurWorkContainer
