import { useEffect, useState, createContext } from "react"
import MixtapeActions from "./MixtapeActions"
import MixtapeDetails from "./MixtapeDetails"
import MixtapeSongsContainer from "./MixtapeSongsContainer"
import groupBySong from "../../shared/utils/songNormalizer"
import LoadingIcon from "../../icons/LoadingIcon"

export const MixtapePageContext = createContext({})

const MixtapePageContainer = () => {
  const container = document.getElementById("mm-mixtape-page-container")
  const details = container && container.getAttribute("data-mixtape-details")
  const mixtapeData = container && container.getAttribute("data-mixtape-play-serializer")
  const user_role = container && container.getAttribute("data-user-role")
  const is_owner = container && container.getAttribute("data-owner") === "true"

  const [mixtapeDetails, setMixtapeDetails] = useState({
    ...JSON.parse(details),
    is_owner: is_owner,
  })
  const [mixtapeSerializer, setMixtapeSerializer] = useState(JSON.parse(mixtapeData))
  const [songVersions, setSongVersions] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const contextValue = {
    user_role,
    is_owner,
    mixtapeSerializer,
    setMixtapeSerializer,
    mixtapeDetails,
    setMixtapeDetails,
    songVersions,
    setSongVersions,
  }

  const fetchMixtapeSongVersions = async () => {
    setIsLoading(true)
    const response = await fetch(`/api/v1/mixtapes/${mixtapeSerializer.id}/get_song_versions`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })

    if (!response.ok) {
      console.log("ERROR: Unable to complete action at this time")
      return
    }

    const result = await response.json()
    var groupedBySong = groupBySong(result.song_versions)
    setSongVersions(groupedBySong)
    setIsLoading(false)
  }

  useEffect(() => {
    //Hacky: If mixtape is staff pick, give container staff-pick class
    var webpage = document.getElementById("container")
    if (mixtapeDetails.staff_pick) {
      webpage.classList.add("staff-pick")
    } else {
      webpage.classList.remove("staff-pick")
    }

    fetchMixtapeSongVersions()
  }, [])

  return (
    <>
      <MixtapePageContext.Provider value={contextValue}>
        {!mixtapeDetails.staff_pick && <MixtapeDetails />}
        <MixtapeActions />
        {isLoading ? <LoadingIcon /> : <MixtapeSongsContainer />}
      </MixtapePageContext.Provider>
    </>
  )
}
export default MixtapePageContainer
