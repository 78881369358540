import VideoItem from "./VideoItem"
import VideoGridItem from "./VideoGridItem"
import cx from "classnames"
import { useEffect, useState, createContext, useContext } from "react"
import VideoGridNav from "./VideoGridNav"
import styles from "../../styles/VideoPlaylist.module.scss"

const RESULTS_PER_PAGE = 12

export const GridPlaylistContext = createContext()

const PaginationList = () => {
  const { count, activePage, setActivePage } = useContext(GridPlaylistContext)

  const getPageList = (totalPages, page, maxLength) => {
    if (maxLength < 5) throw "maxLength must be at least 5"

    function range(start, end) {
      return Array.from(Array(end - start + 1), (_, i) => i + start)
    }

    var sideWidth = Math.ceil(maxLength / 2) - 1
    var rightWidth = (maxLength - sideWidth * 2 - 2) >> 1

    if (totalPages <= maxLength) {
      // List is too small to need breaking
      return range(1, totalPages)
    }
    if (page <= maxLength - sideWidth - 1 - rightWidth) {
      // Close to left side
      return range(1, maxLength)
    }
    if (page >= totalPages - sideWidth - 1) {
      // close to right side
      return range(totalPages - maxLength + 1, totalPages)
    }
    // Somewhere between
    return range(page - sideWidth, page + sideWidth)
  }

  const pages = Math.ceil(count / RESULTS_PER_PAGE)

  return (
    <div className={styles.paginationContainer}>
      <ul className={styles.paginationList}>
        <li className={cx(styles.paginationListItem, activePage === 1 && styles.hidden)}>
          <a className="pageNavLink" aria-label="First" onClick={() => setActivePage(1)}>
            ‹‹
          </a>
        </li>
        <li className={cx(styles.paginationListItem, activePage === 1 && styles.hidden)}>
          <a
            className="pageNavLink"
            aria-label="Previous"
            onClick={() => setActivePage(activePage - 1)}
          >
            ‹
          </a>
        </li>
        {[...getPageList(pages, activePage, 5)].map((i) => (
          <li className={cx(styles.paginationListItem)} key={i}>
            <a className={cx(activePage === i && styles.active)} onClick={() => setActivePage(i)}>
              {i}
            </a>
          </li>
        ))}
        <li
          className={cx(
            styles.paginationListItem,
            styles.next,
            (activePage === pages || pages === 0) && styles.hidden
          )}
        >
          <a
            className="pageNavLink"
            aria-label="Next"
            onClick={() => setActivePage(activePage + 1)}
          >
            ›
          </a>
        </li>
        <li
          className={cx(
            styles.paginationListItem,
            (activePage === pages || pages === 0) && styles.hidden
          )}
        >
          <a className="pageNavLink" aria-label="Last" onClick={() => setActivePage(pages)}>
            ››
          </a>
        </li>
      </ul>
    </div>
  )
}

const VideoPlaylist = ({ slice }) => {
  const { items, primary } = slice
  const playlistType = primary.playlist_type
  const [navTagOptions, setNavTagOptions] = useState([])
  const [navServiceOptions, setNavServiceOptions] = useState([])
  const [currentTag, setCurrentTag] = useState("All Work")
  const [currentService, setCurrentService] = useState("All Services")
  const [filteredItems, setFilteredItems] = useState(items)
  // Pagination Vars
  const [activePage, setActivePage] = useState(1)
  const count = filteredItems.length

  // This can be moved into the Nav component itself
  const getNavOptions = () => {
    let tagOptions = {}
    let serviceOptions = {}
    let videos = items
    videos.forEach((video) => {
      let tags = video.tags
      tags?.split(", ").forEach((tag) => (tagOptions[tag] = 1))
      let services = video.services
      services?.split(", ").forEach((service) => (serviceOptions[service] = 1))
    })
    return { tagOptions, serviceOptions }
  }

  //This can moved into the nav component itself
  useEffect(() => {
    const { tagOptions, serviceOptions } = getNavOptions()
    setNavTagOptions(["All Work", ...Object.keys(tagOptions)])
    setNavServiceOptions(["All Services", ...Object.keys(serviceOptions)])
  }, [])

  useEffect(() => {
    const filtered = items
      .filter((item) => String(item.tags).indexOf(currentTag) >= 0 || currentTag === "All Work")
      .filter(
        (item) =>
          String(item.services?.split(", ")).indexOf(currentService) >= 0 ||
          currentService === "All Services"
      )
    setFilteredItems(filtered)
    setActivePage(1)
  }, [currentTag, currentService])

  return playlistType !== "Grid" ? (
    <article
      className={cx(styles.videoPlaylist, "prismicVideoPlaylist")}
      aria-label="Video playlist"
    >
      <div className={cx(styles.contain)}>
        {filteredItems.map(({ video, featured_composer, uid }) => {
          return <VideoItem key={uid} video={video} featuredComposer={featured_composer} />
        })}
      </div>
    </article>
  ) : (
    <article
      className={cx(styles.videoPlaylist, "prismicVideoPlaylist")}
      aria-label="Video playlist"
    >
      <GridPlaylistContext.Provider
        value={{
          navTagOptions,
          navServiceOptions,
          setCurrentTag,
          currentTag,
          currentService,
          setCurrentService,
          count,
          setActivePage,
          activePage,
        }}
      >
        <div className={styles.nav}>
          <VideoGridNav />
        </div>
        <div className={cx(styles.contain, styles.gridPlaylist)}>
          {filteredItems
            .slice((activePage - 1) * RESULTS_PER_PAGE, activePage * RESULTS_PER_PAGE)
            .map(({ video, featured_composer, uid, tags, services }) => {
              return (
                <VideoGridItem
                  key={uid}
                  video={video}
                  featuredComposer={featured_composer}
                  tags={tags}
                  services={services}
                />
              )
            })}
        </div>
        <PaginationList />
      </GridPlaylistContext.Provider>
    </article>
  )
}

export default VideoPlaylist
