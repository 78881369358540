import { useWorkbenchStore } from "../../store/store"
import cx from "classnames"

const GenresListItem = ({ item, refine }) => {
  const genresToggledOn = useWorkbenchStore((state) => state.genresToggledOn)
  const genresToggledOff = useWorkbenchStore((state) => state.genresToggledOff)
  const addToGenresOn = useWorkbenchStore((state) => state.addToGenresOn)
  const addToGenresOff = useWorkbenchStore((state) => state.addToGenresOff)
  const removeFromGenresOn = useWorkbenchStore((state) => state.removeFromGenresOn)
  const removeFromGenresOff = useWorkbenchStore((state) => state.removeFromGenresOff)

  const handleClick = (e) => {
    e.preventDefault()
    if (!genresToggledOn.includes(item.value) && !genresToggledOff.includes(item.value)) {
      addToGenresOn(item.value)
      refine(item.value)
    } else if (genresToggledOn.includes(item.value)) {
      removeFromGenresOn(item.value)
      addToGenresOff(item.value)
      // Todo: In a future ticket, look into integrating setIndexUiState to handle both refinement updates in one call
      // https://github.com/algolia/react-instantsearch/discussions/3491
      refine(item.value)
      refine("-" + item.value)
    } else if (genresToggledOff.includes(item.value)) {
      removeFromGenresOff(item.value)
      refine("-" + item.value)
    }
  }

  return (
    <li
      className={cx(
        "actionable",
        genresToggledOn.includes(item.value) ? "selected" : "",
        genresToggledOff.includes(item.value) ? "selected-negative" : ""
      )}
      data-cy={`genres-option-${item.value}`}
      // Todo: In a future ticket, remove (or make consistent) data-* elements
      // data-val={item.value}
      // data-pretty={item.value}

      // Todo: Determine if we need this feature?
      // data-audio-mp3="<%= asset_path("genres/#{instrument.parameterize.underscore}.mp3") %>"
    >
      <a data-prevent-default onClick={handleClick}>
        {item.label}
      </a>
    </li>
  )
}

export default GenresListItem
