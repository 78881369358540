const MoutainWavesIcon = ({ classes }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 38" height="38px" className={classes}>
      <g fill="none" fillRule="evenodd">
        <path d="M21.5634 24.5429125c1.778.0039875 3.556-.9510125 5.194-2.8670125l.046-.053c3.275-3.829 7.109-3.823 10.381.017l.009.011c1.639 1.924 3.419 2.885 5.198 2.885" />
        <path d="M.7895 24.5429125c1.778.0039875 3.556-.9510125 5.195-2.8670125l.045-.053c3.275-3.829 7.109-3.823 10.381.017l.009.011c1.64 1.924 3.419 2.885 5.199 2.885M42.3735 24.5429125c1.778.0039875 3.556-.9510125 5.194-2.8670125l.046-.053c3.275-3.829 7.109-3.823 10.381.017l.009.011c1.639 1.924 3.419 2.885 5.199 2.885M21.5634 15.6166125c1.778.0039875 3.556-.9520125 5.194-2.8670125l.046-.053c3.275-3.829 7.109-3.823 10.381.017l.009.011c1.639 1.923 3.419 2.885 5.198 2.885" />
        <path d="M.7895 15.6166125c1.778.0039875 3.556-.9520125 5.195-2.8670125l.045-.053c3.275-3.829 7.109-3.823 10.381.017l.009.011c1.64 1.923 3.419 2.885 5.199 2.885M42.3735 15.6166125c1.778.0039875 3.556-.9520125 5.194-2.8670125l.046-.053c3.275-3.829 7.109-3.823 10.381.017l.009.011c1.639 1.923 3.419 2.885 5.199 2.885M21.5634 33.4692125c1.778.0039875 3.556-.9510125 5.194-2.8670125l.046-.053c3.275-3.829 7.109-3.823 10.381.018l.009.01c1.639 1.924 3.419 2.885 5.198 2.885" />
        <path d="M.7895 33.4692125c1.778.0039875 3.556-.9510125 5.195-2.8670125l.045-.053c3.275-3.829 7.109-3.823 10.381.018l.009.01c1.64 1.924 3.419 2.885 5.199 2.885M42.3735 33.4692125c1.778.0039875 3.556-.9510125 5.194-2.8670125l.046-.053c3.275-3.829 7.109-3.823 10.381.018l.009.01c1.639 1.924 3.419 2.885 5.199 2.885" />
        <path d="M31.996.7128l11.774 18.043 11.774 18.042H8.447l11.775-18.042z" />
      </g>
    </svg>
  )
}

export default MoutainWavesIcon
