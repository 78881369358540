import { PrismicPageParser } from "../../shared/utils/prismicPageParser"
import cx from "classnames"
import styles from "./ArtistsContainer.module.scss"

const ArtistsContainer = () => {
  const data = JSON.parse(window.prismic_data)
  const pageId = window.pageId

  return (
    <section id="mm-artists" className={cx("pageContainer", styles.artists)} aria-label="Artists">
      <PrismicPageParser pageData={data} pageId={pageId} />
    </section>
  )
}

export default ArtistsContainer
