import { Formik, Form } from "formik"
import cx from "classnames"
import { useMutation } from "@apollo/client"
import { CANCEL_ACCOUNT } from "../../../graphql/mutations/account"
import CheckboxField from "../../../shared/components/CheckboxField"
import StatusMessage from "./StatusMessage"
import styles from "../styles/CancelAccountForm.module.scss"
import buttonStyles from "../../../shared/styles/buttons.module.scss"

const CancelAccountForm = ({ id }) => {
  const [cancelAccountMutation, { loading }] = useMutation(CANCEL_ACCOUNT)

  const handleSubmit = ({ cancel_account }, formActions) => {
    const confirm = window.confirm("Are you sure? Your account will be deleted forever!")
    if (confirm) {
      const variables = { account: { id } }

      cancelAccountMutation({ variables })
        .then(({ data: { cancelAccount } }) => {
          const { errors } = cancelAccount

          if (errors.length > 0) {
            formActions.setStatus({ error: true, msg: errors[0] })
          } else {
            mm.user.logout()
            mm.router.navigate("/", { trigger: true })
          }
        })
        .catch((errors) => {
          formActions.setStatus({ error: true, msg: errors.graphQLErrors[0].message })
        })
    }
    return false
  }

  return (
    <Formik onSubmit={handleSubmit} initialValues={{ cancel_account: false }}>
      {({ status, values }) => (
        <article id="cancelAccount" className={styles.cancelAccount}>
          <div className={styles.contain}>
            <Form>
              <h4 className={cx("hFour", styles.header)}>Cancel Account</h4>
              <StatusMessage status={status} />

              <div className={styles.form}>
                <CheckboxField
                  label="I'd like to cancel my account"
                  fieldName="cancel_account"
                  checked={values.cancel_account}
                  wrapperClass={styles.cancelChkbx}
                />
                <button
                  type="submit"
                  className={cx(buttonStyles.black, styles.cancelBtn)}
                  disabled={values.cancel_account === false || (status && status.success)}
                >
                  {loading ? "Canceling..." : "Cancel Account"}
                  <span className={cx({ [buttonStyles.spinner]: loading })} />
                </button>
              </div>
            </Form>
          </div>
        </article>
      )}
    </Formik>
  )
}

export default CancelAccountForm
