import { gql } from "@apollo/client"

const GET_ACCOUNT = gql`
  query account($id: ID) {
    account(id: $id) {
      id
      fullName
      firstName
      lastName
      email
      createdAt
      cards {
        id
        brandFilename
        expiryDisplay
        infoDisplay
      }
      profile {
        company
        phoneNumber
        job
        address {
          line1
          line2
          city
          subregion
          country
          postalCode
        }
      }
    }
  }
`

export default GET_ACCOUNT
