const FilterMLogo = () => {
  return (
    <svg className="filterMLogo" viewBox="0 0 40 40" data-value="internal">
      <g fill="#21928e" fillRule="nonzero" data-value="internal">
        <path
          className="icon-border"
          d="M19.76 0C8.84685334 0 0 8.84685334 0 19.76c0 10.9131467 8.84685334 19.76 19.76 19.76 10.9131467 0 19.76-8.8468533 19.76-19.76 0-5.2406778-2.0818512-10.26671116-5.78757-13.97243C30.0267112 2.08185117 25.0006778 0 19.76 0zm0 38.1634036c-7.4501642.0046071-14.16908717-4.4803241-17.02227185-11.3624971-2.85318468-6.8821729-1.27841719-14.8054703 3.98964542-20.07353293C11.9954362 1.45931096 19.9187336-.11545653 26.8009065 2.73772815 33.6830795 5.59091283 38.1680107 12.3098358 38.1634036 19.76 38.1571114 29.9213112 29.9213112 38.1571114 19.76 38.1634036z"
          data-value="internal"
        />
        <path
          d="M26.4138 13.3c-2.7132 0-3.6138 1.5542-3.9938 3.04-.38-1.748-1.6948-3.04-4.2636-3.04-2.7284 0-3.6062 1.7062-3.9482 3.2262a.56241272.56241272 0 000-.0798.56620892.56620892 0 000-.0798c-.019-.0912-.0418-.1976-.0646-.323-.4636-1.6036-2.7094-2.7132-5.491-2.7436v12.92h5.7v-6.5094c0-2.0634.3078-4.1534 1.4668-4.1534.9044 0 1.0754.9044 1.0754 2.7398v7.923h5.7v-6.6804c0-2.0596.2242-3.9824 1.4668-3.9824.9044 0 1.0716.9044 1.0716 2.7398v7.923h5.7001414v-8.36C30.8484 15.39 29.64 13.3 26.4138 13.3z"
          data-value="internal"
        />
      </g>
    </svg>
  )
}

export default FilterMLogo
