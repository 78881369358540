import { useRef, useContext } from "react"
import cx from "classnames"
import { ProjectSearchContext } from "./ProjectSearch"

const DropdownMenu = ({ children, icon }) => {
  const { openMenu, setOpenMenu } = useContext(ProjectSearchContext)
  const dropdownMenuRef = useRef()

  return (
    <div className="dropdown-menu">
      <button
        onClick={() => setOpenMenu(!openMenu)}
        className={cx("dropdown-btn", { "dropdown-active": openMenu })}
      >
        {icon}
      </button>

      {openMenu && (
        <div className="dropdown-panel corner-expand" ref={dropdownMenuRef}>
          <ul className="settings">{children}</ul>
        </div>
      )}
    </div>
  )
}

export default DropdownMenu
