import { useMutation } from "@apollo/client"
import cx from "classnames"
import { TOGGLE_HIDE_SEARCH_ROUND } from "../../../graphql/mutations/searchRounds"

const HideSearchRound = ({ selectedRound }) => {
  const { id, hidden, name } = selectedRound[0]
  const hideOrUnhide = hidden ? "unhide" : "hide"
  const [toggleHideSearchRound] = useMutation(TOGGLE_HIDE_SEARCH_ROUND)

  const toggleHideSearch = () => {
    const variables = {
      id,
      hidden: !hidden,
    }

    toggleHideSearchRound({ variables }).catch((err) => console.log(err))
  }

  return (
    <button
      className={cx("action", "toggle-btn", { enabled: hidden })}
      onClick={toggleHideSearch}
      data-cy="hideSearchRound"
    >
      {`${hideOrUnhide} ${name}`}
    </button>
  )
}

export default HideSearchRound
