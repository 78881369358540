import { useMutation } from "@apollo/client"
import { SET_STATUS } from "../../../graphql/mutations/projectSearches"

const WonLostActions = ({ projectSearchId, status }) => {
  const [setProjectSearchStatus] = useMutation(SET_STATUS)

  const handleClick = (action) => {
    const variables = {
      id: projectSearchId,
      status: action,
    }

    setProjectSearchStatus({ variables }).catch((error) => Sentry.captureException(error))
  }

  if (status === "active") {
    return (
      <div className="won-lost">
        <button className="action won" data-cy="won" onClick={() => handleClick("won")}>
          Won
        </button>
        <button className="action lost" data-cy="lost" onClick={() => handleClick("lost")}>
          Lost
        </button>
      </div>
    )
  } else {
    return (
      <div className="won-lost">
        <button className={`action disabled ${status}`}>{status}</button>
      </div>
    )
  }
}

export default WonLostActions
