import cx from "classnames"
import AccordionItem from "./AccordionItem"
import styles from "../../styles/Accordion.module.scss"

const Accordion = ({ slice }) => {
  const { items } = slice

  return (
    <article className={cx(styles.accordion, "AccordionItem")} aria-label="Accordion">
      <div className={styles.contain}>
        {items.map((item, index) => {
          return <AccordionItem item={item} key={item.uid} />
        })}
      </div>
    </article>
  )
}

export default Accordion
