import { useContext } from "react"
import cx from "classnames"
import InfoIcon from "../../icons/InfoIcon"
import MoreIcon from "../../icons/MoreIcon"
import PlayIcon from "../../icons/PlayIcon"
import styles from "../styles/DoubleButton.module.scss"
import { PrismicPageContext } from "../utils/prismicPageParser"
import { replaceMMUrl } from "../utils/helpers"

const DoubleButton = ({ slice, white }) => {
  const colorTheme = useContext(PrismicPageContext)
  const { primary } = slice
  const FirstIcon = IconMap[primary.first_button_icon]
  const SecondIcon = IconMap[primary.second_button_icon]

  return (
    <div className={cx("prismicButtonWrapper", styles.wrapper)}>
      {primary.first_button_url && (
        <button
          className={cx("prismicButton", styles[colorTheme], styles.button, {
            [styles.white]: white,
          })}
        >
          <div className={styles.iconAndText}>
            {FirstIcon && <FirstIcon />}
            <a
              href={replaceMMUrl(primary.first_button_url)}
              className={cx(styles.link)}
              data-bypass="true"
            >
              {primary.first_button_text}
            </a>
          </div>
        </button>
      )}
      {primary.second_button_url && (
        <button
          className={cx("prismicButton", styles[colorTheme], styles.button, {
            [styles.white]: white,
          })}
        >
          <div className={styles.iconAndText}>
            {SecondIcon && <SecondIcon />}
            <a
              href={replaceMMUrl(primary.second_button_url)}
              className={cx(styles.link)}
              data-bypass="true"
            >
              {primary.second_button_text}
            </a>
          </div>
        </button>
      )}
    </div>
  )
}

const IconMap = {
  Info: InfoIcon,
  More: MoreIcon,
  Play: PlayIcon,
}

export default DoubleButton
