import { useContext } from "react"
import { PrismicRichText } from "@prismicio/react"
import cx from "classnames"
import htmlSerializer from "../../utils/prismicHTMLSerializer"
import styles from "../../styles/Text.module.scss"
import { PrismicPageContext } from "../../utils/prismicPageParser"

const Text = ({ slice, quoteboxText, globalClass }) => {
  const colorTheme = useContext(PrismicPageContext)
  const { primary } = slice

  return (
    <article className={cx(styles.text, globalClass, "prismicText")}>
      <div className={styles.contain}>
        <div
          id={primary.anchor_tag}
          className={cx(
            styles.richText,
            "prismicRichText",
            styles[colorTheme],
            styles[primary.text_alignment],
            styles[primary.text_margin],
            {
              [styles.quoteboxText]: quoteboxText,
            }
          )}
        >
          <PrismicRichText field={primary.text} components={htmlSerializer} />
        </div>
      </div>
    </article>
  )
}

export default Text
