import { useHits, useCurrentRefinements, useClearRefinements } from "react-instantsearch-hooks-web"
import FilteredBy from "./FilteredBy"
import SearchedBy from "./SearchedBy"
import { useWorkbenchStore } from "../../store/store"
import { transformClearRefinements } from "./FilterTransforms"
import { useEffect, useState } from "react"
import SortedBy from "./SortedBy"

const AlgoliaResultsCount = () => {
  const [state, setState] = useState(null)
  const {
    results: { nbHits },
  } = useHits()

  useEffect(() => {
    cleanupFunction()
    return () => {
      setState(null)
    }
  }, [])

  const cleanupFunction = () => {
    setState({})
  }

  if (nbHits > 0) {
    return (
      <div className="count-wrapper">
        <span className="count">{nbHits}</span> <span className="count-text">Results</span>
      </div>
    )
  } else {
    return null
  }
}

const WorkbenchFooter = () => {
  const searchQuery = useWorkbenchStore((state) => state.searchQuery)
  const sortedIndexName = useWorkbenchStore((state) => state.sortedIndexName)
  const { items, refine } = useCurrentRefinements({ transformItems: transformClearRefinements })
  const { refine: refineAll } = useClearRefinements()
  const [anyFilters, setAnyFilters] = useState(false)

  useEffect(() => {
    // Algolia's refinement list (items) *may* include a placeholder refinement object for range items (i.e. duration)
    // that includes a prototype item named 'empty' in the refinements array; without refinements, its length is 1.
    let anyFiltersVar = false
    const durationRefinement = items.find((item) => item.attribute === "duration")

    if (durationRefinement === undefined) {
      anyFiltersVar = false
    } else if (durationRefinement.refinements[0]) {
      anyFiltersVar = durationRefinement.refinements[0].hasOwnProperty("value") ? true : false
    } else {
      anyFiltersVar = items.length > 1 ? true : false
    }
    setAnyFilters(anyFiltersVar)
  }, [items])

  return (
    <footer>
      <div className="contain browse-summary">
        <div className="group">
          {(anyFilters || searchQuery) && (
            <div className="result-count">
              <AlgoliaResultsCount />
            </div>
          )}
          <div className="vertical-group">
            {(searchQuery || sortedIndexName) && (
              <div className="search-sort-group">
                {searchQuery !== "" && <SearchedBy />}
                {sortedIndexName !== "" && <SortedBy />}
              </div>
            )}
            {anyFilters && <FilteredBy items={items} refine={refine} refineAll={refineAll} />}
          </div>
        </div>
      </div>
    </footer>
  )
}

export default WorkbenchFooter
