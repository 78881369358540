import { useMutation } from "@apollo/client"
import { DELETE_SEARCH_ROUND } from "../../../graphql/mutations/searchRounds"
import GET_PROJECT_SEARCH from "../../../graphql/queries/GetProjectSearch"

const DeleteSearchRound = ({ selectedRound, slug }) => {
  const { id, name } = selectedRound[0]
  const [deleteSearchRound] = useMutation(DELETE_SEARCH_ROUND, {
    refetchQueries: [
      {
        query: GET_PROJECT_SEARCH,
        variables: { slug },
      },
    ],
  })

  const postDelete = () => {
    const confirmation = confirm("Are you sure you want to delete this Search Round?")

    if (confirmation) {
      deleteSearchRound({ variables: { id } }).catch((error) => Sentry.captureException(error))
    }
  }

  return (
    <button className="delete action">
      <a data-prevent-default onClick={postDelete}>
        {`Delete ${name}`}
      </a>
    </button>
  )
}

DeleteSearchRound.defaultProps = {
  searchRound: [],
}

export default DeleteSearchRound
