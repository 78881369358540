import cx from "classnames"
import { useState } from "react"
import SongVersionItem from "./SongVersionItem"
import { getSongColor } from "../utils/browse"

//TODO: use this to pass hidden data between components
//also maybe move other props to context and use them along the tree

const SongItemBeta = ({
  song,
  user_role,
  handleSetExpandedSongVersionId,
  expandedSongVersionId,
  currentBlanketDealModalRef,
}) => {
  const [visibility, setVisibility] = useState({
    visibility: "hidden",
    position: "absolute",
    zindex: "-99999",
  })

  // make sure song version ids are unique
  const songVersionIds = song.songVersions.filter(
    (value, index, self) => self.indexOf(value) === index
  )
  const artist = song.artist

  // the top song version is handled slightly differently
  const topSongVersion = song.songVersions[0]
  const altSongVersions = song.songVersions.slice(1)

  // toggle alt song versions visibility
  const setAltVersionsVisibility = () => {
    var obj = {
      visibility: "hidden",
      position: "absolute",
      zindex: "-99999",
    }
    if (visibility.visibility === "hidden") {
      obj = {
        visibility: "visible",
        position: "inherit",
        zindex: "1",
      }
    }
    setVisibility(obj)
  }

  const songColor = getSongColor(artist, user_role, song.forMarmoset)

  // TODO we will want to add a separate alt_versions_count without the +1 when the legacy browse is retired and use song_versions_count for total song version count
  // at that point we can refactor the SongVersionItem component without breaking the old /browse page
  const songVersionProps = {
    artist,
    song_versions_count: altSongVersions.length + 1,
    song,
    setAltVersionsVisibility,
    user_role,
    songColor,
    handleSetExpandedSongVersionId,
    expandedSongVersionId,
    currentBlanketDealModalRef,
  }

  return (
    <li
      className={cx("song-item", { ["expanded"]: visibility.visibility === "visible" })}
      data-song-id={song.songId}
      data-catalog-color={songColor}
    >
      <ol className="top-version">
        <SongVersionItem
          key={topSongVersion.id}
          sv={topSongVersion}
          topSongVersion={true}
          {...songVersionProps}
        />
      </ol>
      {altSongVersions.length && (
        <ol
          className="alt-versions"
          style={{
            visibility: visibility.visibility,
            position: visibility.position,
            zIndex: visibility.zindex,
          }}
        >
          {altSongVersions.map((sv) => {
            return (
              <SongVersionItem key={sv.id} sv={sv} topSongVersion={false} {...songVersionProps} />
            )
          })}
          <li className="versions-link" style={{ backgroundColor: songColor }}>
            <a
              itemProp="url"
              aria-label={`View ${song.songVersions.length.toString()} alternative versions of ${
                song.name
              }`}
              href={`/versions/${song.songId}`}
            >
              This song has {song.songVersionsCount.toString()} total versions
            </a>
          </li>
        </ol>
      )}
    </li>
  )
}

export default SongItemBeta
