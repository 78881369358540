import { useState, useEffect, useRef } from "react"
import styles from "../../shared/styles/VideoPlayer.module.scss"
import spinnerStyles from "../../shared/styles/spinners.module.scss"

const YoutubeVideoPlayer = ({ videoId }) => {
  const [loading, setLoading] = useState(true)
  const iframeRef = useRef()

  useEffect(() => {
    iframeRef.current.onload = () => setLoading(false)
  }, [iframeRef])

  return (
    <div className="youtube-player-wrapper">
      {loading && (
        <div className={styles.loading}>
          <div className={spinnerStyles.largeSpinner} />
        </div>
      )}
      <div className="youtube-iframe-wrapper">
        <iframe
          src={`https://www.youtube.com/embed/${videoId}`}
          className="youtube-similarity-player"
          frameBorder="0"
          allow="autoplay; fullscreen"
          allowFullScreen
          ref={iframeRef}
        />
      </div>
    </div>
  )
}

export default YoutubeVideoPlayer
