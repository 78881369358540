import CustomerServiceEmail from "../../../shared/components/CustomerServiceEmail"

const DownloadSongVersionsError = ({ expiredToken }) => {
  return (
    <div className="error-message">
      {expiredToken ? (
        <h2>
          Oops! It looks like this page has expired.
          <br />
          Please reach back out to us for a new link: <CustomerServiceEmail />.
        </h2>
      ) : (
        <h2>
          There was an error loading your songs.
          <br />
          Please reload the page or reach out to <CustomerServiceEmail />.
        </h2>
      )}
    </div>
  )
}

export default DownloadSongVersionsError
