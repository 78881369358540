import { PrismicPageParser } from "../../shared/utils/prismicPageParser"
import cx from "classnames"
import styles from "./BrowseFeaturedMixtapesContainer.module.scss"

const BrowseFeaturedMixtapesContainer = () => {
  const data = JSON.parse(window.prismic_data)
  const pageId = window.pageId

  return (
    <section
      id="mm-browse-featured-mixtapes"
      className={cx("pageContainer", styles.browseMixtapes)}
      aria-label="Browse Featured Mixtapes"
    >
      <PrismicPageParser pageData={data} pageId={pageId} />
    </section>
  )
}

export default BrowseFeaturedMixtapesContainer
