import { useContext, useState, useEffect, useRef } from "react"
import { ArtistPageContext } from "./ArtistPageContainer"
import cx from "classnames"
import SongItemBeta from "../../shared/components/SongItemBeta"
import groupBySong from "../../shared/utils/songNormalizer"
import LoadingIcon from "../../icons/LoadingIcon"

const StaffSongsList = () => {
  const { user_role, artist_slug, cacheTag, currentBlanketDealModalRef, triggerFirstQuery } =
    useContext(ArtistPageContext)
  const [isLoading, setIsLoading] = useState(false)
  const [staffSongs, setStaffSongs] = useState([])
  const [expandedSongVersionId, setExpandedSongVersionId] = useState(false)
  const staffSongsTableRef = useRef(null)
  const handleOnClick = (e) => {
    if (
      // Not clicked in actions menu OR NOT clickd within this song version container
      (!e.target.closest([".actions-menu", ".expand-actions-menu"]) ||
        !staffSongsTableRef.current.contains(e.target)) &&
      // AND Not clicked within the blanket deal modal currently open
      !(currentBlanketDealModalRef.current && currentBlanketDealModalRef.current.contains(e.target))
    ) {
      setExpandedSongVersionId(false)
    }
  }

  const handleQuery = async () => {
    setIsLoading(true)
    const res = await fetch(`/api/v1/artists/${artist_slug}/get_staff_picks`, {
      params: {
        order: "listens_count DESC",
        cache_tag: cacheTag,
      },
    })
    if (!res.ok) {
      setIsLoading(false)
      console.log("ERROR: Unable to fetch Staff Picks")
      triggerFirstQuery()
      return
    }
    const data = await res.json()
    const resStaffSongs = data.staff_songs
    const groupedStaffSongs = groupBySong(resStaffSongs)
    setStaffSongs(groupedStaffSongs)
    setIsLoading(false)
    triggerFirstQuery()
    return
  }

  const handleSetExpandedSongVersionId = (svid) => {
    setExpandedSongVersionId(svid)
  }

  useEffect(() => {
    handleQuery()
    document.body.addEventListener("click", handleOnClick)

    return () => {
      document.body.removeEventListener("click", handleOnClick)
    }
  }, [])

  return isLoading ? (
    <article className="staff-picks" aria-label="Staff picks">
      <div className="contain">
        <h5 className="hFive">Staff Picks</h5>
        <LoadingIcon />
      </div>
    </article>
  ) : !staffSongs[0] ? (
    <></>
  ) : (
    <article className="staff-picks" aria-label="Staff picks">
      <div className="contain">
        <h5 className="hFive">Staff Picks</h5>
        <div id="mm-song-versions" ref={staffSongsTableRef}>
          <article id="mm-song-versions-listing" aria-label="Songs listing">
            <div className="mm-song-versions-table" data-title="Search results">
              <ol className={cx("mm-song-versions-table-list")}>
                <li className="mm-song-versions-table-head">
                  <ol>
                    <li className="song-version">Song / Artist</li>
                    <li className="genres desktop">Genres</li>
                    <li className="arc desktop">Arc&trade;</li>
                    <li className="energy desktop" data-touch>
                      Energy
                    </li>
                    <li className="length">Length</li>
                  </ol>
                </li>
                {staffSongs.map((song) => (
                  <SongItemBeta
                    key={song.songId}
                    song={song}
                    user_role={user_role}
                    handleSetExpandedSongVersionId={handleSetExpandedSongVersionId}
                    expandedSongVersionId={expandedSongVersionId}
                    currentBlanketDealModalRef={currentBlanketDealModalRef}
                  />
                ))}
              </ol>
            </div>
          </article>
        </div>
      </div>
    </article>
  )
}

export default StaffSongsList
