import { useContext } from "react"
import EditDirection from "./EditDirection"
import { ProjectSearchContext } from "./ProjectSearch"

const SearchRoundHeader = ({ children, roundDivider, searchRound, direction }) => {
  const { selectedRound, internalUser, status } = useContext(ProjectSearchContext)
  const removeOrWinner = status === "won" ? "winner" : "remove"
  const highResOrMp3 = searchRound.showHighResDownload ? "High-Res Download" : "MP3 Download"

  return (
    <article
      className={`search-round song-versions ${roundDivider ? "all-rounds" : ""}`}
      aria-label="search round song results"
    >
      <div className="contain">
        {roundDivider && <div className="round-divider">{searchRound.name}</div>}
        {direction && <EditDirection direction={direction} />}
        <ol className="mm-project-search-song-versions-table">
          <li className="mm-song-versions-table-head">
            <ol>
              {!mm.isMobile && <li className="position" />}
              <li className="play" />
              <li className="song-version">Song/Artist</li>
              {!mm.isMobile && <li className="customizable">Customizable</li>}
              <li className="rating">Rating</li>
              {!mm.isMobile && <li className="length">Length</li>}
              {internalUser && !mm.isMobile && <li className="chosen-by">Chosen by</li>}
              {!mm.isMobile && (
                <div className="table-head-right">
                  <li style={{ marginRight: "10px" }} className="download">
                    Download
                  </li>
                  {internalUser && selectedRound.length === 1 && (
                    <li className="similar">Get Similar</li>
                  )}
                  <li className="notes">Notes</li>

                  {internalUser && <li className={removeOrWinner}>{removeOrWinner}</li>}
                </div>
              )}
            </ol>
          </li>
          <div className="mm-song-versions-table-list">{children}</div>
        </ol>
      </div>
    </article>
  )
}

export default SearchRoundHeader
