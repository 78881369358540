import React from "react"
import ReactResponsiveSelect from "react-responsive-select"
import { caretIcon } from "../../../shared/utils/reactResponsiveSelectHelpers"

const MixtapeFilterSort = (props) => {
  const handleFilterOrSort = (type, selected) => {
    if (!selected.altered) return false
    props.updateMixtapes(selected)
  }

  return (
    <div className="sort-filter">
      {props.loading && <div className="loader" />}

      <ReactResponsiveSelect
        name="filter"
        options={[
          { text: "Show:", value: "default" },
          {
            text: "Show: Mixtapes and Albums",
            value: "mixtapes&albums",
            markup: <span>Mixtapes and Albums</span>,
          },
          { text: "Show: Albums", value: "albums", markup: <span>Albums</span> },
          { text: "Show: Mixtapes", value: "mixtapes", markup: <span>Mixtapes</span> },
        ]}
        selectedValue={props.selectedFilter}
        caretIcon={caretIcon}
        onChange={(selected) => handleFilterOrSort("filter", selected)}
      />

      <ReactResponsiveSelect
        multiselect
        name="genres"
        prefix="Top Genres: "
        caretIcon={caretIcon}
        options={props.filterOptions}
        selectedValues={props.selectedGenres}
        onChange={(selected) => handleFilterOrSort("genres", selected)}
      />

      <ReactResponsiveSelect
        name="sort"
        options={[
          { text: "Sort: ", value: "" },
          {
            text: "Sort: Marmoset Default",
            value: "default",
            markup: <span>Marmoset Default</span>,
          },
          {
            text: "Sort: Newest to Oldest",
            value: "newest",
            markup: <span>Newest to Oldest</span>,
          },
          {
            text: "Sort: Oldest to Newest",
            value: "oldest",
            markup: <span>Oldest to Newest</span>,
          },
          { text: "Sort: A - Z", value: "a-z", markup: <span>A - Z</span> },
          { text: "Sort: Z - A", value: "z-a", markup: <span>Z - A</span> },
        ]}
        selectedValue={props.selectedSort}
        caretIcon={caretIcon}
        onChange={(selected) => handleFilterOrSort("sort", selected)}
      />
    </div>
  )
}

export default MixtapeFilterSort
