import { useWorkbenchStore } from "../../store/store"
import cx from "classnames"

const InstrumentsListItem = ({ item, refine }) => {
  const instrumentsToggledOn = useWorkbenchStore((state) => state.instrumentsToggledOn)
  const instrumentsToggledOff = useWorkbenchStore((state) => state.instrumentsToggledOff)
  const addToInstrumentsOn = useWorkbenchStore((state) => state.addToInstrumentsOn)
  const addToInstrumentsOff = useWorkbenchStore((state) => state.addToInstrumentsOff)
  const removeFromInstrumentsOn = useWorkbenchStore((state) => state.removeFromInstrumentsOn)
  const removeFromInstrumentsOff = useWorkbenchStore((state) => state.removeFromInstrumentsOff)

  const handleClick = (e) => {
    e.preventDefault()
    if (!instrumentsToggledOn.includes(item.value) && !instrumentsToggledOff.includes(item.value)) {
      addToInstrumentsOn(item.value)
      refine(item.value)
    } else if (instrumentsToggledOn.includes(item.value)) {
      removeFromInstrumentsOn(item.value)
      addToInstrumentsOff(item.value)
      // Todo: In a future ticket, look into integrating setIndexUiState to handle both refinement updates in one call
      // https://github.com/algolia/react-instantsearch/discussions/3491
      refine(item.value)
      refine("-" + item.value)
    } else if (instrumentsToggledOff.includes(item.value)) {
      removeFromInstrumentsOff(item.value)
      refine("-" + item.value)
    }
  }

  return (
    <li
      className={cx(
        "actionable",
        instrumentsToggledOn.includes(item.value) ? "selected" : "",
        instrumentsToggledOff.includes(item.value) ? "selected-negative" : ""
      )}
      data-cy={`instruments-option-${item.value}`}
      // Todo: In a future ticket, remove (or make consistent) data-* elements
      // data-val={item.value}
      // data-pretty={item.value}

      // Todo: Determine if we need this feature?
      // data-audio-mp3="<%= asset_path("instruments/#{instrument.parameterize.underscore}.mp3") %>"
    >
      <a data-prevent-default onClick={handleClick}>
        {item.label}
      </a>
    </li>
  )
}

export default InstrumentsListItem
