const SmileyFaceIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38 38" width="38px">
      <g fill="none" fillRule="evenodd">
        <path d="M36.9418 18.7558c0 10-8.107 18.107-18.107 18.107S.7278 28.7558.7278 18.7558 8.8348.6488 18.8348.6488s18.107 8.107 18.107 18.107z" />
        <path d="M30.6435 18.7558c0 6.522-5.287 11.809-11.809 11.809-6.522 0-11.809-5.287-11.809-11.809M24.9907 14.9643c0-1.561 1.266-2.827 2.827-2.827s2.826 1.266 2.826 2.827M7.0258 14.9643c0-1.561 1.266-2.827 2.827-2.827s2.826 1.266 2.826 2.827" />
        <path d="M22.1264 21.8642h-5.791l5.001-15.155" />
      </g>
    </svg>
  )
}

export default SmileyFaceIcon
