import SvgIcon from "../shared/components/SvgIcon"

const ShareIcon = () => {
  return (
    <SvgIcon svgClass="ShareIcon">
      <path
        className="icon-border"
        d="M15 28.9205441c-7.68906752 0-13.92154341-6.2326763-13.92154341-13.9210264C1.07845659 7.31116756 7.31093248 1.07849127 15 1.07849127c7.6890675 0 13.9215434 6.23267629 13.9215434 13.92102643 0 7.6883501-6.2324759 13.9210264-13.9215434 13.9210264M15 0C6.71575563 0 0 6.71500691 0 14.9995177 0 23.2840284 6.71575563 30 15 30c8.2842444 0 15-6.7159716 15-15.0004823C30 6.71500691 23.2842444 0 15 0"
      />
      <path d="M23.2047414 15.1196121l-.0019397.0242457c-.0116379.1774784-.1590517.3171336-.3404095.3171336h-2.0822198v2.3867457l-2.2461207-2.3867457h-5.175l-.0252155-.0009698c-.1755388-.0126078-.3161638-.1600216-.3161638-.3404095V8.99806034l.0009698-.02424568c.0126078-.17747845.1600216-.3161638.3404095-.3161638h9.5033405l.0252156.00096983c.1765086.01260776.3171336.16002155.3171336.33943965v6.12155176zm-.3423492-7.54719831h-9.5033405c-.7875 0-1.4256465.63814655-1.4256465 1.42564655v2.77273706H7.30150862l-.04073276.0009698c-.77295258.0232759-1.38491379.6546337-1.38491379 1.4246768v6.1632543c.02424569.7719827.65463362 1.3839439 1.42564655 1.3839439h.99795259v2.4323276l.00096982.0310345c.03103449.5615302.71573276.8127155 1.09978449.4044181l2.69999998-2.8677802H16.8475216c.7710129-.0252155 1.3829741-.6546336 1.3829741-1.4256465v-2.0337285h-1.0842673v2.0337285l-.0009698.0242457c-.0096983.1745689-.1580819.3171336-.3394396.3171336h-5.175l-2.24709055 2.3877155V19.659375H7.30150862l-.02521552-.0009698c-.17456896-.0087285-.31616379-.1580819-.31616379-.3404095V13.196444l.00096983-.0242457c.00969827-.174569.15905172-.3171336.34040948-.3171336h4.63189658v2.2645474c0 .7875.6381465 1.4266163 1.4256465 1.4266163h4.7046336l2.6990302 2.8677802c.399569.4179957 1.102694.1396552 1.102694-.4364224v-2.4313578h.9969827c.7875 0 1.4256466-.6391163 1.4256466-1.4266163V8.99806034c0-.7875-.6381466-1.42564655-1.4256466-1.42564655z" />
    </SvgIcon>
  )
}

export default ShareIcon
