import { useState } from "react"
import cx from "classnames"
import styles from "../styles/Artists.module.scss"
import Artist from "./Artist"
import { instruments, genres, moods } from "../data/featuredArtistsTags"

const Artists = ({ artists }) => {
  const [selectedArtist, setSelectedArtist] = useState(artists[0])
  const [selectedArtistIndex, setSelectedArtistIndex] = useState(0)
  const [previouslySelectedArtist, setPreviouslySelectedArtist] = useState(null)
  const tagHighlightColor = `highlight${selectedArtistIndex}`

  const handleSelectedArtist = (artist, index) => {
    setSelectedArtistIndex(index)
    setPreviouslySelectedArtist(selectedArtist)
    setSelectedArtist(artist)
  }

  const previouslySelected = (artist) => {
    return previouslySelectedArtist ? artist.id === previouslySelectedArtist.id : false
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.artistsCluster}>
        <div className={styles.artists}>
          {artists.map((artist, index) => {
            return (
              <Artist
                key={artist.id}
                index={index}
                artist={artist}
                previouslySelected={previouslySelected(artist)}
                selected={artist.id === selectedArtist.id}
                handleSelectedArtist={handleSelectedArtist}
              />
            )
          })}
        </div>
      </div>
      <div className={cx(styles.tagsCluster, styles[tagHighlightColor])}>
        <ul className={styles.mobileTags}>
          <TagItems selectedArtist={selectedArtist} tagCategory="instruments" tags={instruments} />
          <TagItems selectedArtist={selectedArtist} tagCategory="genres" tags={genres} />
          <TagItems selectedArtist={selectedArtist} tagCategory="moods" tags={moods} />
        </ul>

        <TagColumn selectedArtist={selectedArtist} tagCategory="instruments" tags={instruments} />
        <TagColumn selectedArtist={selectedArtist} tagCategory="genres" tags={genres} />
        <TagColumn selectedArtist={selectedArtist} tagCategory="moods" tags={moods} />
      </div>
    </div>
  )
}

const TagItems = ({ selectedArtist, tagCategory, tags }) => {
  return tags.map((tag) => {
    return (
      <li
        key={tag}
        className={cx(styles.tag, {
          [styles.highlight]: selectedArtist[tagCategory].includes(tag),
        })}
      >
        {tag}
      </li>
    )
  })
}

const TagColumn = (props) => {
  return (
    <ul className={cx(styles.tagCol, styles.desktopTags)}>
      <TagItems {...props} />
    </ul>
  )
}

export default Artists
