import { Fragment, useRef, useState, useEffect } from "react"
import axios from "axios"
import { Formik } from "formik"
import * as yup from "yup"
import MarmosetIcon from "../../icons/MarmosetIcon"
import SubmissionsForm from "./components/SubmissionsForm"

const validationSchema = yup.object().shape({
  first_name: yup.string().required("First Name is required"),
  last_name: yup.string().required("Last Name is required"),
  email: yup.string().email("Email is invalid").required("Email is required."),
  phone: yup.string().required("Phone is required"),
  location: yup.string().required("Location is required"),
  own_rights: yup.array().required("Recorded material rights is required"),
  artist_name: yup.string().required("Band Name is required"),
  full_link: yup
    .string()
    .url("Must be a valid URL, including https://...")
    .required("Full Mixes are required"),
  instrumental_link: yup.string().url("Must be a valid URL, including https://..."),
  bio: yup.string().required("Bio/Notes/Fun Facts is required"),
  self_record: yup.array().required("Self-record is required"),
  reel_link: yup.string().url("Must be a valid URL, including https://..."),
  strengths: yup.string().required("Strengths is required"),
  availability: yup.array().required("Availability is required"),
  referral: yup.string().required("How did hear about us is required"),
})

const SubmissionsFormContainer = () => {
  const responseRef = useRef(null)
  const [formOn, toggleFormTo] = useState(false)

  useEffect(() => {
    const today = new Date()
    today.setHours(0, 0, 0)
    const startDate = new Date(window.start)
    const endDate = new Date(window.end)

    if (
      today.toString() === startDate.toString() ||
      (today > startDate && today < endDate) ||
      today.toString() === endDate.toString()
    ) {
      toggleFormTo(true)
    } else {
      toggleFormTo(false)
    }
  }, [])

  const formatErrorKey = (key) => {
    const arr = []
    const keyArr = key.split("_")

    keyArr.forEach(function (str) {
      arr.push(str.charAt(0).toUpperCase() + str.slice(1))
    })
    return arr.join(" ")
  }

  const handleErrors = (errors) => {
    let errorsArr = []

    for (const key in errors) {
      errors[key].forEach(function (err) {
        const keyName = formatErrorKey(key)
        errorsArr.push(`${keyName} ${err}`)
      })
    }
    return errorsArr.join(" // ")
  }

  const handleSubmit = (params, formActions) => {
    params.availability = params.availability[0]
    params.own_rights = params.own_rights[0]
    params.self_record = params.self_record[0]

    axios
      .post("/api/v1/submissions", { submission: params })
      .then(({ data }) => {
        const { success, message, errors } = data
        let errorMessage
        if (errors) errorMessage = handleErrors(errors)

        const responseMsg = errorMessage || message

        if (success) formActions.resetForm()
        formActions.setStatus({ message: responseMsg, success })
        formActions.setSubmitting(false)
        responseRef.current.scrollIntoView({ behavior: "smooth", block: "center" })
      })
      .catch((err) => console.log(err))
  }

  const initialValues = {
    first_name: "",
    last_name: "",
    artist_name: "",
    email: "",
    phone: "",
    location: "",
    own_rights: [],
    full_link: "",
    instrumental_link: "",
    bio: "",
    self_record: [],
    reel_link: "",
    favorite_scores: "",
    strengths: "",
    availability: [],
    referral: "",
    notes: "",
  }

  return (
    <Fragment>
      <header id="mm-submissions-landing">
        <div className="cover-image header-bg" />
        <hgroup>
          <div className="contain">
            <div className="submissions-icon">
              <MarmosetIcon />
            </div>
            <h1 className="hThree">Music Submission Form</h1>
          </div>
        </hgroup>
      </header>

      <div className="submissions-message">
        <div className="contain">
          <p>
            <em>
              Marmoset is always on the lookout for awesome new music: from hip-hop to orchestral,
              traditional folk to hard-hitting EDM, psychedelic cumbia to nostalgic R&B — and
              everything in-between. If you have a collection of music, and/or a video reel you’d
              like to share with us, please fill out the form below. If we seem like a good fit,
              we’ll be in touch!
            </em>
          </p>
        </div>
      </div>
      {formOn ? (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(formikProps) => <SubmissionsForm {...formikProps} responseRef={responseRef} />}
        </Formik>
      ) : (
        <div className="submissions-message">
          <p>
            <em>
              Thanks so much for your interest in submitting your music. Marmoset is currently
              taking some time to catch up and listen to the unprecedented number of submissions in
              our system. We will open the form up again once we have managed to get through this
              backlog. We appreciate your patience and thank you for understanding.
            </em>
          </p>
        </div>
      )}
    </Fragment>
  )
}

export default SubmissionsFormContainer
