import algoliasearch from "algoliasearch/lite"
import { InstantSearch, Configure } from "react-instantsearch-hooks-web"
import { useWorkbenchStore, initialState } from "../../store/store"
import BrowseBetaResultsContainer from "./BrowseBetaResultsContainer"
import WorkbenchContainer from "./WorkbenchContainer"
import { algoliaRouting } from "../../shared/utils/algoliaRouting"
import { useStateFromAlgoliaParams } from "../../shared/utils/useStateFromAlgoliaParams"
import { useEffect, useState } from "react"
import { resultsFilters } from "../../pages/browse/FilterTransforms"

const searchClient = algoliasearch("QWRONMN15C", "16428b96d5772af99517905d409adbd8")

const BrowseBetaContainer = () => {
  const el = document.getElementById("mm-browse-beta-container")
  const user_role = el && el.getAttribute("data-user-role")
  const indexName = el && el.getAttribute("data-index-name")
  const sortedIndexName = useWorkbenchStore((state) => state.sortedIndexName)
  const [restrictedAttributes, setRestrictedAttributes] = useState([])
  const selectedResultsFilter = useWorkbenchStore((state) => state.selectedResultsFilter)

  const setStateFromParams = () => {
    const stateFromParams = useStateFromAlgoliaParams()
    useWorkbenchStore.setState((state) => ({ ...state, ...initialState, ...stateFromParams }))
  }

  // Load any given URL params into state on initial page load
  useEffect(() => {
    setStateFromParams()
  }, [])

  // When a user clicks 'Back', reset state to those given by the URL params set by Algolia
  useEffect(() => {
    window.addEventListener("popstate", setStateFromParams)

    return () => {
      window.removeEventListener("popstate", setStateFromParams)
    }
  }, [])

  // Change Algolia configuration when results filter dropdown changes
  useEffect(() => {
    const matchingResultsFilter = resultsFilters.find((item) => item.name === selectedResultsFilter)
    setRestrictedAttributes(matchingResultsFilter.restrictedAttributes)
  }, [selectedResultsFilter])

  return (
    <InstantSearch
      indexName={sortedIndexName ? sortedIndexName : indexName}
      searchClient={searchClient}
      routing={algoliaRouting}
      stalledSearchDelay={350}
    >
      <Configure
        hitsPerPage={25}
        getRankingInfo={true}
        distinct={10}
        restrictSearchableAttributes={restrictedAttributes}
        ruleContexts={[user_role]}
      />
      <WorkbenchContainer user_role={user_role} />
      <BrowseBetaResultsContainer user_role={user_role} />
    </InstantSearch>
  )
}

export default BrowseBetaContainer
