import { PrismicPageParser } from "../../shared/utils/prismicPageParser"
import cx from "classnames"

const WeddingLicenseContainer = () => {
  const data = JSON.parse(window.prismic_data)
  const pageId = window.pageId

  return (
    <section
      id="mm-wedding-license"
      className={cx("pageContainer", "weddingLicense", "serverRendered")}
      aria-label="Wedding License"
    >
      <PrismicPageParser pageData={data} pageId={pageId} />
    </section>
  )
}

export default WeddingLicenseContainer
