import { useMutation } from "@apollo/client"
import cx from "classnames"
import { CREATE_SEARCH_ROUND } from "../../../graphql/mutations/searchRounds"
import GET_PROJECT_SEARCH from "../../../graphql/queries/GetProjectSearch"

const AddNewSearchRound = ({ projectSearchId, slug }) => {
  const [createSearchRound, { loading: mutationLoading }] = useMutation(CREATE_SEARCH_ROUND, {
    refetchQueries: [
      {
        query: GET_PROJECT_SEARCH,
        variables: { slug },
      },
    ],
  })

  const postNewRound = () => {
    createSearchRound({ variables: { id: projectSearchId } }).catch((error) =>
      Sentry.captureException(error)
    )
  }

  return (
    <button
      className={cx("action new-round", { disabled: mutationLoading })}
      onClick={postNewRound}
      data-cy="newRound"
    >
      Add New Round
    </button>
  )
}

export default AddNewSearchRound
