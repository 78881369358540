const ArcSvgIcon = ({ children, fillRule, svgClass, fill }) => {
  return (
    <svg className={`${svgClass} svgIcon`} viewBox="0 0 200 40">
      <g fill={fill} fillRule={fillRule}>
        {children}
      </g>
    </svg>
  )
}

ArcSvgIcon.defaultProps = {
  svgClass: "",
  fillRule: "nonzero",
  fill: "#f1eff0",
}

export default ArcSvgIcon
