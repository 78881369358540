import { useState } from "react"
import { useMutation } from "@apollo/client"
import { range } from "../../../shared/utils/helpers"
import { SET_SRSV_RATING } from "../../../graphql/mutations/searchRoundSongVersions"

const Rating = (props) => {
  const [rating, setRating] = useState(props.rating)
  const [setSearchRoundSongVersionRating] = useMutation(SET_SRSV_RATING)

  const toggleSiblingStars = (e, action) => {
    const siblings = parseInt(e.target.dataset.rating) - 1
    let target = e.target
    range(siblings).map(() => {
      target = target.previousSibling
      target.classList[action]("backfill")
    })
  }

  const handleRating = (e) => {
    let newRating = parseInt(e.target.dataset.rating)
    // set rating back to zero if user wants to deselect their current rating
    newRating = newRating !== rating ? newRating : 0
    postRating(newRating)
  }

  const postRating = (newRating) => {
    const variables = {
      id: props.id,
      rating: newRating,
    }

    setSearchRoundSongVersionRating({ variables })
      .then(() => setRating(newRating))
      .catch((err) => console.log(err))
  }

  return (
    <li className="action rating">
      <div className="stars">
        {range(5).map((num) => {
          const starNum = num + 1

          return (
            <span
              key={starNum}
              className={`star ${starNum <= rating ? "fill-star" : ""}`}
              data-rating={starNum}
              data-cy="starRating"
              onClick={handleRating}
              onMouseOver={(e) => toggleSiblingStars(e, "add")}
              onMouseOut={(e) => toggleSiblingStars(e, "remove")}
            >
              &#9733;
            </span>
          )
        })}
      </div>
    </li>
  )
}

export default Rating
