import SvgIcon from "../shared/components/SvgIcon"

const CassetteIcon = () => {
  return (
    <SvgIcon svgClass="CassetteIcon" fillRule="evenodd" fill="#21928e">
      <path
        className="icon-border"
        d="M15 28.9205441c-7.68906752 0-13.92154341-6.2326763-13.92154341-13.9210264C1.07845659 7.31116756 7.31093248 1.07849127 15 1.07849127c7.6881029 0 13.9205788 6.23267629 13.9205788 13.92102643 0 7.6883501-6.2324759 13.9210264-13.9205788 13.9210264M15 0C6.71575563 0 0 6.71500691 0 14.9995177 0 23.2840284 6.71575563 30 15 30c8.2832797 0 15-6.7159716 15-15.0004823C30 6.71500691 23.2832797 0 15 0"
      />
      <path d="M6.7893687 20.8376689h16.4140309V9.96382823H6.7893687V20.8376689zm17.0828775 1.2831153H6.11838178c-.339239 0-.61426872-.2739595-.61426872-.6142687V9.29391146c0-.339239.27502972-.61426872.61426872-.61426872H23.8722462c.339239 0 .6142688.27502972.6142688.61426872V21.5065155c0 .3403092-.2750298.6142687-.6142688.6142687z" />
      <path d="M11.353578 13.8534834c-.8571938 0-1.55493462.6966706-1.55493462 1.5538644 0 .8561237.69774082 1.5549346 1.55493462 1.5549346s1.5538644-.6988109 1.5538644-1.5549346c0-.8571938-.6966706-1.5538644-1.5538644-1.5538644m0 4.3919144c-1.56563617 0-2.83912012-1.2724138-2.83912012-2.83805 0-1.5645659 1.27348395-2.8380499 2.83912012-2.8380499 1.564566 0 2.8380499 1.273484 2.8380499 2.8380499 0 1.5656362-1.2734839 2.83805-2.8380499 2.83805M18.7180251 13.8534834c-.8571939 0-1.5549346.6966706-1.5549346 1.5538644 0 .8561237.6977407 1.5549346 1.5549346 1.5549346.8571938 0 1.5538644-.6988109 1.5538644-1.5549346 0-.8571938-.6966706-1.5538644-1.5538644-1.5538644m0 4.3919144c-1.5656362 0-2.83805-1.2724138-2.83805-2.83805 0-1.5645659 1.2724138-2.8380499 2.83805-2.8380499 1.564566 0 2.8369798 1.273484 2.8369798 2.8380499 0 1.5656362-1.2724138 2.83805-2.8369798 2.83805" />
      <path d="M11.4006648 18.2386201h7.275981v-1.2831153h-7.275981z" />
    </SvgIcon>
  )
}

export default CassetteIcon
