import { gql, useQuery } from "@apollo/client"
import { PrismicRichText } from "@prismicio/react"
import htmlSerializer from "../../../shared/utils/prismicHTMLSerializer"
import Artists from "./Artists"
import Button from "../../../shared/components/prismic/Button"
import HorizontalRule from "../../../shared/components/prismic/HorizontalRule"
import styles from "../styles/FeaturedArtists.module.scss"

const GET_FEATURED_ARTISTS = gql`
  query FeaturedArtists($limit: Int) {
    featuredArtists(limit: $limit) {
      id
      genres
      featuredArtistImages {
        goldPhoto
        greenPhoto
        purplePhoto
      }
      instruments
      moods
      name
      slug
    }
  }
`

const FeaturedArtists = ({ slice }) => {
  const variables = { limit: 3 } // amount of artists the query returns
  const { loading, error, data } = useQuery(GET_FEATURED_ARTISTS, { variables })
  const { primary } = slice

  if (loading) return null
  if (error) console.log(error)

  const { featuredArtists } = data
  if (featuredArtists.length < 3) {
    return null
  } else {
    return (
      <article className={styles.featuredArtists} aria-label="Featured artists">
        <div className={styles.contain}>
          <div className={styles.richText}>
            <PrismicRichText field={primary.rich_text} components={htmlSerializer} />
          </div>
          <Artists artists={featuredArtists} />
          <Button slice={slice} />
          <HorizontalRule />
        </div>
      </article>
    )
  }
}

export default FeaturedArtists
