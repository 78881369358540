import React from "react"
import UserIcon from "../../../icons/UserIcon"
import styles from "../styles/Banner.module.scss"

const Banner = ({ header, subText, emSubText }) => {
  return (
    <header>
      <div className="cover-image" />
      <div className={styles.banner}>
        <div className={styles.icon}>
          <UserIcon />
        </div>
        <h1 className="hThree">{header}</h1>
        <sub className={styles.subHeader}>
          <em className={styles.emSubText}>{emSubText}</em> {subText}
        </sub>
      </div>
    </header>
  )
}

Banner.defaultProps = {
  header: "",
  subText: "",
  emSubText: "",
}

export default Banner
