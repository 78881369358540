const AmexIcon = () => {
  return (
    <svg className="amexIcon" height="48px" width="48px" viewBox="0 0 48 48">
      <path
        fill="#1976d2"
        d="M45 35c0 2.21-1.79 4-4 4H7c-2.21 0-4-1.79-4-4V13c0-2.21 1.79-4 4-4h34c2.21 0 4 1.79 4 4z"
      />
      <path
        fill="#fff"
        d="M22.254 20l-2.113 4.684L18.039 20h-2.695v6.727L12.34 20h-2.274L7 26.98h1.816l.668-1.558h3.434l.684 1.558h3.464v-5.183l2.297 5.183h1.563l2.351-5.093v5.093H25V20zm-12.117 3.914l1.023-2.437 1.067 2.437zm27.746-.5L41 20.02h-2.219l-1.992 2.164L34.86 20H28v6.98h6.637l2.09-2.308L38.766 27h2.21zm-4.156 2.102h-4.008v-1.38h3.836v-1.323h-3.836v-1.31l4.23.013 1.696 1.898z"
      />
    </svg>
  )
}

export default AmexIcon
