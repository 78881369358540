import { useState } from "react"
import cx from "classnames"
import SavedCard from "./SavedCard"
import styles from "../styles/SavedCards.module.scss"

const SavedCards = ({ cards, userId }) => {
  const [responseMessage, setResponseMessage] = useState(null)

  return (
    <article id="savedCards" className={styles.savedCards}>
      <div className={styles.contain}>
        <h4 className={cx("hFour", styles.header)}>Saved Cards</h4>
        {cards.length > 9 && (
          <div className={styles.maxCardsMessage}>
            <p>You've saved the maximum of ten cards.</p>
            <p>To save a new card, remove a previously saved card from the list.</p>
          </div>
        )}
        {responseMessage && (
          <div className={styles.responseMessage}>
            <p>{responseMessage}</p>
          </div>
        )}
        <div>
          <ol className={styles.tableHead}>
            <li className={styles.icon}></li>
            <li className={styles.info}>Card</li>
            <li className={styles.expires}>Expires</li>
            <li className={styles.remove}>Remove</li>
          </ol>
          <ol>
            {cards.map((card) => (
              <SavedCard
                key={card.id}
                card={card}
                setResponseMessage={setResponseMessage}
                userId={userId}
              />
            ))}
          </ol>
        </div>
      </div>
    </article>
  )
}

export default SavedCards
