import { gql } from "@apollo/client"

export const ADD_DIRECTION = gql`
  mutation addDirection($id: ID!, $name: String!, $songVersionIds: [ID!]) {
    addDirection(id: $id, name: $name, songVersionIds: $songVersionIds) {
      searchRound {
        id
        directions {
          id
          name
          searchRoundId
          songVersionsCount
          createdAt
          searchRoundSongVersions {
            id
          }
        }
        searchRoundSongVersions {
          id
        }
      }
    }
  }
`

export const DELETE_ALL_DIRECTIONS = gql`
  mutation deleteAllDirections($id: ID!) {
    deleteAllDirections(id: $id) {
      searchRound {
        id
        directions {
          id
          searchRoundSongVersions {
            id
          }
        }
        searchRoundSongVersions {
          id
        }
      }
    }
  }
`

export const DELETE_DIRECTION = gql`
  mutation deleteDirection($id: ID!) {
    deleteDirection(id: $id) {
      projectSearch {
        id
        searchRounds {
          id
          directions {
            id
          }
        }
      }
    }
  }
`

export const UPDATE_DIRECTION_NAME = gql`
  mutation updateDirectionName($id: ID!, $name: String!, $searchRoundId: ID!) {
    updateDirectionName(id: $id, name: $name, searchRoundId: $searchRoundId) {
      projectSearch {
        id
        searchRounds {
          id
          directions {
            id
            name
          }
        }
      }
    }
  }
`
