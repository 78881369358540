export const captureAnalytics = (order, products) => {
  dataLayer.push({
    ecommerce: {
      purchase: {
        actionField: {
          id: order.id,
          affiliation: "Marmoset Music",
          revenue: order.sale_price,
        },
        products: products,
      },
    },
    event: "impressionSent",
  })
}
