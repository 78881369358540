import React, { Component } from "react"
import { Formik } from "formik"
import * as yup from "yup"
import axios from "axios"
import valid from "card-validator"
import CheckoutForm from "./components/CheckoutForm"
import CheckoutDetails from "./components/CheckoutDetails"
import PromoCodeForm from "./components/PromoCodeForm"
import FormFlashMsg from "../../shared/components/FormFlashMsg"
import ErrorBoundary from "../../shared/components/ErrorBoundary"
import { checkoutParams } from "../../shared/utils/paramModifiers"
import { captureAnalytics } from "./utils/checkout"

const validationSchema = yup.object().shape({
  billing_information_attributes: yup.object().shape({
    first_name: yup.string().required("First name is required."),
    last_name: yup.string().required("Last name is required."),
    end_client: yup.string().required("End user is required."),
    email: yup.string().email("Email is invalid").required("Email is required."),
    address_attributes: yup.object().shape({
      line1: yup.string().required("Billing address is required."),
      country: yup.object().shape({
        label: yup.string().required("Country is required."),
        value: yup.string().required("Country is required."),
      }),
      subregion: yup.object().shape({
        label: yup.string().required("State/Region is required."),
        value: yup.string().required("State/Region is required."),
      }),
      city: yup.string().required("City is required."),
      postal_code: yup.string().required("Postal code is required."),
    }),
  }),
  saved_card: yup.object().shape({
    label: yup.string(),
    value: yup.string(),
  }),
  card_number: yup.string().when("saved_card", {
    is: (selectedCard) => isNaN(selectedCard.value),
    then: yup
      .string()
      .required("Card number is required.")
      .test("card-number", "Card number is invalid.", (value) => valid.number(value).isValid),
  }),
  exp_month: yup.object().shape({
    label: yup.string(),
    value: yup.string().required("Exp month is required."),
  }),
  exp_year: yup.object().shape({
    label: yup.string(),
    value: yup.string().required("Exp year is required."),
  }),
  cvc: yup
    .string()
    .required("CVC is required.")
    .matches(/^[0-9*]{3,4}$/, "CVC must be 3-4 digits."),
  agree_to_terms: yup.bool().oneOf([true], "You must agree to terms before submitting."),
})

class CheckoutContainer extends Component {
  state = {
    authenticity_token: "",
    discount: 0,
    isClick: true,
    isCustom: false,
    isLoggedIn: false,
    isLoading: true,
    licenseVersions: [],
    lineItems: [],
    masterLicenseAgreement: "",
    orderId: "",
    orderType: "",
    promoCode: "",
    promoCodeResponse: [],
    promoCodeSuccess: false,
    savedCards: [],
    stripeKey: "",
    subtotal: 0,
    total: 0,
    billing_information: {
      first_name: "",
      last_name: "",
      company: "",
      end_client: "",
      phone: "",
      email: "",
      address_attributes: {
        line1: "",
        line2: "",
        country: { label: "Select a country...", value: "" },
        subregion: { label: "Select a state/region...", value: "" },
        city: "",
        postal_code: "",
      },
    },
  }

  componentDidMount = () => {
    const drawerSpinner = document.querySelector(".loading")
    if (drawerSpinner) drawerSpinner.remove()
    const orderId = window.location.pathname.match(/[0-9]+/)[0]
    const orderType = window.location.pathname.split("/")[1]

    axios
      .get(`/api/v1/orders/order_props/${orderId}/type=${orderType}`)
      .then(({ data }) => this.setState(data))
  }

  initialValues = () => {
    return {
      save_new_card: false,
      agree_to_terms: false,
      card_number: "",
      exp_month: "",
      exp_year: "",
      cvc: "",
      id: this.state.orderId,
      notes: "",
      billing_information_attributes: {
        first_name: this.state.billing_information.first_name || "",
        last_name: this.state.billing_information.last_name || "",
        company: this.state.billing_information.company || "",
        end_client: "",
        phone: this.state.billing_information.phone || "",
        email: this.state.billing_information.email || "",
        address_attributes: {
          line1: this.state.billing_information.address_attributes.line1 || "",
          line2: this.state.billing_information.address_attributes.line2 || "",
          country: { label: "Select a country...", value: "" },
          subregion: { label: "Select a state/region...", value: "" },
          city: this.state.billing_information.address_attributes.city || "",
          postal_code: this.state.billing_information.address_attributes.postal_code || "",
        },
      },
    }
  }

  handleSubmit = (params, actions) => {
    const { country, subregion, postal_code } =
      params.billing_information_attributes.address_attributes

    fbq("track", "AddPaymentInfo")

    if (!_.isEmpty(params.saved_card) && !isNaN(params.saved_card.value)) {
      this.orderPatchRequest(params, actions)
    } else {
      Stripe.card.createToken(
        {
          number: params.card_number,
          cvc: params.cvc,
          exp_month: params.exp_month.value,
          exp_year: params.exp_year.value,
          address_zip: postal_code,
        },
        (status, response) => {
          if (status === 200) {
            params.stripe_card_token = response.id
            this.orderPatchRequest(params, actions)
          } else {
            actions.setErrors({ server: [response.error.message] })
            actions.setSubmitting(false)
            document.querySelector(".flash-msg").scrollIntoView({ behavior: "smooth" })
          }
          // these fields get cleared out for no apparent reason so need to set them again to persist
          actions.setFieldValue(
            "billing_information_attributes.address_attributes.country",
            country
          )
          actions.setFieldValue(
            "billing_information_attributes.address_attributes.subregion",
            subregion
          )
        }
      )
    }
  }

  handlePromoCode = (promoCode, discount) => {
    this.setState({
      promoCode,
      discount,
      total: this.state.subtotal - discount,
    })
  }

  setPromoCodeResponse = (promoCodeResponse, promoCodeSuccess) => {
    this.setState({ promoCodeResponse, promoCodeSuccess })
  }

  orderPatchRequest = (params, actions) => {
    axios
      .request(`/order/complete/${this.state.orderType}`, {
        method: "patch",
        data: {
          order: checkoutParams(params),
          authenticity_token: this.props.authenticity_token,
          agree_to_terms: params.agree_to_terms,
          save_new_card: params.save_new_card,
          saved_card: params.saved_card && params.saved_card.value,
        },
      })
      .then(({ data }) => {
        if (data.success) {
          fbq("track", "Purchase", { value: data.order.sale_price, currency: "USD" })
          mm.cart.hydrate()
          if (data.show_analytics) captureAnalytics(data.order, data.analytics)
          mm.router.navigate(`/order/${encodeURI(data.encrypted_id)}/receipt/${data.order_type}`, {
            trigger: true,
          })
        } else {
          actions.setErrors({ server: data.errors })
          actions.setSubmitting(false)
          document.querySelector(".flash-msg").scrollIntoView({ behavior: "smooth" })
        }
      })
      .catch((error) => {
        console.log(error)
        actions.setSubmitting(false)
        document.querySelector("body").scrollIntoView({ behavior: "smooth" })
      })
  }

  render() {
    const { isLoading, lineItems, promoCodeResponse, promoCodeSuccess } = this.state
    const cartIsEmpty = !isLoading && lineItems.length < 1

    if (cartIsEmpty) {
      return <h1>Your cart is empty.</h1>
    } else {
      return (
        <main className="contain">
          <CheckoutDetails {...this.state} />

          {promoCodeResponse.length > 0 && (
            <FormFlashMsg
              type="public"
              status={`${promoCodeSuccess === true ? "success" : "error"}`}
              messages={promoCodeResponse}
            />
          )}

          {!this.state.promoCode && (
            <ErrorBoundary>
              <PromoCodeForm
                handlePromoCode={this.handlePromoCode}
                id={this.state.orderId}
                orderType={this.state.orderType}
                setPromoCodeResponse={this.setPromoCodeResponse}
              />
            </ErrorBoundary>
          )}

          <article className="billing-info">
            <ErrorBoundary>
              <Formik
                enableReinitialize={true}
                validationSchema={validationSchema}
                initialValues={this.initialValues()}
                onSubmit={this.handleSubmit}
              >
                {(formikProps) => <CheckoutForm {...formikProps} {...this.state} />}
              </Formik>
            </ErrorBoundary>
          </article>
        </main>
      )
    }
  }
}

export default CheckoutContainer
