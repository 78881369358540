export const instruments = [
  "Strings",
  "Heavy Bass",
  "Organ",
  "Horns/Brass",
  "Electric Guitar",
  "Big Drums",
  "Synthesizer",
  "Piano",
]

export const genres = [
  "Ambient",
  "Vintage",
  "Folk",
  "Rock",
  "Hip Hop & Rap",
  "RnB",
  "Orchestral",
  "Dance",
]

export const moods = [
  "Empowering",
  "Gritty",
  "Calm",
  "Anthemic",
  "Quirky",
  "Rebellious",
  "Vulnerable",
  "Cinematic",
]
