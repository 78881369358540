import { useEffect, useState, createContext } from "react"
import cx from "classnames"
import LoadingIcon from "../../icons/LoadingIcon"
import SimilaritySearchInput from "./SimilaritySearchInput"
import YoutubeVideoPlayer from "./YoutubeVideoPlayer"
import LongRunningProcess from "../../shared/utils/LongRunningProcess"
import SongsContainer from "../../shared/components/SongsContainer"
import groupBySong from "../../shared/utils/songNormalizer"
import { SPOTIFY_TRACK_LINK_REGEX, YOUTUBE_LINK_REGEX } from "../../shared/utils/helpers"
import axios from "axios"

export const SimilaritySearchContext = createContext()

const OEMBED_BASE_URL =
  "https://open.spotify.com/oembed?url=https%3A%2F%2Fopen.spotify.com%2Ftrack%2F"

const SimilaritySearchContainer = () => {
  const el = document.getElementById("mm-youtube-similarity-container")
  const user_role = el && el.getAttribute("data-user-role")
  const [searchQueryTerm, setSearchQueryTerm] = useState("")
  const [failedSearchMessage, setFailedSearchMessage] = useState("")
  const [currentActionId, setCurrentActionId] = useState("")
  const [isProcessing, setIsProcessing] = useState(false)
  const [isLoadingSpotify, setIsLoadingSpotify] = useState(false)
  const [songs, setSongs] = useState([])

  const contextValue = {
    searchQueryTerm,
    setSearchQueryTerm,
  }

  const handleSuccess = (data) => {
    let { songs } = data
    setIsProcessing(false)
    setFailedSearchMessage(null)
    const groupedSongs = groupBySong(songs)
    setSongs(groupedSongs)
  }

  const getSpotifyEmbed = async (trackId) => {
    try {
      var instance = axios.create()
      delete instance.defaults.headers.common["X-CSRF-Token"]
      delete instance.defaults.headers.common["Accepts"]
      const resp = await instance.get(`${OEMBED_BASE_URL}${trackId}`, {
        headers: { "Content-Type": "application/json" },
      })

      if (resp.status != 200) {
        throw new Error(resp.statusText)
      }

      getSpotifySimilarSongs()
    } catch (err) {
      console.log(err)
      setIsLoadingSpotify(false)
      setSongs([])
      setFailedSearchMessage(err.message)
    }
  }

  const getSpotifySimilarSongs = async () => {
    try {
      const resp = await fetch(
        `/spotify-search/${SPOTIFY_TRACK_LINK_REGEX.exec(searchQueryTerm)[3]}`
      )
      if (!resp.ok) {
        throw new Error(`Response Status: ${resp.status}`)
      }

      const json = await resp.json()

      if (!json.success) {
        throw new Error(json.message)
      }
      if (json.songs) {
        const groupedSongs = groupBySong(json.songs)
        setSongs(groupedSongs)
      }
      setFailedSearchMessage(null)
      setIsLoadingSpotify(false)
    } catch (err) {
      setSongs([])
      setFailedSearchMessage(err.message)
      setIsLoadingSpotify(false)
    }
  }

  const initializeYoutubeSearchAction = async () => {
    try {
      const resp = await fetch(`/youtube-search/${searchQueryTerm}`, {
        method: "POST",
        headers: {
          "X-CSRF-Token": mm.csrf,
        },
      })
      if (!resp.ok) {
        throw new Error(`Response Status: ${resp.status}`)
        return
      }
      const result = await resp.json()
      return result
    } catch (err) {
      setIsProcessing(false)
      setFailedSearchMessage(err.message)
      return { result: {} }
    }
  }

  useEffect(async () => {
    if (YOUTUBE_LINK_REGEX.test(`v=${searchQueryTerm}`)) {
      setIsProcessing(true)
      const result = await initializeYoutubeSearchAction()
      if (result.success) {
        handleSuccess(result)
        return
      }
      setFailedSearchMessage(null)
      setCurrentActionId(result.id)
      setIsLoadingSpotify(false)
    } else if (SPOTIFY_TRACK_LINK_REGEX.test(searchQueryTerm)) {
      setCurrentActionId("")
      setIsLoadingSpotify(true)
      setIsProcessing(false)
      getSpotifyEmbed(SPOTIFY_TRACK_LINK_REGEX.exec(searchQueryTerm)[3])
    } else {
      setCurrentActionId("")
      setIsLoadingSpotify(false)
      setIsProcessing(false)
    }
  }, [searchQueryTerm])

  return (
    <>
      <SimilaritySearchContext.Provider value={contextValue}>
        <section className={cx("search-wrap")} aria-label="Search">
          <nav
            className="tabs desktop"
            aria-label="Browse by Search & Filters or Youtube/Spotify Link"
          >
            <ul>
              <li className="">
                <a href="/browse">Search & Browse</a>
              </li>
              <li className="current">
                <a data-prevent-default>
                  <span className="new">AI Similarity Search (beta)</span>
                </a>
              </li>
            </ul>
          </nav>
          <SimilaritySearchInput />
        </section>
        {YOUTUBE_LINK_REGEX.test(`v=${searchQueryTerm}`) && (
          <YoutubeVideoPlayer videoId={searchQueryTerm} />
        )}
        {isProcessing && currentActionId !== "" && (
          <LongRunningProcess
            url={`/youtube-search/${searchQueryTerm}`}
            body={{ id: currentActionId }}
            pollInterval={1000}
            timeout={100000}
            callback={handleSuccess}
          >
            <LoadingIcon />
          </LongRunningProcess>
        )}
        {isProcessing && !currentActionId && <LoadingIcon />}
        {SPOTIFY_TRACK_LINK_REGEX.test(searchQueryTerm) && (
          <div className="contain">
            <iframe
              style={{ borderRadius: "12px", marginTop: "2rem" }}
              width="100%"
              height="152"
              frameBorder="0"
              allowFullScreen
              allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
              loading="lazy"
              src={`https://open.spotify.com/embed/track/${
                SPOTIFY_TRACK_LINK_REGEX.exec(searchQueryTerm)[3]
              }?utm_source=oembed`}
            ></iframe>
          </div>
        )}
        {isLoadingSpotify ? (
          <LoadingIcon />
        ) : failedSearchMessage !== "" ? (
          <div className="spotify-message">
            <span className="failed">{failedSearchMessage}</span>
          </div>
        ) : (
          <></>
        )}
        {/** Show songs here */}
        {songs[0] && !isProcessing && !isLoadingSpotify && (
          <SongsContainer songs={songs} user_role={user_role} />
        )}
        {songs.length === 0 && !isProcessing && <div id="empty-search-wrapper"></div>}
      </SimilaritySearchContext.Provider>
    </>
  )
}

export default SimilaritySearchContainer
