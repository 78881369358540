import { Fragment } from "react"
import { useQuery } from "@apollo/client"
import AccountNav from "./components/AccountNav"
import Banner from "./components/Banner"
import CancelAccountForm from "./components/CancelAccountForm"
import EditProfileFormContainer from "./components/EditProfileFormContainer"
import ResetPasswordForm from "./components/ResetPasswordForm"
import SavedCards from "./components/SavedCards"
import GET_ACCOUNT from "../../graphql/queries/GetAccount"
import CookiePreferences from "./components/CookiePreferences"
import styles from "./AccountContainer.module.scss"

const AccountContainer = () => {
  const id = document.location.pathname.split("/")[2]
  const { loading, error, data } = useQuery(GET_ACCOUNT, { variables: { id } })

  if (loading) {
    return (
      <Fragment>
        <Banner />
        <div className={styles.loading}>Loading ...</div>
      </Fragment>
    )
  }

  if (error) {
    return (
      <Fragment>
        <Banner />
        <div className={styles.error}>
          <div>
            {error.graphQLErrors.map(({ message }, i) => (
              <p key={i}>{message}</p>
            ))}
          </div>
        </div>
      </Fragment>
    )
  }

  const { account } = data
  const showSavedCards = account.cards.length > 0

  return (
    <Fragment>
      <Banner header={account.fullName} emSubText="Member since" subText={account.createdAt} />
      <AccountNav savedCards={showSavedCards} />
      <EditProfileFormContainer account={account} />
      <ResetPasswordForm />
      {showSavedCards && <SavedCards cards={account.cards} userId={id} />}
      <CookiePreferences />
      <CancelAccountForm id={account.id} />
      <footer className={styles.footer}>
        <div className={styles.contain}>
          Please let us know how we can make things better. Send us a note directly to{" "}
          <a className={styles.emailLink} href="mailto:support@marmosetmusic.com" target="_blank">
            support@marmosetmusic.com
          </a>
          .
        </div>
      </footer>
    </Fragment>
  )
}

export default AccountContainer
