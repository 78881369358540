import { Pagination } from "react-instantsearch-hooks-web"
import { useEffect, useState, useRef } from "react"
import cx from "classnames"
import SongItemBeta from "../../shared/components/SongItemBeta"

const BrowseBetaResults = ({ groupedHits, user_role, status }) => {
  const [expandedSongVersionId, setExpandedSongVersionId] = useState(false)
  const blanketDealModalRef = useRef(null)

  const handleSetExpandedSongVersionId = (svid) => {
    setExpandedSongVersionId(svid)
  }

  //Handles clicking outside of Song Version Action Menu
  const handleOnClick = (e) => {
    if (
      !e.target.closest([".actions-menu", ".expand-actions-menu"]) &&
      !(blanketDealModalRef.current && blanketDealModalRef.current.contains(e.target))
    ) {
      setExpandedSongVersionId(false)
    }
  }

  useEffect(() => {
    document.body.addEventListener("click", handleOnClick)

    return () => {
      document.body.removeEventListener("click", handleOnClick)
    }
  })

  return (
    <>
      <div className="mm-song-versions-table" data-title="Search results">
        <ol
          className={cx(
            "mm-song-versions-table-list",
            status === "stalled" || status === "loading" ? "hidden" : ""
          )}
        >
          <li className="mm-song-versions-table-head">
            <ol>
              <li className="song-version">Song / Artist</li>
              <li className="genres desktop">Genres</li>
              <li className="arc desktop">Arc&trade;</li>
              <li className="energy desktop" data-touch>
                Energy
              </li>
              <li className="length">Length</li>
            </ol>
          </li>
          {groupedHits.map((hit) => (
            <SongItemBeta
              key={hit.songId}
              song={hit}
              user_role={user_role}
              handleSetExpandedSongVersionId={handleSetExpandedSongVersionId}
              expandedSongVersionId={expandedSongVersionId}
              currentBlanketDealModalRef={blanketDealModalRef}
            />
          ))}
        </ol>
      </div>
      <Pagination
        className={status === "stalled" || status === "loading" ? "hidden" : ""}
        classNames={{
          root: "mm-pagelist",
          list: "mm-pagelist-inner",
          disabledItem: "hidden",
          selectedItem: "selected",
          firstPageItem: "mm-page-nav",
          previousPageItem: "mm-page-nav prev",
          nextPageItem: "mm-page-nav next",
          lastPageItem: "mm-page-nav",
        }}
      />
    </>
  )
}

export default BrowseBetaResults
