import SvgIcon from "../shared/components/SvgIcon"

const CustomizeIcon = () => {
  return (
    <SvgIcon svgClass="CustomizeIcon" fillRule="evenodd" fill="#21928e">
      <path
        className="icon-border"
        d="M15.0001 1.01570964C7.28883239 1.01570964 1.01580964 7.28873239 1.01580964 15c0 7.7112676 6.27302275 13.9842904 13.98429036 13.9842904 7.7102519 0 13.9842904-6.2730228 13.9842904-13.9842904 0-7.71126761-6.2740385-13.98429036-13.9842904-13.98429036M15.0001 30c-8.27092362 0-15-6.7290764-15-15 0-8.27092362 6.72907638-15 15-15 8.2709236 0 15 6.72907638 15 15 0 8.2709236-6.7290764 15-15 15"
      />
      <path d="M8.85 20.218h1V14.42h-1zM12.93 23.605h1V12.3h-1zM8.85 12.304h1V8.36h-1zM21.28 20.218h1V14.42h-1zM21.28 12.304h1V8.36h-1zM17.07 14.158h1V8.36h-1zM17.07 20.225h1V16.28h-1zM12.93 10.335h1V6.39h-1z" />
    </SvgIcon>
  )
}

export default CustomizeIcon
