import { useEffect } from "react"
import { MAX_DURATION } from "./FilterTransforms"

const CircleRange = ({ min, max, value, step, onChange, onChangeEnd }) => {
  const [minValue, setMinValue] = React.useState(value ? value.min : min)
  const [maxValue, setMaxValue] = React.useState(value && value.max ? value.max : max)

  // Refs for the actual input controllers
  const minSliderRef = React.useRef(null)
  const maxSliderRef = React.useRef(null)

  // Refs for the circles used for display
  const minCircleRef = React.useRef(null)
  const maxCircleRef = React.useRef(null)

  // Ref for inner rail (controls color between circles)
  const innerRailRef = React.useRef(null)

  const sliders = [minSliderRef, maxSliderRef]
  const displayCircles = [minCircleRef, maxCircleRef]

  function formatTime(time) {
    // "<< 0" simply removes decimal values
    var minutes = (time / 1000 / 60) << 0,
      seconds = (time / 1000) % 60,
      pretty
    seconds = seconds.toFixed(0)
    if (seconds < 10) seconds = "0" + seconds
    if (minutes <= 0) minutes = ""
    return minutes + ":" + seconds
  }

  React.useEffect(() => {
    if (value) {
      setMinValue(value.min)
      setMaxValue(value.max)
    }
    if (value.min === 0) {
      minCircleRef.current.className = "control"
      minSliderRef.current.className = "input"
    }
    if (value.max === MAX_DURATION) {
      maxCircleRef.current.className = "control"
      maxSliderRef.current.className = "input"
    }
  }, [value])

  const handleMinChange = (e) => {
    e.preventDefault()
    const newMinVal = Math.min(+e.target.value, maxValue - step)
    if (!value) setMinValue(newMinVal)
    onChange({ min: newMinVal, max: maxValue })
  }

  const handleMaxChange = (e) => {
    e.preventDefault()
    const newMaxVal = Math.max(+e.target.value, minValue + step)
    if (!value) setMaxValue(newMaxVal)
    onChange({ min: minValue, max: newMaxVal })
  }

  const handleDragStart = (e) => {
    displayCircles.forEach((circle) => {
      circle.current.className = "control"
    })
    sliders.forEach((slider) => {
      slider.current.className = "input"
    })
    if (minSliderRef.current.contains(e.target)) {
      minCircleRef.current.className = "control active"
      minSliderRef.current.className = "input active"
    } else {
      maxCircleRef.current.className = "control active"
      maxSliderRef.current.className = "input active"
    }
    sliders.forEach((slider) => {
      slider.current.addEventListener("touchend", handleDragEnd)
      slider.current.addEventListener("mouseleave", handleDragEnd)
      slider.current.addEventListener("mouseup", handleDragEnd)
    })
  }

  const handleDragEnd = (e) => {
    sliders.forEach((slider) => {
      slider.current.removeEventListener("mouseup", handleDragEnd)
      slider.current.removeEventListener("mouseleave", handleDragEnd)
      slider.current.removeEventListener("touchend", handleDragEnd)
    })
    onChangeEnd(minSliderRef.current.value, maxSliderRef.current.value)
  }

  const position = (value) => {
    return ((value - min) / (max - min)) * 100
  }

  React.useEffect(() => {
    //set event listeners that will tell us when to trigger the onDragEnd event
    let sliders = [minSliderRef, maxSliderRef]
    sliders.forEach((slider) => {
      slider.current.addEventListener("mousedown", handleDragStart)
      slider.current.addEventListener("touchstart", handleDragStart)
    })

    return () => {
      sliders.forEach((slider) => {
        slider.current.removeEventListener("mousedown", handleDragStart)
        slider.current.removeEventListener("touchstart", handleDragStart)
      })
    }
  }, [])

  return (
    <div className="wrapper">
      <div className="input-wrapper">
        <input
          className="input"
          type="range"
          value={minValue}
          min={min}
          max={max}
          step={step}
          onChange={handleMinChange}
          ref={minSliderRef}
        />
        <input
          className="input"
          type="range"
          value={maxValue}
          min={min}
          max={max}
          step={step}
          onChange={handleMaxChange}
          ref={maxSliderRef}
        />
      </div>

      <div className="control-wrapper">
        <div className="control" ref={minCircleRef} style={{ left: `${position(minValue)}%` }}>
          {formatTime(minValue)}
        </div>
        <div className="rail">
          <div
            className="inner-rail"
            ref={innerRailRef}
            style={{ left: `${position(minValue)}%`, right: `${100 - position(maxValue)}%` }}
          />
        </div>
        <div className="control" ref={maxCircleRef} style={{ left: `${position(maxValue)}%` }}>
          {formatTime(maxValue)}
        </div>
      </div>
    </div>
  )
}

export default CircleRange
