const searchIcon = () => {
  return (
    <svg className="searchIcon" viewBox="0 0 30 30">
      <g fill="#21928e" fillRule="evenodd">
        <path
          className="icon-border"
          d="M15.0004823 28.9205441c-7.68931474 0-13.92199103-6.2326763-13.92199103-13.9210264 0-7.68835014 6.23267629-13.92102643 13.92199103-13.92102643 7.6883501 0 13.9210264 6.23267629 13.9210264 13.92102643 0 7.6883501-6.2326763 13.9210264-13.9210264 13.9210264m0-28.9205441C6.71597157 0 0 6.71500691 0 14.9995177 0 23.2840284 6.71597157 30 15.0004823 30 23.2840284 30 30 23.2840284 30 14.9995177 30 6.71500691 23.2840284 0 15.0004823 0"
        />
        <path d="M13.9858836 18.7978448c-2.6515086 0-4.80258619-2.1501077-4.80258619-4.8006465 0-2.6524785 2.15107759-4.8016164 4.80258619-4.8016164 2.6505388 0 4.8006466 2.1491379 4.8006466 4.8016164 0 2.6505388-2.1501078 4.8006465-4.8006466 4.8006465m5.8858836-4.8006465c0-3.2518319-2.6359913-5.88685347-5.8858836-5.88685347C10.7340517 8.11034483 8.1 10.7453664 8.1 13.9971983c0 3.2498922 2.6340517 5.8858836 5.8858836 5.8858836 1.1628233 0 2.2761854-.3384698 3.2266164-.9630388l3.6484914 3.6494612.7671336-.7671336-3.5631466-3.5631466c1.1443966-1.0997844 1.8067888-2.6175646 1.8067888-4.2420258" />
      </g>
    </svg>
  )
}

export default searchIcon
