import SvgIcon from "../shared/components/SvgIcon"

const TrashIcon = () => {
  return (
    <SvgIcon fillRule="evenodd" svgClass="TrashIcon">
      <path
        className="icon-border"
        d="M15 28.9205441c-7.68859734 0-13.92147405-6.2326763-13.92147405-13.9210264C1.07852595 7.31116756 7.31140266 1.07849127 15 1.07849127c7.689562 0 13.9224387 6.23267629 13.9224387 13.92102643 0 7.6883501-6.2328767 13.9210264-13.9224387 13.9210264M15 0C6.71522284 0 0 6.71500691 0 14.9995177 0 23.2840284 6.71522284 30 15 30c8.2847772 0 15-6.7159716 15-15.0004823C30 6.71500691 23.2847772 0 15 0"
      />
      <path d="M12.2586207 21.3905172h1.0852371v-8.9767241h-1.0852371zM16.6758621 21.3905172h1.0842672v-8.9767241h-1.0842672z" />
      <path d="M9.86799569 23.8842672H20.1326509V9.55118534H9.86799569V23.8842672zM12.4913793 7.134375l.0009698-.03006466.0019397-.02909482c.028125-.28318966.2705819-.50721983.5644396-.50721983h3.9132544l.0310344.00290948c.2841595.02618535.5072199.26961207.5072199.56346983v1.27823276h-5.0188578V7.134375zm6.1040948 0l-.0019396-.04461207c-.0242457-.89515086-.7554957-1.60700431-1.6496767-1.60700431H13.0141164c-.8941811.02618535-1.6070043.75743535-1.6070043 1.65161638v1.27823276l-4.82780176.00096983v1.08426724h2.20441811V24.9695043H21.2169181V9.49784483h2.2073276V8.41357759l-4.8287716-.00096983V7.134375z" />
    </SvgIcon>
  )
}

export default TrashIcon
