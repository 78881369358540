import { useWorkbenchStore } from "../../store/store"
import { arcs, energies, internal_flags } from "./FilterTransforms"

const FilterItems = ({ items, refine }) => {
  const removeFromArcs = useWorkbenchStore((state) => state.removeFromArcs)
  const removeFromEnergies = useWorkbenchStore((state) => state.removeFromEnergies)
  const removeFromGenresOn = useWorkbenchStore((state) => state.removeFromGenresOn)
  const removeFromGenresOff = useWorkbenchStore((state) => state.removeFromGenresOff)
  const removeFromInstrumentsOn = useWorkbenchStore((state) => state.removeFromInstrumentsOn)
  const removeFromInstrumentsOff = useWorkbenchStore((state) => state.removeFromInstrumentsOff)
  const removeFromMoodsOn = useWorkbenchStore((state) => state.removeFromMoodsOn)
  const removeFromMoodsOff = useWorkbenchStore((state) => state.removeFromMoodsOff)
  const removeFromFlagsOn = useWorkbenchStore((state) => state.removeFromFlagsOn)
  const resetArtistCollab = useWorkbenchStore((state) => state.resetArtistCollab)
  const resetCustomizable = useWorkbenchStore((state) => state.resetCustomizable)
  const resetForMarmoset = useWorkbenchStore((state) => state.resetForMarmoset)
  const resetNotForTV = useWorkbenchStore((state) => state.resetNotForTV)
  const resetPreRelease = useWorkbenchStore((state) => state.resetPreRelease)
  const resetFervor = useWorkbenchStore((state) => state.resetFervor)
  const resetLengthMin = useWorkbenchStore((state) => state.resetLengthMin)
  const resetLengthMax = useWorkbenchStore((state) => state.resetLengthMax)
  const resetIsInTrackClub = useWorkbenchStore((state) => state.resetIsInTrackClub)
  const resetHasStems = useWorkbenchStore((state) => state.resetHasStems)
  const toggleInstrumental = useWorkbenchStore((state) => state.toggleInstrumental)
  const toggleVocals = useWorkbenchStore((state) => state.toggleVocals)
  const toggleVocalsFemale = useWorkbenchStore((state) => state.toggleVocalsFemale)
  const toggleVocalsMale = useWorkbenchStore((state) => state.toggleVocalsMale)

  const handleRefinementClick = (refinement) => {
    let clearNegativeListItem = false
    switch (refinement.attribute) {
      case "arc":
        removeFromArcs(refinement.value)
        break
      case "internal_flags":
        if (refinement.value.substr(0, 2) !== "\\-") {
          removeFromFlagsOn(refinement.value)
        }
        break
      case "energy":
        removeFromEnergies(refinement.value)
        break
      case "mood_list":
        if (refinement.value.substr(0, 2) !== "\\-") {
          removeFromMoodsOn(refinement.value)
        } else {
          clearNegativeListItem = true
          removeFromMoodsOff(refinement.value.substr(2))
        }
        break
      case "is_customizable":
      case "is_not_customizable":
        resetCustomizable()
        break
      case "duration":
        if (refinement.operator === ">=") {
          resetLengthMin()
        } else {
          resetLengthMax()
        }
        break
      case "instrument_list":
        // Negative refinements in Algolia have values that are prefixed with "\\-"
        // That prefix needs to be stripped to remove it from the "Off" list in state
        // and just "\\" needs to be stripped (leading '-' preserved) to remove it from Algolia
        if (refinement.value.substr(0, 2) !== "\\-") {
          removeFromInstrumentsOn(refinement.value)
        } else {
          clearNegativeListItem = true
          removeFromInstrumentsOff(refinement.value.substr(2))
        }
        break
      case "genre_list":
        if (refinement.value.substr(0, 2) !== "\\-") {
          removeFromGenresOn(refinement.value)
        } else {
          clearNegativeListItem = true
          removeFromGenresOff(refinement.value.substr(2))
        }
        break
      case "is_instrumental":
        toggleInstrumental()
        break
      case "is_vocals":
        toggleVocals()
        break
      case "is_female_vocals":
        toggleVocalsFemale()
        break
      case "is_male_vocals":
        toggleVocalsMale()
        break
      case "is_for_marmoset":
      case "is_not_for_marmoset":
        resetForMarmoset()
        break
      case "is_artist_collab":
      case "is_not_artist_collab":
        resetArtistCollab()
        break
      case "is_pre_release":
      case "is_not_pre_release":
        resetPreRelease()
        break
      case "is_fervor":
      case "is_not_fervor":
        resetFervor()
        break
      case "is_for_tv_film":
      case "is_not_for_tv_film":
        resetNotForTV()
        break
      case "is_in_track_club":
      case "is_not_in_track_club":
        resetIsInTrackClub()
        break
      case "is_has_stems":
      case "is_not_has_stems":
        resetHasStems()
        break
    }
    clearNegativeListItem
      ? refine({ ...refinement, value: refinement.value.substr(1) })
      : refine(refinement)
  }

  if (!items.length) return null

  return items.map((attribute) =>
    attribute.refinements.map((refinement, index) => (
      <li key={index}>
        {refinement.label}
        <span data-label={refinement.label} onClick={(e) => handleRefinementClick(refinement)}>
          x
        </span>
      </li>
    ))
  )
}

const ClearAllRefinements = ({ refineAll }) => {
  const resetAllFilters = useWorkbenchStore((state) => state.resetAllFilters)

  const handleClearAllClick = () => {
    resetAllFilters()
    refineAll()
  }

  return (
    <li
      className="clear-all"
      data-clear="filters"
      data-cy="clearAllFilters"
      onClick={handleClearAllClick}
    >
      Clear All
    </li>
  )
}

const FilteredBy = ({ items, refine, refineAll }) => {
  return (
    <div className="filtered-by">
      <div className="label">Filtered By:</div>
      <ul>
        <FilterItems items={items} refine={refine} />
        <ClearAllRefinements refineAll={refineAll} />
      </ul>
    </div>
  )
}

export default FilteredBy
