import { useContext } from "react"
import { PrismicPageContext } from "../../utils/prismicPageParser"

const CSSSandbox = ({ slice }) => {
  const { items } = slice

  return (
    <style>
      {items.map((item, index) => {
        return item.css_code
      })}
    </style>
  )
}

export default CSSSandbox
