import { useEffect, useRef, useCallback } from "react"

const LazyLoad = (props) => {
  const adjustSrcs = (el) => {
    const newImgSrc = el.dataset.src
    const newImgSrcSet = el.dataset.srcset
    const newImgStyle = el.dataset.style
    // only swap out the image source if the new url exists
    if (!newImgSrc && !newImgSrcSet && !newImgStyle) {
      console.error("Image source is invalid")
    } else {
      if (newImgSrc) {
        el.src = newImgSrc
      }
      if (newImgSrcSet) {
        el.srcset = newImgSrcSet
      }
      if (newImgStyle) {
        el.style = newImgStyle
      }
      el.className = el.className.replace(/\blazy\b/g, " ").trim()
    }
    // adjust the wrapper if necessary
    const wrapper = el.closest(".lazyWrapper")
    if (wrapper) {
      wrapper.className = wrapper.className.replace(/\blazyWrapper\b/g, " ").trim()
    }
  }

  // the imageObserver method for newer browsers
  const currentEl = useRef()

  const imgObserver = useCallback((node) => {
    const intObs = new IntersectionObserver(
      (entries) => {
        entries.forEach((en) => {
          if (en.intersectionRatio > 0) {
            const currentImg = en.target
            adjustSrcs(currentImg)
            intObs.unobserve(node) // detach the observer when done
          }
        })
      },
      {
        root: null,
        threshold: [0.0, 0.05, 0.25],
      }
    )
    intObs.observe(node)
  }, [])

  useEffect(() => {
    if (currentEl.current) {
      var lazyEl = currentEl.current.querySelector(".lazy")
      if ("IntersectionObserver" in window) {
        imgObserver(lazyEl)
      } else {
        // we don't lazyload for older browsers
        adjustSrcs(lazyEl)
      }
    }
  }, [imgObserver, currentEl])

  return (
    <div className="lazyload" ref={currentEl}>
      {props.children}
    </div>
  )
}

export default LazyLoad
