import { useState, useContext, useEffect, useLayoutEffect } from "react"
import cx from "classnames"
import Modal from "../../components/Modal"
import VideoPlayer from "./VideoPlayer"
import htmlSerializer from "../../utils/prismicHTMLSerializer"
import PlayIcon from "../../../icons/PlayIcon"
import styles from "../../styles/AccordionItem.module.scss"
import BasicImage from "./BasicImage"
import BasicRichText from "./BasicRichText"
import { PrismicPageContext } from "../../utils/prismicPageParser"
import { escapeToClose } from "../../utils/helpers"

const AccordionItem = ({ item }) => {
  const colorTheme = useContext(PrismicPageContext)
  const [showThumb, setShowThumb] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const { content_type, header, image, rich_text, video } = item

  // these functions control the popup functionality
  useEffect(() => {
    return escapeToClose(setOpenModal)
  })

  const handleMouseEnter = () => {
    if (!openModal) setShowThumb(true)
  }

  const handleMouseLeave = () => {
    if (!openModal) setShowThumb(false)
  }

  const toggleModal = (e) => {
    e.stopPropagation()
    setOpenModal(false)
  }

  // render different components for different types of content
  const VideoItem = ({ video }) => {
    const { title, thumbnail_url, video_id } = video
    return (
      <div className={cx(styles.videoContainer, "videoContainer")}>
        <div className={styles.videoWrapper}>
          <img src={thumbnail_url} className={cx(styles.thumb)} />
        </div>
        {openModal && (
          <Modal>
            <VideoPlayer videoId={video_id} toggleModal={toggleModal} />
          </Modal>
        )}
      </div>
    )
  }

  // figure out which component to render
  const getContent = (item) => {
    const { content_type, image, rich_text, video } = item
    switch (content_type) {
      case "Video Popup":
        return <VideoItem className={styles.videoWrapper} video={video} />
      case "Image":
        return <BasicImage className={styles.imgWrapper} image={image} />
      case "Rich Text":
        return (
          <BasicRichText
            className={styles.richText}
            rich_text={rich_text}
            htmlSerializer={htmlSerializer}
          />
        )
      default:
        return null
    }
  }

  // use the content type to determine what is visible
  const getClass = (item) => {
    const { content_type } = item
    const thisClass = content_type.replace(/\s/g, "")
    return thisClass
  }

  return (
    <div
      className={cx("accordionItem", { [styles.showThumb]: showThumb }, getClass(item))}
      aria-label="Accordion Item"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={() => setOpenModal(true)}
    >
      <div className={styles.popupWrapper}>
        <h2 className="hTwo" style={{ borderBottomColor: `var(--${colorTheme})` }}>
          <span className={styles.headingText}>{header[0].text}</span>
        </h2>
        <PlayIcon />
      </div>
      <div className={cx("contentWrapper", getClass(item))}>{getContent(item)}</div>
    </div>
  )
}

export default AccordionItem
