import { useContext, useEffect } from "react"
import { GridPlaylistContext } from "./VideoPlaylist"
import styles from "../../../shared/styles/VideoSubNav.module.scss"
import cx from "classnames"

const VideoGridNav = () => {
  const {
    navTagOptions,
    navServiceOptions,
    currentTag,
    setCurrentTag,
    currentService,
    setCurrentService,
  } = useContext(GridPlaylistContext)

  return (
    <>
      <nav className={styles.videoSubNav}>
        {navTagOptions.length > 1 &&
          navTagOptions.map((option) => {
            return (
              <option
                className={cx(styles.navItem, option === currentTag && styles.active)}
                key={option}
                onClick={() => setCurrentTag(option)}
              >
                {option}
              </option>
            )
          })}
      </nav>
      <nav className={styles.videoSubNav}>
        {navServiceOptions.length > 1 &&
          navServiceOptions.map((option) => {
            return (
              <option
                className={cx(styles.navItem, option === currentService && styles.active)}
                key={option}
                onClick={() => setCurrentService(option)}
              >
                {option}
              </option>
            )
          })}
      </nav>
    </>
  )
}

export default VideoGridNav
