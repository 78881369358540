export default function Buttons({ wrapperClass, buttons }) {
  return (
    <div className={wrapperClass}>
      {buttons.map((button, index) => {
        return (
          <div key={index} className={`button ${button}`}>
            {button}
          </div>
        )
      })}
    </div>
  )
}
