import { useContext } from "react"
import { PrismicRichText } from "@prismicio/react"
import cx from "classnames"
import htmlSerializer from "../../utils/prismicHTMLSerializer"
import HorizontalRule from "./HorizontalRule"
import styles from "../../styles/Masthead.module.scss"
import { PrismicPageContext } from "../../utils/prismicPageParser"

const Masthead = ({ slice, page }) => {
  const colorTheme = useContext(PrismicPageContext)
  const { primary } = slice

  const Kicker = (props) => {
    switch (props.page) {
      case "Home":
      case "Studio":
        return (
          <div style={{ color: `var(--${colorTheme})` }} className={styles.kicker}>
            {primary.kicker}
          </div>
        )
      default:
        return (
          <h1 style={{ color: `var(--${colorTheme})` }} className={styles.kicker}>
            {primary.kicker}
          </h1>
        )
    }
  }

  return (
    <article className={cx("prismicMasthead", styles.masthead)} aria-label="Subheader">
      <div className={styles.contain}>
        <Kicker page={page} />
        <PrismicRichText field={primary.heading} components={htmlSerializer} />
        <HorizontalRule />
      </div>
    </article>
  )
}

export default Masthead
