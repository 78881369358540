import { useState } from "react"
import { gql, useMutation } from "@apollo/client"
import cx from "classnames"
import LazyLoad from "./LazyLoad"
import MoreIcon from "../../icons/MoreIcon"
import PlayIcon from "../../icons/PlayIcon"
import styles from "../styles/Mixtape.module.scss"
import spinnerStyles from "../styles/spinners.module.scss"

const PLAY_MIXTAPE = gql`
  mutation PlayMixtape($id: ID!) {
    playMixtape(id: $id) {
      songVersions {
        id
        customizable
        displayName
        exclusive
        hasClickLicenses
        length
        lyrics
        playerArt
        songVersionFile
        artist {
          id
          name
        }
      }
    }
  }
`

const Mixtape = ({ id, name, slug, imageUrl, featuredAlbum }) => {
  const [show, setShow] = useState(false)
  const [playMixtape, { loading }] = useMutation(PLAY_MIXTAPE)

  const handlePlayMixtape = (e) => {
    e.preventDefault()
    e.stopPropagation()
    // If mobile audio element is already playing, pause it
    var audioElement = $('audio[src^="/song_versions/play/"]')
    if (audioElement.length > 0) {
      if (!audioElement[0].paused) {
        audioElement[0].pause()
        return
      }
    }
    const variables = { id }
    playMixtape({ variables })
      .then(({ data: { playMixtape } }) => {
        mm.playerProxy.trigger("play", {
          songVersions: playMixtape.songVersions,
          type: "mixtape",
        })
      })
      .catch((err) => console.log(err))
  }

  const handleViewMixtape = (e) => {
    e.preventDefault()
    window.mm.router.navigate(`/mixtapes/${slug}`, { trigger: true })
  }

  return (
    <LazyLoad>
      <div
        className={cx("lazy", styles.mixtape)}
        onMouseEnter={() => setShow(true)}
        onMouseLeave={() => setShow(false)}
        data-style={`background: url(${imageUrl}) center center / cover no-repeat;`}
      >
        <div className={cx(styles.overlay, { [styles.show]: show })}>
          <div className={styles.nameWrapper}>
            {featuredAlbum ? (
              <span className={"smallFont"}>Album</span>
            ) : (
              <span className={"smallFont"}>Mixtape</span>
            )}
            <h5 className={"hFive"}>{name}</h5>
          </div>
        </div>
        <div className={styles.actions}>
          {loading ? (
            <div className={spinnerStyles.mediumSpinner} />
          ) : (
            <a onClick={handlePlayMixtape}>
              <PlayIcon />
            </a>
          )}
          <a onClick={handleViewMixtape} className={styles.more}>
            <MoreIcon />
          </a>
        </div>
      </div>
    </LazyLoad>
  )
}

export default Mixtape
