import { useMutation } from "@apollo/client"
import cx from "classnames"
import { TOGGLE_HIGH_RES_DOWNLOAD } from "../../../graphql/mutations/searchRounds"

const ToggleHighResDownload = ({ id, showHighResDownload }) => {
  const [toggleHighResDownload, { loading: mutationLoading }] =
    useMutation(TOGGLE_HIGH_RES_DOWNLOAD)

  const handleToggleDownload = () => {
    const variables = {
      id,
      show: !showHighResDownload,
    }

    toggleHighResDownload({ variables }).catch((error) => Sentry.captureException(error))
  }

  return (
    <button
      className={cx("action", "toggle-btn", {
        enabled: showHighResDownload,
        disabled: mutationLoading,
      })}
      onClick={handleToggleDownload}
    >
      {`${showHighResDownload ? "Disable" : "Enable"} High-Res Download`}
    </button>
  )
}

export default ToggleHighResDownload
