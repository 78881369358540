import { useState, useEffect, useRef } from "react"
import styles from "../../styles/VideoPlayer.module.scss"
import modalStyles from "../../styles/modal.module.scss"
import spinnerStyles from "../../styles/spinners.module.scss"

const VideoPlayer = ({ videoId, toggleModal }) => {
  const [loading, setLoading] = useState(true)
  const iframeRef = useRef()

  useEffect(() => {
    iframeRef.current.onload = () => setLoading(false)
  }, [iframeRef])

  return (
    <div className={modalStyles.container} onClick={toggleModal}>
      <div className={styles.playerWrapper}>
        {loading && (
          <div className={styles.loading}>
            <div className={spinnerStyles.largeSpinner} />
          </div>
        )}
        <div className={styles.iframeWrapper}>
          <iframe
            src={`https://player.vimeo.com/video/${videoId}?app_id=122963&playsinline=0`}
            className={styles.iframe}
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen
            ref={iframeRef}
          />
          <button className={styles.closeModal} onClick={toggleModal}>
            CLOSE
          </button>
        </div>
      </div>
    </div>
  )
}

export default VideoPlayer
