import { useWorkbenchStore } from "../../store/store"

const SearchedBy = () => {
  const resetSearchQuery = useWorkbenchStore((state) => state.resetSearchQuery)
  const searchQuery = useWorkbenchStore((state) => state.searchQuery)

  const handleClearClick = () => {
    resetSearchQuery()
  }

  return (
    <div className="searched-by">
      <div className="label">Searched By:</div>
      <ul>
        <li data-filter="search">
          {searchQuery}
          <span onClick={handleClearClick}>x</span>
        </li>
        <li
          className="clear-all"
          data-clear="search"
          data-cy="clearSearch"
          onClick={handleClearClick}
        >
          Clear
        </li>
      </ul>
    </div>
  )
}

export default SearchedBy
