import { useEffect, useState } from "react"
import { gql, useMutation } from "@apollo/client"
import { Formik, Form } from "formik"
import cx from "classnames"
import styles from "../styles/ClientNoteModal.module.scss"
import buttonStyles from "../../../shared/styles/buttons.module.scss"
import formStyles from "../../../shared/styles/form.module.scss"
import modalStyles from "../../../shared/styles/modal.module.scss"

const ADD_CLIENT_NOTE = gql`
  mutation addClientNote($id: ID!, $clientNotes: String!) {
    addClientNote(id: $id, clientNotes: $clientNotes) {
      lineItem {
        id
        clientNotes
      }
    }
  }
`
const ClientNoteModal = ({
  lineItemId,
  openModalButton,
  clientNotes,
  setOpenModal,
  noteInput,
  handleSetError,
  handleCloseModal,
}) => {
  const [addClientNote] = useMutation(ADD_CLIENT_NOTE)
  const [noteText, setNoteText] = useState(clientNotes != "None" ? clientNotes : "")

  useEffect(() => {
    return () => {
      if (openModalButton.current) openModalButton.current.focus()
    }
  }, [])

  const handleSubmit = () => {
    const variables = {
      id: lineItemId,
      clientNotes: noteText,
    }

    addClientNote({ variables })
      .then(() => setOpenModal(false))
      .catch((err) => handleSetError(err.graphQLErrors))
  }

  const handleChange = (e) => {
    setNoteText(e.target.value)
  }

  return (
    <div className={modalStyles.container}>
      <div className={styles.addNoteWrapper}>
        <Formik initialValues={{ noteText: "" }} onSubmit={handleSubmit}>
          {({ isSubmitting }) => (
            <Form id="addClientNote" className={styles.addNoteForm}>
              <div className={styles.addNoteFormContainer}>
                {!isSubmitting ? (
                  <div className={styles.inputWrapper}>
                    <label className={styles.label}>Start/Air Date + Project Title/Notes</label>
                    <input
                      ref={noteInput}
                      className={styles.input}
                      value={noteText}
                      onChange={(e) => handleChange(e)}
                    />
                    <label className={styles.required}>Start/Air Date is required</label>
                  </div>
                ) : (
                  <p className={styles.updatingMessage}>
                    Your note is being {clientNotes === "None" ? "added" : "updated"}...
                  </p>
                )}
                <button
                  type="submit"
                  aria-label="add note"
                  disabled={noteText === ""}
                  className={
                    isSubmitting ? styles.submitting : cx(buttonStyles.black, formStyles.submitBtn)
                  }
                >
                  {clientNotes === "None" ? "Create Note" : "Edit Note"}
                </button>
              </div>
            </Form>
          )}
        </Formik>

        <div className={styles.buttonWrapper}>
          <button className={styles.cancelButton} aria-label="cancel" onClick={handleCloseModal}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  )
}

export default ClientNoteModal
