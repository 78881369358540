import { useState, useEffect, useRef } from "react"
import cx from "classnames"
import Modal from "../../../shared/components/Modal"
import SongSearchModal from "./SongSearchModal"
import ClientNoteModal from "./ClientNoteModal"
import { escapeToClose } from "../../../shared/utils/helpers"
import DownloadIcon from "../../../icons/DownloadIcon"
import styles from "../styles/LineItem.module.scss"
import { useDidMount } from "../../../shared/utils/useDidMount"

const LineItem = ({ item, allowClientToSelectTbd }) => {
  const {
    id,
    songVersionTitle,
    songVersionId,
    showDownloadLink,
    showDownloadStemsLink,
    stemsAvailableUponRequest,
    artistName,
    clientNotes,
    license,
    price,
  } = item
  const [currentModal, setCurrentModal] = useState("")
  const [openModal, setOpenModal] = useState(false)
  const [error, setError] = useState(null)
  const [tbdUpdated, setTbdUpdated] = useState(false)
  const openModalButton = useRef()
  const songInput = useRef()
  const noteInput = useRef()
  const didMount = useDidMount()

  useEffect(() => {
    return escapeToClose(setOpenModal)
  })

  useEffect(() => {
    if (openModal && currentModal === "song") {
      songInput.current.focus()
    } else if (openModal && currentModal === "note") {
      noteInput.current.focus()
    }
  })

  useEffect(() => {
    if (didMount) setTbdUpdated(true)
  }, [songVersionTitle, clientNotes])

  const handleOpenModal = (e) => {
    setCurrentModal(e.target.name)
    setOpenModal(true)
  }
  const handleCloseModal = (e) => {
    e.stopPropagation()
    setOpenModal(false)
  }
  const handleCloseError = () => setError([])
  const handleSetError = (error) => setError(error)

  const NotesSection = ({ clientNotes, allowClientToSelectTbd }) => {
    if (clientNotes === "None" && allowClientToSelectTbd) {
      return (
        <li className={styles.noteButtonWrapper}>
          <button
            className={styles.songOrNoteModalButton}
            ref={openModalButton}
            name="note"
            onClick={handleOpenModal}
          >
            Add Project Note
          </button>
        </li>
      )
    } else if (allowClientToSelectTbd) {
      return (
        <li className={styles.noteContent}>
          <img
            src="/images/icons/pencil.svg"
            alt="edit note"
            ref={openModalButton}
            className={cx(styles.editIcon)}
            name="note"
            onClick={handleOpenModal}
          />
          {clientNotes}
        </li>
      )
    }
    return null
  }

  const StemsSection = () => {
    if (!songVersionId) {
      return <span className={styles.notApplicable}>n/a</span>
    } else {
      if (showDownloadStemsLink) {
        return (
          <a href={`/song_versions/${songVersionId}/purchase_stems_download`} target="_blank">
            <DownloadIcon />
          </a>
        )
      } else {
        // TODO: Link to new form (TBD) here:
        // return stemsAvailableUponRequest ? (
        //   <a className={styles.stemsRequest} href="/licensing/custom-request">
        //     Request
        //   </a>
        // ) : (
        //   <span className={styles.notAvailable}>Not Available</span>
        // )
        return <span className={styles.notAvailable}>Not Available</span>
      }
    }
  }

  return (
    <ol
      className={cx(styles.lineItemInfo, { [styles.tbdUpdated]: tbdUpdated })}
      data-line-item-id={id}
    >
      {error && error.length > 0 && (
        <div className={styles.errorMessageWrapper}>
          <div className={styles.errorMessage}>
            {error.map(({ message }, ind) => (
              <span key={ind}>{message}</span>
            ))}
          </div>
          <div className={styles.closeMessage}>
            <img
              src="/images/icons/cancel.svg"
              className={styles.closeIcon}
              alt="close error message"
              onClick={handleCloseError}
            />
          </div>
        </div>
      )}
      <li className={styles.song}>
        {songVersionTitle === "TBD" && allowClientToSelectTbd ? (
          <button
            className={styles.songOrNoteModalButton}
            ref={openModalButton}
            name="song"
            onClick={handleOpenModal}
          >
            Select Song
          </button>
        ) : (
          songVersionTitle
        )}
      </li>
      <li className={styles.artist}>{artistName}</li>
      <NotesSection clientNotes={clientNotes} allowClientToSelectTbd={allowClientToSelectTbd} />
      <li className={styles.item}>{license}</li>
      <li className={styles.cost}>{price}</li>
      <li className={styles.downloadSong}>
        {showDownloadLink ? (
          <a href={`/song_versions/${songVersionId}/purchase_download`} target="_blank">
            <DownloadIcon />
          </a>
        ) : (
          <span className={styles.notApplicable}>n/a</span>
        )}
      </li>
      <li className={styles.downloadStems}>
        <StemsSection />
      </li>
      {openModal && (
        <Modal>
          {currentModal === "song" ? (
            <SongSearchModal
              openModalButton={openModalButton}
              songInput={songInput}
              setOpenModal={setOpenModal}
              lineItemId={id}
              handleCloseModal={handleCloseModal}
              handleSetError={handleSetError}
            />
          ) : (
            <ClientNoteModal
              openModalButton={openModalButton}
              noteInput={noteInput}
              setOpenModal={setOpenModal}
              lineItemId={id}
              clientNotes={clientNotes}
              handleSetError={handleSetError}
              handleCloseModal={handleCloseModal}
            />
          )}
        </Modal>
      )}
    </ol>
  )
}

export default LineItem
