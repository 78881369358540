import React from "react"

const Ellipsis = ({ height, width }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 490 490"
      height={height}
      width={width}
      className="ellipsis-icon"
    >
      <path d="M304.5 245c0-32.8-26.7-59.5-59.5-59.5s-59.5 26.7-59.5 59.5 26.7 59.5 59.5 59.5 59.5-26.7 59.5-59.5zM245 280c-19.3 0-35-15.7-35-35s15.7-35 35-35 35 15.7 35 35-15.7 35-35 35zM59.5 185.5C26.7 185.5 0 212.2 0 245s26.7 59.5 59.5 59.5S119 277.8 119 245s-26.7-59.5-59.5-59.5zm0 94.5c-19.3 0-35-15.7-35-35s15.7-35 35-35 35 15.7 35 35-15.7 35-35 35zM430.5 185.5c-32.8 0-59.5 26.7-59.5 59.5s26.7 59.5 59.5 59.5S490 277.8 490 245s-26.7-59.5-59.5-59.5zm0 94.5c-19.3 0-35-15.7-35-35s15.7-35 35-35 35 15.7 35 35-15.7 35-35 35z" />
    </svg>
  )
}

Ellipsis.defaultProps = {
  height: 25,
  width: 25,
}

export default Ellipsis
