import { useEffect, useState, useRef } from "react"
import cx from "classnames"
import groupBySong from "../../shared/utils/songNormalizer"
import SongItemBeta from "../../shared/components/SongItemBeta"

const SongVersionsContainer = ({ container = "mm-song-versions-container" }) => {
  const el = document.getElementById(container)
  const songVersionsData = el && el.getAttribute("data-song-versions")
  const user_role = el && el.getAttribute("data-user-role")
  //const svCyaniteId = el && el.getAttribute("data-sv-cyanite-id")
  const [songVersions, setSongVersions] = useState([])
  const [expandedSongVersionId, setExpandedSongVersionId] = useState(false)
  const currentBlanketDealModalRef = useRef(null)
  const thisContainerRef = useRef(null)
  const handleSetExpandedSongVersionId = (svid) => {
    setExpandedSongVersionId(svid)
  }

  const handleOnClick = (e) => {
    if (
      // Not clicked in actions menu OR NOT clickd within this song version container
      (!e.target.closest([".actions-menu", ".expand-actions-menu"]) ||
        !thisContainerRef.current.contains(e.target)) &&
      // AND Not clicked within the blanket deal modal currently open
      !(currentBlanketDealModalRef.current && currentBlanketDealModalRef.current.contains(e.target))
    ) {
      setExpandedSongVersionId(false)
    }
  }

  useEffect(() => {
    var parsedSongVersionData = JSON.parse(songVersionsData)
    var normalizedSongs = groupBySong(parsedSongVersionData)
    setSongVersions(normalizedSongs)

    document.body.addEventListener("click", handleOnClick)

    return () => {
      document.body.removeEventListener("click", handleOnClick)
    }
  }, [])

  const songVersionProps = {
    song_versions_count: 1,
    user_role,
    handleSetExpandedSongVersionId,
    expandedSongVersionId,
    currentBlanketDealModalRef,
  }

  return (
    //Done loading, also there are songs
    <div id="mm-song-versions" ref={thisContainerRef}>
      <article id="mm-song-versions-listing" aria-label="Songs listing">
        <div className="mm-song-versions-table" data-title="Search results">
          <ol className={cx("mm-song-versions-table-list")}>
            <li className="mm-song-versions-table-head">
              <ol>
                <li className="song-version">Song / Artist</li>
                <li className="genres desktop">Genres</li>
                <li className="arc desktop">Arc&trade;</li>
                <li className="energy desktop" data-touch>
                  Energy
                </li>
                <li className="length">Length</li>
              </ol>
            </li>
            {songVersions.map((song) => (
              <SongItemBeta song={song} {...songVersionProps} key={song.songVersions[0].id} />
            ))}
          </ol>
        </div>
      </article>
    </div>
  )
}

export default SongVersionsContainer
