import React from "react"
import axios from "axios"
import cx from "classnames"

const DownloadRound = ({ disableDownload, selectedRound, dispatch }) => {
  let loop
  let timeoutId

  const checkDownloadResponse = ({ success, url }) => {
    if (success) {
      clearInterval(loop)
      clearTimeout(timeoutId)
      dispatch({ type: "ZIP_COMPLETE", url })
    } else {
      dispatch({ type: "ZIP_PROCESSING" })
    }
  }

  const beginDownload = () => {
    dispatch({ type: "BEGIN_ZIP" })
    timeoutId = setTimeout(() => {
      clearInterval(loop)
      dispatch({ type: "ZIP_FAILED" })
    }, 300000) // kill the poll after 5 mins
    loop = setInterval(poll, 2000)
  }

  const poll = () => {
    axios
      .get(`/api/v1/search_rounds/${selectedRound[0].id}/download`)
      .then((response) => {
        dispatch({ type: "SHOW_NOTICE" })
        checkDownloadResponse(response.data)
      })
      .catch((error) => Sentry.captureException(error))
  }

  return (
    <button
      className={cx("action", "download", { disabled: disableDownload })}
      onClick={beginDownload}
      data-cy="downloadSearchRound"
    >
      {`Download ${selectedRound[0].name}`}
    </button>
  )
}

export default DownloadRound
