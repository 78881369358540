import SvgIcon from "../shared/components/SvgIcon"

const SimilarIcon = () => {
  return (
    <SvgIcon svgClass="SimilarIcon">
      <path
        className="icon-border"
        d="M15 28.9205441c-7.68906752 0-13.92154341-6.2326763-13.92154341-13.9210264C1.07845659 7.31116756 7.31093248 1.07849127 15 1.07849127c7.6890675 0 13.9215434 6.23267629 13.9215434 13.92102643 0 7.6883501-6.2324759 13.9210264-13.9215434 13.9210264M15 0C6.71575563 0 0 6.71500691 0 14.9995177 0 23.2840284 6.71575563 30 15 30c8.2842444 0 15-6.7159716 15-15.0004823C30 6.71500691 23.2842444 0 15 0"
      />
      <path
        d="M11.8,8.1c0.1,0.1,0.2,0.2,0.4,0.2c0,0,0.1,0,0.1,0c0.2-0.1,0.3-0.2,0.4-0.4c0-0.2,0-0.4-0.1-0.5l-1.2-1.2
        c-0.1-0.1-0.3-0.2-0.5-0.1c-0.2,0.1-0.3,0.2-0.4,0.4c0,0.2,0,0.4,0.1,0.5L11.8,8.1z"
      />
      <path d="M11.8,12.8c0.3,0,0.5-0.2,0.5-0.5s-0.2-0.5-0.5-0.5s-0.5,0.2-0.5,0.5S11.5,12.8,11.8,12.8z" />
      <path
        d="M16.7,6.3c0.3,0,0.5-0.2,0.5-0.5V4.1c0-0.3-0.2-0.5-0.5-0.5s-0.5,0.2-0.5,0.5v1.8C16.2,6.1,16.4,6.3,16.7,6.3z
        "
      />
      <path
        d="M21.1,8.2c0,0,0.1,0,0.1,0c0.1,0,0.3-0.1,0.4-0.2l1.2-1.2C23,6.7,23.1,6.5,23,6.3C23,6.1,22.8,6,22.6,5.9
        c-0.2,0-0.4,0-0.5,0.1l-1.2,1.2c-0.1,0.1-0.2,0.3-0.1,0.5C20.8,8,21,8.2,21.1,8.2z M21.5,7.9L21.5,7.9L21.5,7.9L21.5,7.9z"
      />
      <path
        d="M24.9,11.7h-1.8c-0.3,0-0.5,0.2-0.5,0.5c0,0.3,0.2,0.5,0.5,0.5h1.8c0.3,0,0.5-0.2,0.5-0.5
        C25.4,11.9,25.2,11.7,24.9,11.7z"
      />
      <path
        d="M24,16.7l-0.8-0.8l0.1-0.1c0.1-0.1,0.2-0.2,0.2-0.4s-0.1-0.3-0.2-0.4L22,13.7c0.3-0.9,0.3-1.8,0.1-2.7
        c-0.2-1-0.7-1.9-1.5-2.7c-1-1-2.4-1.6-3.9-1.6c-1.5,0-2.8,0.6-3.9,1.6c-0.7,0.7-1.2,1.5-1.4,2.5c0,0.1,0,0.3,0.1,0.4
        c0.1,0.1,0.2,0.2,0.3,0.2c0.3,0.1,0.6-0.1,0.7-0.4c0.2-0.8,0.6-1.4,1.1-2c0.8-0.8,1.9-1.3,3.1-1.3c1.2,0,2.3,0.5,3.1,1.3
        c0.6,0.6,1,1.3,1.2,2.1c0.2,0.8,0.1,1.7-0.1,2.5c-0.1,0.2,0,0.3,0,0.5l-2.3,2.3c-0.1-0.1-0.3-0.1-0.5,0c-0.2,0.1-0.4,0.1-0.7,0.2
        c-0.1,0-0.2,0.1-0.3,0.2v-4.4c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.3-0.1-0.5-0.1l-6.7,1.8c-0.2,0.1-0.4,0.3-0.4,0.5v5.3
        C9,19.2,8.5,19,8,19c-1.4,0-2.5,1.1-2.5,2.5S6.6,24,8,24c1.4,0,2.5-1.1,2.5-2.5V17l5.7-1.5v2.2c-0.4-0.3-0.9-0.5-1.4-0.5
        c-1.4,0-2.5,1.1-2.5,2.5s1.1,2.5,2.5,2.5s2.5-1.1,2.5-2.5v-2.3c0,0,0,0,0,0c0.1,0.1,0.3,0.2,0.5,0.2c0.2,0,0.3-0.1,0.5-0.1l1.3,1.3
        c0.1,0.1,0.2,0.2,0.4,0.2h0c0.1,0,0.3-0.1,0.4-0.2l0.1-0.1l0.8,0.8c0.1,0.1,0.2,0.2,0.4,0.2h0c0.1,0,0.3-0.1,0.4-0.2l2.1-2.1
        C24.2,17.3,24.2,16.9,24,16.7z M8,23c-0.8,0-1.4-0.6-1.4-1.4c0-0.8,0.6-1.4,1.4-1.4c0.8,0,1.4,0.6,1.4,1.4C9.4,22.3,8.8,23,8,23z
        M10.5,15.9v-1.3l5.7-1.5v1.3L10.5,15.9z M14.7,21.2c-0.8,0-1.4-0.6-1.4-1.4c0-0.8,0.6-1.4,1.4-1.4c0.8,0,1.4,0.6,1.4,1.4
        C16.2,20.5,15.5,21.2,14.7,21.2z M21.6,18.4l-0.8-0.8c-0.1-0.1-0.2-0.2-0.4-0.2c-0.1,0-0.3,0.1-0.4,0.2l-0.1,0.1l-0.5-0.5l2.2-2.2
        l0.5,0.5l-0.1,0.1c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.3,0.2,0.4l0.8,0.8L21.6,18.4z"
      />
      <path
        d="M8.6,12.8h1.8c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5H8.6c-0.3,0-0.5,0.2-0.5,0.5
        C8,12.5,8.3,12.8,8.6,12.8z"
      />
    </SvgIcon>
  )
}

export default SimilarIcon
