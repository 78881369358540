import { gql, useMutation } from "@apollo/client"
import { Formik, Form } from "formik"
import cx from "classnames"
import { validationSchema } from "../../../shared/utils/mailingListValidationSchema"
import MoreIcon from "../../../icons/MoreIcon"
import TextField from "../../../shared/components/TextField"
import buttonStyles from "../../../shared/styles/Button.module.scss"
import spinnerStyles from "../../../shared/styles/spinners.module.scss"
import styles from "../styles/EmailSignUpForm.module.scss"

const SUBSCRIBE_TO_EMAIL_LIST = gql`
  mutation subscribeToEmailList(
    $firstName: String!
    $email: String!
    $listId: String!
    $source: String!
  ) {
    subscribeToEmailList(firstName: $firstName, email: $email, listId: $listId, source: $source) {
      success
    }
  }
`

const EmailSignUpForm = ({ primary }) => {
  const [subscribeToEmailList, { loading }] = useMutation(SUBSCRIBE_TO_EMAIL_LIST)

  const handleCloseErrorMsg = (e, setStatus) => {
    e.preventDefault()
    setStatus({})
  }

  const handleSubmit = (params, formActions) => {
    const variables = {
      firstName: params.signUpFirstName,
      email: params.signUpEmail,
      listId: primary.mailchimp_audience_id,
      source: "guide",
    }

    subscribeToEmailList({ variables })
      .then(({ data: { subscribeToEmailList } }) => {
        const { success } = subscribeToEmailList
        if (success) {
          formActions.setStatus({ success: true })
        }
      })
      .catch((errors) => {
        console.log(errors.graphQLErrors[0].message)
        formActions.setStatus({
          error: true,
          msg: errors.graphQLErrors[0].message,
        })
      })
  }

  const initialValues = {
    signUpFirstName: "",
    signUpEmail: "",
  }

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {({ setStatus, status }) => (
        <div className={cx(styles.fieldset)}>
          <Form className={styles.form}>
            {status && status.msg && (
              <div className={styles.errorMsg}>
                {`${status.msg}.`}
                <button onClick={(e) => handleCloseErrorMsg(e, setStatus)}>CLOSE</button>
              </div>
            )}
            <TextField
              placeholder="First Name"
              fieldName="signUpFirstName"
              wrapperClass={styles.inputWrapper}
            />
            <TextField
              placeholder="Email Address"
              fieldName="signUpEmail"
              wrapperClass={styles.inputWrapper}
            />
            <button type="submit" className={styles.submitButton} disabled={loading}>
              <div className={buttonStyles.iconAndText}>
                {loading ? (
                  <div className={styles.spinnerWrapper}>
                    <div className={spinnerStyles.mediumSpinner} />
                  </div>
                ) : (
                  <MoreIcon />
                )}
                <div className={styles.buttonText} id={status?.success && "success"}>
                  {status && status.success ? "Success!" : primary.button_text}
                </div>
              </div>
            </button>
          </Form>
        </div>
      )}
    </Formik>
  )
}

export default EmailSignUpForm
