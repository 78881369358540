import { useEffect } from "react"
import cx from "classnames"
import { triggerNewsletterSignup } from "../../shared/utils/backbone/drawerProxyService"
import CreateAccountForm from "./CreateAccountForm"

const ReceiptNextSteps = () => {
  const container = document.getElementById("mm-next-steps-container")
  const purchaser = container && container.getAttribute("data-purchaser") === "true"
  const user_name = container && container.getAttribute("data-user-name")
  const user_role = container && container.getAttribute("data-user-role")
  const order_id = container && container.getAttribute("data-order-id")
  const receipt_url = (container && container.getAttribute("data-receipt-url")) || "#"
  const purchase_date = container && container.getAttribute("data-order-processed_at")
  const first_name = container && container.getAttribute("data-order-first-name")
  const last_name = container && container.getAttribute("data-order-last-name")
  const email = container && container.getAttribute("data-order-email")
  const order_user_id = container && container.getAttribute("data-order-user-id")

  return (
    <>
      <article className="message">
        <div className="contain">
          {purchaser ? (
            <div className={cx("thank-you", user_role === "guest" ? "" : "float")}>
              {user_role === "guest" ? (
                <h4 className="hFour">Thank you!</h4>
              ) : (
                <h4 className="hFour">Thank you {user_name}!</h4>
              )}
              <p className="purchase-date">{purchase_date}</p>
              <p>
                Your Order Number is #<strong>{order_id}</strong>
              </p>
              <p>
                <a href={receipt_url} target="_blank">
                  Download PDF Receipt
                </a>
              </p>
            </div>
          ) : (
            <>
              <h4 className="hFour">Thank you!</h4>
              <p>
                <a href="/login">Sign in</a> to download the PDF receipt.
              </p>
            </>
          )}

          <div className={cx("next-step", user_role === "guest" ? "" : "float")}>
            {user_role === "guest" ? (
              <>
                <CreateAccountForm
                  props={{
                    first_name: first_name,
                    last_name: last_name,
                    email: email,
                    // Below should address the issue for the short term, but backend solutions should be implemented.
                    id: purchaser === "true" ? order_user_id : "",
                  }}
                />
              </>
            ) : (
              <>
                <a
                  data-prevent-default
                  onClick={() => triggerNewsletterSignup()}
                  className="box-button-black newsletter"
                >
                  Add me to the Newsletter
                </a>
                <em>
                  Sign up for MarmoNews to keep up on fresh music, new artists, events and promo
                  codes.
                </em>
              </>
            )}
          </div>
        </div>
      </article>
    </>
  )
}

export default ReceiptNextSteps
