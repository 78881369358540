import React, { useState, useEffect, useCallback, useRef } from "react"
import axios from "axios"
import cx from "classnames"

function LongRunningProcess({
  url,
  body = {},
  pollInterval = 2000,
  timeout = 20000,
  callback,
  children,
}) {
  const [status, setStatus] = useState("waiting")
  const [message, setMessage] = useState("Processing...")
  const [isWaitLong, setIsWaitLong] = useState(false)
  const lastMessageRef = useRef("Processing...")
  const intervalRef = useRef(0)
  let intervalId = null
  let timeoutId = null

  // Using useCallback to ensure stable identity of the poll function across re-renders
  const poll = useCallback(() => {
    axios
      .post(url, body)
      .then((response) => {
        const { data } = response
        if (intervalId === null) return // Check if polling was cleared while request was in flight

        /* Logic for stopping polling in case of fail or success and setting messages */
        if (data.success) {
          setStatus("success")
          setMessage(data.message)
          clearInterval(intervalId)
          intervalId = null
          if (callback) callback(data)
        } else if (data.failure) {
          setStatus("error")
          setIsWaitLong(false)
          intervalRef.current = 0
          setMessage(`Error: ${data.message} Please try another track.`)
          clearInterval(intervalId)
          intervalId = null
          clearInterval(timeoutId)
          timeoutId = null
        } else {
          /** Logic for capturing number of polls for sake up updating messages */
          if (lastMessageRef.current === data.message && intervalRef.current > 10) {
            setIsWaitLong(true)
            intervalRef.current += 1
          } else if (lastMessageRef.current != data.message) {
            intervalRef.current = 0
            setIsWaitLong(false)
          }

          /** Setting message/status for display */
          setMessage(data.message)
          setStatus("processing")
        }
        intervalRef.current = intervalRef.current + 1
        lastMessageRef.current = data.message
      })
      .catch((error) => {
        console.error("Error polling:", error)
        setStatus("error")
        setMessage(error.message)
        setIsWaitLong(false)
        intervalRef.current = 0
        clearInterval(intervalId)
        intervalId = null
        clearInterval(timeoutId)
        timeoutId = null
      })
  }, [url, callback])

  useEffect(() => {
    if (!url) return undefined

    setMessage("Processing...")
    setStatus("processing")
    intervalId = setInterval(poll, pollInterval)
    timeoutId =
      timeout &&
      typeof timeout === "number" &&
      setTimeout(() => {
        clearInterval(intervalId)
        intervalId = null
        setStatus("timeout")
        setMessage("Process timeout...")
      }, timeout)

    return () => {
      if (intervalId !== null) {
        clearInterval(intervalId)
        intervalId = null
      }
      if (timeoutId) clearTimeout(timeoutId)
    }
  }, [url, pollInterval, timeout, poll]) // Dependency on poll ensures the latest function is used

  const displayText = () => {
    switch (status) {
      case "waiting":
        return "Waiting to start..."
      case "processing":
        return "Your download is being generated."
      case "success":
        return "Download ready!"
      case "timeout":
        return "Timeout reached, process stopped."
      case "error":
        return "An error occurred."
      default:
        return "Initializing..."
    }
  }

  return (
    <div className={cx("long-process-wrapper")}>
      <p className="long-process-text">{displayText()}</p>
      <div className="long-process-children">
        {status !== "timeout" && status !== "error" && children}
      </div>
      <p
        className={cx(
          "long-process-message",
          (status === "timeout" || status === "error") && "failed"
        )}
      >
        {!isWaitLong ? message : "Tiny AI Robots at work…"}
      </p>
    </div>
  )
}

export default LongRunningProcess
