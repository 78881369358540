const WorkbenchLegend = ({ user_role }) => {
  const internalFilterColors = [
    { name: "For Marmoset", hex_color: "#dac8ef" },
    { name: "Artist Collab", hex_color: "#ffd39d" },
    { name: "Pre Release", hex_color: "#7ae1dd" },
  ]
  const groupedCatalogs = [
    { name: "Fervor Vintage & Fervor Contemporary", hex_color: "#b2cee0" },
    { name: "Not for TV, Film, or Vintage Catalogs", hex_color: "#edaaa1" },
  ]

  const isInternalUser = user_role === "internal"
  const isInternalUserOrIntlPartner =
    user_role === "internal" || user_role === "international_partner"

  return (
    <>
      {isInternalUserOrIntlPartner && (
        <ul className="admin-key contain">
          {isInternalUser && (
            <>
              {internalFilterColors.map((item, index) => {
                return (
                  <li key={index} className="catalog">
                    <div
                      className="catalog-symbol"
                      style={{ backgroundColor: item.hex_color }}
                    ></div>
                    <div className="catalog-name">{item.name}</div>
                  </li>
                )
              })}
            </>
          )}
          {groupedCatalogs.map((catalog, index) => {
            return (
              <li key={index} className="catalog">
                <div
                  className="catalog-symbol"
                  style={{ backgroundColor: catalog.hex_color }}
                ></div>
                <div className="catalog-name">{catalog.name}</div>
              </li>
            )
          })}
        </ul>
      )}
    </>
  )
}

export default WorkbenchLegend
