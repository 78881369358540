const FilterCustomizableYesIcon = () => {
  return (
    <svg className="filterCustomizableYesIcon" viewBox="0 0 144 146">
      <g fill="#21928e" fillRule="nonzero">
        <path d="M40 20v20h24V0H40v20zM120 36v20h24V16h-24v20zM0 40v16h24V24H0v16zM80 48v24h24V24H80v24zM40 100v44h24V56H40v44zM0 96v24h24V72H0v24zM120 100v28h24V72h-24v28zM80 104v16h24V88H80v16z" />
      </g>
    </svg>
  )
}

export default FilterCustomizableYesIcon
