import { useContext, useEffect, useRef, useState } from "react"
import { MixtapePageContext } from "./MixtapePageContainer"
import cx from "classnames"
import MixtapeSongItem from "./MixtapeSongItem"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  background: isDragging ? "rgba(138, 71, 131, 0.6)" : "",
  ...draggableStyle,
})

export const DraggableMixtapeSongs = ({
  songVersions,
  disableSort,
  user_role,
  mixtapeDetails,
  expandedSongVersionId,
  currentBlanketDealModalRef,
  handleSetExpandedSongVersionId,
}) => {
  return songVersions.map((song, index) => (
    <Draggable
      key={song.songVersions[0]?.id.toString()}
      draggableId={song.songVersions[0]?.id.toString()}
      index={index}
      isDragDisabled={disableSort}
    >
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
        >
          <MixtapeSongItem
            song={song}
            user_role={user_role}
            handleSetExpandedSongVersionId={handleSetExpandedSongVersionId}
            expandedSongVersionId={expandedSongVersionId}
            currentBlanketDealModalRef={currentBlanketDealModalRef}
            key={song.songVersions[0]?.id}
            mixtape={mixtapeDetails}
          />
        </div>
      )}
    </Draggable>
  ))
}

const MixtapeSongsContainer = () => {
  const { user_role, is_owner, mixtapeDetails, mixtapeSerializer, songVersions, setSongVersions } =
    useContext(MixtapePageContext)

  const [expandedSongVersionId, setExpandedSongVersionId] = useState(false)
  const currentBlanketDealModalRef = useRef(null)
  const handleSetExpandedSongVersionId = (svid) => {
    setExpandedSongVersionId(svid)
  }

  let notClickedInTheActionMenuOrModal = (e) => {
    // Not clicked in actions menu OR NOT clickd within this song version container
    return (
      !e.target.closest([".actions-menu", ".expand-actions-menu"]) &&
      // AND Not clicked within the blanket deal modal currently open
      !(currentBlanketDealModalRef.current && currentBlanketDealModalRef.current.contains(e.target))
    )
  }

  const handleOnClick = (e) => {
    if (notClickedInTheActionMenuOrModal(e)) {
      setExpandedSongVersionId(false)
    }
  }

  const updateMixtape = async (svid, destination) => {
    // For clarity, position on the back end is 1 indexed
    let adjustedDestination = destination + 1
    const response = await fetch(`/mixtapes/${mixtapeSerializer.id}/sort`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": mm.csrf,
      },
      body: JSON.stringify({
        collaboratable_id: mixtapeSerializer.id,
        song_version_id: svid,
        position: adjustedDestination,
      }),
    })

    if (!response.ok) {
      console.log("ERROR: unable to complete sort action at this time")
      return false
    }

    return true
  }

  const onDragEnd = ({ source, destination, draggableId }) => {
    if (!destination) return
    let success = updateMixtape(draggableId, destination.index)
    if (!success) return
    const newSongVersions = Array.from(songVersions)
    const [draggedItem] = newSongVersions.splice(source.index, 1)
    newSongVersions.splice(destination.index, 0, draggedItem)
    setSongVersions(newSongVersions)
  }

  useEffect(() => {
    document.body.addEventListener("click", handleOnClick)

    return () => {
      document.body.removeEventListener("click", handleOnClick)
    }
  }, [])

  const removeSongFromMixtape = async (svId) => {
    const response = await fetch(`/mixtapes/${mixtapeSerializer.id}/remove/${svId}`)
    if (!response.ok) {
      return
    }
    const result = await response.json()
    if (!result.success) return
    const newList = songVersions.filter((song) => song.songVersions[0].id !== svId)
    setSongVersions(newList)
  }

  const mixtapeProps = {
    ...mixtapeDetails,
    ...mixtapeSerializer,
    removeSongFromMixtape,
  }

  return (
    <>
      <article className="song-versions">
        <div className="contain">
          <ol
            className={cx(
              "mm-song-versions-table",
              mixtapeDetails.collaborative_in_some_way &&
                !mixtapeDetails.staff_pick &&
                "mixtape-collaborators"
            )}
          >
            <li className="mm-song-versions-table-head">
              <ol>
                <li className="song-version">Song / Artist</li>
                <li className="genres desktop">Genres</li>
                <li className="arc desktop">Arc&trade;</li>
                <li className="energy desktop" data-touch>
                  Energy
                </li>
                <li className="length">Length</li>
                {mixtapeDetails.collaborative_in_some_way && !mixtapeDetails.staff_pick && (
                  <li className="collaborator desktop">Collaborator</li>
                )}
              </ol>
            </li>
            {/**className="mm-song-versions-table-list <%= 'hide' if song_versions.nil? %>" */}
            <div className="mm-song-versions-table-list">
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="songItems">
                  {(provided, snapshot) => (
                    <div className="direction" ref={provided.innerRef} {...provided.draggableProps}>
                      <DraggableMixtapeSongs
                        songVersions={songVersions}
                        disableSort={!is_owner}
                        user_role={user_role}
                        mixtapeDetails={mixtapeProps}
                        handleSetExpandedSongVersionId={handleSetExpandedSongVersionId}
                        expandedSongVersionId={expandedSongVersionId}
                        currentBlanketDealModalRef={currentBlanketDealModalRef}
                      />
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </ol>
        </div>
      </article>
    </>
  )
}

export default MixtapeSongsContainer
