import * as yup from "yup"

export const validationSchema = yup.object().shape({
  first_name: yup.string().required("First name is required."),
  last_name: yup.string().required("First name is required."),
  email: yup.string().email("Email is invalid").required("Email is required."),
  email_confirmation: yup
    .string()
    .email("Email is invalid")
    .oneOf([yup.ref("email"), null], "Emails don't match")
    .required("Email is required."),
  id: yup.string().nullable(),
})
