import cx from "classnames"
import styles from "../../styles/Image.module.scss"
import { createSrcSet } from "../../utils/helpers"

const Image = ({ slice, globalClass }) => {
  const { primary } = slice

  const sizeobj = {
    Tablet: "768w",
    Mobile: "480w",
    Standard: "920w",
    HomepageLarge: "1280w",
    LargeDesktop: "1440w",
  }

  return (
    <article className={cx(styles.imageWrapper, globalClass, "prismicImageWrapper")}>
      <div className={styles.contain}>
        <img
          srcSet={createSrcSet(primary.image, sizeobj)}
          sizes="(min-height: 920px) 920px, 100vw"
          src={primary.image.url}
          className={styles.image}
          alt={primary.image.alt}
        />
        <span className="smallFont">{primary.caption}</span>
      </div>
    </article>
  )
}

export default Image
