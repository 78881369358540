import React, { useState, useEffect, useRef } from "react"
import { Form } from "formik"
import TextField from "../../../shared/components/TextField"
import SelectField from "../../../shared/components/SelectField"
import ScrollToError from "../../../shared/components/ScrollToError"
import StatusMessage from "./StatusMessage"
import cx from "classnames"
import jobOptions from "../data/jobOptions"
import jobParser from "../utils/jobParser"
import styles from "../styles/EditProfileForm.module.scss"
import buttonStyles from "../../../shared/styles/buttons.module.scss"
import formStyles from "../../../shared/styles/form.module.scss"
import { showErrorBorder } from "../../../shared/utils/form"
import { countryOptions, regionOptions } from "../../../shared/data/selectOptions"

const EditProfileForm = (props) => {
  const { values, errors, status, touched, setFieldValue, setFieldTouched, isSubmitting } = props
  const userJob = jobParser(values.profile.job)
  const [regions, setRegions] = useState([])
  const [selectedJob, setSelectedJob] = useState(userJob)
  const otherJobInput = useRef()
  const isFirstRun = useRef(true)

  useEffect(() => {
    if (selectedJob === "Other Storyteller") setFieldValue("otherJob", values.profile.job)
  }, [])

  useEffect(() => {
    const regions = regionOptions(values.profile.address.country.value)
    setRegions(regions)
    const regionWithinCountry = regions
      .map((r) => r.label)
      .includes(values.profile.address.subregion.value)

    if (!regionWithinCountry) {
      setFieldValue("profile.address.subregion", {
        label: "Select...",
        value: "",
      })
    }
  }, [values.profile.address.country])

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false
      return
    }

    if (isSubmitting) {
      isFirstRun.current = true
      return
    }

    if (selectedJob === "Other Storyteller") {
      otherJobInput.current.focus()
      setFieldValue("profile.job", values.otherJob)
    }
  }, [isSubmitting, selectedJob, values.otherJob])

  const handleJobOptions = (e, label) => {
    e.preventDefault()
    setSelectedJob(label)

    if (label === "Other Storyteller") {
      setFieldValue("profile.job", "")
    } else {
      setFieldValue("profile.job", label)
      setFieldValue("otherJob", "")
    }
  }

  return (
    <article id="editProfile" className={styles.editProfile}>
      <div className={styles.contain}>
        <Form>
          <ScrollToError />
          <h4 className={cx("hFour", styles.header)}>Edit Profile</h4>
          <StatusMessage status={status} />

          <div className={formStyles.columnWrapper}>
            <div className={formStyles.column}>
              <div className={formStyles.groupedInputs}>
                <TextField
                  label="First Name*"
                  fieldName="firstName"
                  showErrorBorder={showErrorBorder(errors, touched, "firstName")}
                />
                <TextField label="Last Name" fieldName="lastName" />
              </div>
              <TextField
                label="Email*"
                fieldName="email"
                type="email"
                showErrorBorder={showErrorBorder(errors, touched, "email")}
              />
              <TextField label="Company/Organization" fieldName="profile.company" />
              <TextField label="Phone Number" fieldName="profile.phoneNumber" type="tel" />
            </div>

            <div className={formStyles.column}>
              <p className={styles.jobLabel}>I am a..</p>
              <ul className={cx(formStyles.groupedInputs, styles.jobs)}>
                {jobOptions.map((job) => {
                  const { label, Icon } = job
                  return (
                    <li
                      key={label}
                      className={cx({
                        [styles.jobSelected]: label === selectedJob,
                      })}
                      onClick={(e) => handleJobOptions(e, label)}
                    >
                      <div className={styles.jobIcon}>
                        <Icon />
                      </div>
                      {label}
                    </li>
                  )
                })}
              </ul>

              <TextField
                label="If other, please list below"
                fieldName="otherJob"
                disabled={selectedJob !== "Other Storyteller"}
                innerRef={otherJobInput}
              />
              <TextField type="hidden" fieldName="profile.job" wrapperClass={styles.hiddenInput} />
            </div>

            <div className={formStyles.column}>
              <TextField
                label="Address Line 1"
                fieldName="profile.address.line1"
                showErrorBorder={showErrorBorder(errors, touched, "profile.address.line1")}
              />
              <TextField
                label="Address Line 2"
                fieldName="profile.address.line2"
                placeholder="Apt, suite, unit, building, etc."
              />

              <div className="country select">
                <SelectField
                  label="Country"
                  fieldName="profile.address.country"
                  options={countryOptions}
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                />
              </div>

              <TextField label="City" fieldName="profile.address.city" />

              <div className={formStyles.groupedInputs}>
                <SelectField
                  label="State/Region"
                  fieldName="profile.address.subregion"
                  options={regions}
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                  isDisabled={regions.length === 0}
                />
                <TextField label="Postal Code" fieldName="profile.address.postalCode" />
              </div>

              <button
                type="submit"
                className={cx(styles.button, buttonStyles.black, formStyles.submitBtn)}
                disabled={isSubmitting}
              >
                {isSubmitting ? "Updating..." : "Update Profile"}
                <span className={cx({ [buttonStyles.spinner]: isSubmitting })} />
              </button>
            </div>
          </div>
          <TextField type="hidden" fieldName="id" wrapperClass={styles.hiddenInput} />
        </Form>
      </div>
    </article>
  )
}

export default EditProfileForm
