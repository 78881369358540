import { useState, useEffect } from "react"
import cx from "classnames"
import { useWorkbenchStore } from "../../store/store"
import { sortOptions } from "./FilterTransforms"

const WorkbenchSort = ({ id = "sort" }) => {
  const el = document.getElementById("mm-browse-beta-container")
  const indexName = el && el.getAttribute("data-index-name")

  const currentFilter = useWorkbenchStore((state) => state.currentFilter)

  const sortedIndexName = useWorkbenchStore((state) => state.sortedIndexName)
  const [selectedSort, setSelectedSort] = useState(
    sortedIndexName === "" ? "default" : sortedIndexName
  )
  const setSortedIndexName = useWorkbenchStore((state) => state.setSortedIndexName)
  const resetSortedIndexName = useWorkbenchStore((state) => state.resetSortedIndexName)

  const handleClickSort = (e) => {
    const value = e.target.getAttribute("value")

    value !== "default" ? setSortedIndexName(indexName + "_" + value) : resetSortedIndexName()
    setSelectedSort(value)
  }

  useEffect(() => {
    setSelectedSort(sortedIndexName === "" ? "default" : selectedSort)
  }, [sortedIndexName])

  return (
    <fieldset id={id} className={cx("mm-filter", currentFilter === id ? "current" : "")}>
      <ul>
        {sortOptions.map((option, index) => (
          <li
            key={index}
            className={cx("actionable", "sort-option")}
            data-cy={`sort-option-${option.value}`}
            data-value={option.value}
            value={option.value}
            onClick={handleClickSort}
          >
            <a
              className={cx(selectedSort === option.value && "selected")}
              value={option.value}
              data-value={option.value}
              data-prevent-default
            >
              {option.text}
            </a>
          </li>
        ))}
      </ul>
    </fieldset>
  )
}

export default WorkbenchSort
