import { useToggleRefinement } from "react-instantsearch-hooks-web"
import cx from "classnames"
import FilterInstrumentalIcon from "../../icons/FilterInstrumentalIcon"
import FilterVocalsIcon from "../../icons/FilterVocalsIcon"
import FilterVocalsFemaleIcon from "../../icons/FilterVocalsFemaleIcon"
import FilterVocalsMaleIcon from "../../icons/FilterVocalsMaleIcon"
import { useWorkbenchStore } from "../../store/store"

const WorkbenchFilterVocals = ({ id }) => {
  const currentFilter = useWorkbenchStore((state) => state.currentFilter)
  const vocalsToggledOn = useWorkbenchStore((state) => state.vocalsToggledOn)
  const instrumentalToggledOn = useWorkbenchStore((state) => state.instrumentalToggledOn)
  const vocalsFemaleToggledOn = useWorkbenchStore((state) => state.vocalsFemaleToggledOn)
  const vocalsMaleToggledOn = useWorkbenchStore((state) => state.vocalsMaleToggledOn)
  const toggleInstrumental = useWorkbenchStore((state) => state.toggleInstrumental)
  const toggleVocals = useWorkbenchStore((state) => state.toggleVocals)
  const toggleVocalsFemale = useWorkbenchStore((state) => state.toggleVocalsFemale)
  const toggleVocalsMale = useWorkbenchStore((state) => state.toggleVocalsMale)
  const { value: valueInstrumental, refine: refineInstrumental } = useToggleRefinement({
    attribute: "is_instrumental",
  })
  const { value: valueVocals, refine: refineVocals } = useToggleRefinement({
    attribute: "is_vocals",
  })
  const { value: valueMale, refine: refineMale } = useToggleRefinement({
    attribute: "is_male_vocals",
  })
  const { value: valueFemale, refine: refineFemale } = useToggleRefinement({
    attribute: "is_female_vocals",
  })

  const handleInstrumentalClick = () => {
    toggleInstrumental()
    if (vocalsToggledOn) handleVocalsClick()
    if (vocalsFemaleToggledOn) handleFemaleClick()
    if (vocalsMaleToggledOn) handleMaleClick()
    refineInstrumental({ isRefined: instrumentalToggledOn })
  }

  const handleVocalsClick = () => {
    toggleVocals()
    if (instrumentalToggledOn) handleInstrumentalClick()
    if (vocalsFemaleToggledOn) handleFemaleClick()
    if (vocalsMaleToggledOn) handleMaleClick()
    refineVocals({ isRefined: vocalsToggledOn })
  }

  const handleFemaleClick = () => {
    toggleVocalsFemale()
    if (instrumentalToggledOn) handleInstrumentalClick()
    if (vocalsToggledOn) handleVocalsClick()
    refineFemale({ isRefined: vocalsFemaleToggledOn })
  }

  const handleMaleClick = () => {
    toggleVocalsMale()
    if (instrumentalToggledOn) handleInstrumentalClick()
    if (vocalsToggledOn) handleVocalsClick()
    refineMale({ isRefined: vocalsMaleToggledOn })
  }

  // Todo: Pull refinements out of handlers and into useEffects

  // Todo: Experiment with setIndexUiState within a single useEffect refinement to capture
  // complex changing combinations like changing from Instrumental to Male Vocals (and, implicitly, Vocals)

  // useEffect(() => {
  //   if (!vocalsToggledOn) {
  //     console.log('in useEffect, switching male/female vocals')
  //     if (vocalsFemaleToggledOn) handleFemaleClick()
  //     if (vocalsMaleToggledOn) handleMaleClick()
  //   }
  // }, [vocalsToggledOn])

  return (
    <fieldset id={id} className={cx("mm-filter", currentFilter === id ? "current" : "")}>
      <ul>
        <li
          className={cx("actionable", "false", instrumentalToggledOn ? "selected" : "")}
          data-val="false"
          data-pretty="Instrumental"
          data-cy="vocals-option-Instrumental"
        >
          <a data-prevent-default className="inner" onClick={handleInstrumentalClick}>
            <figure>
              <span className="icon">
                <FilterInstrumentalIcon />
              </span>
              <figcaption className="desktop">Instrumental</figcaption>
              <figcaption className="mobile">None</figcaption>
            </figure>
          </a>
        </li>

        <li
          className={cx("actionable", "true", vocalsToggledOn ? "selected" : "")}
          data-val="true"
          data-pretty="Vocals"
          data-cy="vocals-option-Vocals"
        >
          <a data-prevent-default className="inner" onClick={handleVocalsClick}>
            <figure>
              <span className="icon">
                <FilterVocalsIcon />
              </span>
              <figcaption className="desktop">All Vocals</figcaption>
              <figcaption className="mobile">Vocals</figcaption>
            </figure>
          </a>
        </li>

        <li
          className={cx("actionable", "female", vocalsFemaleToggledOn ? "selected" : "")}
          data-val="female"
          data-pretty="Female"
          data-cy="vocals-option-Female"
        >
          <a data-prevent-default className="inner" onClick={handleFemaleClick}>
            <figure>
              <span className="icon">
                <FilterVocalsFemaleIcon />
              </span>
              <figcaption>Female</figcaption>
            </figure>
          </a>
        </li>

        <li
          className={cx("actionable", "male", vocalsMaleToggledOn ? "selected" : "")}
          data-val="male"
          data-pretty="Male"
          data-cy="vocals-option-Male"
        >
          <a data-prevent-default className="inner" onClick={handleMaleClick}>
            <figure>
              <span className="icon">
                <FilterVocalsMaleIcon />
              </span>
              <figcaption>Male</figcaption>
            </figure>
          </a>
        </li>
      </ul>
    </fieldset>
  )
}

export default WorkbenchFilterVocals
