export const useStateFromAlgoliaParams = () => {
  const params = parseParams()
  const newState = {}
  if (params.query) {
    const query = params.query
    newState.searchQuery = query
  }
  if (params.vocals) {
    const options = params.vocals.split("~")
    options.forEach((option) => {
      switch (option) {
        case "instrumental": {
          newState.instrumentalToggledOn = true
          break
        }
        case "vocals": {
          newState.vocalsToggledOn = true
          break
        }
        case "female_vocals": {
          newState.vocalsFemaleToggledOn = true
          break
        }
        case "male_vocals": {
          newState.vocalsMaleToggledOn = true
          break
        }
      }
    })
  }
  if (params.instruments) {
    const instrumentsOn = params.instruments.split("~").filter((item) => item[0] !== "-")
    const instrumentsOff = params.instruments
      .split("~")
      .filter((item) => item[0] === "-")
      .map((item) => item.slice(1))
    if (instrumentsOn.length) {
      newState.instrumentsToggledOn = instrumentsOn
    }
    if (instrumentsOff.length) {
      newState.instrumentsToggledOff = instrumentsOff
    }
  }
  if (params.genres) {
    const genresOn = params.genres.split("~").filter((item) => item[0] !== "-")
    const genresOff = params.genres
      .split("~")
      .filter((item) => item[0] === "-")
      .map((item) => item.slice(1))
    if (genresOn.length) {
      newState.genresToggledOn = genresOn
    }
    if (genresOff.length) {
      newState.genresToggledOff = genresOff
    }
  }
  if (params.moods) {
    const moodsOn = params.moods.split("~").filter((item) => item[0] !== "-")
    const moodsOff = params.moods
      .split("~")
      .filter((item) => item[0] === "-")
      .map((item) => item.slice(1))
    if (moodsOn.length) {
      newState.moodsToggledOn = moodsOn
    }
    if (moodsOff.length) {
      newState.moodsToggledOff = moodsOff
    }
  }
  if (params.flags) {
    const flagsOn = params.flags.split("~").filter((item) => item[0] !== "-")
    if (flagsOn.length) {
      newState.flagsToggledOn = flagsOn
    }
  }
  if (params.arcs) {
    const arcs = params.arcs.split("~")
    newState.arcsToggledOn = arcs
  }
  if (params.energies) {
    const energies = params.energies.split("~")
    newState.energiesToggledOn = energies
  }
  if (params.length) {
    const duration = params.length.split("-")
    newState.lengthMin = duration[0]
    newState.lengthMax = duration[1]
  }
  if (params.flags) {
    const flags = params.flags.split("~")
    newState.flagsToggledOn = flags
  }
  if (params.options) {
    const options = params.options.split("~")
    options.forEach((option) => {
      switch (option) {
        case "customizable": {
          newState.customizableSelection = "yes"
          break
        }
        case "not_customizable": {
          newState.customizableSelection = "no"
          break
        }
        case "for_marmoset": {
          newState.forMarmosetSelection = "selected"
          break
        }
        case "not_for_marmoset": {
          newState.forMarmosetSelection = "selected-negative"
          break
        }
        case "artist_collab": {
          newState.artistCollabSelection = "selected"
          break
        }
        case "not_artist_collab": {
          newState.artistCollabSelection = "selected-negative"
          break
        }
        case "pre_release": {
          newState.preReleaseSelection = "selected"
          break
        }
        case "not_pre_release": {
          newState.preReleaseSelection = "selected-negative"
          break
        }
        case "fervor": {
          newState.fervorSelection = "selected"
          break
        }
        case "not_fervor": {
          newState.fervorSelection = "selected-negative"
          break
        }
        case "for_tv_film": {
          newState.notForTVSelection = "selected-negative"
          break
        }
        case "not_for_tv_film": {
          newState.notForTVSelection = "selected"
          break
        }
        case "has_stems": {
          newState.hasStemsSelection = "selected"
          break
        }
        case "not_has_stems": {
          newState.hasStemsSelection = "selected-negative"
          break
        }
        case "in_track_club": {
          newState.isInTrackClubSelection = "selected"
          break
        }
        case "not_in_track_club": {
          newState.isInTrackClubSelection = "selected-negative"
          break
        }
      }
    })
  }
  if (params.filter) {
    newState.selectedResultsFilter = params.filter
  }

  return newState
}

const parseParams = () => {
  const queryParams = new URLSearchParams(window.location.search)
  const query = queryParams.get("query")
  const vocals = queryParams.get("vocals")
  const moods = queryParams.get("moods")
  const energies = queryParams.get("energies")
  const arcs = queryParams.get("arcs")
  const length = queryParams.get("length")
  const instruments = queryParams.get("instruments")
  const genres = queryParams.get("genres")
  const options = queryParams.get("options")
  const filter = queryParams.get("filter")
  const page = queryParams.get("page")
  const flags = queryParams.get("flags")

  return {
    query,
    vocals,
    moods,
    energies,
    arcs,
    length,
    instruments,
    genres,
    options,
    filter,
    page,
    flags,
  }
}
