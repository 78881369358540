import { gql } from "@apollo/client"

export const REMOVE_SRSV = gql`
  mutation removeSearchRoundSongVersion($id: ID!) {
    removeSearchRoundSongVersion(id: $id) {
      searchRound {
        id
        directions {
          id
          searchRoundSongVersions {
            id
          }
        }
        searchRoundSongVersions {
          id
        }
      }
    }
  }
`

export const SET_SRSV_RATING = gql`
  mutation setSearchRoundSongVersionRating($id: ID!, $rating: Int!) {
    setSearchRoundSongVersionRating(id: $id, rating: $rating) {
      searchRoundSongVersion {
        id
        rating
      }
    }
  }
`

export const SORT_SEARCH_ROUND_SONG_VERSION = gql`
  mutation sortSearchRoundSongVersion($id: ID!, $position: Int!, $directionId: ID) {
    sortSearchRoundSongVersion(id: $id, position: $position, directionId: $directionId) {
      searchRound {
        id
        directions {
          id
          searchRoundSongVersions {
            id
            position
          }
        }
        searchRoundSongVersions {
          id
          position
        }
      }
    }
  }
`

export const UPDATE_SRSV_NOTES = gql`
  mutation updateSearchRoundSongVersionNotes($id: ID!, $notes: String!) {
    updateSearchRoundSongVersionNotes(id: $id, notes: $notes) {
      searchRoundSongVersion {
        id
        notes
      }
    }
  }
`

export const UPDATE_SRSV_WINNER = gql`
  mutation setSearchRoundSongVersionWinner($id: ID!, $winner: Boolean!) {
    setSearchRoundSongVersionWinner(id: $id, winner: $winner) {
      searchRoundSongVersion {
        id
        winner
      }
    }
  }
`
