import { PrismicRichText } from "@prismicio/react"
import htmlSerializer from "../../utils/prismicHTMLSerializer"
import { createSrcSet } from "../../utils/helpers"
import styles from "../../styles/LogoGridRow.module.scss"
import MoreIcon from "../../../icons/MoreIcon"
import { useEffect } from "react"

const LogoGridRow = ({ slice, title }) => {
  const { items, primary } = slice
  const sizeobj = { Lowres: "1x", Hires: "2x" }
  const backgroundColorMap = {
    Purple: "purple",
    Coral: "coral",
    Gold: "gold",
    Green: "green",
    Black: "black",
    White: "white",
    Track_Red: "track-red",
    Track_Yellow: "track-yellow",
    Track_Blue: "track-blue",
    Track_Pink: "track-pink",
    Track_Orange: "track-orange",
    Track_Ocean_Blue: "track-ocean-blue",
    Track_Sunflower: "track-sunflower",
  }

  useEffect(() => {
    /** Hacky way of getting the last child to have different css rules as module scss files make this hard to do **/
    const rows = document.querySelectorAll(`.${styles.logoGrid}`)
    let lastRow = rows[rows.length - 1]
    lastRow.classList = styles.lastRow

    let lastRows = document.querySelectorAll(`.${styles.lastRow}`)
    if (lastRows.length > 1) {
      lastRows[0].classList = styles.logoGrid
    }
  }, [])

  return (
    <>
      <article className={styles.logoGrid} aria-label="Featured collaborators">
        <div className={styles.contain}>
          <div
            className={
              title === "Music Licensing" ? styles.richTextMusicLicensing : styles.richText
            }
          >
            <PrismicRichText field={primary.rich_text} components={htmlSerializer} />
          </div>
          <div className={styles.logosWrapper}>
            <div className={styles.logos}>
              {items.slice(0, 4).map((item) => {
                const { alt, url, uid } = item.logo
                const header = item.hover_header[0].text
                const subheader = item.hover_subheader[0].text
                const backgroundColor = backgroundColorMap[item.hover_color]
                return (
                  <a
                    href={item.url?.url}
                    target="_blank"
                    className={styles.logoContainer}
                    key={uid}
                  >
                    <summary
                      className={styles.hoverContent}
                      style={{ backgroundColor: `var(--${backgroundColor})` }}
                    >
                      <h3>{header}</h3>
                      <span>{subheader}</span>
                      <div className={styles.more}>
                        <MoreIcon />
                      </div>
                    </summary>
                    <img
                      src={url}
                      srcSet={createSrcSet(item.logo, sizeobj)}
                      className={styles.logo}
                      alt={alt}
                    />
                  </a>
                )
              })}
            </div>
          </div>
        </div>
      </article>
    </>
  )
}

export default LogoGridRow
