import ArcSvgIcon from "../shared/components/ArcSvgIcon"

const ArcSteadyIcon = () => {
  return (
    <ArcSvgIcon svgClass="ArcSteadyIcon" fill="none" fillRule="nonzero">
      <path fill="#21928E" d="M0 20h200v20H0z" />
      <path fill="#FFF" d="M0 0h200v20H0z" />
    </ArcSvgIcon>
  )
}

export default ArcSteadyIcon
