import cx from "classnames"
import { useRefinementList } from "react-instantsearch-hooks-web"
import { transformEnergies } from "./FilterTransforms"
import { useWorkbenchStore } from "../../store/store"
import BoltIcon from "../../icons/BoltIcon"

const EnergyRefinementListItem = ({ item, refine }) => {
  const energiesToggledOn = useWorkbenchStore((state) => state.energiesToggledOn)
  const addToEnergies = useWorkbenchStore((state) => state.addToEnergies)
  const removeFromEnergies = useWorkbenchStore((state) => state.removeFromEnergies)

  const handleClick = () => {
    energiesToggledOn.includes(item.value)
      ? removeFromEnergies(item.value)
      : addToEnergies(item.value)
    refine(item.value)
  }

  return (
    <li
      className={cx(
        "actionable",
        item.label.toLowerCase(),
        energiesToggledOn.includes(item.value) ? "selected" : ""
      )}
      data-val={item.label}
      data-pretty={`${item.label} Energy`}
      data-cy={item.label.toLowerCase()}
    >
      <a data-prevent-default onClick={handleClick}>
        <figure>
          <div className={cx("energy-wrapper", item.label.toLowerCase())}>
            <div className="center">
              <BoltIcon />
            </div>

            <div className="inner-spin">
              <div className="inner-arc inner-arc_start-a"></div>
              <div className="inner-arc inner-arc_end-a"></div>
              <div className="inner-arc inner-arc_start-b"></div>
              <div className="inner-arc inner-arc_end-b"></div>
            </div>
            <div className="outer-spin">
              <div className="outer-arc outer-arc_start-a"></div>
              <div className="outer-arc outer-arc_end-a"></div>
              <div className="outer-arc outer-arc_start-b"></div>
              <div className="outer-arc outer-arc_end-b"></div>
            </div>
          </div>
          <span className="icon mobile">
            <span className="default"></span>
            <span className="active"></span>
          </span>
          <figcaption>{item.label}</figcaption>
        </figure>
      </a>
    </li>
  )
}

const EnergyRefinementList = (props) => {
  const { items, refine } = useRefinementList(props)

  return (
    <ol>
      {items.map((item, index) => (
        <EnergyRefinementListItem key={index} item={item} refine={refine} />
      ))}
    </ol>
  )
}

const WorkbenchFilterEnergy = ({ id }) => {
  const currentFilter = useWorkbenchStore((state) => state.currentFilter)

  return (
    <fieldset id={id} className={cx("mm-filter", currentFilter === id ? "current" : "")}>
      <EnergyRefinementList attribute="energy" opreator="and" transformItems={transformEnergies} />
    </fieldset>
  )
}

export default WorkbenchFilterEnergy
