import { PrismicPageParser } from "../../shared/utils/prismicPageParser"
import cx from "classnames"
import styles from "./ReelLicenseContainer.module.scss"

const ReelLicenseContainer = () => {
  const data = JSON.parse(window.prismic_data)
  const pageId = window.pageId

  return (
    <section
      id="mm-reel-license"
      className={cx(styles.reel, "pageContainer")}
      aria-label="Reel License"
    >
      <PrismicPageParser pageData={data} pageId={pageId} />
    </section>
  )
}

export default ReelLicenseContainer
