const MastercardIcon = () => {
  return (
    <svg className="mastercardIcon" height="48px" width="48px" viewBox="0 0 48 48">
      <path
        fill="#3f51b5"
        d="M45 35c0 2.21-1.79 4-4 4H7c-2.21 0-4-1.79-4-4V13c0-2.21 1.79-4 4-4h34c2.21 0 4 1.79 4 4z"
      />
      <path
        fill="#ffc107"
        d="M40 24c0 5.523-4.477 10-10 10s-10-4.477-10-10 4.477-10 10-10 10 4.477 10 10z"
      />
      <path
        fill="#ff3d00"
        d="M22.016 30a9.956 9.956 0 01-1.176-2h5.324a9.946 9.946 0 00.637-2h-6.598A9.895 9.895 0 0120 24h7c0-.688-.07-1.355-.2-2h-6.6c.144-.695.359-1.363.64-2h5.324a9.956 9.956 0 00-1.176-2h-2.972c.433-.578.93-1.121 1.48-1.594A9.964 9.964 0 0017 14c-5.523 0-10 4.477-10 10s4.477 10 10 10c3.27 0 6.16-1.574 7.984-4z"
      />
    </svg>
  )
}

export default MastercardIcon
