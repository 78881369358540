import { PrismicPageParser } from "../../shared/utils/prismicPageParser"
import { useEffect, useState } from "react"
import cx from "classnames"
import styles from "./PrismicDraftContainer.module.scss"

const PrismicDraftContainer = () => {
  const [documentId, setDocumentId] = useState("")
  const [isHome, setIsHome] = useState(false)

  useEffect(() => {
    const path = window.location.pathname.split("/")
    const id = path[2]
    setDocumentId(id)
    if (id == "XfAL1RAAACIAWkf0" || id == "YgRvlhIAAB4Af58_") {
      setIsHome(true)
    }
  })

  return (
    <section
      id={isHome ? "mm-home-new" : "mm-draft"}
      className={cx(
        styles.draft,
        "pageContainer",
        isHome ? "home homeContainer serverRendered backgroundWhite" : ""
      )}
      aria-label="Draft page"
    >
      {documentId && <PrismicPageParser pageId={documentId} />}
    </section>
  )
}

export default PrismicDraftContainer
