import React from "react"
import styles from "../styles/Cards.module.scss"

const cardTypes = ["visa", "mastercard", "discover", "amex"]

const Cards = ({ activeType }) => {
  return (
    <ul className={styles.cards}>
      {cardTypes.map((type) => {
        const cardChosen = type === activeType
        const noMatch = activeType === "" || !cardTypes.includes(activeType)

        return (
          <li key={type}>
            <img
              src={`/images/icons/${type}.svg`}
              alt={type}
              style={{ opacity: cardChosen || noMatch ? 1 : 0.3 }}
              height="42"
            />
          </li>
        )
      })}
    </ul>
  )
}

export default Cards
