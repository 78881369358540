import { createContext, useEffect, useState, useRef, useCallback } from "react"
import cx from "classnames"
import groupBySong from "../../shared/utils/songNormalizer"
import axios from "axios"
import ArtistDiscogContainer from "./ArtistDiscogContainer"
import LoadingIcon from "../../icons/LoadingIcon"
import StaffSongsList from "./StaffSongsList"

export const ArtistPageContext = createContext({})

const ArtistPageContainer = () => {
  const el = document.getElementById("mm-artist-page-container")
  const cacheTag = document.getElementById("#mm-artist")?.data("cache-tag")
  const user_role = el && el.getAttribute("data-user-role")
  const artist_slug = el && el.getAttribute("data-artist-slug")
  const query = `/api/v1/artists/${artist_slug}/get_songs`
  const queryPageSize = 14

  const [songs, setSongs] = useState([])
  const [staffSongs, setStaffSongs] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const offsetRef = useRef(0)
  const totalCountRef = useRef(0)
  const initialLoad = useRef(true)
  const currentBlanketDealModalRef = useRef(null)

  const handleLoadMore = (e) => {
    if (isLoading) return
    offsetRef.current += queryPageSize
    let count = songs.length
    offsetRef.current = offsetRef.current <= count ? offsetRef.current : count
    handleQuery()
  }

  const handleQuery = useCallback(() => {
    setIsLoading(true)
    axios
      .get(query, {
        params: {
          order: "listens_count DESC",
          offset: offsetRef.current,
          limit: queryPageSize,
          cache_tag: cacheTag,
        },
      })
      .then((response) => {
        const data = response.data
        const resSongs = data.songs
        totalCountRef.current = data.result_count
        const groupedSongs = groupBySong(resSongs)
        setSongs([...songs, ...groupedSongs])
      })
      .then((next) => {
        setIsLoading(false)
        initialLoad.current = false
      })
      .catch((e) => {
        console.log(e)
        setIsLoading(false)
      })
  }, [offsetRef, totalCountRef, songs])

  const contextValue = {
    user_role,
    songs,
    artist_slug,
    cacheTag,
    initialLoad: initialLoad.current,
    currentBlanketDealModalRef,
    triggerFirstQuery: handleQuery,
  }

  return (
    <>
      <ArtistPageContext.Provider value={contextValue}>
        <StaffSongsList />
        <article className="discog" aria-label="Full songography">
          <div className="contain">
            <h5 className="hFive">Full Songography</h5>
            {isLoading && initialLoad.current ? (
              <div>
                <LoadingIcon />
              </div>
            ) : !songs[0] ? (
              <></>
            ) : (
              <>
                <ArtistDiscogContainer />
                <a
                  data-prevent-default
                  onClick={handleLoadMore}
                  className={cx(
                    "box-button-black",
                    "view-more",
                    songs.length < totalCountRef.current && "available"
                  )}
                >
                  {isLoading ? "Loading..." : "View More"}
                </a>
              </>
            )}
          </div>
        </article>
      </ArtistPageContext.Provider>
    </>
  )
}

export default ArtistPageContainer
