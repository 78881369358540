import { useWorkbenchStore } from "../../store/store"
import cx from "classnames"

const MoodsListItem = ({ item, refine }) => {
  const { label, rank, value } = item
  const moodsToggledOn = useWorkbenchStore((state) => state.moodsToggledOn)
  const moodsToggledOff = useWorkbenchStore((state) => state.moodsToggledOff)
  const addToMoodsOn = useWorkbenchStore((state) => state.addToMoodsOn)
  const addToMoodsOff = useWorkbenchStore((state) => state.addToMoodsOff)
  const removeFromMoodsOn = useWorkbenchStore((state) => state.removeFromMoodsOn)
  const removeFromMoodsOff = useWorkbenchStore((state) => state.removeFromMoodsOff)

  const handleClick = (e) => {
    e.preventDefault()
    if (!moodsToggledOn.includes(value) && !moodsToggledOff.includes(value)) {
      addToMoodsOn(value)
      refine(value)
    } else if (moodsToggledOn.includes(value)) {
      removeFromMoodsOn(value)
      addToMoodsOff(value)
      // Todo: In a future ticket, look into integrating setIndexUiState to handle both refinement updates in one call
      // https://github.com/algolia/react-instantsearch/discussions/3491
      refine(value)
      refine("-" + value)
    } else if (moodsToggledOff.includes(value)) {
      removeFromMoodsOff(value)
      refine("-" + value)
    }
  }

  const rankClass = (rank) => {
    const DESKTOP_QTY = 66 // Display a 11x6 grid
    const SM_DESKTOP_QTY = 54 // Display a 9x6 grid
    const TABLET_QTY = 42 // Display a 7x6 grid
    const MOBILE_QTY = 40 // Display a 4x10 grid
    const SM_MOBILE_QTY = 30 // Display a 3x10 grid

    if (rank > DESKTOP_QTY - 1) return "hidden"
    if (rank > SM_DESKTOP_QTY - 1) return "sm-desktop-hidden"
    if (rank > TABLET_QTY - 1) return "tablet-hidden"
    if (rank > MOBILE_QTY - 1) return "mobile-hidden"
    if (rank > SM_MOBILE_QTY - 1) return "sm-mobile-hidden"
  }

  return (
    <li
      className={cx(
        "actionable",
        rankClass(rank),
        moodsToggledOn.includes(value) ? "selected" : "",
        moodsToggledOff.includes(value) ? "selected-negative" : ""
      )}
      data-cy={`moods-option-${value}`}
      // Todo: In a future ticket, remove (or make consistent) data-* elements
      // data-val={value}
      // data-pretty={value}
    >
      <a data-prevent-default onClick={handleClick}>
        {label}
      </a>
    </li>
  )
}

export default MoodsListItem
