import SvgIcon from "../shared/components/SvgIcon"

const SecretIcon = () => {
  return (
    <SvgIcon svgClass="SecretIcon">
      <path
        className="icon-border"
        d="M15.0004823 28.9205441c-7.68931474 0-13.92199103-6.2326763-13.92199103-13.9210264 0-7.68835014 6.23267629-13.92102643 13.92199103-13.92102643 7.6883501 0 13.9210264 6.23267629 13.9210264 13.92102643 0 7.6883501-6.2326763 13.9210264-13.9210264 13.9210264m0-28.9205441C6.71597157 0 0 6.71500691 0 14.9995177 0 23.2840284 6.71597157 30 15.0004823 30 23.2849931 30 30 23.2840284 30 14.9995177 30 6.71500691 23.2849931 0 15.0004823 0"
      />
      <path d="M14.9951509 11.4610991c.4936422 0 .9737069.1008621 1.4198275.2967673l.2133621.0921336.4364224-.9950431-.2996767-.1280172c-.5605603-.2317888-1.1579741-.3510776-1.7699353-.3510776-2.5506466 0-4.6192888 2.0686422-4.6192888 4.6202586 0 .5644396.1018319 1.1182112.3006465 1.6399784l.0824354.2172414 1.0115301-.3850215-.1095905-.2996768c-.1318965-.3733836-.1997845-.7690732-.1997845-1.1725215 0-1.9522629 1.5817888-3.5350216 3.5340518-3.5350216M20.85 8.5137931L7.8 20.7181034l.74094828.7923492L21.5909483 9.30614224z" />
      <path d="M8.44331897 19.3567888l.65851293-.8602371-.32780173-.253125c-.23275862-.185237-.46551724-.3801724-.69342672-.584806-.69342673-.6235991-1.33157328-1.3121767-1.89795259-2.0385776-.14644396-.1881465-.28125-.3695043-.40344827-.5440733l-.05818966-.0834051.14838362-.2123923.05528017-.0775862c.07952587-.1086207.16293104-.2211207.25021552-.3355603.55959052-.7264009 1.19288793-1.4149785 1.88340517-2.036638C10.1851293 10.412069 12.515625 9.40247845 14.9983836 9.40247845c1.0260776 0 2.0346983.18038793 3.0258621.534375l.2182112.07855605.3665948-1.02219829-.3394396-.1192888c-1.0706897-.36853448-2.1617457-.55668103-3.2712285-.55668103-2.7669181 0-5.34471981 1.1143319-7.66648705 3.20818962-.73997845.6662716-1.41594827 1.4023707-2.01627155 2.1801724-.15614224.202694-.29967672.399569-.43060345.5886854l-.09116379.1328664-.08922414.1357758c-.03976293.0610992-.0737069.1134699-.09698276.1532328l-.16971983.28125.18232759.2967672.04655172.0737069c.01163794.0174569.02424569.0378233.03782328.0572199l.09310345.1386853.05043103.0737069c.14353449.2085129.30355604.4257543.478125.6488147.60614224.7778017 1.28696121 1.512931 2.02984914 2.1792025.29482759.264763.59547414.5140087.90290948.7496768l.18426725.1415948zM15.0003233 18.5311422c-.6294181 0-1.2384699-.1639008-1.7757543-.4771551l-.2007543-.1173492-.5479526.9387931.275431.1580819c.6876078.3821121 1.456681.5828664 2.2490302.5828664 2.5516164 0 4.6202586-2.0686422 4.6202586-4.6192888 0-.7176724-.1639009-1.4169181-.4810345-2.0550646l-.1037715-.2075431-.9698276.4820043.1367457.28125c.2182112.4655172.3336207.9756465.3336207 1.4993534 0 1.9522629-1.5827587 3.5340517-3.5359914 3.5340517" />
      <path d="M22.6412716 11.5293103c-.3733837-.3345905-.7554957-.6459051-1.1473061-.9339439l-.1871767-.1367457-.641056.8728448.3248922.2414871c.3142241.2366379.6226293.4917026.9261853.7632543.6953664.6235991 1.3325431 1.3121767 1.8989225 2.0385776.1464439.1881465.28125.3695043.4034482.5440733l.0581897.084375-.0756466.1076508c-.1144396.1648707-.2424569.3384698-.3782327.5169181-.5595905.7264009-1.192888 1.4149785-1.8834052 2.036638-2.1278017 1.9183189-4.4573276 2.9279094-6.9391164 2.9279094-1.2501077 0-2.4720905-.2657327-3.6649784-.7884698l-.2143319-.0921336-.4354526.9940733.331681.1425646c1.29375.5489224 2.6233837.8292026 3.9830819.8292026 2.7659483 0 5.34375-1.1143319 7.6655173-3.2072198.7399784-.6672414 1.4159482-1.4033405 2.0153017-2.1811423.1561422-.2026939.2996767-.3995689.4325431-.5877155l.090194-.1338362.0688577-.1028017c.0494612-.0766164.090194-.1396552.1173492-.1862069l.1697198-.2822198-.225-.3627156-.1328664-.2017241-.0523707-.0746767c-.1445043-.2085129-.3045258-.4247845-.4790948-.6488147-.6051724-.7778017-1.2859914-1.512931-2.0298491-2.1792026" />
    </SvgIcon>
  )
}

export default SecretIcon
