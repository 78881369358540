const FilterVocalsFemaleIcon = () => {
  return (
    <svg className="filterVocalsFemaleIcon" viewBox="0 0 120 260">
      <g fill="#21928e" fillRule="nonzero">
        <path d="M51 3.1c-9 4.1-13.3 10.7-13.3 20.4 0 10.1 6.4 19.1 15.3 21.5 3.3.9 4 1.5 4 3.5 0 2.1-.5 2.5-3 2.5-2.8 0-3 .3-3 3.5s.2 3.5 3 3.5 3 .3 3 3.5.2 3.5 3 3.5 3-.3 3-3.5.2-3.5 3-3.5 3-.3 3-3.5-.2-3.5-3-3.5c-2.5 0-3-.4-3-2.5 0-2 .7-2.6 4-3.5 5-1.4 10.5-6.1 13.2-11.4 4.2-8.1 2.2-20.2-4.4-26.3C72.2 3.9 65 1 60 1c-2.5 0-6.5 1-9 2.1zm18.1 7.4c6.8 4.4 9.1 14.9 4.6 21.5C65 45 44 39.5 44 24.3 44 10.5 57.7 3 69.1 10.5zM48.8 80.6c-5.7 1.7-12.4 8.1-15.8 14.9l-2.5 5v32c0 31.3.1 32.1 2.3 37 2.9 6.3 7.4 11 13.7 14.3 4.3 2.2 6.3 2.6 13.5 2.6s9.2-.4 13.5-2.6c9.1-4.8 14.5-12.6 15.9-23l.6-4.7-13.3-.3-13.2-.3-.3-4.2-.3-4.2 13.3-.3 13.3-.3.3-4.2.3-4.2-13.3-.3-13.3-.3v-9l13.3-.3 13.2-.3v-7.8l-13.2-.3-13.3-.3v-9l13.3-.3 13.3-.3-.7-5.2c-1.5-11.3-9.2-21.4-18.7-24.2-6.3-1.9-15.7-1.8-21.9.1z" />
        <path d="M1 158.5c0 6 2.1 13.7 5.9 22 8.1 17.4 25.2 30 44.9 33l4.2.7V251H18v8h84v-8H64v-36.8l4.3-.7c24.1-3.7 43-21 49.2-45 .8-3.2 1.5-7.6 1.5-9.7 0-3.8 0-3.8-4-3.8s-4 0-4 3.9c0 6.7-4.6 19.3-9.4 25.8-20.7 28.2-61.6 28.5-82.8.5-5.1-6.8-9.8-19.3-9.8-26.3 0-3.9 0-3.9-4-3.9-3.9 0-4 .1-4 3.5z" />
      </g>
    </svg>
  )
}

export default FilterVocalsFemaleIcon
