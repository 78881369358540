import { gql, useQuery } from "@apollo/client"
import { PrismicRichText } from "@prismicio/react"
import cx from "classnames"
import htmlSerializer from "../../../shared/utils/prismicHTMLSerializer"
import Mixtapes from "../../../shared/components/Mixtapes"
import DoubleButton from "../../../shared/components/DoubleButton"
import HorizontalRule from "../../../shared/components/prismic/HorizontalRule"
import styles from "../styles/FeaturedMixtapes.module.scss"

const GET_FEATURED_MIXTAPES = gql`
  query StaffPickMixtapes($limit: Int) {
    staffPickMixtapes(limit: $limit) {
      id
      name
      slug
      imageUrl
      featuredAlbum
    }
  }
`

const FeaturedMixtapes = ({ slice }) => {
  const variables = { limit: 8 } // amount of mixtapes the query returns
  const { loading, error, data } = useQuery(GET_FEATURED_MIXTAPES, {
    variables,
  })
  const { primary } = slice

  if (loading) return null
  if (error) console.log(error)

  const { staffPickMixtapes } = data
  return (
    <article className={styles.featuredMixtapes} aria-label="Featured mixtapes">
      <div className={styles.contain}>
        <div className={cx("textWrapper", styles.richText)}>
          <PrismicRichText field={primary.rich_text} components={htmlSerializer} />
        </div>
        <Mixtapes mixtapes={staffPickMixtapes} />
        <DoubleButton slice={slice} />
        <HorizontalRule />
      </div>
    </article>
  )
}

export default FeaturedMixtapes
