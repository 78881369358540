import cx from "classnames"
import styles from "../styles/DropdownMenu.module.scss"
import ReactResponsiveSelect from "react-responsive-select"
import { caretIcon } from "../utils/reactResponsiveSelectHelpers"
import { filterItems } from "../utils/helpers"

const DropdownMenu = ({ nav_type, items }) => {
  const handleChange = (nav_type, value) => {
    if (value != "default") {
      if (nav_type === "tags") {
        filterItems(value)
      } else {
        window.location = value
      }
    }
  }

  return (
    <div className={cx(styles.dropdownMenu, "dropdownMenu")}>
      <ReactResponsiveSelect
        name="dropdown"
        options={items}
        caretIcon={caretIcon}
        selectedValue={decodeURI(window.location.hash).substr(1)}
        onChange={(selected) => handleChange(nav_type, selected.value)}
      />
    </div>
  )
}

export default DropdownMenu
