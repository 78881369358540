import { useState, useEffect, useRef } from "react"
import { Formik, Form } from "formik"
import { useMutation } from "@apollo/client"
import TextField from "../../../shared/components/TextField"
import { ADD_DIRECTION } from "../../../graphql/mutations/directions"

const AddDirection = ({ searchRoundId }) => {
  const [showForm, setShowForm] = useState(false)
  const directionInput = useRef(null)
  const [addDirection, { error: mutationError }] = useMutation(ADD_DIRECTION)

  useEffect(() => {
    if (showForm) directionInput.current.focus()
  }, [showForm])

  const handleSubmit = (params) => {
    const variables = {
      id: searchRoundId,
      name: params.directionName,
    }

    addDirection({ variables })
      .then(() => {
        setShowForm(false)
      })
      .catch((error) => {
        Sentry.captureException(error)
        setShowForm(false)
      })
  }

  const handleCloseForm = () => setShowForm(false)
  const handleShowForm = () => setShowForm(true)

  const initialValues = {
    directionName: "",
  }

  return (
    <div className="add-direction">
      {showForm ? (
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          {({ isSubmitting, values }) => (
            <Form>
              <div className="direction-form">
                <TextField
                  fieldName="directionName"
                  innerRef={directionInput}
                  maxLength="50"
                  placeholder="Direction name"
                  dataCy="addDirectionInput"
                />

                <button
                  type="submit"
                  className="save-button"
                  disabled={isSubmitting || values.directionName === ""}
                  data-cy="addDirectionSave"
                >
                  {isSubmitting ? "Saving..." : "Save"}
                </button>
                <button className="cancel-button" onClick={handleCloseForm} disabled={isSubmitting}>
                  Cancel
                </button>
              </div>
            </Form>
          )}
        </Formik>
      ) : (
        <button className="add-direction-button" onClick={handleShowForm} data-cy="addDirection">
          Add Direction
        </button>
      )}
      {mutationError && mutationError.graphQLErrors[0].message !== "" && (
        <div className="error-message">{mutationError.graphQLErrors[0].message}</div>
      )}
    </div>
  )
}

export default AddDirection
