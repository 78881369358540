import { PrismicRichText } from "@prismicio/react"
import cx from "classnames"
import htmlSerializer from "../../../shared/utils/prismicHTMLSerializer"
import Button from "../../../shared/components/prismic/Button"
import BasicImage from "../../../shared/components/prismic/BasicImage"
import styles from "../../styles/Hero.module.scss"

const Hero = ({ slice }) => {
  const { primary } = slice

  return (
    <header className={cx(styles.hero, "prismicHero")}>
      <div className={styles.contain}>
        <BasicImage className={styles.imageCol} image={primary.hero_image} />
        <div className={styles.descriptionCol}>
          <PrismicRichText field={primary.hero_text} components={htmlSerializer} />
          {primary.button_text && primary.button_url && <Button slice={slice} />}
        </div>
      </div>
    </header>
  )
}

export default Hero
