import { PrismicRichText } from "@prismicio/react"
import cx from "classnames"
import htmlSerializer from "../../utils/prismicHTMLSerializer"
import LazyLoad from "../../components/LazyLoad"
import Button from "../../components/prismic/Button"
import styles from "../../styles/CallToAction.module.scss"

const CallToAction = ({ slice }) => {
  const { primary } = slice
  const bgImg = `background: url( ${primary.cta_image.url} ) center center / cover no-repeat;`

  return (
    <article className={cx(styles.cta, "prismicCallToAction")}>
      <LazyLoad>
        <div className={cx("lazy", styles.contain)} data-style={bgImg}>
          <div className={styles.text}>
            <PrismicRichText field={primary.cta_text} components={htmlSerializer} />
          </div>
          <div className={styles.button}>
            <Button slice={slice} white />
          </div>
        </div>
      </LazyLoad>
    </article>
  )
}

export default CallToAction
