import FlashlightIcon from "../../../icons/FlashlightIcon"
import styles from "../styles/LoadingOrErrorMessage.module.scss"

const LoadingOrError = ({ children }) => {
  return (
    <section id="mm-project-search" className="client-user">
      <header id="mm-project-search-landing">
        <div className="cover-image" />
        <div className="heading">
          <div className="contain">
            <div id="project-search-info">
              <div className="project-search-icon">
                <FlashlightIcon />
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className={styles.message}>{children}</div>
    </section>
  )
}

export default LoadingOrError
