import SvgIcon from "../shared/components/SvgIcon"

const JoinIcon = () => {
  return (
    <SvgIcon svgClass="JoinIcon" fillRule="evenodd" fill="#21928e">
      <path
        className="icon-border"
        d="M15.0004823 28.9205441c-7.68931474 0-13.92295569-6.2326763-13.92295569-13.9210264 0-7.68835014 6.23364095-13.92102643 13.92295569-13.92102643 7.6883501 0 13.9200618 6.23267629 13.9200618 13.92102643 0 7.6883501-6.2317117 13.9210264-13.9200618 13.9210264m0-28.9205441C6.71500691 0 0 6.71500691 0 14.9995177 0 23.2840284 6.71500691 30 15.0004823 30 23.2840284 30 30 23.2840284 30 14.9995177 30 6.71500691 23.2840284 0 15.0004823 0"
      />
      <path d="M15.0882543 24.2101293l-6.91681033-3.1170259V7.85786638l6.91681033 3.11799572v13.2342672zm.7642241-14.0799569l-5.7016163-2.57004309h8.431681v4.00441809h1.0842672V6.47586207H7.0862069V21.4422414c0 .2143319.12510776.4073276.3200431.4955819l8.0010776 3.6058189c.359806.1619612.765194-.100862.765194-.494612V10.6247845c0-.2143319-.1251078-.4073276-.3200432-.4946121z" />
      <path d="M21.7574353 11.9188578l-.7681034-.7671337-3.9307112 3.9307112 3.9307112 3.9307113.7681034-.7671337-2.5457974-2.5457974h6.6452587v-1.0852371h-6.7965518z" />
      <path d="M18.5793103 21.6782328h1.0852371v-3.3265087h-1.0852371z" />
    </SvgIcon>
  )
}

export default JoinIcon
