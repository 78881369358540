import React from "react"
import LicenseVersion from "./LicenseVersion"

const SelectedLicenses = ({ licenseVersions, isCustom }) => {
  return (
    <div className="selected-licenses">
      <div className="contain">
        <h6 className="hSix">Selected Licenses</h6>
        <p>
          This is a highlight of your license only. For full terms, please read the Master Legal
          Agreement below.
        </p>
        <dl>
          {licenseVersions.length > 0 &&
            licenseVersions.map((lv) => (
              <LicenseVersion key={lv.id} licenseVersion={lv} isCustom={isCustom} />
            ))}
        </dl>
      </div>
    </div>
  )
}

export default SelectedLicenses
