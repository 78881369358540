import ArcSvgIcon from "../shared/components/ArcSvgIcon"

const ArcFreneticIcon = () => {
  return (
    <ArcSvgIcon svgClass="ArcFreneticIcon" fill="none" fillRule="nonzero">
      <path fill="#FFF" d="M0 0h200v40H0z" />
      <path fill="#21928E" d="M0 40h200V6l-31.8 13.2L142.5 8l-32.1 12L76.5 1.5 43 23.1 0 16.5z" />
    </ArcSvgIcon>
  )
}

export default ArcFreneticIcon
