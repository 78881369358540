export const playSong = (songVersion) => {
  mm.playerProxy.trigger("play", songVersion)
}

export const playMixtape = (song_versions, mixtapeId) => {
  mm.playerProxy.trigger("play", {
    songVersions: song_versions,
    type: "mixtape",
  })
  // Switch out ajax for fetch:
  fetch(`/mixtapes/${mixtapeId}/play`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok")
      }
    })
    .catch((error) => {
      console.error("Error:", error)
    })
}
