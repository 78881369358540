import ArcSvgIcon from "../shared/components/ArcSvgIcon"

const ArcAscendingIcon = () => {
  return (
    <ArcSvgIcon svgClass="ArcAscendingIcon" fill="none" fillRule="nonzero">
      <path fill="#FFF" d="M200 0H0v40h200" />
      <path fill="#21928E" d="M200 2.2L0 36.2V40h200" />
    </ArcSvgIcon>
  )
}

export default ArcAscendingIcon
