import SvgIcon from "../shared/components/SvgIcon"

const FlashlightIcon = () => {
  return (
    <SvgIcon svgClass="FlashlightIcon" fillRule="evenodd">
      <path
        className="icon-border"
        d="M15.0004823 28.9205441c-7.68931474 0-13.92199103-6.2326763-13.92199103-13.9210264 0-7.68835014 6.23267629-13.92102643 13.92199103-13.92102643 7.6883501 0 13.9210264 6.23267629 13.9210264 13.92102643 0 7.6883501-6.2326763 13.9210264-13.9210264 13.9210264m0-28.9205441C6.71500691 0 0 6.71500691 0 14.9995177 0 23.2840284 6.71500691 30 15.0004823 30 23.2840284 30 30 23.2840284 30 14.9995177 30 6.71500691 23.2840284 0 15.0004823 0"
      />
      <path d="M12.4034483 7.67392241h1.085237v-2.41875h-1.085237zM17.037931 7.67392241h1.0842673v-2.41875H17.037931zM14.7206897 7.67392241h1.0842672v-2.41875h-1.0842672zM10.81875 10.5801724h8.6935345v-.90872843H10.81875v.90872843zm6.3135776 3.7600216l-.0193966.0310344c-.0514008.084375-.078556.1813578-.078556.28125l-.0038793 8.9844828-.0009698.033944c-.0184268.5120689-.4373923.9203664-.9523707.9203664h-1.8223061l-.0349138-.0009699c-.5110991-.0184267-.9203663-.438362-.9203663-.9533405l.0339439-8.9709052-.0019396-.0368534c-.0048492-.0998923-.0407328-.196875-.0979526-.2783405l-1.906681-2.6854526h7.6839439l-1.878556 2.6747845zm2.9220905-5.7539871h-9.7778017c-.29967674 0-.54213364.24342672-.54213364.54213362v1.99493538c0 .1125.03491379.2220905.09989224.3142241l2.4139009 3.3992457-.0329742 8.7982759c0 1.1269396.9135776 2.0405172 2.0395474 2.0405172h1.8223061c1.1259698 0 2.0376077-.9135776 2.0376077-2.0385776l.0038793-8.8137931 2.3789871-3.3885776c.0649785-.0911638.0989224-.1997845.0989224-.3113146V9.12834052c0-.2987069-.2424569-.54213362-.5421336-.54213362zM10.0862069 7.67392241h1.0842672v-2.41875h-1.0842672zM19.3551724 7.67392241h1.0842673v-2.41875h-1.0842673z" />
      <path d="M15.1829741 16.6946121c.2162716 0 .3908406.1755388.3908406.3918103 0 .2162716-.174569.3908405-.3908406.3908405-.2172413 0-.3918103-.1745689-.3918103-.3908405 0-.2162715.1755388-.3918103.3918103-.3918103m0 1.8678879c.815625 0 1.4751078-.6614224 1.4751078-1.4760776 0-.815625-.6594828-1.4760776-1.4751078-1.4760776-.815625 0-1.4760775.6604526-1.4760775 1.4760776 0 .8146552.6604525 1.4760776 1.4760775 1.4760776" />
    </SvgIcon>
  )
}

export default FlashlightIcon
