import cx from "classnames"
import styles from "../styles/StatusMessage.module.scss"

const StatusMessage = ({ status }) => {
  if (status && status.msg) {
    return (
      <div
        className={cx(
          styles.responseMsg,
          { [styles.error]: status.error },
          { [styles.success]: status.success }
        )}
      >
        {status.msg}
      </div>
    )
  } else {
    return null
  }
}

export default StatusMessage
