import { useEffect, useState } from "react"
import { useRange } from "react-instantsearch-hooks-web"
import cx from "classnames"
import { useWorkbenchStore } from "../../store/store"
import { MAX_DURATION } from "./FilterTransforms"
import CircleRange from "./CircleRange"

const WorkbenchFilterLength = ({ id }) => {
  const currentFilter = useWorkbenchStore((state) => state.currentFilter)
  const lengthMin = useWorkbenchStore((state) => state.lengthMin)
  const lengthMax = useWorkbenchStore((state) => state.lengthMax)
  const setLengthMax = useWorkbenchStore((state) => state.setLengthMax)
  const setLengthMin = useWorkbenchStore((state) => state.setLengthMin)
  const [value, setValue] = useState({ min: 0, max: MAX_DURATION })
  const { refine } = useRange({ attribute: "duration", max: MAX_DURATION })

  const handleChange = (values) => {
    setValue(values)
  }

  const handleDragEnd = (minValue, maxValue) => {
    setLengthMin(minValue)
    setLengthMax(maxValue)
    refine([minValue, maxValue])
  }

  useEffect(() => {
    if (currentFilter === id) {
      setValue({ min: lengthMin || 0, max: lengthMax || MAX_DURATION })
    }
  }, [currentFilter])

  useEffect(() => {
    // MIN val has been set to undefined or 0 but MAX is still a defined number
    if (!(lengthMin > 0) && lengthMax) {
      setValue({ min: 0, max: lengthMax })
    }
    // MAX val has been set to undefined but MIN is still a defined number > 0
    else if ((!lengthMax || lengthMax === MAX_DURATION) && lengthMin > 0) {
      setValue({ min: value.min, max: MAX_DURATION })
    }

    // Both Values have been set to undefined in workbench state so display them at their respective extremes
    if (!(lengthMin > 0) && (lengthMax == undefined || lengthMax === MAX_DURATION)) {
      setValue({ min: 0, max: MAX_DURATION })
    }

    refine([lengthMin, lengthMax])
  }, [lengthMin, lengthMax])

  return (
    <>
      <fieldset id={id} className={cx("mm-filter", currentFilter === id ? "current" : "")}>
        <p>Drag the sliders to the desired length of your song.</p>
        <div className="mm-filter-length">
          <em className="min">min</em>
          <em className="max">max</em>
          <CircleRange
            attribute="duration"
            min={0}
            max={MAX_DURATION}
            step={1000}
            value={value}
            onChange={handleChange}
            onChangeEnd={handleDragEnd}
          />
        </div>
      </fieldset>
    </>
  )
}

export default WorkbenchFilterLength
