import cx from "classnames"
import ArcAscendingIcon from "../../icons/ArcAscendingIcon"
import ArcDescendingIcon from "../../icons/ArcDescendingIcon"
import ArcFreneticIcon from "../../icons/ArcFreneticIcon"
import ArcMiddleCrescendoIcon from "../../icons/ArcMiddleCrescendoIcon"
import ArcMissingIcon from "../../icons/ArcMissingIcon"
import ArcMultipleCrescendosIcon from "../../icons/ArcMultipleCrescendosIcon"
import ArcSteadyIcon from "../../icons/ArcSteadyIcon"

const SongVersionArc = ({ arc }) => {
  const setArcIcon = (arc) => {
    switch (arc) {
      case "Ascending":
        return <ArcAscendingIcon />
      case "Descending":
        return <ArcDescendingIcon />
      case "Frenetic":
        return <ArcFreneticIcon />
      case "Middle Crescendo":
        return <ArcMiddleCrescendoIcon />
      case "Multiple Crescendos":
        return <ArcMultipleCrescendosIcon />
      case null:
        return <ArcMissingIcon />
      default:
        return <ArcSteadyIcon />
    }
  }

  const arcIcon = setArcIcon(arc)

  return (
    <li
      className={
        arc
          ? cx("arc", "desktop", { [arc.toLowerCase().replace(" ", "-")]: arc })
          : cx("arc", "desktop", "missing")
      }
    >
      <span className={arc ? arc.toLowerCase().replace(" ", "-") : "missing"}>{arcIcon}</span>
    </li>
  )
}

export default SongVersionArc
