const SortIcon = () => {
  return (
    <svg
      className="sort-icon"
      width="30px"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -1000 960 960"
      data-value="sort"
    >
      <path
        data-value="sort"
        d="M120-240v-80h240v80H120Zm0-200v-80h480v80H120Zm0-200v-80h720v80H120Z"
      />
    </svg>
  )
}

export default SortIcon
