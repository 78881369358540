import { useState, useContext, Fragment } from "react"
import { useMutation } from "@apollo/client"
import { DragDropContext, Droppable } from "react-beautiful-dnd"
import { DraggableItems, getListStyle, move, reorder } from "../utils/reactDnd"
import AddDirection from "./AddDirection"
import MissingContentMessage from "./MissingContentMessage"
import SearchRoundHeader from "./SearchRoundHeader"
import SearchRoundSongVersionItem from "./SearchRoundSongVersionItem"
import { ProjectSearchContext } from "./ProjectSearch"
import { SORT_SEARCH_ROUND_SONG_VERSION } from "../../../graphql/mutations/searchRoundSongVersions"

const SelectedSearchRound = () => {
  const { internalUser, selectedRound, calculateUpdatedSearchRound } =
    useContext(ProjectSearchContext)
  const { directions, id, searchRoundSongVersions, songVersionsPresent } = selectedRound[0]
  const [disableSort, setDisableSort] = useState(!internalUser)
  const [sortSearchRoundSongVersion] = useMutation(SORT_SEARCH_ROUND_SONG_VERSION)

  const getList = (id) => {
    return id === "songVersions"
      ? searchRoundSongVersions
      : directions.filter((dir) => dir.id === id)[0].searchRoundSongVersions
  }

  const onDragEnd = ({ source, destination, draggableId }) => {
    let directionId
    let updatedSort

    if (!destination) return // dropped outside the list

    if (source.droppableId === destination.droppableId) {
      const items = reorder(getList(source.droppableId), source.index, destination.index)
      updatedSort = { [source.droppableId]: items }
    } else {
      updatedSort = move(
        getList(source.droppableId),
        getList(destination.droppableId),
        source,
        destination
      )
      directionId = destination.droppableId
    }

    const updatedSearchRound = calculateUpdatedSearchRound(updatedSort)
    postSortOrder(destination.index, draggableId, directionId, updatedSearchRound)
  }

  const postSortOrder = (position, srsvId, directionId = null, updatedSearchRound) => {
    setDisableSort(true)
    sortSearchRoundSongVersion({
      variables: {
        id: srsvId,
        position: position,
        directionId: directionId,
      },
      optimisticResponse: {
        sortSearchRoundSongVersion: {
          searchRound: updatedSearchRound,
        },
      },
    })
      .then(() => setDisableSort(false))
      .catch((error) => {
        console.error(error)
      })
  }

  if (selectedRound.length > 1) {
    return <AllRounds />
  } else if (songVersionsPresent || directions?.length) {
    return <Round onDragEnd={onDragEnd} disableSort={disableSort} />
  } else {
    return (
      <Fragment>
        <SearchRoundHeader key={id} searchRound={selectedRound[0]}>
          <MissingContentMessage message="There are no songs in this round yet." />
        </SearchRoundHeader>
        {internalUser && <AddDirection searchRoundId={id} />}
      </Fragment>
    )
  }
}

const AllRounds = () => {
  const { selectedRound } = useContext(ProjectSearchContext)

  return selectedRound.map((round) => {
    return (
      <SearchRoundHeader key={round.id} searchRound={round} roundDivider>
        {!round.songVersionsPresent ? (
          <MissingContentMessage message="There are no songs in this round yet." />
        ) : (
          round.searchRoundSongVersions.map((srsv, index) => (
            <SearchRoundSongVersionItem
              key={srsv.id}
              searchRoundSongVersion={srsv}
              searchRoundId={round.id}
              position={index + 1}
            />
          ))
        )}
      </SearchRoundHeader>
    )
  })
}

const Round = ({ onDragEnd, disableSort }) => {
  const { internalUser, selectedRound } = useContext(ProjectSearchContext)
  const round = selectedRound[0]
  // If no directions exist, create a temporary direction with the songVersions stored at the root of the Round object
  const directions =
    round.directions.length === 0
      ? [
          {
            id: "songVersions",
            searchRoundSongVersions: round.searchRoundSongVersions,
          },
        ]
      : round.directions

  return (
    <Fragment>
      <DragDropContext onDragEnd={onDragEnd}>
        {directions.map((direction) => (
          <SearchRoundHeader
            key={direction.id}
            searchRound={round}
            direction={direction.id === "songVersions" ? null : direction}
          >
            <Droppable key={direction.id} droppableId={`${direction.id}`}>
              {(provided, snapshot) => (
                <div
                  className="direction"
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                  {direction.searchRoundSongVersions.length < 1 ? (
                    <div className="drag-message">
                      <div>&#62;&#62; Drag Song Here &#60;&#60;</div>
                    </div>
                  ) : (
                    <DraggableItems
                      searchRoundSongVersions={direction.searchRoundSongVersions}
                      disableSort={disableSort}
                      round={round}
                    />
                  )}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </SearchRoundHeader>
        ))}
      </DragDropContext>
      {internalUser && <AddDirection searchRoundId={round.id} />}
    </Fragment>
  )
}

export default SelectedSearchRound
