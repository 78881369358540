import { gql } from "@apollo/client"

export const CREATE_SEARCH_ROUND = gql`
  mutation createSearchRound($id: ID!) {
    createSearchRound(id: $id) {
      projectSearch {
        id
        searchRounds {
          id
          name
          projectSearchId
          hidden
          showHighResDownload
          songVersionsPresent
          directions {
            id
            searchRoundSongVersions {
              id
            }
          }
          searchRoundSongVersions {
            id
          }
        }
      }
    }
  }
`

export const DELETE_SEARCH_ROUND = gql`
  mutation deleteSearchRound($id: ID!) {
    deleteSearchRound(id: $id) {
      projectSearch {
        id
        searchRounds {
          id
        }
      }
    }
  }
`

export const TOGGLE_HIDE_SEARCH_ROUND = gql`
  mutation toggleHideSearchRound($id: ID!, $hidden: Boolean!) {
    toggleHideSearchRound(id: $id, hidden: $hidden) {
      searchRound {
        id
        hidden
      }
    }
  }
`

export const TOGGLE_HIGH_RES_DOWNLOAD = gql`
  mutation toggleHighResDownload($id: ID!, $show: Boolean!) {
    toggleHighResDownload(id: $id, show: $show) {
      searchRound {
        id
        showHighResDownload
      }
    }
  }
`
