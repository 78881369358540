import { PrismicPageParser } from "../../shared/utils/prismicPageParser"
import cx from "classnames"
import styles from "./PurposeContainer.module.scss"

const PurposeContainer = () => {
  const data = JSON.parse(window.prismic_data)
  const pageId = window.pageId

  return (
    <section id="mm-purpose" className={cx(styles.purpose, "pageContainer")} aria-label="Purpose">
      <PrismicPageParser pageData={data} pageId={pageId} />
    </section>
  )
}

export default PurposeContainer
