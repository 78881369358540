import { Fragment } from "react"
import cx from "classnames"
import { Form, FieldArray, Field, ErrorMessage } from "formik"
import ScrollToError from "../../../shared/components/ScrollToError"
import TextField from "../../../shared/components/TextField"
import TextAreaField from "../../../shared/components/TextAreaField"
import { showErrorBorder } from "../../../shared/utils/form"
import { yesNoTFOptions, fullPartTimeOptions } from "../../../shared/data/radioOptions"
import formStyles from "../../../shared/styles/form.module.scss"

const SubmissionsForm = (props) => {
  const { values, errors, status, touched, isSubmitting, responseRef } = props

  const handleRadio = (e, val, arrayHelpers) => {
    if (e.currentTarget.checked) {
      arrayHelpers.pop()
      arrayHelpers.push(val)
    }
  }

  return (
    <Fragment>
      <div id="submissions-response-container" ref={responseRef}>
        {status && status.message && (
          <div className={`response-message ${status.success ? "success" : "error"}`}>
            <div>
              <p>{status.message}</p>
            </div>
          </div>
        )}
      </div>

      <article className="mm-react-form submissions-form">
        <div className="contain">
          <Form id="new_submission">
            <ScrollToError />
            <fieldset className="form-inputs">
              <ol>
                <TextField
                  label="First Name*"
                  fieldName="first_name"
                  placeholder="First Name"
                  inputClass={cx({ invalid: showErrorBorder(errors, touched, "first_name") })}
                />
                <TextField
                  label="Last Name*"
                  fieldName="last_name"
                  placeholder="Last Name"
                  inputClass={cx({ invalid: showErrorBorder(errors, touched, "last_name") })}
                />
                <TextField
                  label="Email*"
                  fieldName="email"
                  placeholder="your@email.com"
                  inputClass={cx({ invalid: showErrorBorder(errors, touched, "email") })}
                />
                <TextField
                  label="Phone*"
                  fieldName="phone"
                  placeholder="555-555-55555"
                  type="tel"
                  inputClass={cx({ invalid: showErrorBorder(errors, touched, "phone") })}
                />
                <TextField
                  label="Location*"
                  fieldName="location"
                  placeholder="Where are you?"
                  inputClass={cx({ invalid: showErrorBorder(errors, touched, "location") })}
                />
                <FieldArray
                  name="own_rights"
                  render={(arrayHelpers) => (
                    <div>
                      <label>
                        Are you in a band with recorded material that you control the rights for?*
                      </label>
                      {yesNoTFOptions.map((option, ind) => (
                        <div
                          className="radio-wrapper"
                          aria-label="control the rights to music radio button"
                          key={ind}
                        >
                          <Field
                            type="radio"
                            name="controlOption"
                            value={option.value}
                            aria-checked="false"
                            aria-label={`radio button value: ${option.label}`}
                            id={`ownRights${ind}`}
                            onChange={(e) => handleRadio(e, option.value, arrayHelpers)}
                            checked={values.own_rights.includes(option.value)}
                          />
                          <span className="radio-label" htmlFor="radio">
                            {option.label}
                          </span>
                        </div>
                      ))}
                      <ErrorMessage
                        name="own_rights"
                        className={cx(formStyles.errorMsg, "error-msg")}
                        component="div"
                      />
                    </div>
                  )}
                />
                <TextField
                  label="Band/Artist Moniker*"
                  fieldName="artist_name"
                  placeholder="My Band Name"
                  inputClass={cx({ invalid: showErrorBorder(errors, touched, "artist_name") })}
                />
                <TextField
                  label="Streaming Link -- Full Mixes*"
                  fieldName="full_link"
                  placeholder="https://www.soundcloud.com/BANDNAME"
                  inputClass={cx({ invalid: showErrorBorder(errors, touched, "full_link") })}
                />
                <TextField
                  label="Streaming Link -- Instrumental Mixes"
                  fieldName="instrumental_link"
                  placeholder="https://BANDNAME.bandcamp.com"
                />
                <TextAreaField
                  label="Bio/Notes/Fun Facts*"
                  fieldName="bio"
                  cols="25"
                  rows="10"
                  placeholder="Type away!"
                  inputClass={cx({ invalid: showErrorBorder(errors, touched, "bio") })}
                />
                <FieldArray
                  name="self_record"
                  render={(arrayHelpers) => (
                    <div>
                      <label>Do you self-record at a professional level?*</label>
                      {yesNoTFOptions.map((option, ind) => (
                        <div
                          className="radio-wrapper"
                          aria-label="self-record at professional level radio button"
                          key={ind}
                        >
                          <Field
                            type="radio"
                            name="recordOption"
                            value={option.value}
                            aria-checked="false"
                            aria-label={`radio button value: ${option.label}`}
                            id={`selfRecord${ind}`}
                            onChange={(e) => handleRadio(e, option.value, arrayHelpers)}
                            checked={values.self_record.includes(option.value)}
                          />
                          <span className="radio-label" htmlFor="radio">
                            {option.label}
                          </span>
                        </div>
                      ))}
                      <ErrorMessage
                        name="self_record"
                        className={cx(formStyles.errorMsg, "error-msg")}
                        component="div"
                      />
                    </div>
                  )}
                />
                <TextField
                  label="If you have experience composing music to picture, share your video reel here."
                  fieldName="reel_link"
                  placeholder="https://cool.reel.com"
                />
                <TextField
                  label="What’s your favorite score on your reel and why?"
                  fieldName="favorite_scores"
                  placeholder="My favorite score is..."
                />
                <TextField
                  label="Strengths: Favorite/most comfortable genre(s) to compose in?*"
                  fieldName="strengths"
                  placeholder="Genre 1, Genre 2, ..."
                  inputClass={cx({ invalid: showErrorBorder(errors, touched, "strengths") })}
                />
                <FieldArray
                  name="availability"
                  render={(arrayHelpers) => (
                    <div>
                      <label>Availability*</label>
                      {fullPartTimeOptions.map((option, ind) => (
                        <div
                          className="radio-wrapper"
                          aria-label="availability radio button"
                          key={ind}
                        >
                          <Field
                            type="radio"
                            name="availabilityOption"
                            value={option.value}
                            aria-checked="false"
                            aria-label={`radio button value: ${option.label}`}
                            id={`availability${ind}`}
                            onChange={(e) => handleRadio(e, option.value, arrayHelpers)}
                            checked={values.availability.includes(option.value)}
                          />
                          <span className="radio-label" htmlFor="radio">
                            {option.label}
                          </span>
                        </div>
                      ))}
                      <ErrorMessage
                        name="availability"
                        className={cx(formStyles.errorMsg, "error-msg")}
                        component="div"
                      />
                    </div>
                  )}
                />
                <TextField
                  label="How did you hear about us?*"
                  fieldName="referral"
                  placeholder="My friend, Jill Scott"
                  inputClass={cx({ invalid: showErrorBorder(errors, touched, "referral") })}
                />
                <TextAreaField
                  label="Anything else you'd like to share about yourself?"
                  fieldName="notes"
                  cols="25"
                  rows="10"
                  placeholder="Type away!"
                />
              </ol>
            </fieldset>
            <div className="spin-target">
              <button type="submit" className="box-button-black" disabled={isSubmitting}>
                Send Submission
              </button>
            </div>
          </Form>
        </div>
      </article>
    </Fragment>
  )
}

export default SubmissionsForm
