import React, { Fragment } from "react"
import { formatMoney } from "accounting"

const LineItem = ({ song, artist, license, price }) => {
  return (
    <Fragment>
      <div className="order-cell song">
        <span className="truncated-content">{song}</span>
      </div>
      <div className="order-cell">
        <span className="truncated-content">{artist}</span>
      </div>
      <div className="order-cell">
        <span className="truncated-content">{license}</span>
      </div>
      <div className="order-cell order-cost">{formatMoney(price)}</div>
    </Fragment>
  )
}

export default LineItem
