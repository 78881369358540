import { useState, useEffect } from "react"
import { useHits, useInstantSearch } from "react-instantsearch-hooks-web"
import groupHitsBySong from "../../shared/utils/browseBeta"
import BrowseBetaNoResults from "./BrowseBetaNoResults"
import BrowseBetaResults from "./BrowseBetaResults"
import spinnerStyles from "../../shared/styles/spinners.module.scss"

const BrowseBetaResultsContainer = ({ user_role }) => {
  const { hits } = useHits()
  const [groupedHits, setGroupedHits] = useState([])
  const { results, status } = useInstantSearch()

  useEffect(() => {
    const grouped = groupHitsBySong(hits)
    setGroupedHits(grouped)
    return () => {
      setGroupedHits([])
    }
  }, [hits])

  return (
    <article id="mm-song-versions-listing" aria-label="Songs listing">
      <div className="contain">
        <NoResultsBoundary fallback={<BrowseBetaNoResults />} results={results} status={status}>
          <BrowseBetaResults groupedHits={groupedHits} user_role={user_role} status={status} />
        </NoResultsBoundary>
      </div>

      {/* Beta: "Browse Featured Mixtapes" removed, this div just creates some space */}
      <div style={{ marginBottom: 50 + "px" }}></div>
    </article>
  )
}

const NoResultsBoundary = ({ children, fallback, results, status }) => {
  // The `__isArtificial` flag makes sure not to display the No Results message
  // when no hits have been returned yet.
  if (!results.__isArtificial && results.nbHits === 0) {
    return (
      <>
        {fallback}
        <div hidden>{children}</div>
      </>
    )
  }

  return (
    <>
      <LoadingIndicator status={status} />
      {children}
    </>
  )
}

const LoadingIndicator = ({ status }) => {
  if (status === "stalled") {
    return (
      <div className="loading">
        <div className={spinnerStyles.largeSpinner} />
      </div>
    )
  }
  return null
}

export default BrowseBetaResultsContainer
