export const triggerLogin = () => {
  mm.drawerLoginPage.notify(
    "You must be logged in to perform this action. Sign in or register below."
  )
  mm.drawer.trigger("login")
}

export const addToCart = (svData) => {
  mm.drawer.trigger("cart:add", svData)
}

export const addToProjectSearch = (svId, songId) => {
  mm.drawer.trigger("projectSearch:add", { id: svId, song_id: songId })
}

export const addToMixtape = (songVersionId) => {
  mm.drawer.trigger("mixtape:add", songVersionId)
}

export const customizeSongRequest = (songVersionId) => {
  mm.drawer.trigger("customize", songVersionId)
}

export const shareSongVersion = (id, display_name, artist_name) => {
  var data = {
    type: "song version",
    id: id,
    segment: "browse",
    api: "song_versions",
    songVersion: {
      id: id,
      name: display_name,
      artist: artist_name,
    },
  }
  mm.drawerProxy.trigger("share:songVersion", data)
}
export const shareSongMixtape = (slug, id) => {
  mm.drawerProxy.trigger("share:mixtape", {
    type: "mixtape",
    id: slug,
    internalID: id,
    segment: "mixtapes",
    api: "mixtapes",
  })
}

export const triggerNewsletterSignup = () => {
  mm.drawerProxy.trigger("subscribe")
}
