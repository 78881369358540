import ArcSvgIcon from "../shared/components/ArcSvgIcon"

const ArcDescendingIcon = () => {
  return (
    <ArcSvgIcon svgClass="ArcDescendingIcon" fill="none" fillRule="nonzero">
      <path fill="#FFF" d="M0 0h200v40H0z" />
      <path fill="#21928E" d="M200 36.2L0 2.2V40h200z" />
    </ArcSvgIcon>
  )
}

export default ArcDescendingIcon
