import { PrismicRichText } from "@prismicio/react"

const BasicRichText = (props) => {
  return (
    <div className={props.className}>
      <PrismicRichText field={props.rich_text} components={props.htmlSerializer} />
    </div>
  )
}

export default BasicRichText
