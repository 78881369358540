import Mixtape from "./Mixtape"
import styles from "../styles/Mixtapes.module.scss"

const Mixtapes = ({ mixtapes }) => {
  return (
    <div className={styles.mixtapes}>
      {mixtapes.map((mixtape) => {
        return (
          <Mixtape
            key={mixtape.slug}
            slug={mixtape.slug}
            name={mixtape.name}
            id={mixtape.id}
            imageUrl={mixtape.imageUrl}
            featuredAlbum={mixtape.featuredAlbum}
          />
        )
      })}
    </div>
  )
}

export default Mixtapes
