import SvgIcon from "../shared/components/SvgIcon"

const CassetteReelIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 43 36" height="36px">
      <g fill="none" fillRule="evenodd">
        <path d="M17.1386 17.8837c0 4.507-3.653 8.16-8.16 8.16s-8.16-3.653-8.16-8.16 3.653-8.16 8.16-8.16 8.16 3.653 8.16 8.16zM41.768 17.8837c0 4.507-3.653 8.16-8.16 8.16s-8.16-3.653-8.16-8.16 3.653-8.16 8.16-8.16 8.16 3.653 8.16 8.16zM8.9789 26.0439h24.629M.8188 34.705h40.949M.8188 1.0624h40.949" />
      </g>
    </svg>
  )
}

export default CassetteReelIcon
