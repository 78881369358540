import { multiSelectOptionMarkup } from "../utils/reactResponsiveSelectHelpers"

const genreSelectOptions = [
  { text: "All", value: "", markup: multiSelectOptionMarkup("All") },
  { text: "Ambient", value: "Ambient", markup: multiSelectOptionMarkup("Ambient") },
  { text: "Beats", value: "Beats", markup: multiSelectOptionMarkup("Beats") },
  { text: "Blues & Jazz", value: "Blues & Jazz", markup: multiSelectOptionMarkup("Blues & Jazz") },
  { text: "Country", value: "Country", markup: multiSelectOptionMarkup("Country") },
  { text: "Dance", value: "Dance", markup: multiSelectOptionMarkup("Dance") },
  { text: "Electronic", value: "Electronic", markup: multiSelectOptionMarkup("Electronic") },
  { text: "Folk", value: "Folk", markup: multiSelectOptionMarkup("Folk") },
  {
    text: "Hip Hop & Rap",
    value: "Hip Hop & Rap",
    markup: multiSelectOptionMarkup("Hip Hop & Rap"),
  },
  { text: "Orchestral", value: "Orchestral", markup: multiSelectOptionMarkup("Orchestral") },
  { text: "Pop", value: "Pop", markup: multiSelectOptionMarkup("Pop") },
  { text: "Rock", value: "Rock", markup: multiSelectOptionMarkup("Rock") },
  { text: "RnB & Soul", value: "RnB & Soul", markup: multiSelectOptionMarkup("RnB & Soul") },
  { text: "Vintage", value: "Vintage", markup: multiSelectOptionMarkup("Vintage") },
  { text: "World", value: "World", markup: multiSelectOptionMarkup("World") },
]

export default genreSelectOptions
