import { history } from "instantsearch.js/es/lib/routers"
import { resultsFilters, MAX_DURATION } from "../../pages/browse/FilterTransforms"

const vocalItems = ["is_instrumental", "is_vocals", "is_female_vocals", "is_male_vocals"]

export const algoliaRouting = {
  router: history(),
  stateMapping: {
    stateToRoute(uiState) {
      const indexUiState = uiState[getIndexName()]
      return {
        query: indexUiState?.query,
        vocals: toggledVocalItems(indexUiState?.toggle),
        moods: indexUiState?.refinementList?.mood_list?.join("~"),
        energies: indexUiState?.refinementList?.energy?.join("~"),
        arcs: indexUiState?.refinementList?.arc?.join("~"),
        length: selectedDuration(indexUiState?.range?.duration),
        instruments: indexUiState?.refinementList?.instrument_list?.join("~"),
        genres: indexUiState?.refinementList?.genre_list?.join("~"),
        options: toggledOptionItems(indexUiState?.toggle),
        filter: selectedResultsFilter(indexUiState?.configure?.restrictSearchableAttributes),
        page: indexUiState?.page,
        flags: indexUiState?.refinementList?.internal_flags?.join("~"),
      }
    },
    routeToState(routeState) {
      return {
        [getIndexName()]: {
          query: routeState.query,
          refinementList: {
            instrument_list: routeState.instruments?.split("~"),
            genre_list: routeState.genres?.split("~"),
            mood_list: routeState.moods?.split("~"),
            arc: routeState.arcs?.split("~"),
            energy: routeState.energies?.split("~"),
            internal_flags: routeState.flags?.split("~"),
          },
          range: { duration: routeState.length?.replace("-", ":") },
          toggle: combineToggledItems(routeState),
          page: routeState.page,
        },
      }
    },
  },
}

const getIndexName = () => {
  const el = document.getElementById("mm-browse-beta-container")
  const indexName = el && el.getAttribute("data-index-name")
  return indexName
}

const selectedDuration = (duration) => {
  if (!duration) return
  const range = duration.split(":")
  const min = range[0] === "0" ? "" : range[0]
  const max = range[1] === MAX_DURATION.toString() ? "" : range[1]
  if (!min && !max) return
  return `${min}-${max}`
}

const selectedResultsFilter = (restrictedList) => {
  if (!restrictedList || !restrictedList.length) return

  const matchingResultsFilter = resultsFilters.find(
    (item) => item.restrictedAttributes === restrictedList
  )
  return matchingResultsFilter ? matchingResultsFilter.name : undefined
}

const toggledVocalItems = (toggledList) => {
  if (!toggledList) return

  const toggledItems = Object.keys(toggledList)
    .filter((item) => vocalItems.includes(item))
    .map((item) => item.replace("is_", ""))
    .join("~")
  return toggledItems.length ? toggledItems : undefined
}

const toggledOptionItems = (toggledList) => {
  if (!toggledList) return

  const toggledItems = Object.keys(toggledList)
    .filter((item) => !vocalItems.includes(item))
    .map((item) => item.replace("is_", ""))
    .join("~")
  return toggledItems.length ? toggledItems : undefined
}

const combineToggledItems = (routeState) => {
  if (!routeState.vocals && !routeState.options) return

  const hasToggledItems = [routeState.vocals, routeState.options].filter(
    (item) => item && item.length
  )
  const toggledItems = [...hasToggledItems].join("~")
  return toggledItems.split("~").reduce((acc, cur) => ({ ...acc, ["is_" + cur]: true }), {})
}
