import { PrismicPageParser } from "../../shared/utils/prismicPageParser"
import cx from "classnames"
import styles from "./CareersContainer.module.scss"

const CareersContainer = () => {
  const data = JSON.parse(window.prismic_data)
  const pageId = window.pageId

  return (
    <section
      id="mm-careers"
      className={cx(styles.careers, "pageContainer")}
      aria-label="Careers page"
    >
      <PrismicPageParser pageData={data} pageId={pageId} />
    </section>
  )
}

export default CareersContainer
