export const groupBySong = (songs) => {
  const normalizedSongs = []
  songs.forEach((song) => {
    const newSong = {
      songId: song.id,
      rank: song.rank,
      artist: {
        id: song.artist.id,
        name: song.artist.name,
        slug: song.artist.slug,
        catalog: song.artist.catalog.name,
      },
      exclusive: song.exclusive,
      forMarmoset: song.for_marmoset,
      hasPastLicenses: song.has_past_licenses,
      name: song.title,
      songVersionsCount: song.song_versions_count,
      songVersions: normalizeSongVersions(song.song_versions),
    }
    normalizedSongs.push(newSong)
  })
  return normalizedSongs
}

const normalizeSongVersions = (song_versions) => {
  const normalizedVersions = []
  song_versions.forEach((sv) => {
    const newSv = {
      id: sv.id,
      arc: sv.arc,
      archived: sv.archived || false,
      artistCollab: sv.is_artist_collab,
      availableForClick: sv.is_available_for_click,
      customizable: sv.customizable,
      displayName: sv.instrumental ? sv.title + " (Instrumental)" : sv.title,
      energy: sv.energy,
      fervor: sv.fervor,
      hasStems: sv.has_stems,
      isNew: Date.now() / 1000 - sv.created_at < 7776000, // created_at epoch value is less than 90 days ago in seconds
      joinedGenreList: sv.joined_genre_list,
      length: sv.pretty_duration,
      lyrics: sv.lyrics,
      notForTvFilm: sv.is_not_for_tv_film,
      playSerializer: sv.play_serializer,
      playerArt: sv.image_url.url,
      position: sv.position,
      preRelease: sv.is_pre_release,
      songVersionFile: sv.song_version_file.url || sv.song_version_file,
      title: sv.title,
      vintage: sv.is_vintage,
      rankingInfo: sv._rankingInfo,
      rollingRank: sv.rolling_rank,
      collaborator: sv.collaborator || null,
    }

    if (sv.position == 1) normalizedVersions.unshift(newSv)
    else normalizedVersions.push(newSv)
  })

  return normalizedVersions
}

export default groupBySong
