const FilterVocalsMaleIcon = () => {
  return (
    <svg className="filterVocalsMaleIcon" viewBox="0 0 120 268">
      <g fill="#21928e" fillRule="nonzero">
        <path d="M73 4.5c0 3.3.2 3.5 3.2 3.5h3.3l-3.2 3.2-3.2 3.2-4.3-2C51.6 4.7 32.6 20.6 37 39.1c1 3.9 2.5 6.6 5.8 10.1C58.2 65.6 85.3 53 82.6 30.6c-.3-2.6-1.4-6.3-2.5-8.1l-1.9-3.3 2.7-2.6c3.5-3.3 4.1-3.3 4.1.4 0 2.8.3 3 3.5 3H92V1H73v3.5zm-4.1 15.8c9.4 6.3 9.3 19-.3 26.1-3.9 2.9-13.3 2.9-17.2 0-7.2-5.3-9.1-12.8-5.2-20.4 4.5-8.7 14.4-11.2 22.7-5.7zM49.9 89.3c-9.6 3.6-15.7 9.9-18.4 19-2.2 7.4-2.2 60.1 0 67.5 2.2 7.4 7 13.1 14 16.7 5.2 2.6 6.9 3 14.4 3 7.4 0 9.4-.4 14.3-2.8 8.9-4.4 14.2-12.2 15.5-22.7l.6-5H63.9l.3-4.8.3-4.7 12.8-.3 12.7-.3V146H64v-8h26v-10H64v-8h26v-3.3c0-9.9-6.1-20.1-14.9-25-4.6-2.6-6.7-3.1-13.5-3.4-5.1-.2-9.4.2-11.7 1z" />
        <path d="M1 168.3c0 18.4 14.2 39.4 33 48.6 5 2.5 11.3 4.7 15.3 5.4l6.7 1.1-.2 18.1-.3 18-18.7.3-18.8.2v8h84v-8l-18.7-.2-18.8-.3-.3-18.3L64 223h2.3c4.1 0 13.8-3 20.5-6.3 8-3.9 18.2-13.1 23.4-21.1 4.2-6.5 8.2-18.1 9.1-26.4l.6-5.2H111v3.8c0 8.1-5.1 20.5-11.5 28.3-15 17.9-40.5 23.7-61.2 13.8-8.8-4.2-19.4-14.3-23.3-22.3-3.5-7-6-15.5-6-20.4 0-3.1-.2-3.2-4-3.2H1v4.3z" />
      </g>
    </svg>
  )
}

export default FilterVocalsMaleIcon
