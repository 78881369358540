const playerData = (selectedRound) => {
  let songVersions = []
  if (selectedRound.length > 1) {
    selectedRound.map((round) => {
      round.searchRoundSongVersions.map((srsv) => songVersions.push(srsv.songVersion))
    })
  } else if (selectedRound[0].songVersionsPresent) {
    songVersions = selectedRound[0].searchRoundSongVersions.map((srsv) => srsv.songVersion)
  }

  return songVersions
}

export default playerData
