const colorThemeMap = {
  Coral: "coral",
  Gold: "gold",
  Green: "green",
  Purple: "purple",
  Track_Red: "track-red",
  Track_Yellow: "track-yellow",
  Track_Blue: "track-blue",
  Track_Pink: "track-pink",
  Track_Orange: "track-orange",
  Track_Ocean_Blue: "track-ocean-blue",
  Track_Sunflower: "track-sunflower",
}

export default colorThemeMap
