export default function Fonts({ fonts }) {
  return (
    <div className="fonts">
      {fonts.map(({ style, text, proxima }, index) => {
        return (
          <p key={index} className={proxima ? "proxima" : ""} style={style}>
            {text}
          </p>
        )
      })}
    </div>
  )
}
