import { useEffect, useState, useRef, useContext } from "react"
import SongItemBeta from "../../shared/components/SongItemBeta"
import axios from "axios"
import cx from "classnames"
import groupBySong from "../../shared/utils/songNormalizer"
import LoadingIcon from "../../icons/LoadingIcon"

const SongsContainer = ({ passedQuery = null }) => {
  const el = document.getElementById("mm-similar-songs-container")
  const songVersionId = el && el.getAttribute("data-sv-id")
  var query = `/api/v1/song_versions/${songVersionId}/get_similar_songs`
  query = passedQuery || query
  const user_role = el && el.getAttribute("data-user-role")
  //Pass the query from the containing div
  const [songs, setSongs] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const currentBlanketDealModalRef = useRef(null)
  const thisContainerRef = useRef(null)
  const [expandedSongVersionId, setExpandedSongVersionId] = useState(false)

  const handleSetExpandedSongVersionId = (svid) => {
    setExpandedSongVersionId(svid)
  }

  const handleOnClick = (e) => {
    if (
      // Not clicked in actions menu OR NOT clickd within this song version container
      (!e.target.closest([".actions-menu", ".expand-actions-menu"]) ||
        !thisContainerRef.current.contains(e.target)) &&
      // AND Not clicked within the blanket deal modal currently open
      !(currentBlanketDealModalRef.current && currentBlanketDealModalRef.current.contains(e.target))
    ) {
      setExpandedSongVersionId(false)
    }
  }

  useEffect(() => {
    setIsLoading(true)
    axios
      .get(query)
      .then((response) => {
        const data = response.data
        const groupedSongs = groupBySong(data.related_songs)
        setSongs(groupedSongs)
      })
      .then((next) => {
        setIsLoading(false)
      })
      .catch((e) => {
        console.log(e)
        setIsLoading(false)
      })

    //For some reason these seem to break the SelectBlanketOrderModals
    document.body.addEventListener("click", handleOnClick)

    return () => {
      document.body.removeEventListener("click", handleOnClick)
    }
  }, [])

  return isLoading ? ( //If loading show loading icon
    <div ref={thisContainerRef}>
      <h5 className="hFive">You May Also Like</h5>
      <LoadingIcon />
    </div>
  ) : !songs[0] ? ( //Done loading but there are no songs
    <div ref={thisContainerRef}></div>
  ) : (
    //Done loading, also there are songs
    <div id="mm-song-versions" ref={thisContainerRef}>
      <h5 className="hFive">You May Also Like</h5>
      <article id="mm-song-versions-listing" aria-label="Songs listing">
        <div className="mm-song-versions-table" data-title="Search results">
          <ol className={cx("mm-song-versions-table-list", isLoading ? "hidden" : "")}>
            <li className="mm-song-versions-table-head">
              <ol>
                <li className="song-version">Song / Artist</li>
                <li className="genres desktop">Genres</li>
                <li className="arc desktop">Arc&trade;</li>
                <li className="energy desktop" data-touch>
                  Energy
                </li>
                <li className="length">Length</li>
              </ol>
            </li>
            {songs.map((song) => (
              <SongItemBeta
                key={song.songId}
                song={song}
                user_role={user_role}
                handleSetExpandedSongVersionId={handleSetExpandedSongVersionId}
                expandedSongVersionId={expandedSongVersionId}
                currentBlanketDealModalRef={currentBlanketDealModalRef}
              />
            ))}
          </ol>
        </div>
      </article>
    </div>
  )
}

export default SongsContainer
