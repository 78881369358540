import { useQuery } from "@apollo/client"
import LoadingOrErrorMessage from "./components/LoadingOrErrorMessage"
import { ProjectSearch } from "./components/ProjectSearch"
import GET_PROJECT_SEARCH from "../../graphql/queries/GetProjectSearch"

const ProjectSearchContainer = () => {
  const projectSearchSlug = document.location.pathname.split("/")[2]
  const { loading, error, data } = useQuery(GET_PROJECT_SEARCH, {
    variables: { slug: projectSearchSlug },
    fetchPolicy: "cache-and-network",
  })

  if (loading) {
    return (
      <LoadingOrErrorMessage>
        <h4 className="hFour">Loading ...</h4>
      </LoadingOrErrorMessage>
    )
  }

  if (error) {
    Sentry.captureException(error)

    return (
      <LoadingOrErrorMessage>
        <h4 className="hFour loading-error-header">Looks like there was an error :(</h4>
        {error.graphQLErrors.map(({ message }, i) => (
          <p className="loading-error-message" key={i}>
            {message}
          </p>
        ))}
      </LoadingOrErrorMessage>
    )
  }

  document.title = `${data.projectSearch.name} | Marmoset`

  return <ProjectSearch projectSearch={data.projectSearch} />
}

export default ProjectSearchContainer
