import { useState, useEffect, useRef, useContext } from "react"
import { useMutation } from "@apollo/client"
import { ProjectSearchContext } from "./ProjectSearch"
import { UPDATE_NAME_OR_DESCRIPTION } from "../../../graphql/mutations/projectSearches"

const NameDescription = () => {
  const projectSearchData = useContext(ProjectSearchContext)
  const [updateProjectSearch] = useMutation(UPDATE_NAME_OR_DESCRIPTION)
  const [description, setDescription] = useState(projectSearchData.description)
  const [descriptionHeight, setDescriptionHeight] = useState("48px")
  const [name, setName] = useState(projectSearchData.name)
  const descTextArea = useRef(null)
  const nameInput = useRef(null)
  const { id, internalUser } = projectSearchData

  useEffect(() => {
    setTextAreaHeight()
  }, [])

  const handleChange = (e) => {
    const val = e.target.value
    e.target.name === "name" ? setName(val) : setDescription(val)
    setTextAreaHeight()
  }

  const handleFocus = (e) => {
    if (e.target.contains(descTextArea.current)) descTextArea.current.focus()
    if (e.target.contains(nameInput.current)) nameInput.current.focus()
  }

  const handleKeyDown = (e) => {
    const key = e.keyCode || e.which
    const prop = e.target.name
    const val = prop === "name" ? name : description
    const data = { [prop]: val }

    if (key === 13 && !e.shiftKey && projectSearchData[prop] !== data[prop]) {
      e.preventDefault()
      postUpdate(data)
    }
  }

  const postUpdate = (data) => {
    const variables = { id, ...data }

    updateProjectSearch({ variables })
      .then(() => {
        descTextArea.current.blur()
        nameInput.current.blur()
      })
      .catch((error) => Sentry.captureException(error))
  }

  const setTextAreaHeight = () => {
    const desc = descTextArea.current
    if (desc !== null) setDescriptionHeight(desc.scrollHeight)
  }

  if (!internalUser) {
    return (
      <hgroup>
        <h1 id="project-search-name" className="hThree">
          <p style={{ minHeight: "55px" }}>{name}</p>
        </h1>
      </hgroup>
    )
  } else {
    return (
      <hgroup>
        <h1 id="project-search-name" className="hThree" onClick={handleFocus}>
          <input
            type="text"
            value={name}
            id="name"
            name="name"
            aria-label="name of project search"
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            ref={nameInput}
          />
        </h1>
        <div className="project-search-description" onClick={handleFocus}>
          <textarea
            id="desc"
            maxLength="1000"
            name="description"
            aria-label="description of project search"
            placeholder="Click to add a description..."
            style={{ height: descriptionHeight }}
            value={description}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            ref={descTextArea}
          />
        </div>
      </hgroup>
    )
  }
}

export default NameDescription
