import { gql } from "@apollo/client"

const GET_USER_ORDERS = gql`
  {
    account {
      orders {
        id
        allowClientToSelectTbd
        endClient
        projectName
        lineItems {
          id
          songVersionTitle
          songVersionId
          artistName
          clientNotes
        }
      }
    }
  }
`

export default GET_USER_ORDERS
