import { useContext } from "react"
import { Formik, Form } from "formik"
import { useMutation } from "@apollo/client"
import cx from "classnames"
import TextField from "../../../shared/components/TextField"
import { ProjectSearchContext } from "./ProjectSearch"
import { DELETE_DIRECTION, UPDATE_DIRECTION_NAME } from "../../../graphql/mutations/directions"
import GET_PROJECT_SEARCH from "../../../graphql/queries/GetProjectSearch"

const EditDirection = ({ direction }) => {
  const inputName = `directionName${direction.id}`
  const { internalUser, slug } = useContext(ProjectSearchContext)
  const [updateDirectionName] = useMutation(UPDATE_DIRECTION_NAME)
  const [deleteDirection] = useMutation(DELETE_DIRECTION, {
    refetchQueries: [
      {
        query: GET_PROJECT_SEARCH,
        variables: { slug },
      },
    ],
  })

  const handleSubmit = (params, formActions) => {
    const variables = {
      id: direction.id,
      name: params[inputName],
      searchRoundId: direction.searchRoundId,
    }

    updateDirectionName({ variables })
      .then(() => formActions.setSubmitting(false))
      .catch((error) => {
        Sentry.captureException(error)
        formActions.setSubmitting(false)
        formActions.setStatus({
          err: true,
          msg: error.graphQLErrors[0].message,
        })
      })
  }

  const handleDelete = (setStatus, setSubmitting) => {
    setSubmitting(true)
    const variables = {
      id: direction.id,
    }

    deleteDirection({ variables })
      .then(() => setSubmitting(false))
      .catch((error) => {
        Sentry.captureException(error)
        setSubmitting(false)
        setStatus({
          err: true,
          msg: "The round does not contain this direction.",
        })
      })
  }

  const initialValues = {
    [inputName]: direction.name,
  }

  return internalUser ? (
    <div className="direction-divider edit-actions">
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ isSubmitting, resetForm, setStatus, setSubmitting, status, values }) => (
          <Form>
            {status && status.err && (
              <div className="error-message">
                <span
                  className="close"
                  onClick={() => {
                    setSubmitting(false)
                    setStatus({ err: false, msg: null })
                  }}
                >
                  X
                </span>
                {status.msg}
              </div>
            )}
            <TextField
              fieldName={inputName}
              maxLength="50"
              placeholder="Direction name"
              wrapperClass="input-wrapper"
              dataCy="updateDirectionInput"
            />
            <div className="action-buttons">
              {direction.name !== values[inputName] ? (
                <div className="save-cancel-actions">
                  <button
                    type="submit"
                    className={cx("save-name", "edit-action", {
                      disabled: isSubmitting || values[inputName] === "",
                    })}
                    disabled={isSubmitting || values[inputName] === ""}
                    data-cy="updateDirectionSave"
                  >
                    {isSubmitting ? "Saving..." : "Save"}
                  </button>
                  <button
                    type="button"
                    className={cx("cancel-edit", "edit-action", {
                      disabled: isSubmitting,
                    })}
                    onClick={() => {
                      resetForm()
                    }}
                    disabled={isSubmitting}
                  >
                    Cancel
                  </button>
                </div>
              ) : (
                <button
                  type="button"
                  className={cx("delete-direction", "edit-action", {
                    disabled: isSubmitting,
                  })}
                  onClick={() => handleDelete(setStatus, setSubmitting)}
                  disabled={isSubmitting}
                  data-cy="deleteDirection"
                >
                  {isSubmitting ? "Deleting..." : "Delete Direction"}
                </button>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  ) : (
    <div className="direction-divider">{direction.name}</div>
  )
}

export default EditDirection
