import { useEffect } from "react"
import { PrismicRichText } from "@prismicio/react"
import cx from "classnames"
import htmlSerializer from "../../utils/prismicHTMLSerializer"
import TeamItems from "./TeamItems"
import styles from "../../styles/Team.module.scss"
import { filterItems } from "../../utils/helpers"

const Team = ({ slice }) => {
  const { primary, items } = slice

  let pos = 0

  // filtering based on url from careers page
  useEffect(() => {
    filterItems(decodeURI(window.location.hash).substr(1))
  })

  return (
    <div className={cx(styles.team, "prismicTeam")}>
      <div className={styles.contain}>
        <div className={styles.header}>
          <div className={styles.inner}>
            <PrismicRichText field={primary.team_text} components={htmlSerializer} />
          </div>
        </div>
        <div className={styles.teamItems}>
          {items.map((item, index) => {
            pos += 1
            if (pos === 7) {
              pos = 4
            }
            return (
              <TeamItems
                item={item}
                pos={pos}
                shouldUseModal={primary.should_use_modal}
                key={item.uid}
              />
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Team
