const FilterCustomizableNoIcon = () => {
  return (
    <svg className="filterCustomizableNoIcon" viewBox="0 0 144 146">
      <g fill="#21928e" fillRule="nonzero">
        <path d="M0 42v22h24V20H0v22zM40 42v22h24V20H40v22zM80 42v22h24V20H80v22zM120 42v22h24V20h-24v22zM0 102v22h24V80H0v22zM40 102v22h24V80H40v22zM80 102v22h24V80H80v22zM120 102v22h24V80h-24v22z" />
      </g>
    </svg>
  )
}

export default FilterCustomizableNoIcon
