import { useContext, useEffect, useState, memo, useRef } from "react"
import SongItemBeta from "../../shared/components/SongItemBeta"
import cx from "classnames"
import { ArtistPageContext } from "./ArtistPageContainer"

//TODO: refactor so that setting expanded ActionMenu doesn't rerender whole list.
const SongsList = memo(function SongsList() {
  const { songs, isLoading, user_role, initialLoad, currentBlanketDealModalRef } =
    useContext(ArtistPageContext)
  const [expandedSongVersionId, setExpandedSongVersionId] = useState(false)
  const discogTableRef = useRef(null)

  const handleOnClick = (e) => {
    if (
      // Not clicked in actions menu OR NOT clickd within this song version container
      (!e.target.closest([".actions-menu", ".expand-actions-menu"]) ||
        !discogTableRef.current.contains(e.target)) &&
      // AND Not clicked within the blanket deal modal currently open
      !(currentBlanketDealModalRef.current && currentBlanketDealModalRef.current.contains(e.target))
    ) {
      setExpandedSongVersionId(false)
    }
  }

  const handleSetExpandedSongVersionId = (svid) => {
    setExpandedSongVersionId(svid)
  }

  useEffect(() => {
    document.body.addEventListener("click", handleOnClick)

    return () => {
      document.body.removeEventListener("click", handleOnClick)
    }
  }, [])

  return (
    <div id="mm-song-versions" ref={discogTableRef}>
      <article id="mm-song-versions-listing" aria-label="Songs listing">
        <div className="mm-song-versions-table" data-title="Search results">
          <ol className={cx("mm-song-versions-table-list")}>
            <li className="mm-song-versions-table-head">
              <ol>
                <li className="song-version">Song / Artist</li>
                <li className="genres desktop">Genres</li>
                <li className="arc desktop">Arc&trade;</li>
                <li className="energy desktop" data-touch>
                  Energy
                </li>
                <li className="length">Length</li>
              </ol>
            </li>
            {songs.map((song) => (
              <SongItemBeta
                key={song.songId}
                song={song}
                user_role={user_role}
                handleSetExpandedSongVersionId={handleSetExpandedSongVersionId}
                expandedSongVersionId={expandedSongVersionId}
                currentBlanketDealModalRef={currentBlanketDealModalRef}
              />
            ))}
          </ol>
        </div>
      </article>
    </div>
  )
})

// This container is the result of refactoring multiple times over the course of the
// ticket and then ending up with this redundant container component. Refactor later
const ArtistDiscogContainer = () => {
  return <SongsList />
}

export default ArtistDiscogContainer
