import { createContext, useEffect, useState } from "react"
import SongsContainer from "./SongsContainer"
import { internalRoles } from "../../shared/utils/helpers"
import groupBySong from "../../shared/utils/songNormalizer"
import SongVersionsContainer from "./SongVersionsContainer"
import SongVersionPageActions from "./SongVersionPageActions"
import SongVersionDetails from "./SongVersionDetails"

export const SongPageContext = createContext({})

const SongVersionPageContainer = () => {
  const containingDiv = "mm-song-version-page-container"
  const el = document.getElementById("mm-song-version-page-container")
  const songVersionData = el && el.getAttribute("data-song-version")
  const songVersionId = el && el.getAttribute("data-song-version-id")
  const altSongVersionsData = el && el.getAttribute("data-song-versions")
  const user_role = el && el.getAttribute("data-user-role")
  const isInternal = internalRoles.includes(user_role)

  const [songVersion, setSongVersion] = useState({})
  const shouldShowTooltip =
    !songVersion.is_pre_release && (songVersion.song?.exclusive || songVersion.is_vintage)

  useEffect(() => {
    setSongVersion(JSON.parse(songVersionData))
  }, [])

  const contextValue = {
    user_role,
    isInternal,
    sv: songVersion,
    shouldShowTooltip,
    svPlaySerializer: songVersion.play_serializer,
  }

  return (
    <>
      <SongPageContext.Provider value={contextValue}>
        <SongVersionPageActions />
        <SongVersionDetails />
        {altSongVersionsData != "[]" && (
          <article className="alternatives" aria-label="Alternative versions of this song">
            <div className="contain">
              <h5 className="hFive">Alternative Versions of this Song</h5>
              <SongVersionsContainer container={containingDiv} />
            </div>
          </article>
        )}
      </SongPageContext.Provider>
    </>
  )
}

export default SongVersionPageContainer
