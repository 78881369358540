import { PrismicRichText } from "@prismicio/react"
import htmlSerializer from "../../../shared/utils/prismicHTMLSerializer"
import LazyLoad from "../../../shared/components/LazyLoad"
import HorizontalRule from "../../../shared/components/prismic/HorizontalRule"
import cx from "classnames"
import styles from "../styles/FeaturedContent.module.scss"

const FeaturedContent = ({ slice }) => {
  const { primary } = slice

  return (
    <article className={styles.featuredContent} aria-label="Featured content">
      <div className={styles.contain}>
        <div className={styles.content}>
          <div className={styles.richText}>
            <PrismicRichText field={primary.text} components={htmlSerializer} />
          </div>
          <LazyLoad>
            <div className={cx("lazyWrapper", styles.imgWrapper)}>
              <img
                data-src={primary.image.url}
                className={cx("lazy", styles.img)}
                alt={primary.image.alt}
              />
            </div>
          </LazyLoad>
        </div>
        <HorizontalRule />
      </div>
    </article>
  )
}

export default FeaturedContent
