export const groupHitsBySong = (hits) => {
  const groupedHits = []
  hits.forEach((hit) => {
    const newSongVersion = songVersionFromHit(hit)
    const matchingSong = groupedHits.find((groupedHit) => groupedHit.songId === hit.song_id)
    if (!matchingSong) {
      const newSong = {
        songId: hit.song_id,
        rank: hit.song_rank,
        artist: {
          id: hit.artist_id,
          name: hit.artist_name,
          slug: hit.artist_slug,
          catalog: hit.artist_catalog_name,
        },
        exclusive: hit.has_exclusivities,
        forMarmoset: hit.is_for_marmoset,
        hasPastLicenses: hit.has_past_licenses,
        name: hit.song_name,
        songVersionsCount: hit.song_song_versions.length,
        songVersions: [newSongVersion],
      }
      groupedHits.push(newSong)
    } else {
      // Slot hit into order within songVersions array of matching Song based on 'position' attribute
      const svLength = matchingSong.songVersions.length
      if (newSongVersion.position >= matchingSong.songVersions[svLength - 1].position) {
        matchingSong.songVersions.push(newSongVersion)
      } else {
        for (let i = 0; i < svLength; i++) {
          if (newSongVersion.position < matchingSong.songVersions[i].position) {
            matchingSong.songVersions.splice(i, 0, newSongVersion)
            break
          }
        }
      }
    }
  })
  return groupedHits
}

const songVersionFromHit = (hit) => {
  return {
    id: hit.id,
    arc: hit.arc,
    artistCollab: hit.is_artist_collab,
    availableForClick: hit.is_available_for_click,
    customizable: hit.is_customizable,
    displayName: hit.display_name,
    energy: hit.energy,
    fervor: hit.is_fervor,
    hasStems: hit.is_has_stems,
    isNew: Date.now() / 1000 - hit.created_at < 7776000, // created_at epoch value is less than 90 days ago in seconds
    joinedGenreList: hit.joined_genre_list,
    length: hit.pretty_duration,
    lyrics: hit.lyrics,
    notForTvFilm: hit.is_not_for_tv_film,
    playSerializer: hit.play_serializer,
    playerArt: hit.image_url,
    position: hit.position,
    preRelease: hit.is_pre_release,
    songVersionFile: hit.file_url,
    title: hit.title,
    vintage: hit.is_vintage,
    rankingInfo: hit._rankingInfo,
    rollingRank: hit.rolling_rank,
  }
}

export default groupHitsBySong
