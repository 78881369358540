import SvgIcon from "../shared/components/SvgIcon"

const VideoIcon = () => {
  return (
    <SvgIcon svgClass="VideoIcon" fillRule="evenodd">
      <path
        className="icon-border"
        d="M15.0002883 28.9205441c-7.6883501 0-13.92102639-6.2326763-13.92102639-13.9210264 0-7.68835014 6.23267629-13.92102643 13.92102639-13.92102643S28.9213147 7.31116756 28.9213147 14.9995177c0 7.6883501-6.2326763 13.9210264-13.9210264 13.9210264m0-28.9205441C6.71577755 0-.00019402 6.71500691-.00019402 14.9995177-.00019402 23.2840284 6.71577755 30 15.0002883 30c8.2835461 0 14.9995177-6.7159716 14.9995177-15.0004823C29.999806 6.71500691 23.2838344 0 15.0002883 0"
      />
      <path d="M23.555688 19.2038535l-4.2015703-4.2006001 4.2015703-4.2015702v8.4021703zm-15.90116323.7673614H17.9474503v-9.9368931H7.65452477v9.9368931zM23.7147869 9.10883161l-4.6827475 4.68080729V9.49202723l-.0009701-.03201381c-.0213426-.29103465-.2570806-.51028076-.5413245-.51028076H7.11126008l-.03201381.00097012c-.29103465.02037243-.51028075.25611049-.51028075.54132445V20.5144796l.00097011.0310437c.02134254.2910346.2561105.5102807.54132445.5102807H18.4897448l.0320138-.0009701c.2910347-.0203724.5102808-.2561105.5102808-.5403543v-4.2985818l4.6827475 4.6817774c.3414807.3414807.9264603.0999219.9264603-.3831956V9.49202723c0-.48408764-.5849796-.7256464-.9264603-.38319562z" />
    </SvgIcon>
  )
}

export default VideoIcon
