import React, { useState, useEffect, useRef, useContext } from "react"
import { useMutation } from "@apollo/client"
import cx from "classnames"
import { ProjectSearchContext } from "./ProjectSearch"
import DownloadIcon from "../../../icons/DownloadIcon"
import ShareIcon from "../../../icons/ShareIcon"
import TrashIcon from "../../../icons/TrashIcon"
import SimilarIcon from "../../../icons/SimilarIcon"
import { ADD_DIRECTION } from "../../../graphql/mutations/directions"
import GET_PROJECT_SEARCH from "../../../graphql/queries/GetProjectSearch"
import {
  REMOVE_SRSV,
  UPDATE_SRSV_NOTES,
  UPDATE_SRSV_WINNER,
} from "../../../graphql/mutations/searchRoundSongVersions"
import spinnerStyles from "../../../shared/styles/spinners.module.scss"

const SearchRoundSongVersionActions = (props) => {
  const {
    selectedRound,
    internalUser,
    openNote,
    setOpenNote,
    downloadMenu,
    setDownloadMenu,
    status,
    slug,
  } = useContext(ProjectSearchContext)
  const { searchRoundSongVersion, searchRoundId } = props
  const svCyaniteId = searchRoundSongVersion.songVersion.cyaniteId
  const [notes, setNotes] = useState(props.notes)
  const [saveSuccess, setSaveSuccess] = useState(false)
  const [winner, setWinner] = useState(props.winner)
  const notesRef = useRef(null)
  const [removeSearchRoundSongVersion] = useMutation(REMOVE_SRSV)
  const [updateSearchRoundSongVersionNotes] = useMutation(UPDATE_SRSV_NOTES)
  const [setSearchRoundSongVersionWinner] = useMutation(UPDATE_SRSV_WINNER)
  const { id } = searchRoundSongVersion
  const cssExpanded = openNote === id && "expanded"
  const downloadExpanded = downloadMenu === id && "expanded"
  const searchRoundSongVersionsIds = selectedRound[0].searchRoundSongVersions.map((sv) =>
    parseInt(sv.songVersion.id)
  )

  const [addDirection] = useMutation(ADD_DIRECTION, {
    refetchQueries: [
      {
        query: GET_PROJECT_SEARCH,
        variables: { slug },
      },
    ],
  })
  const [isLoadingSimilar, setIsLoadingSimilar] = useState(false)

  useEffect(() => {
    if (openNote) notesRef.current.focus()
  }, [openNote])

  const handleChange = (e) => {
    setNotes(e.target.value)
    setSaveSuccess(false)
  }

  const handleDownloadMenu = () => {
    downloadMenu ? setDownloadMenu(null) : setDownloadMenu(id)
  }

  const handleOpenNote = () => {
    openNote ? setOpenNote(null) : setOpenNote(id)
  }

  const handleRemove = () => {
    const variables = { id }

    removeSearchRoundSongVersion({ variables }).catch((error) => {
      Sentry.captureException(error)
    })
  }

  const handleSaveNotes = (e) => {
    e.preventDefault()
    const variables = { id, notes }
    updateSearchRoundSongVersionNotes({ variables })
      .then(() => {
        setSaveSuccess(true)
      })
      .catch((error) => {
        Sentry.captureException(error)
      })
  }

  const handleWinner = () => {
    const variables = {
      id,
      winner: !winner,
    }
    setWinner(!winner)
    setSearchRoundSongVersionWinner({ variables }).catch((error) => {
      Sentry.captureException(error)
    })
  }

  const createInitialDirection = async () => {
    const variables = {
      id: searchRoundId,
      name: "Initial Direction",
    }
    await addDirection({ variables })
  }

  const createSimilarDirection = async (songVersionIds) => {
    const songName = (name) => {
      return name.length < 33 ? name : name.substring(0, 30) + "..."
    }
    const variables = {
      id: searchRoundId,
      name: `Songs similar to ${songName(searchRoundSongVersion.songVersion.displayName)}`,
      songVersionIds: songVersionIds,
    }
    await addDirection({ variables })
  }

  const handleCreateSimilar = async () => {
    setIsLoadingSimilar(true)
    // Set up the Cyanite API request. See cyanite_controller for more query detail.
    try {
      const url = `/api/v1/song_versions/${searchRoundSongVersion.songVersion.id}/get_similar_song_version_ids`
      const requestOptions = {
        body: JSON.stringify({ existing: searchRoundSongVersionsIds }),
        method: "POST",
        headers: { Accept: "application/json", "Content-Type": "application/json" },
      }
      const response = await fetch(url, requestOptions)
      const data = await response.json()
      if (!data.success) {
        alert(data.message)
        console.error(data.message)
      } else if (!data.song_version_ids.length) {
        alert("No similar songs were found in the catalog.")
      } else {
        // If no directions exists yet create one for the initial song versions.
        // This needs to happen before a new similar songs direction is created.
        if (!selectedRound[0].directions.length) {
          await createInitialDirection()
        }
        const songVersionIds = data.song_version_ids
        songVersionIds.length > 0
          ? await createSimilarDirection(songVersionIds)
          : alert("No similar songs not already in the catalog")
      }
    } catch (error) {
      console.error(error)
    }
    setIsLoadingSimilar(false)
  }

  if (!mm.isMobile) {
    return (
      <div className="actions-right">
        <li className={cx("download-menu", downloadExpanded)} data-cy="downloadSongVersion">
          <div className={cx("download-button-container", "action", "download", "ignore-sortable")}>
            <a
              className={cx("box-button-black", "download-button", "ignore-sortable")}
              value="high_res"
              href={`/song_versions/${searchRoundSongVersion.songVersion.id}/download/high`}
              target="_blank"
            >
              {`High Res (WAV/AIF)`}
            </a>
            <a
              className={cx("box-button-black", "download-button", "ignore-sortable")}
              value="low_res"
              href={`/song_versions/${searchRoundSongVersion.songVersion.id}/download/low`}
              target="_blank"
            >
              MP3
            </a>
            {searchRoundSongVersion.songVersion?.hasStems && (
              <a
                className={cx("box-button-black", "download-button", "ignore-sortable")}
                value="stems"
                href={`/song_versions/${searchRoundSongVersion.songVersionId}/download_stems`}
                target="_blank"
              >
                Stems
              </a>
            )}
          </div>
        </li>

        <li
          className="action download ignore-sortable"
          data-cy="downloadSongVersion"
          onClick={handleDownloadMenu}
        >
          <a className={cx(downloadExpanded)} data-prevent-default>
            <span className="icon">
              <DownloadIcon />
            </span>
          </a>
        </li>
        {internalUser && selectedRound.length === 1 && (
          <li
            onClick={svCyaniteId ? handleCreateSimilar : undefined}
            className={cx("action", "similar", !svCyaniteId || isLoadingSimilar ? "disabled" : "")}
          >
            <a data-prevent-default>
              {isLoadingSimilar ? (
                <div className={cx("loading", spinnerStyles.mediumSpinner)} />
              ) : (
                <span className="icon">
                  <SimilarIcon />
                </span>
              )}
            </a>
          </li>
        )}
        <li className={cx("action", "notes-field", cssExpanded)} data-cy="noteField">
          <form onSubmit={handleSaveNotes}>
            <textarea
              cols="28"
              rows="9"
              placeholder="Let us know what you like or don't like about this song..."
              value={notes || ""}
              data-cy="note"
              ref={notesRef}
              onChange={handleChange}
            />
            <button
              className={cx("box-button-black", "save-notes", "ignore-sortable", {
                success: saveSuccess,
              })}
              data-cy="noteButton"
            >
              {saveSuccess ? "SAVED!" : "SAVE NOTE"}
            </button>
          </form>
          <span className="tri">▸</span>
        </li>
        <li className="action notes" onClick={handleOpenNote}>
          <a className={cx({ notify: notes }, cssExpanded)} data-prevent-default data-cy="addNote">
            <span className="icon">
              <ShareIcon />
            </span>
          </a>
        </li>

        {internalUser &&
          (status === "won" ? (
            <WinnerAction handleWinner={handleWinner} winner={winner} />
          ) : (
            <RemoveAction handleRemove={handleRemove} />
          ))}
      </div>
    )
  } else {
    return null
  }
}

const RemoveAction = ({ handleRemove }) => {
  return (
    <li className="action remove ignore-sortable" data-cy="removeSongVersion">
      <a data-prevent-default onClick={handleRemove}>
        <span className="icon">
          <TrashIcon />
        </span>
      </a>
    </li>
  )
}

const WinnerAction = ({ handleWinner, winner }) => {
  return (
    <li
      className={cx("action", "winner", "ignore-sortable", { won: winner })}
      data-cy="winnerSongVersion"
    >
      <a data-prevent-default onClick={handleWinner}>
        <span className="icon" />
      </a>
    </li>
  )
}

export default SearchRoundSongVersionActions
